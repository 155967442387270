import React, { useContext } from 'react';
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames';
import { Collapse } from 'reactstrap';
import { FilterSearchGroupStateContext } from '../index';

export const RESPONSIVE_FILTER_WIDTH = 527;
const FilterGroup = ({ label, children, className, labelIconName = 'tune' }) => {
    const state = useContext(FilterSearchGroupStateContext);
    const { filterOpen } = state;

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => {
                const isSmall = width < RESPONSIVE_FILTER_WIDTH;
                return (
                    <div className="filter-box">
                        {label && (
                            <div className={cx('filter-label', className)}>
                                <span className="material-icons-round md-18">{labelIconName}</span>
                                {label}
                            </div>
                        )}
                        {!isSmall ? (
                            children[0] ? (
                                children[0]
                            ) : (
                                children
                            )
                        ) : (
                            <Collapse className={'w-100'} isOpen={filterOpen}>
                                {children[0] ? children[0] : children}
                            </Collapse>
                        )}
                        {children[1]}
                    </div>
                );
            }}
        />
    );
};

export default FilterGroup;

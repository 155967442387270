import React, { createContext, useReducer } from 'react';
import { ErrorHandleSwitch } from '../../Components/Router';
import { Redirect, Route } from 'react-router-dom';
import FilterList from './FilterList';
import FilterDetail from './FilterDetail';
import reducer, { initialState } from './reducer';
import { PV_FILTER_NUM } from '../../Components/Router/path';

export const FilterStateContext = createContext();
export const FilterDispatchContext = createContext();

const FilterManagement = ({ match }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <FilterDispatchContext.Provider value={dispatch}>
            <FilterStateContext.Provider value={state}>
                <ErrorHandleSwitch>
                    <Route exact path={`${match.path}/`} render={() => <Redirect to={`${match.path}/list`} />} />
                    <Route exact path={`${match.path}/list`} component={FilterList} />
                    <Route
                        exact
                        path={[`${match.path}/create`, `${match.path}${PV_FILTER_NUM}`]}
                        component={FilterDetail}
                    />
                </ErrorHandleSwitch>
            </FilterStateContext.Provider>
        </FilterDispatchContext.Provider>
    );
};

export default FilterManagement;

import React, { useEffect, useState, useCallback } from 'react';
import { useAsync } from '@hooks';
import { fetchExcelLogList } from '@api/log';
import Card from '../Components/WidgetCard';
import List from './Component/List';
import Search from './Component/Search';
import moment from 'moment';

/**
 *
 * 위치로그 저장이력 위젯
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

const LocationLogStorageHistory = ({ children, widgetInfo, ...restProps }) => {
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf(),
    );
    const handleDateCallback = ({ selected, type }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().startOf('day').subtract(1, 'day');
                setStartDate(yesterday.valueOf());
                setEndDate(yesterday.add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf());
                break;
        }
    };

    const [list, setList] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const { promise: getExcelLogList, state: excelLogInfo } = useAsync({
        promise: fetchExcelLogList,
        fixedParam: { pageSize: 15, exportLogType: 'LOCATION' },
        resolve: res => setList(res),
        reject: err => console.error(err),
    });

    useEffect(() => {
        getExcelLogList({ startDate: startDate / 1000, endDate: endDate / 1000 });
    }, []);

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <Search
                startDate={startDate}
                endDate={endDate}
                handleDateCallback={handleDateCallback}
                promise={getExcelLogList}
            />
            <List list={list} state={excelLogInfo} promise={getExcelLogList} />
            {children}
        </Card>
    );
};

export default LocationLogStorageHistory;

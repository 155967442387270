import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dashboard: {},
    apiRequestDebounceTime: 400,
};

const { actions, reducer } = createSlice({
    name: 'common/appInfo',
    initialState,
    reducers: {
        setInitAppInfo: (state, action) => {
            const customAppConfig = action.payload;
            Object.entries(customAppConfig).forEach(([configId, configValue]) => {
                state[configId] = configValue;
            });
        },
    },
});

export const { setInitAppInfo } = actions;
export default reducer;

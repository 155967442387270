import React, { useContext, useRef } from 'react';
import { Button } from '@components';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { fetchExcelLogApi, fetchGeofenceExcelLogExport } from '@api/log';
import moment from 'moment';
import { excelExport } from '../../util/excelExport';
import { GeofenceLogStateContext } from '../index';
import { makeParameter } from '../../util/commons';

/**
 * 지오펜스 로그 엑셀 내보내기 기능 컴포넌트
 *
 * @param {date} startDate 검색 시작 일자
 * @param {date} endDate  검색 마지막 일자
 * @param {number} listCount  리스트 카운트
 *
 * @author jinbeom-jung
 * */
const INTERVAL_TIME = 1000;
const ExcelExport = ({ startDate, endDate, listCount }) => {
    const t = useTranslation('Geofence Log');
    const { keyword, searchOption, fcNums, floorIds, floorIdPath } = useContext(GeofenceLogStateContext);
    const excelTimerRef = useRef({});

    const { promise: createNewLogFile } = useAsync({
        promise: fetchGeofenceExcelLogExport,
        fixedParam: {
            zipFileName: 'Geofence_Log',
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            fileName: 'Geofence_Log',
            columnMetas: [
                {
                    key: 'logNum',
                    name: t('Log Number', 'CommonColumn'),
                },
                {
                    key: 'categoryCode',
                    name: t('Category Name', 'CommonColumn'),
                },
                {
                    key: 'targetName',
                    name: t('Target Name', 'CommonColumn'),
                },
                {
                    key: 'targetId',
                    name: t('Target ID', 'CommonColumn'),
                },
                {
                    key: 'floorPath',
                    name: t('Floor ID', 'CommonColumn'),
                },
                {
                    key: 'fcName',
                    name: t('Geofence', 'CommonColumn'),
                },
                {
                    key: 'permitted',
                    name: t('Authorized Not Authorized', 'CommonColumn'),
                },
                {
                    key: 'inDate',
                    name: t('In Date', 'CommonColumn'),
                    isDateFormat: 'Y',
                },
                {
                    key: 'inScannerName',
                    name: t('In Scanner Name', 'CommonColumn'),
                },
                {
                    key: 'outDate',
                    name: t('Out Date', 'CommonColumn'),
                    isDateFormat: 'Y',
                },
                {
                    key: 'outScannerName',
                    name: t('Out Scanner Name', 'CommonColumn'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL({ reqNum });
            }
        },
        reject: err => {
            toggleExcelConfirmModal();
            console.error(err);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, reqNum, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                toggleExcelDownloadResultModal();
            }
            clearExcelTimer({ reqNum });
        },
        reject: err => console.error(err),
    });

    const downloadWithURL = ({ reqNum }) => {
        excelTimerRef.current[reqNum] = setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = ({ reqNum }) => {
        clearInterval(excelTimerRef.current[reqNum]);
        excelTimerRef.current[reqNum] = null;
    };

    const handleExportFileClick = () => {
        if (listCount) {
            const params = {
                keyword: keyword,
                opt: searchOption.value,
                fcNums: fcNums.join(','),
                floorIds: floorIds.floorId,
                floorIdPath: floorIdPath,
            };
            const result = makeParameter({ searchRequirement: params, useIsAll: true });
            createNewLogFile(result);
        } else {
            toggleExcelConfirmModal();
        }
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'Report') },
        confirmText: t('Failed to download the excel.', 'Report'),
    });

    const { Modal: ExcelConfirmModal, toggleModal: toggleExcelConfirmModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t('No data', 'Report'),
    });

    return (
        <>
            <Button className="btn-lightgray btn-icon" onClick={handleExportFileClick}>
                <span className={'material-icons-round'}>file_upload</span>
                {t('Export', 'Button')}
            </Button>
            <ExcelDownloadResultModal />
            <ExcelConfirmModal />
        </>
    );
};

export default ExcelExport;

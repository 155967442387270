import { useMemo } from 'react';
import useAppSelector from '@hooks/useAppSelector';
import produce from 'immer';
import { makeFilterParam } from '@reducer/FilterInfo';

interface Parameter {
    preParam?: object;
    postParam?: object;
}

type YN = 'Y' | 'N';

interface FilterInfo {
    categoryCodes?: string;
    comNum?: number;
    description?: string;
    filterInfoAccessRight?: any[];
    filterInfoCondition: FilterCondition[];
    filterName?: string;
    filterNum?: number;
    isApplyMenu?: YN;
    isFavorite?: YN;
    modDate?: number;
    regDate?: number;
}
interface FilterParam {
    categoryCodes?: string;
}
interface FilterCondition {
    conditionId: string;
    conditionValues: any;
}
interface CategoryInfo {
    categoryCode: string;
    parentCode: string;
}

type InputType = 'checkbox' | 'date' | 'image' | 'login' | 'login_group' | 'radio' | 'select' | 'text' | 'textarea';

interface CategoryPropertyInfo {
    comNum: number;
    categoryCode: string;
    categoryName: string;
    propertyId: string;
    displayName: string;
    type: string | null;
    inputType: InputType;
    inputValues: string | any[];
    validRule?: null;
    isRequired: YN;
    isListProperty: YN;
    isSearchable: YN;
    isKey: YN;
    sortOrder: number;
    modDate: number;
    regDate: number;
}

const FILTER_CONDITION_ID_CATEGORY_CODE = 'categoryCodes';
const INPUT_TYPE_LOGIN_GROUP = 'login_group';
const INPUT_TYPE_LOGIN = 'login';

/*
 * @param {object} preParam 필터정보로 덮어 씌워질 파라미터 정보
 * @param {object} postParam 필터정보를 덮어 쓸 파라미터 정보
 * */
const useFilter = ({ preParam, postParam }: Parameter = {}): object => {
    const { filterParam, filterInfo } = useAppSelector(state => state.FilterInfo);
    const { categoryList, searchableCategoryPropertiesList } = useAppSelector(state => state.CategoryInfo);
    const userInfo: { groupNums?: number[]; userNum?: number } = useAppSelector(state => state.UserInfo.userInfo);

    return useMemo(() => {
        let editableFilterParam: FilterParam = produce(filterParam, () => {});
        let editableFilterInfo: FilterInfo = produce(filterInfo, () => {});
        // 필터 없는 페이지 진입 시, filterInfo가 빈 객체가 됨.
        if (filterInfo.filterInfoCondition) {
            const { categoryCodes }: FilterInfo = filterInfo;
            // 필터 유형이 단일 카테고리 일 경우에만, 카테고리 속성에 대한 부유화 진행
            if (categoryCodes && categoryCodes.split(',').length === 1) {
                editableFilterInfo = enrichLoginGroup(
                    editableFilterInfo,
                    categoryCodes,
                    searchableCategoryPropertiesList,
                    userInfo.groupNums || [],
                );
                editableFilterInfo = enrichLoginUser(
                    editableFilterInfo,
                    categoryCodes,
                    searchableCategoryPropertiesList,
                    userInfo.userNum,
                );

                editableFilterParam = makeFilterParam(editableFilterInfo);
            }

            // makeFilterParam 을 호출하는 함수 뒤에서 실행해야 카테고리 코드가 적용됨.
            editableFilterParam = enrichCategory(
                editableFilterParam,
                editableFilterInfo.filterInfoCondition,
                categoryList,
            );
        }
        return { ...preParam, ...editableFilterParam, ...postParam };
    }, [preParam, postParam, filterParam, filterInfo, categoryList, searchableCategoryPropertiesList, userInfo]);
};

const enrichCategory = (
    filterParam: FilterParam,
    filterInfoCondition: FilterCondition[],
    categoryList: CategoryInfo[],
) => {
    const selectedCategoryFilter = filterInfoCondition.find(
        filter => filter.conditionId === FILTER_CONDITION_ID_CATEGORY_CODE,
    )?.conditionValues?.length;
    return produce(filterParam, draft => {
        if (filterParam.categoryCodes && !selectedCategoryFilter) {
            const filterCategory = filterParam.categoryCodes.split(',');
            draft.categoryCodes = filterCategory
                .reduce((categoryAcc: string[], curr: string) => {
                    categoryAcc = categoryAcc.concat(
                        categoryList.reduce(
                            (childrenCategoryAcc: string[], { categoryCode, parentCode }: CategoryInfo) => {
                                if (parentCode === curr) {
                                    childrenCategoryAcc.push(categoryCode);
                                }
                                return childrenCategoryAcc;
                            },
                            [],
                        ),
                    );
                    return categoryAcc;
                }, filterCategory)
                .join(',');
        }
    });
};

function enrichLoginGroup(
    filterInfo: FilterInfo,
    filterCategoryCode: string,
    searchableCategoryPropertiesList: CategoryPropertyInfo[],
    groupNums: number[],
) {
    const loginGroupProperties = searchableCategoryPropertiesList.reduce((acc: string[], property) => {
        if (property.categoryCode === filterCategoryCode && property.inputType === INPUT_TYPE_LOGIN_GROUP) {
            acc.push(property.propertyId);
        }
        return acc;
    }, []);

    return produce(filterInfo, draft => {
        filterInfo.filterInfoCondition.forEach((condition, i) => {
            if (loginGroupProperties.includes(condition.conditionId) && condition.conditionValues[0] === '-1') {
                draft.filterInfoCondition[i].conditionValues = groupNums;
            }
        });
    });
}

function enrichLoginUser(
    filterInfo: FilterInfo,
    filterCategoryCode: string,
    searchableCategoryPropertiesList: CategoryPropertyInfo[],
    userNum?: number,
) {
    if (userNum) {
        const loginProperties = searchableCategoryPropertiesList.reduce((acc: string[], property) => {
            if (property.categoryCode === filterCategoryCode && property.inputType === INPUT_TYPE_LOGIN) {
                acc.push(property.propertyId);
            }
            return acc;
        }, []);

        return produce(filterInfo, draft => {
            filterInfo.filterInfoCondition.forEach((condition, i) => {
                if (loginProperties.includes(condition.conditionId) && condition.conditionValues[0] === '-1') {
                    draft.filterInfoCondition[i].conditionValues = [userNum];
                }
            });
        });
    }
    return filterInfo;
}

export default useFilter;

import React, { useEffect, useRef } from 'react';
import { Portal } from 'react-overlays';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';
import LocationPopup from './AppHeader/Components/LocationPopup';

import ConfirmModal from '@components/ConfirmModal';
import { MainRouter } from '../MainPages/Components/Router';

import { fetchCategory } from '@api/asset';
import { useAsync, useTranslation, useLicenseCheck } from '@hooks';
import { setAlarmType, setAlert } from '@reducer/Notification';
import useSocket from '@util/socket/hooks/useSocket';
import { useUserTokenCheck } from '@hooks/utilHooks';
import { setFloorList } from '@reducer/Common/FloorInfo';
import { getLsUserInfo } from '@util/common/util';
import { logOut, setAdditionalInfo, setLanguage, setOauthInfo, setOauthStatusModal } from '@reducer/UserInfo';
import { setAllCategoryList, setCategoryPropertiesList } from '@reducer/Common/CategoryInfo';
import { fetchFloorList, getCategoryPropertiesListApi } from '@api/common';
import { fetchMenuList } from '@api/menu';
import { setMenuList } from '@reducer/CustomMenu';
import { setColorScheme } from '@reducer/ThemeOptions';
import { EVENT_TYPE_HOOK_RESPONSE, MESSAGE_TYPE_HOOK, MESSAGE_TYPE_HOOK_OPEN_SETUP } from '@reducer/SocketInfo';
import useCompanyInfo from '@hooks/commonData/useCompanyInfo';

const AppMain = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const { userInfo, modal } = useSelector(state => state.UserInfo);
    const { fullScreen } = useSelector(state => state.ScreenInfo);
    const { loadedMenu } = useSelector(state => state.CustomMenu);

    const t = useTranslation('Login');
    const appMainOuterRef = useRef();
    const portal = document.body;

    const {
        socket,
        setSocketEvent,
        removeSocketEvent,
        closeSocket,
        connectSocket,
        sendMessage,
        socketInfo,
    } = useSocket();

    // 화면 리프레쉬 후 유저의 정보가 사라졌을때
    // 로컬스토리지에 저장된 정보 호출 후 저장
    useEffect(() => {
        if (!userInfo.userName) {
            const { oAuthInfo, userInfo } = getLsUserInfo();
            dispatch(setOauthInfo(oAuthInfo));
            if (userInfo) {
                const { lang, ...restUserInfo } = userInfo;
                dispatch(setAdditionalInfo(restUserInfo));
                dispatch(setLanguage(lang));
                dispatch(setAlarmType(userInfo.alertType));
                dispatch(setColorScheme(userInfo.propValue));
            }
        }
    }, [userInfo]);

    // refresh token의 에러가 발생했을 시 토글 메뉴 실행 후 유저인포 초기화
    // 유저인포의 초기화로 인하여 로그인 페이지로 이동
    const toggleOauthStatus = () => {
        dispatch(logOut());
        dispatch(setOauthStatusModal(!modal.modalOauthStatusOpen));
    };

    useUserTokenCheck();
    useLicenseCheck();

    useEffect(() => {
        connectSocket();
        return () => {
            closeSocket();
        };
    }, []);

    useEffect(() => {
        if (socket && socket.connected) {
            setSocketEvent('notification', data => {
                // console.log('noti', { data });
                dispatch(setAlert(data));
            });

            const channels = Object.values(socketInfo.channels);
            setSocketEvent(EVENT_TYPE_HOOK_RESPONSE, ({ event, channel, code }) => {
                // 채널 구독 완료 체크
                if (event === MESSAGE_TYPE_HOOK && channels.includes(channel) && code === 200) {
                    // 구독 시작(데이터 전송 시작)
                    sendMessage(MESSAGE_TYPE_HOOK_OPEN_SETUP, {
                        channel: channel,
                        isOpen: true,
                    });
                }
            });
            channels.forEach(channelName => {
                sendMessage(MESSAGE_TYPE_HOOK, channelName);
            });
        }
        return () => {
            removeSocketEvent('notification');
            removeSocketEvent(EVENT_TYPE_HOOK_RESPONSE);
        };
    }, [socket]);

    useCompanyInfo();

    // FloorList 받아서 Redux에 저장
    useAsync({
        promise: fetchFloorList,
        param: { isAll: 'Y' },
        resolve: response => {
            dispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    // CategoryList 받아서 Redux에 저장
    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setAllCategoryList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: getCategoryPropertiesListApi,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setCategoryPropertiesList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    const { promise: getMenuList } = useAsync({
        promise: fetchMenuList,
        fixedParam: { isAll: 'Y', includeScreenInfo: 'Y', groupNum: userInfo.authGroupNum },
        resolve: res => {
            dispatch(setMenuList(res.rows ?? []));
        },
        reject: err => {
            console.log({ err });
            dispatch(setMenuList([]));
        },
    });

    useEffect(() => {
        if (userInfo.roleNum) {
            getMenuList({ roleNum: userInfo.roleNum });
        }
    }, [userInfo]);

    return (
        <>
            <AppHeader />
            <div className="app-main">
                {/* sidebar */}
                <AppSidebar />
                {/* main */}
                <div className="app-main__outer" ref={appMainOuterRef}>
                    <div className="app-main__inner">
                        {/* 메뉴 로딩이 완료되기전에 라우터를 랜더링 시키면,
                             url로 바로 접근 시, 메뉴 라우터가 랜더링 되기 전이므로 '/no-authority' 로 리다이렉트 됨
                             '/no-authority' 라우터만 다시 그리는 방법도 있음 */}
                        {loadedMenu && <MainRouter />}
                        <ConfirmModal
                            initModal={modal.modalOauthStatusOpen}
                            toggleModal={toggleOauthStatus}
                            header={{ title: t('Authentication Error') }}
                            confirmText={
                                <span>{t('The authentication information is incorrect. Please log in again.')}</span>
                            }
                            okCallback={toggleOauthStatus}
                            removeCancel={true}
                        />
                        <Portal container={portal}>
                            <LocationPopup appMainOuterRef={appMainOuterRef} />
                        </Portal>
                        <div id="select-container" />
                    </div>
                </div>
                {/* footer */}
                <AppFooter />
            </div>
            {/*<ToastAlert />*/}
        </>
    );
};

export default withRouter(AppMain);

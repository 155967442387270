import React from 'react';
import { useColumns, useTranslation } from '@hooks';
import { Table } from '@components';
import * as column from '../../../MainPages/util/grid/column';

/**
 * Iot 아이템 맵핑 로그 테이블 생성 컴포넌트
 *
 * @param {Object} list Iot 아이템 맵핑 로그 리스트 데이터
 * @param {Object} state Iot 아이템 맵핑 로그 리스트 API 호출 상태`값
 * @param {function} promise Iot 아이템 맵핑 로그 리스트 호출 callback
 *
 * @author jinbeom-jung
 * */
const List = ({ list, state, promise }) => {
    const t = useTranslation('Iot Item Mapping Log');
    const columns = useColumns([
        column.number(),
        column.dataType({
            Header: 'Map/Unmap',
            width: 80,
            Cell: ({ value }) => {
                return <div>{t(value)}</div>;
            },
        }),
        column.targetNum({ width: 80 }),
        column.targetId({ width: 100 }),
        column.targetName({
            Header: 'IoT Item Name',
            width: 100,
        }),
        column.deviceType({ width: 100 }),
        column.deviceNum({ width: 100 }),
        column.deviceId({ width: 100 }),
        column.deviceName({
            width: 100,
            accessor: 'deviceName',
        }),
        column.registeredDate({
            Header: 'Map/Unmap Date',
            width: 100,
        }),
    ]);
    // state reducer로 보관

    const handlePageChange = pageIndex => {
        const param = state.request;
        let result = {};
        for (let key in param) {
            if (param[key]) {
                result[key] = param[key];
            }
        }
        promise({ ...result, page: pageIndex });
    };

    return (
        <div style={{ height: '35rem' }}>
            <Table
                data={{ ...list, pageSize: 15 }}
                columns={columns}
                onPageChange={handlePageChange}
                loading={state.isLoading}
            />
        </div>
    );
};

export default List;

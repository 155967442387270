import { createSlice } from '@reduxjs/toolkit';
import { defaultStaticFilterInfo } from '../Components/MainPages/Components/FilterWidget/Option';
import cloneDeep from 'lodash/cloneDeep';

const DEFAULT_CATEGORY_CODE = 'ASSET';
export const makeFilterParam = (filterInfo, categoryList) => {
    const {
        filterInfoCondition,
        categoryCodes,
        // , categoryCodes: filterCategoryCodes
    } = filterInfo;
    // const allCategoryList = categoryList ?? [];
    // const categoryCodes = filterCategoryCodes.split(',').reduce((acc, curr) => {
    //     const childrenCategory = allCategoryList
    //         .filter(category => category.parentCode === curr)
    //         .map(category => category.categoryCode);
    //     if (childrenCategory.length) {
    //         acc = [acc, childrenCategory.join(',')].join(',');
    //     } else {
    //         acc = [acc, curr].join(',');
    //     }
    //     return acc;
    // }, '');
    let dynamicCount = 0;
    return filterInfoCondition.reduce(
        (acc, { conditionId, conditionValues, conditionType }) => {
            if (conditionValues.length) {
                if (conditionType === 'dynamic') {
                    acc['targetPropertiesSearch.categoryCode'] = categoryCodes ? categoryCodes : DEFAULT_CATEGORY_CODE;
                    conditionValues.forEach(value => {
                        acc[`targetPropertiesSearch.propertySearchs[${dynamicCount}].propertyId`] = conditionId;
                        acc[`targetPropertiesSearch.propertySearchs[${dynamicCount}].values`] = value;
                        dynamicCount++;
                    });
                } else {
                    acc[conditionId] = conditionValues.join(',');
                }
            }
            return acc;
        },
        { categoryCodes },
    );
};

export const initialFilterInfo = {
    filterInfoCondition: defaultStaticFilterInfo,
};

const initialState = {
    filterList: [],
    originFilterInfo: {},
    filterInfo: initialFilterInfo,
    filterParam: {},
};

const { actions, reducer } = createSlice({
    name: 'filterInfo',
    initialState,
    reducers: {
        setFilterList: (state, action) => {
            state.filterList = action.payload;
        },
        setSelectedFilter: (state, action) => {
            if (action.payload) {
                const filterInfo = cloneDeep(action.payload);
                // const { filterInfoCondition } = filterInfo;

                // filterInfo.filterInfoCondition = filterInfoCondition.map(filterInfo => {
                //     const filter = initialFilterInfo.filterInfoCondition.find(
                //         defaultFilter => filterInfo.conditionId === defaultFilter.conditionId,
                //     );
                //     return { ...filter, ...filterInfo };
                // });

                state.originFilterInfo = filterInfo;
                state.filterInfo = filterInfo;
                if (filterInfo && filterInfo.filterInfoCondition) {
                    state.filterParam = makeFilterParam(filterInfo);
                } else {
                    state.filterParam = {};
                }
            } else {
                state.originFilterInfo = {};
                state.filterInfo = {};
                state.filterParam = {};
            }
        },
        updateFilterInfo: (state, action) => {
            const filterInfo = action.payload;
            if (filterInfo && filterInfo.filterInfoCondition) {
                state.filterInfo.filterInfoCondition = filterInfo.filterInfoCondition;
                state.filterParam = makeFilterParam({ ...state.filterInfo, ...filterInfo });
            } else {
                state.filterInfo.filterInfoCondition = initialFilterInfo.filterInfoCondition;
                state.filterParam = {};
            }
        },
        updateFilterParam: (state, action) => {
            const filterInfo = action.payload;
            if (filterInfo && filterInfo.filterInfoCondition) {
                state.filterParam = makeFilterParam(filterInfo);
            } else {
                state.filterParam = {};
            }
        },
    },
});

export const { setFilterList, setSelectedFilter, updateFilterInfo, updateFilterParam } = actions;
export default reducer;

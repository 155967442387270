import React, { useState, useEffect, useCallback, useRef, createContext, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../../Components/PageTitle';
import ScreenFrame from '../../../Components/Screen/ScreenFrame';
import useWidgetGenerator from '../../../Components/Screen/util/useWidgetGenerator';
import { getMaxY } from '../../../Components/Screen/util';
import moment from 'moment';
import cx from 'classnames';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW, setMode } from '@reducer/ScreenInfo';
import PalettePopup from '../../../../Widgets/PalettePopup';
import { temp } from '../../../../Widgets';
import useAsync from '@hooks/useAsync';
import ConfirmModal from '@components/ConfirmModal';
import useTranslation from '@hooks/useTranslation';
import reducer, {
    initialState,
    setEditScreen,
    editWidgets,
    editLayout,
    setTempWidgetLayout,
    editFilterNum,
} from './reducer';
import { updateScreenApi } from '@api/menu';
import { setSelectedFilter } from '@reducer/FilterInfo';
import { Button } from '@components';

export const ScreenEditContext = createContext();
export const ScreenEditDispatchContext = createContext();

const ScreenEdit = ({ history, match }) => {
    const {
        params: { screenCode },
    } = match;

    const t = useTranslation('Screen');
    const storeDispatch = useDispatch();
    const { originScreenList } = useSelector(state => state.CustomMenu);
    const [state, dispatch] = useReducer(reducer, initialState);

    const { origin, editable, tempWidgetLayout } = state;
    const { layouts, Widgets } = useWidgetGenerator(editable.widgetInfo);

    const { filterInfo, originFilterInfo } = useSelector(state => state.FilterInfo);
    const [screenKey, setScreenKey] = useState(Date.now());

    useEffect(() => {
        storeDispatch(setMode(SCREEN_MODE_EDIT));
        if (screenCode && originScreenList && originScreenList.length) {
            const screenInfo = originScreenList.find(screen => screen.screenCode === screenCode);
            if (screenInfo) {
                dispatch(setEditScreen({ ...screenInfo }));
            }
        }
        return () => {
            dispatch(setEditScreen());
        };
    }, [originScreenList, screenCode]);

    const { promise: saveWidgetInfo } = useAsync({
        promise: updateScreenApi,
        resolve: () => {
            history.goBack();
        },
    });

    const [preView, setPreView] = useState(false);
    const handlePreviewToggle = () => {
        let mode = SCREEN_MODE_PREVIEW;
        if (preView) {
            mode = SCREEN_MODE_EDIT;
        }
        storeDispatch(setMode(mode));
        setPreView(!preView);
    };

    // const [addWidgetPopup, setAddWidgetPopup] = useState(false);
    const [addWidgetPopup, setAddWidgetPopup] = useState(false);
    const handleAddWidgetPopup = () => {
        setAddWidgetPopup(!addWidgetPopup);
        dispatch(setTempWidgetLayout(null));
    };

    const [saveModal, setSaveModal] = useState(false);
    const toggleSaveModal = () => {
        setSaveModal(!saveModal);
    };

    const [cancelModal, setCancelModal] = useState(false);
    const toggleCancelModal = () => {
        setCancelModal(!cancelModal);
    };

    const [resetModal, setResetModal] = useState(false);
    const toggleResetModal = () => {
        setResetModal(!resetModal);
    };

    const handleSave = screenInfo => {
        const idPrefix = `d${screenInfo.menuNum}_`;
        const updateTime = moment().valueOf();
        saveWidgetInfo({
            ...screenInfo,
            widgetInfo: JSON.stringify(
                screenInfo.widgetInfo.reduce((acc, curr, i) => {
                    const newId = idPrefix + (updateTime + i).toString(36);
                    if (curr.type !== 'temp') {
                        acc.push({ ...curr, id: newId, layout: { ...curr.layout, i: newId } });
                    }
                    return acc;
                }, []),
            ),
        });
    };

    const editTimeout = useRef();

    const handleEditLayout = layout => {
        if (editTimeout.current) {
            clearTimeout(editTimeout.current);
            editTimeout.current = null;
        }
        editTimeout.current = setTimeout(() => {
            dispatch(editLayout(layout));
        }, 200);
    };

    const addPanel = e => {
        const id = moment().valueOf().toString(36);
        dispatch(
            editWidgets([
                ...(editable.widgetInfo ?? []),
                {
                    id,
                    type: temp.type,
                    config: temp.config,
                    layout: {
                        ...temp.layout,
                        i: id,
                        x: 0,
                        y: getMaxY(layouts.lg ?? []),
                    },
                },
            ]),
        );
    };

    const backToOrigin = useCallback(() => {
        dispatch(editWidgets(origin.widgetInfo));
        storeDispatch(setSelectedFilter(originFilterInfo));
        setScreenKey(Date.now());
    }, [origin, originFilterInfo]);

    const cancelEdit = () => {
        backToOrigin();
        storeDispatch(setMode(null));
        history.goBack();
    };

    useEffect(() => {
        if (tempWidgetLayout) {
            setAddWidgetPopup(true);
        } else {
            setAddWidgetPopup(false);
        }
        return () => {
            setAddWidgetPopup(false);
        };
    }, [tempWidgetLayout]);

    useEffect(() => {
        dispatch(editFilterNum(filterInfo.filterNum));
    }, [filterInfo]);

    useEffect(() => {
        return () => {
            storeDispatch(setMode(null));
        };
    }, []);

    return (
        <ScreenEditDispatchContext.Provider value={dispatch}>
            <ScreenEditContext.Provider value={state}>
                <PageTitle
                    className={cx(!preView && 'd-none', 'dashboard-edit-title')}
                    customIcon={<span className="material-icons-round">edit</span>}
                    heading={t('Preview')}
                    enablePageTitleSubheading={true}
                    subheading={t('It is a Screen Preview Page.')}
                    titleAction={
                        <Button className="mr-2 btn-preview btn-lightgray" onClick={handlePreviewToggle}>
                            {t('End preview')}
                        </Button>
                    }
                />

                <PageTitle
                    className={cx(preView && 'd-none', 'dashboard-edit-title')}
                    customIcon={<span className="material-icons-round">edit</span>}
                    heading={t('Edit screen')}
                    enablePageTitleSubheading={true}
                    subheading={t('It is a Screen Edit Page.')}
                    titleAction={
                        <>
                            <Button className="mr-2 btn-line btn-secondary" onClick={handlePreviewToggle}>
                                {t('Preview')}
                            </Button>
                            <Button className="mr-2 btn-secondary" onClick={toggleResetModal}>
                                {t('Revert to the first')}
                            </Button>
                            <Button className="mr-2 btn-secondary" onClick={toggleCancelModal}>
                                {t('Cancel')}
                            </Button>
                            <Button className="mr-2 btn-secondary" onClick={addPanel}>
                                {t('Add panel')}
                            </Button>
                            <Button className="pnt-btn btn-icon btn-brand" iconName="save" onClick={toggleSaveModal}>
                                {t('Save')}
                            </Button>
                        </>
                    }
                />
                <ScreenFrame
                    key={`screen_${screenKey}`}
                    layouts={layouts}
                    onDragStop={handleEditLayout}
                    onResizeStop={handleEditLayout}
                >
                    {Widgets}
                </ScreenFrame>
                <PalettePopup initOpen={addWidgetPopup} toggleModal={handleAddWidgetPopup} layout={tempWidgetLayout} />
                <ConfirmModal
                    initModal={saveModal}
                    toggleModal={toggleSaveModal}
                    confirmText={t('Do you want to save the layout?')}
                    okCallback={handleSave}
                    callbackParam={editable}
                />
                <ConfirmModal
                    initModal={cancelModal}
                    toggleModal={toggleCancelModal}
                    confirmText={t('Do you want to exit editing mode?')}
                    okCallback={cancelEdit}
                />
                <ConfirmModal
                    initModal={resetModal}
                    toggleModal={toggleResetModal}
                    confirmText={t('Do you want to reset edits?')}
                    okCallback={backToOrigin}
                />
            </ScreenEditContext.Provider>
        </ScreenEditDispatchContext.Provider>
    );
};

export default ScreenEdit;

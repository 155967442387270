import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_PAGE_SIZE = 20;
const initMenuListInfo = { totalPage: 1, page: 1, rows: [], pageSize: DEFAULT_PAGE_SIZE };

export const initialState = {
    menuListInfo: initMenuListInfo,
    refreshFlag: null,
};

const { actions, reducer } = createSlice({
    name: 'filterList',
    initialState,
    reducers: {
        setMenuListInfo: (state, action) => {
            const menuListInfo = action.payload;
            menuListInfo.pageSize = menuListInfo.rows ? menuListInfo.rows.length : DEFAULT_PAGE_SIZE;
            state.menuListInfo = { ...initMenuListInfo, ...menuListInfo };
        },
        refreshList: (state, action) => {
            state.refreshFlag = {};
        },
    },
});

export const { setMenuListInfo, refreshList } = actions;
export default reducer;

import React from 'react';

import { Col } from 'reactstrap';
import { Modal, Label } from '../../../../Common';

import userImg from '../../../../../assets/images/unknown_user.png';

import useTranslation from '@hooks/useTranslation';

const ProfileModal = ({ initModal, userInfo, toggleModal }) => {
    const t = useTranslation('UserBox');
    return (
        <Modal initModal={initModal} toggleModal={toggleModal} headerTitle={t('Profile')} removeCancel>
            <div className="modal-body">
                <Col>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px' }}>
                        <div>
                            <div className="profile-picture__wrap">
                                <img
                                    src={userImg}
                                    alt="profileImg"
                                    style={{ width: '150px', height: '150px', marginRight: '1rem' }}
                                />
                            </div>
                        </div>
                        <div className="flx-col" style={{ justifyContent: 'space-around' }}>
                            <Label name={t('Name')} labelValueClassName="color-brand" value={userInfo.userName} />
                            <Label name={t('ID')} labelValueClassName="color-brand" value={userInfo.userID} />
                            <Label name={t('Email')} labelValueClassName="color-brand" value={userInfo.userEmail} />
                        </div>
                    </div>
                </Col>
            </div>
        </Modal>
    );
};

export default ProfileModal;

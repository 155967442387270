import { createSlice } from '@reduxjs/toolkit';
import { MODAL_MODE_MAPP, MODAL_MODE_UNMAPP } from './Components/BulkProcessModal';

export const USER_LIST_PAGE_SIZE = 20;
export const INITIAL_USER_LIST_INFO = { totalCount: 0, totalPage: 0, pageSize: USER_LIST_PAGE_SIZE, page: 1, rows: [] };

export const initialState = {
    origin: {},
    editing: {},
    userListInfo: INITIAL_USER_LIST_INFO,
    searchedListInfo: INITIAL_USER_LIST_INFO,
};

export const makeTableInfo = (newList = [], page = 0, pageSize = USER_LIST_PAGE_SIZE) => {
    return {
        totalCount: newList.length,
        totalPage: Math.ceil(newList.length / pageSize) || 0,
        pageSize: newList.length ? USER_LIST_PAGE_SIZE : 0,
        page: newList.length ? page : 1,
        rows: newList,
    };
};

export const searchMappedUserList = ({ searchParam: { id, name, groupNum }, searchList }) => {
    const searchId = id?.trim().toLowerCase();
    const searchName = name?.trim().toLowerCase();
    const searchGroupNum = groupNum;
    return searchList.filter(({ userId, userName, loginGroupList }) => {
        let idPass = true;
        let namePass = true;
        const groupPass = !!loginGroupList.find(group => group.groupNum === searchGroupNum);
        if (searchId) {
            idPass = userId.toLowerCase().includes(searchId);
        }
        if (searchName) {
            namePass = userName.toLowerCase().includes(searchName);
        }
        return idPass && namePass && groupPass;
    });
};

const { actions, reducer } = createSlice({
    name: 'authorizationGroupDetail',
    initialState,
    reducers: {
        setInitialState: (state, action) => {
            const groupInfo = action.payload || {};
            state.origin = groupInfo;
            if (groupInfo.authUserList) {
                const authUserList = groupInfo.authUserList;
                state.origin.authUserNumList = authUserList.map(user => user.userNum);
                state.userListInfo = makeTableInfo(authUserList);
            } else {
                state.origin.authUserNumList = [];
                state.origin.authUserList = [];
                state.userListInfo = makeTableInfo();
            }
            state.editing = state.origin;
        },
        editGroupInfo: (state, action) => {
            const editInfo = action.payload;
            if (editInfo) {
                for (const key in editInfo) {
                    if (editInfo.hasOwnProperty(key)) {
                        state.editing[key] = editInfo[key];
                    }
                }
            }
        },
        unmapUser: (state, action) => {
            const userNums = action.payload;
            state.editing.authUserList = state.editing.authUserList.filter(user => !userNums.includes(user.userNum));
            state.editing.authUserNumList = state.editing.authUserNumList.filter(
                userNum => !userNums.includes(userNum),
            );
            state.userListInfo = makeTableInfo(state.editing.authUserList);
        },
        addUser: (state, action) => {
            const userList = action.payload;
            const notExistedUserList = userList.filter(user => !state.editing.authUserNumList.includes(user.userNum));
            state.editing.authUserList = state.editing.authUserList.concat(notExistedUserList);
            state.editing.authUserNumList = state.editing.authUserList.map(({ userNum }) => userNum);
            state.userListInfo = makeTableInfo(state.editing.authUserList);
        },
        setSearchedListInfo: (state, action) => {
            const searchedList = action.payload || [];
            state.searchedListInfo = makeTableInfo(searchedList, 0, searchedList.length);
        },
    },
});

export const { setInitialState, editGroupInfo, unmapUser, addUser, setSearchedListInfo } = actions;
export default reducer;

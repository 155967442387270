import React from 'react';
import { Table } from '@components';
import { useColumns } from '@hooks';
import styles from 'assets/pnt/widgets/VitalSignsMonitoring.module.scss';
import cx from 'classnames';

const DEFAULT_VALUE = '-';
const InfoTable = ({ vitalSensor }) => {
    const {
        L_BLOODPRESSURE,
        M_BLOODPRESSURE,
        H_BLOODPRESSURE,
        PULSE_RATE = DEFAULT_VALUE,
        TEMPERATURE = DEFAULT_VALUE,
    } = vitalSensor;

    const data = [
        {
            minPressure: L_BLOODPRESSURE ? Math.floor(L_BLOODPRESSURE) : DEFAULT_VALUE,
            maxPressure: H_BLOODPRESSURE ? Math.floor(H_BLOODPRESSURE) : DEFAULT_VALUE,
            avgPressure: M_BLOODPRESSURE ? Math.floor(M_BLOODPRESSURE) : DEFAULT_VALUE,
            pulse: PULSE_RATE,
            bodyTemperature: TEMPERATURE,
        },
    ];

    const columns = useColumns([
        {
            Header: 'LBP',
            accessor: 'minPressure',
            className: 'text-ellipsis',
            headerClassName: 'text-ellipsis',
            // width: 250,
        },
        {
            Header: 'HBP',
            accessor: 'maxPressure',
            className: 'text-ellipsis',
            headerClassName: 'text-ellipsis',
            // width: 250,
        },
        {
            Header: 'AVG BP',
            accessor: 'avgPressure',
            className: 'text-ellipsis',
            headerClassName: 'text-ellipsis',
            // width: 250,
        },
        {
            Header: 'PR',
            accessor: 'pulse',
            className: 'text-ellipsis',
            headerClassName: 'text-ellipsis',
            // width: 250,
        },
        {
            Header: 'BT',
            accessor: 'bodyTemperature',
            className: 'text-ellipsis',
            headerClassName: 'text-ellipsis',
            // width: 250,
        },
    ]);

    const isValidData = data => {
        return !!Object.values(...data).every(el => el === '-');
    };

    return (
        <div className={cx(styles.detail_info_table, 'mb-3')}>
            <Table data={{ rows: isValidData(data) ? [] : data }} columns={columns} paging={false} />
        </div>
    );
};

export default InfoTable;

import React, { useContext } from 'react';
import Control from 'react-leaflet-control';
import { Button, GeofenceLayer } from '../../../Common';
import { LayersControl } from 'react-leaflet';
import { MassRealTimeLocationStatusContext, MassRealTimeLocationStatusDispatchContext } from '../index';
import { setFloorInfo, setPopup, setSelectedFloor } from '../massRealTimeLocationReducer';
import { useTranslation } from '@hooks';
import FloorTreeSelect from '../../Components/FloorTreeSelect';
import { useSelector } from 'react-redux';
import FloorDetailPopup from './FloorDetailPopup';

const AllTarget = ({ widgetRef, mapRef }) => {
    const t = useTranslation('RealTimeLocationStatus');
    const { floorList } = useSelector(state => state.FloorInfo);

    const { selectedFloor, geofenceInfo, popup, realTimeLog } = useContext(MassRealTimeLocationStatusContext);
    const dispatch = useContext(MassRealTimeLocationStatusDispatchContext);

    const handleFloorChange = selects => {
        if (selects[0]) {
            dispatch(setSelectedFloor(selects[0].floorId));
            dispatch(setFloorInfo(selects[0]));
        }
    };

    const handlePopupClick = (name, value) => {
        dispatch(setPopup({ [name]: !value }));
    };

    const handleModalToggle = value => {
        dispatch(setPopup({ [value]: !popup[value] }));
    };

    return (
        <>
            <Control position="topleft" className={'control-container'}>
                <Button
                    className={'leaflet-map-btn btn-icon'}
                    iconName="info_outline"
                    onClick={() => handlePopupClick('floorDetailPopup', popup.floorDetailPopup)}
                >
                    {t('Detail')}
                </Button>
                <FloorTreeSelect
                    isMap={true}
                    floorList={floorList}
                    selectedFloorStr={selectedFloor}
                    mode={'radioSelect'}
                    handleChange={handleFloorChange}
                />
                {/*<Button*/}
                {/*    iconName="search"*/}
                {/*    onClick={() => handlePopupClick('filteringPopup', popup.filteringPopup)}*/}
                {/*    className={'btn-icon btn-lightgray'}*/}
                {/*>*/}
                {/*    {t('Filter')}*/}
                {/*</Button>*/}
            </Control>
            <Control position="bottomleft" className="control-container">
                <div className="pnt-nameplate leaflet-map-nameplate">
                    <span className="material-icons-round">info_outline</span>
                    <div className>{`${t('Total Number of Items Detected')} : ${realTimeLog.count}`}</div>
                </div>
            </Control>
            <LayersControl position={'topright'}>
                <LayersControl.Overlay checked name={t('Geofence')}>
                    <GeofenceLayer geofenceList={geofenceInfo} />
                </LayersControl.Overlay>
            </LayersControl>

            <FloorDetailPopup
                modal={popup.floorDetailPopup}
                toggleModal={() => handleModalToggle('floorDetailPopup')}
                widgetRef={widgetRef}
                mapRef={mapRef}
            />
            {/*<FilteringPopup*/}
            {/*    modal={popup.filteringPopup}*/}
            {/*    toggleModal={() => handleModalToggle('filteringPopup')}*/}
            {/*    widgetRef={widgetRef}*/}
            {/*    mapRef={mapRef}*/}
            {/*/>*/}
        </>
    );
};

export default AllTarget;

import { useSettings } from '../util/useSettings';
import { editWidgetSettings } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import React, { useContext, useState } from 'react';
import useTranslation from '../../../util/hooks/useTranslation';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import Label from '../../Common/Label';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { Select } from '@components';

const RealTimeLocationSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const dispatch = useContext(ScreenEditDispatchContext);
    const { settings: customSettings } = config;
    const t = useTranslation('RealTimeLocationStatus');
    const options = [
        { value: 'ALL TARGET', label: t('All Target') },
        { value: 'SINGLE TARGET', label: t('Single Target') },
    ];

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleTargetOptionChange = selected => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, targetOption: selected.value };
        });
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const TargetOptionValue = options.find(e =>
        updatedWidgetInfo.targetOption ? e.value === updatedWidgetInfo.targetOption : e.value === settings.targetOption,
    );

    return (
        <WidgetSettingModal
            headerTitle={t('Setting For Monitoring Realtime Location')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Target Monitoring Option')}
                value={
                    <Select
                        options={options}
                        value={TargetOptionValue}
                        onChange={handleTargetOptionChange}
                        isModalSelect
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default RealTimeLocationSetting;

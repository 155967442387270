import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Card as RSCard, CardBody } from 'reactstrap';

const Tab = ({ tabs = [{}], className, styles = {}, handleTabChange = () => {}, activeTabId }) => {
    const [tabOn, setTabOn] = useState(activeTabId ? activeTabId : tabs[0].tabId);
    useEffect(() => {
        handleTabChange(tabOn);
    }, [tabOn]);

    const handleTabClick = e => {
        e.preventDefault();
        setTabOn(e.target.id);
    };

    return (
        <RSCard className={className}>
            <div className={'card-tab'}>
                <ul>
                    {tabs.map((data, index) => (
                        <li
                            key={`${data.tabId}_${index}`}
                            id={data.tabId}
                            className={cx(tabOn === data.tabId && 'on', 'cursor-pointer')}
                            style={{ ...styles.headerItem }}
                            onClick={handleTabClick}
                        >
                            <a href={'#!'} id={data.tabId} onClick={handleTabClick}>
                                {data.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <CardBody>
                {tabs.map((data, index) => (
                    <div key={`${data.tabId}_${index}`} className={cx(tabOn !== data.tabId && 'd-none')}>
                        {data.content}
                    </div>
                ))}
            </CardBody>
        </RSCard>
    );
};

export default Tab;

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from '@hooks';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { Button, DatePicker } from '../../../Common';
import { setEndDate, setSelectedCategory, setSelectedFloor, setStartDate } from '../congestedGeofenceReducer';
import { CongestedGeofenceContext, CongestedGeofenceDispatchContext } from '../index';
import { useSelector } from 'react-redux';
import FloorTreeSelect from '../../Components/FloorTreeSelect';
import moment from 'moment';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import { FilterList, SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import { Select } from '@components';
import FilterGroup from '../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';

const CongestedGeofenceFilterGroup = ({ handleSearchClick }) => {
    const t = useTranslation('CongestionStatus');
    const [categoryListWithAllOption, setCategoryListWithAllOption] = useState([]);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useContext(CongestedGeofenceContext);
    const dispatch = useContext(CongestedGeofenceDispatchContext);

    const handleSelectCategoryChange = e => {
        dispatch(setSelectedCategory(e.value));
    };

    const handleYesterdayBtnClick = () => {
        dispatch(setStartDate(moment().subtract(1, 'days').startOf('day').valueOf()));
        dispatch(setEndDate(moment().subtract(1, 'days').endOf('day').valueOf()));
    };

    const handleTodayBtnClick = () => {
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    const handleFloorChange = selects => {
        if (selects[0]) {
            dispatch(setSelectedFloor(selects[0].floorId));
        }
    };

    useEffect(() => {
        setCategoryListWithAllOption([{ categoryCode: '', categoryName: 'All' }, ...categoryList]);
    }, [categoryList]);

    return (
        <FilterSearchGroup className={'card absolute-filter'}>
            <FilterGroup label={t('Filter')}>
                <SelectGroup>
                    <Select
                        placeholder={'All'}
                        value={categoryListWithAllOption.length === 0 ? [] : selectedCategory}
                        onChange={handleSelectCategoryChange}
                        options={categoryListWithAllOption}
                        valueKey={'categoryCode'}
                        labelKey={'categoryName'}
                        listStyle={{ height: '200px', overflow: 'auto' }}
                    />
                </SelectGroup>
                <SelectGroup>
                    <FloorTreeSelect
                        key={selectedFloor}
                        floorList={floorList}
                        selectedFloorStr={selectedFloor}
                        mode={'radioSelect'}
                        handleChange={handleFloorChange}
                    />
                </SelectGroup>
            </FilterGroup>
            <SearchGroup label={t('Search')}>
                <FilterList>
                    <SelectGroup>
                        <DatePicker
                            value={startDate}
                            handleChange={selected => dispatch(setStartDate(selected))}
                            valueType={'ms'}
                            maxDate={endDate || moment().valueOf()}
                            showTimeInput
                        />
                    </SelectGroup>
                    <div> ~ </div>
                    <SelectGroup>
                        <DatePicker
                            value={endDate}
                            handleChange={selected => dispatch(setEndDate(selected))}
                            valueType={'ms'}
                            minDate={startDate}
                            maxDate={moment().valueOf()}
                            showTimeInput
                        />
                    </SelectGroup>
                    <SearchButtonArea>
                        <div className={'flx-row gap-1'}>
                            <Button className={'btn-gray btn-yesterday'} onClick={handleYesterdayBtnClick}>
                                {t('Yesterday', 'Button')}
                            </Button>
                            <Button className={'btn-gray btn-yesterday'} onClick={handleTodayBtnClick}>
                                {t('Today', 'Button')}
                            </Button>
                            <Button className="pnt-btn btn-secondary" onClick={handleSearchClick}>
                                {t('Search')}
                            </Button>
                        </div>
                    </SearchButtonArea>
                </FilterList>
            </SearchGroup>
        </FilterSearchGroup>
    );
};
export default CongestedGeofenceFilterGroup;

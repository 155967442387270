import { useTranslation } from '@hooks';

export const useAssetListFloorDetailColumns = () => {
    const t = useTranslation('RealTimeLocationStatus');
    return [
        {
            Header: t('IoT Item Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('IoT Item ID'),
            headerClassName: 'flex-center',
            accessor: 'targetId',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('Category Name'),
            headerClassName: 'flex-center',
            accessor: 'categoryName',
            className: 'flex-center',
        },
        {
            Header: t('Entry Time'),
            headerClassName: 'flex-center',
            accessor: 'entryTime',
            className: 'flex-center',
        },
    ];
};

export const useGeofenceTargetInfoColumns = () => {
    const t = useTranslation('GeofenceStatus');
    return [
        {
            Header: t('Target Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('Category'),
            headerClassName: 'flex-center',
            accessor: 'category',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('Authorized'),
            headerClassName: 'flex-center',
            accessor: 'authorized',
            className: 'flex-center',
        },
        {
            Header: t('Entry Time'),
            headerClassName: 'flex-center',
            accessor: 'entryTime',
            className: 'flex-center',
        },
    ];
};

export const categoryName = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        accessor: 'categoryName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const targetName = ({ ...restProps }) => {
    return {
        Header: 'People Name',
        accessor: 'targetName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const tagName = ({ ...restProps }) => {
    return {
        Header: 'Tag Name',
        accessor: 'beaconName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const floorName = ({ ...restProps }) => {
    return {
        Header: 'Floor',
        accessor: 'floorName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const location = ({ ...restProps }) => {
    return {
        Header: 'Location',
        accessor: 'geofence',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const signalStatus = ({ ...restProps }) => {
    return {
        Header: 'Signal Status',
        accessor: 'status',
        className: 'p-0',
        ...restProps,
    };
};

export const lastDate = ({ ...restProps }) => {
    return {
        Header: 'Last Detected Time',
        accessor: 'lastDate',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const bookmark = ({ ...restProps }) => {
    return {
        Header: 'Bookmark',
        headerClassName: 'text-ellipsis',
        accessor: 'bookmark',
        className: 'text-ellipsis p-1',
        ...restProps,
    };
};

export const ward = ({ ...restProps }) => {
    return {
        Header: 'Ward',
        accessor: 'properties.ward',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const room = ({ ...restProps }) => {
    return {
        Header: 'Room',
        accessor: 'properties.room',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const bed = ({ ...restProps }) => {
    return {
        Header: 'Bed',
        accessor: 'properties.bed',
        className: 'text-ellipsis',
        ...restProps,
    };
};

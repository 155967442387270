import moment from 'moment';

export const time = ({ t, ...restProps }) => {
    return {
        Header: t('Time'),
        className: 'text-ellipsis',
        accessor: 'evtTime',
        headerClassName: 'text-ellipsis',
        Cell: ({ value }) => moment(value * 1000).format('HH:mm:ss'),
        ...restProps,
    };
};
export const targetName = ({ t, ...restProps }) => {
    return {
        Header: t('Target name'),
        className: 'text-ellipsis',
        accessor: 'targetName',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};
export const categoryName = ({ t, ...restProps }) => {
    return {
        Header: t('Category name'),
        className: 'text-ellipsis',
        accessor: 'categoryName',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};
export const tagState = ({ t, ...restProps }) => {
    return {
        Header: t('Tag state'),
        className: 'text-ellipsis',
        accessor: 'tagStateLabel',
        headerClassName: 'text-ellipsis',
        ...restProps,
    };
};
export const patName = {
    Header: '이름',
    headerClassName: 'flex-center',
    accessor: 'patName',
    className: 'flex-center',
};
export const patAge = {
    Header: '나이',
    headerClassName: 'flex-center',
    accessor: 'patAge',
    className: 'flex-center',
};
export const respiratoryRate = {
    Header: '호흡 수',
    headerClassName: 'flex-center',
    accessor: 'respiratoryRate',
    className: 'flex-center',
};
export const temperature = {
    Header: '체온',
    headerClassName: 'flex-center',
    accessor: 'temperature',
    className: 'flex-center',
};
export const pulse = {
    Header: '맥박 수',
    headerClassName: 'flex-center',
    accessor: 'pulse',
    className: 'flex-center',
};
export const mmHg = {
    Header: '수축기 혈압 (mmHg)',
    headerClassName: 'flex-center',
    accessor: 'mmHg',
    className: 'flex-center',
};
export const oxygen = {
    Header: '산소 포화도',
    headerClassName: 'flex-center',
    accessor: 'oxygen',
    className: 'flex-center',
};

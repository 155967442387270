import { createSlice } from '@reduxjs/toolkit';

const IV_INPUT_KEY = {
    INJECTION_VALUE: 'injectionVal',
    MIN_LIMIT_SPEED: 'minLimitSpeed',
    MAX_LIMIT_SPEED: 'maxLimitSpeed',
};
// 선택 수액 대상정보 초기값
const initialIVInjectionState = {
    targetInfo: {},
    IVInjection: {},
    sensorItems: [],
};

export const initialState = {
    IVInjectionList: {},
    searchInput: {
        ward: '',
    },
    currentIVInjectionNum: null, // 선택 수액 고유번호
    currentIVInjection: initialIVInjectionState, // 선택 수액 대상정보
    remainingValueDisplay: false, // 잔량 표시
};

const { actions, reducer } = createSlice({
    name: 'IVInjectionMonitoring',
    initialState,
    reducers: {
        setIVInjectionList: (state, action) => {
            state.IVInjectionList = action.payload;
        },
        changeCurrentIVInjection: (state, action) => {
            if (!action.payload) {
                state.currentIVInjection = initialIVInjectionState;
                return;
            }
            const IVInjection = action.payload;
            const { injectionVal, maxLimitSpeed, minLimitSpeed } = IVInjection;
            // 최대 제한 속도(버림)
            const initMaxLimitSpeed =
                maxLimitSpeed <= 0 && injectionVal > 0 ? Math.floor(injectionVal + injectionVal / 2) : maxLimitSpeed;
            // 최소 제한 속도(올림)
            const initMinLimitSpeed =
                minLimitSpeed <= 0 && injectionVal > 0 ? Math.ceil(injectionVal - injectionVal / 2) : minLimitSpeed;

            state.currentIVInjection = {
                ...state.currentIVInjection,
                IVInjection: {
                    ...IVInjection,
                    maxLimitSpeed: initMaxLimitSpeed,
                    minLimitSpeed: initMinLimitSpeed,
                },
            };
        },
        selectCurrentIVInjection: (state, action) => {
            if (!action.payload) {
                state.currentIVInjectionNum = null;
                state.currentIVInjection = initialIVInjectionState;
                return;
            }

            state.currentIVInjectionNum = action.payload.IVInjectionNum;

            const targetInfo = action.payload.targetInfo || {};
            const IVInjection = action.payload.IVInjection || {};
            const sensorItems = action.payload.sensorItems || [];
            state.currentIVInjection = {
                targetInfo,
                IVInjection,
                sensorItems,
            };
        },
        changeCurrentIVInjectionValue: (state, action) => {
            if (!action.payload) {
                return;
            }
            // action state
            const IVInjectionInput = action.payload;
            // ivInjection state
            const currentIVInjection = state.currentIVInjection.IVInjection;
            // 존재하는 항목인지 확인
            const checkIVInjectionKey = Object.keys(IVInjectionInput).some(value =>
                Object.keys(currentIVInjection).includes(value),
            );
            if (!checkIVInjectionKey) {
                return;
            }

            // 오더량(injectionVal) 입력의 경우
            const checkInjectionInput = Object.keys(IVInjectionInput).includes(IV_INPUT_KEY.INJECTION_VALUE);
            if (checkInjectionInput) {
                const IVInjectionInputValue = Number(IVInjectionInput[IV_INPUT_KEY.INJECTION_VALUE]);
                // 오더량이 수액량을 넘지 않도록 설정
                const confirmIVInjectionValue =
                    currentIVInjection.totalVal < IVInjectionInputValue
                        ? currentIVInjection.totalVal
                        : IVInjectionInputValue;
                // 오더량
                IVInjectionInput[IV_INPUT_KEY.INJECTION_VALUE] = Number(confirmIVInjectionValue);
                // 최대 제한 속도(버림)
                IVInjectionInput[IV_INPUT_KEY.MAX_LIMIT_SPEED] =
                    confirmIVInjectionValue > 0 ? Math.floor(confirmIVInjectionValue + confirmIVInjectionValue / 2) : 0;
                // 최소 제한 속도(올림)
                IVInjectionInput[IV_INPUT_KEY.MIN_LIMIT_SPEED] =
                    confirmIVInjectionValue > 0 ? Math.ceil(confirmIVInjectionValue - confirmIVInjectionValue / 2) : 0;
            }

            state.currentIVInjection = {
                ...state.currentIVInjection,
                IVInjection: {
                    ...currentIVInjection,
                    ...IVInjectionInput,
                },
            };
        },
        resetCurrentIVInjectionNum: state => {
            state.currentIVInjectionNum = null;
        },
        toggleRemainingValueDisplay: state => {
            state.remainingValueDisplay = !state.remainingValueDisplay;
        },
    },
});

export const {
    setIVInjectionList,
    changeCurrentIVInjection,
    selectCurrentIVInjection,
    resetCurrentIVInjectionNum,
    toggleRemainingValueDisplay,
    changeCurrentIVInjectionValue,
} = actions;

export default reducer;

import _ from 'lodash';

export const DUMMY_DATA = {
    rows: [
        {
            categoryCode: 'child2',
            categoryName: 'PNT ASSET',
            categoryCodePath: 'Example2>child2',
            targetCnt: _.random(9, 10),
        },
        {
            categoryCode: 'Example1',
            categoryName: 'Example1',
            categoryCodePath: 'Example1',
            targetCnt: _.random(30, 35),
        },
        {
            categoryCode: 'child3',
            categoryName: 'PNT ASSET',
            categoryCodePath: 'Example3>child3',
            targetCnt: _.random(10, 11),
        },
    ],
};

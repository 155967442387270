import React, { useState } from 'react';
import Card from '../Components/WidgetCard';
import List from './Component/List';
import Search from './Component/Search';
import { useAsync } from '@hooks';
import { getLoginLogListApi } from '@api/log';

/**
 *
 * 로그인 이력 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

const LoginHistoryLog = ({ children, widgetInfo, ...restProps }) => {
    const [loginLogData, setLoginLogData] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const { promise: getLoginLogList, state: loginLogListInfo } = useAsync({
        promise: getLoginLogListApi,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => setLoginLogData(res),
        reject: err => console.error(err),
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <Search promise={getLoginLogList} />
            <List list={loginLogData} state={loginLogListInfo} promise={getLoginLogList} />
            {children}
        </Card>
    );
};

export default LoginHistoryLog;

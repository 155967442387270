import React from 'react';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';

const STATUS_COLOR = {
    OUTOFRANGE: 'color-secondary',
    CRITICAL: 'color-danger',
    WARNING: 'color-warning',
    NORMAL: 'color-success',
    null: 'color-gray',
};
const DEFAULT_VALUE = 'N/A';

const SensorInfo = ({ value, status }) => {
    const t = useTranslation('RefrigeratorSensor');
    const temp = value ? value.toFixed(2) : DEFAULT_VALUE;

    return (
        <div className="flx-row flx-sb w-100">
            <span className={cx('pnt-txt txt-bold s-4', !status && 'color-gray')}>{temp}</span>
            <span className={`pnt-txt s-7 ${STATUS_COLOR[status]}`}>{t(status)}</span>
        </div>
    );
};

export default SensorInfo;

import { createSlice } from '@reduxjs/toolkit';

export const URL_ROOT_MAIN = 'main';
export const URL_ROOT_SETTING = 'setting';

const initialState = {
    // 메뉴 로딩 여부
    loadedMenu: false,

    // 메뉴 원본 리스트
    originMenuList: [],

    // 화면을 그리기 위한 위젯 정보를 포함한 화면 리스트
    originScreenList: [],

    // 메뉴 랜더링을 위한 리스트
    menuList: [],

    // 라우팅을 위한 화면 리스트
    screenList: [],
};

// UI 랜더링에 필요한 메뉴 데이터 생성
const makeMenuData = (menuList, parentMenuPath = `/${URL_ROOT_MAIN}`) => {
    return menuList
        .filter(menu => {
            return menu.screenCode || !!menu.screenList.length;
        })
        .map(menu => {
            const tempMenuData = { ...menu, screenList: [...(menu.screenList ?? [])] };
            const menuPath = parentMenuPath + `/${menu.screenCode ?? menu.menuCode}`;
            tempMenuData.screenList = makeMenuData(
                tempMenuData.screenList.sort((a, b) => a.order - b.order),
                menuPath,
            );
            const menuData = {
                id: menuPath,
                label: tempMenuData.screenName ?? tempMenuData.menuName,
                icon: tempMenuData.icon,
                content: tempMenuData.screenList,
            };
            if (tempMenuData.screenCode) {
                menuData.to = `#${menuPath}`;
            }
            return menuData;
        });
};

const makeOriginScreenList = (originMenuList = []) => {
    return originMenuList.reduce((acc, curr) => {
        if (curr.screenList) {
            acc = acc.concat(
                curr.screenList
                    .map(screen => ({
                        ...screen,
                        groupNumList: (screen.authGroups || []).map(group => group.groupNum),
                    }))
                    .sort((a, b) => a.order - b.order),
            );
        }
        return acc;
    }, []);
};

// 메뉴 목록 중 화면 목록만 배열로 리턴
const extractScreenList = (menuList, screenList = []) => {
    menuList.forEach(menu => {
        if (menu.to) {
            screenList.push(menu);
        }
        if (menu.content && menu.content[0]) {
            extractScreenList(menu.content, screenList);
        }
    });
    return screenList;
};

const { actions, reducer } = createSlice({
    name: 'customMenu',
    initialState,
    reducers: {
        setMenuList: (state, action) => {
            const originMenuList = action.payload;
            const sortedMenuList = [...originMenuList].sort((a, b) => a.order - b.order);
            state.originMenuList = sortedMenuList;
            state.originScreenList = makeOriginScreenList(sortedMenuList);
            state.menuList = makeMenuData(sortedMenuList);
            state.screenList = extractScreenList(state.menuList);
            state.loadedMenu = true;
        },
        setScreenList: (state, action) => {
            const newScreenList = action.payload;
            state.originMenuList = state.originMenuList.map(menu => {
                menu.screenList = newScreenList.filter(screen => screen.menuCode === menu.menuCode);
                return menu;
            });
            state.originScreenList = makeOriginScreenList(state.originMenuList);
            state.menuList = makeMenuData(state.originMenuList);
            state.screenList = extractScreenList(state.menuList);
        },
        deleteMenuScreen: (state, action) => {
            const { menuCode, screenCode } = action.payload;
            if (menuCode && screenCode) {
                state.originMenuList = state.originMenuList.map(menu => {
                    menu.screenList = menu.screenList.filter(
                        screen => screen.menuCode !== menuCode || screen.screenCode !== screenCode,
                    );
                    return menu;
                });
                state.originScreenList = makeOriginScreenList(state.originMenuList);
                state.menuList = makeMenuData(state.originMenuList);
                state.screenList = extractScreenList(state.menuList);
            }
        },
    },
});

export const { setMenuList, setScreenList, deleteMenuScreen } = actions;
export default reducer;

import React from 'react';
import { ToggleButton } from '@components';
import useTranslation from '@hooks/useTranslation';
import styled from 'styled-components';

const BookmarkToggle = ({ selected, handleChange }) => {
    const t = useTranslation('Filter');
    return (
        <div className="select-dropdown">
            <BookmarkToggleContainer className="select-container flx-row gap-2">
                <div className={'px-1'}>
                    <p className="pnt-txt txt-bold">{`${t('Bookmark')} :`}</p>
                </div>
                <div className={'p-1'}>
                    <ToggleButton
                        checked={selected && selected[0] === 'Y'}
                        handleChecked={e => {
                            handleChange(e.currentTarget.checked ? ['Y'] : ['N']);
                        }}
                    />
                </div>
            </BookmarkToggleContainer>
        </div>
    );
};

const BookmarkToggleContainer = styled.div`
    border-radius: 2px;
`;

export default BookmarkToggle;

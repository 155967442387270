import React, { useContext, useEffect, useRef } from 'react';

import moment from 'moment';

import useTranslation from '@hooks/useTranslation';

import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import { Button, DatePicker, SearchableSelect } from '@components';
import FloorTreeSelect from '../../Components/FloorTreeSelect';
import { useSelector } from 'react-redux';
import useConfirmModal from '@hooks/useConfirmModal';
import { ScannerStatusLogDispatchContext, ScannerStatusLogStateContext } from '../index';
import { reset, setFloorIdPath, setFloorIds, setScannerNums } from '../scannerStatusLogReducer';
import { makeParameter } from '../../util/commons';
import { ButtonGroup } from 'reactstrap';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';

/**
 * 스캐너 상태 로그 검색 기능 컴포넌트
 *
 * @param {function} promise 스캐너 상태 로그 리스트 호출 callback
 * @param {date} startDate 검색 시작 일자
 * @param {date} endDate  검색 마지막 일자
 * @param {function} handleDateCallback  날짜 변경 함수
 *
 * @author jinbeom-jung
 * */

const Search = ({ promise, startDate, endDate, handleDateCallback }) => {
    const t = useTranslation('Scanner State Log');
    const dispatch = useContext(ScannerStatusLogDispatchContext);
    const { floorIds, floorIdPath, scannerNums, scannerList } = useContext(ScannerStatusLogStateContext);
    const { floorList, selectableFloorList } = useSelector(state => state.FloorInfo);

    const handleSelectedFloor = selected => {
        const { floorId, floorIdPath, floorName } = selected[0];
        dispatch(setFloorIds({ floorId, floorName }));
        dispatch(setFloorIdPath(floorIdPath));
    };

    const handleSelectedScanner = selected => dispatch(setScannerNums(selected.map(({ value }) => value)));

    const handleSearchClick = () => {
        const param = { scannerNums: scannerNums.join(','), floor: floorIds.floorId, floorPath: floorIdPath };
        if (startDate) {
            if (!endDate) {
                toggleWarningModal();
                return false;
            } else {
                param['startDate'] = moment(startDate).unix();
                param['endDate'] = moment(endDate).unix();
            }
        }
        const result = makeParameter({ searchRequirement: param });
        promise(result);
    };

    const handleYesterdayButtonClick = () => {
        handleDateCallback({ selected: 0, type: 'yesterday' });
    };

    const handleRefreshClick = () => {
        const { floorId, floorIdPath, floorName } = selectableFloorList[0];
        dispatch(
            reset({
                floorIds: { floorId, floorName },
                floorIdPath,
                scannerNums: scannerList.map(({ value }) => value),
                scannerList: scannerList,
            }),
        );
        handleDateCallback({ selected: 0, type: 'reset' });
        promise({
            startDate: moment().startOf('day').unix(),
            endDate: moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
            floorIds: floorIds.floorId,
            floorIdPath: floorIdPath,
            scannerNums: scannerList.map(({ value }) => value).join(','),
        });
    };

    const { Modal: WarningModal, toggleModal: toggleWarningModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Please select an end date', 'ConfirmModal'),
    });
    const handleStartDate = selected => {
        if (endDate > selected) {
            handleDateCallback({ selected, type: 'startDate' });
        }
    };
    const handleEndDate = selected => {
        if (startDate < selected) {
            handleDateCallback({ selected, type: 'endDate' });
        }
    };

    useEffect(() => {
        const { floorId, floorIdPath, floorName } = selectableFloorList[0];
        dispatch(setFloorIds({ floorId, floorName }));
        dispatch(setFloorIdPath(floorIdPath));
    }, []);

    const inputStartRef = useRef(null);
    const inputEndRef = useRef(null);
    useEffect(() => {
        if (inputStartRef && inputStartRef.current) {
            inputStartRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
        if (inputEndRef && inputEndRef.current) {
            inputEndRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
    }, [startDate, endDate]);

    return (
        <>
            <FilterSearchGroup className={'p-0'}>
                <SearchGroup>
                    <SelectGroup>
                        <FloorTreeSelect
                            mode="radioSelect"
                            floorList={floorList}
                            selectedFloorStr={floorIds.floorId}
                            handleChange={handleSelectedFloor}
                        />
                    </SelectGroup>
                    <SelectGroup>
                        <SearchableSelect
                            title={t('Scanner')}
                            data={scannerList}
                            selected={scannerNums}
                            onChange={handleSelectedScanner}
                        />
                    </SelectGroup>
                    <SelectGroup className={'w-20'}>
                        <div className="datePicker-container" ref={inputStartRef}>
                            <DatePicker
                                key={`dp_start_${startDate}_${endDate}`}
                                value={startDate}
                                valueType="ms"
                                maxDate={endDate ?? moment().valueOf()}
                                handleChange={handleStartDate}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center">~</div>
                    <SelectGroup className={'w-20'}>
                        <div className="datePicker-container" ref={inputEndRef}>
                            <DatePicker
                                key={`dp_end_${startDate}_${endDate}`}
                                value={endDate}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment().valueOf()}
                                disabled={!startDate}
                                handleChange={handleEndDate}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>

                    <ButtonGroup>
                        <Button className="btn-gray" onClick={handleYesterdayButtonClick}>
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button
                            className="btn-secondary mx-1"
                            onClick={handleSearchClick}
                            //disabled={startDate && !endDate}
                        >
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-line btn-icon-only" iconName="refresh" onClick={handleRefreshClick} />
                    </ButtonGroup>
                </SearchGroup>
            </FilterSearchGroup>
            <WarningModal />
        </>
    );
};

export default Search;

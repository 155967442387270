import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    sensorType: '',
    floorInfo: {},
    selectedFloor: [],
    selectedSensor: {},
    sensorList: [],
    selectedSensorType: [],
    selectedSensingTypes: {
        Environment: [],
        Health: [],
        Movement: [],
        Chemistry: [],
        Other: [],
    },
};

const { actions, reducer } = createSlice({
    name: 'realTimeSensorMonitoring',
    initialState,
    reducers: {
        setSensorType: (state, action) => {
            state.sensorType = action.payload;
        },
        setFloorInfo: (state, action) => {
            state.floorInfo = { ...action.payload };
        },
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },
        setSensorList: (state, action) => {
            const sensorList = action.payload;
            state.sensorList = sensorList.reduce((sensorItemAcc, sensor) => {
                if (sensor.targetId && sensor.sensorItems) {
                    sensor.sensorItems.forEach(sensorItem => {
                        sensorItemAcc.push({ ...sensor, ...sensorItem });
                    });
                }
                return sensorItemAcc;
            }, []);
        },
        setSelectedSensor: (state, action) => {
            state.selectedSensor = { ...action.payload };
        },
        setSelectedSensorType: (state, action) => {
            state.selectedSensorType = action.payload;
        },
        setSelectedSensingTypes: (state, action) => {
            const { name, value } = action.payload;
            state.selectedSensingTypes = { ...state.selectedSensingTypes, [name]: [...value] };
        },
        clearSensingTypes: (state, action) => {
            state.selectedSensingTypes = {
                Environment: [],
                Health: [],
                Movement: [],
                Chemistry: [],
                Other: [],
            };
        },
    },
});

export const {
    setSensorType,
    setFloorInfo,
    setSelectedFloor,
    setSensorList,
    setSelectedSensor,
    setSelectedSensorType,
    setSelectedSensingTypes,
    clearSensingTypes,
} = actions;
export default reducer;

import React, { createContext, useMemo, useReducer } from 'react';
import Select from 'react-select';
import {
    Control,
    DropdownIndicator,
    IndicatorsContainer,
    MenuList,
    MultiValue,
    MultiValueContainer,
    MultiValueLabel,
    Option,
    ValueContainer,
} from './Parts';
import { useTranslation } from '@hooks';

export const MultiSelectContext = createContext();

const MultiSelect = ({
    className = '',
    options = [],
    values = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    onChange,
    name,
    placeholder,
    keepSortOrder = false,
    isModalSelect = false,
}) => {
    const [state] = useReducer(null, { title, labelKey, valueKey });
    const t = useTranslation('Select');
    const getOptionValue = option => option[valueKey];
    const getOptionLabel = option => option[labelKey];

    const selectedOptions = useMemo(() => {
        if (keepSortOrder) {
            return (values || []).reduce((acc, curr) => {
                const selectedData = options.find(option => option[valueKey] === curr);
                if (selectedData) {
                    acc.push(selectedData);
                }
                return acc;
            }, []);
        }
        return options.reduce((acc, curr) => {
            if ((values || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [options, values, valueKey, keepSortOrder]);

    return (
        <MultiSelectContext.Provider value={state}>
            <Select
                menuPortalTarget={document.getElementById(
                    `${!isModalSelect ? 'select-container' : 'modal-select-container'}`,
                )}
                menuPosition={'fixed'}
                components={{
                    Control,
                    ValueContainer,
                    MultiValue,
                    MultiValueContainer,
                    MultiValueLabel,
                    IndicatorsContainer,
                    DropdownIndicator,
                    MenuList,
                    Option,
                }}
                unstyled
                isMulti
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={false}
                menuShouldBlockScroll
                closeMenuOnScroll
                onChange={onChange}
                options={options}
                className={`react-select pnt-select--group ${className}`}
                classNamePrefix="react-select"
                placeholder={placeholder || t('Select')}
                noOptionsMessage={() => t('No options')}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                value={selectedOptions}
                name={name}
            />
        </MultiSelectContext.Provider>
    );
};

export default MultiSelect;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CHART_COLORS } from '../../../Common/Charts/config';
import { useAppSelector } from '@hooks';
import ReactResizeDetector from 'react-resize-detector';
import NoDataBlock from '../../Components/NoDataBlock';
import Charts from '@components/Charts/Highcharts';

const CHART_OPTION = {
    chart: {
        type: 'column',
        marginBottom: 70,
        backgroundColor: 'transparent',
    },
    xAxis: {
        categories: [],
        labels: {
            style: {
                fontSize: '1rem',
            },
        },
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
        },
    },
    title: null,
    colors: CHART_COLORS,
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false,
            },
        },
        series: {
            showInLegend: true,
        },
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        symbolRadius: 0,
        itemHiddenStyle: {
            color: '#77777C',
        },
        itemHoverStyle: {
            color: null,
        },
        itemStyle: {
            color: 'black',
        },
    },
    series: [
        {
            name: '',
            data: [
                {
                    name: '',
                    y: 0,
                },
            ],
        },
    ],
    credits: {
        enabled: false,
    },
};

const CategoryBarChart = ({ congestedGeofenceInfo }) => {
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const chartRef = useRef(null);
    const [chartOptions, setChartOptions] = useState(CHART_OPTION);
    const [containerProps, setContainerProps] = useState({
        style: {
            width: 0,
            height: 0,
        },
    });

    useEffect(() => {
        let tempArr = [];
        congestedGeofenceInfo.forEach(geofence => {
            let obj = {};
            geofence.categoryInfo.forEach(category => {
                obj[category.categoryName] = category.categoryCount;
                if (!categoryList.includes(category.categoryName)) {
                    categoryList.push(category.categoryName);
                    setCategoryList(categoryList);
                }
            });

            tempArr.push({ fcName: geofence.fcName, ...obj });
        });

        const chartArr = categoryList.map(e => {
            return {
                name: e,
                data: tempArr.map(v => v[e]),
            };
        });

        setChartOptions(prevState => ({
            ...prevState,
            xAxis: {
                ...prevState.xAxis,
                categories: tempArr.map(e => e.fcName),
            },
            series: chartArr,
        }));

        setGeofenceInfo(tempArr);
    }, [congestedGeofenceInfo]);

    const onResize = useCallback((width, height) => {
        setContainerProps({
            style: {
                width,
                height,
            },
        });
        const chart = chartRef.current?.chart;

        if (chart) chart.reflow();
    }, []);

    useEffect(() => {
        setChartOptions(prevState => ({
            ...prevState,
            legend: {
                ...prevState.legend,
                itemStyle: {
                    color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                },
            },
            xAxis: {
                ...prevState.xAxis,
                labels: {
                    style: {
                        ...prevState.xAxis.labels.style,
                        color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                    },
                },
            },
        }));
    }, [colorScheme]);

    return (
        <>
            {!!geofenceInfo && geofenceInfo.length ? (
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={100}
                    handleHeight
                    handleWidth
                    onResize={onResize}
                >
                    <Charts reflowDeps={[containerProps]} containerProps={containerProps} options={chartOptions} />
                </ReactResizeDetector>
            ) : (
                <NoDataBlock />
            )}
        </>
    );
};

export default CategoryBarChart;

import React from 'react';
import { ButtonProps } from '../type';
import cx from 'classnames';

const Button = ({
    className,
    onClick,
    iconClassName = 'material-icons-round',
    iconName,
    title,
    children,
    disabled,
}: ButtonProps): JSX.Element => {
    return (
        <button
            className={cx('pnt-btn', className, disabled && 'form-disable')}
            title={title}
            onClick={onClick}
            disabled={disabled}
        >
            {iconName && <span className={iconClassName}>{iconName}</span>}
            {children}
        </button>
    );
};

export default Button;

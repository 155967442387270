import React from 'react';
import { useColumns, useTranslation } from '@hooks';

const UseGeofenceColumn = () => {
    const t = useTranslation('GeofenceCongestionStatus');

    return useColumns(
        [
            {
                Header: 'Ranking',
                headerClassName: 'text-ellipsis',
                accessor: 'Rank',
                className: 'text-ellipsis',
                width: 50,
                Cell: ({ row: { index } }) => index + 1,
            },
            {
                Header: 'Geofence Name',
                headerClassName: 'text-ellipsis',
                accessor: 'name',
                className: 'text-ellipsis',
                width: 50,
            },
            {
                Header: 'Number of Tags Entered',
                headerClassName: 'text-ellipsis',
                accessor: 'value',
                className: 'text-ellipsis',
                width: 50,
            },
        ],
        t,
    );
};

export default UseGeofenceColumn;

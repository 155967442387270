import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from '../../../Common/Card';
import { useAppSelector, useTranslation } from '@hooks';
import ReactResizeDetector from 'react-resize-detector';
import NoDataBlock from '../../Components/NoDataBlock';
import Charts from '@components/Charts/Highcharts';

const CHART_OPTION = {
    chart: {
        type: 'areaspline',
        backgroundColor: null,
    },
    xAxis: {
        categories: [],
        labels: {
            style: {
                fontSize: '1rem',
            },
        },
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
        },
    },
    title: null,
    plotOptions: {
        series: {
            showInLegend: true,
        },
        areaspline: {
            fillOpacity: 0.5,
        },
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        symbolRadius: 0,
        itemHiddenStyle: {
            color: '#77777C',
        },
        itemHoverStyle: {
            color: null,
        },
        itemStyle: {
            color: 'black',
        },
    },
    series: [
        {
            name: '',
            data: [
                {
                    name: '',
                    y: 0,
                },
            ],
        },
    ],
    credits: {
        enabled: false,
    },
};
const LineChartByTime = ({ dataForCongestion }) => {
    const t = useTranslation('HourlyCongestionStatus');
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const [chartOptions, setChartOptions] = useState(CHART_OPTION);
    const [containerProps, setContainerProps] = useState({
        style: {
            width: 0,
            height: 0,
        },
    });

    const onResize = useCallback((width, height) => {
        setContainerProps({
            style: {
                width,
                height,
            },
        });
    }, []);

    useEffect(() => {
        const dataArr = ['totalEntry', 'uniqueEntry'];
        const chartArr = dataArr.map(e => {
            return {
                name: e,
                data: dataForCongestion.map(v => v[e]),
            };
        });

        setChartOptions(prevState => ({
            ...prevState,
            xAxis: {
                ...prevState.xAxis,
                categories: dataForCongestion.map(e => e.time),
                type: 'string',
            },

            series: chartArr,
        }));
    }, [dataForCongestion]);

    useEffect(() => {
        setChartOptions(prevState => ({
            ...prevState,
            legend: {
                ...prevState.legend,
                itemStyle: {
                    color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                },
            },
            xAxis: {
                ...prevState.xAxis,
                labels: {
                    style: {
                        ...prevState.xAxis.labels.style,
                        color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                    },
                },
            },
        }));
    }, [colorScheme]);

    return (
        <Card
            header={{
                title: t('Hourly congestion status'),
            }}
        >
            {!!dataForCongestion && dataForCongestion.length ? (
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={100}
                    handleHeight
                    handleWidth
                    onResize={onResize}
                >
                    <Charts reflowDeps={[containerProps]} containerProps={containerProps} options={chartOptions} />
                </ReactResizeDetector>
            ) : (
                <NoDataBlock />
            )}
        </Card>
    );
};

export default LineChartByTime;

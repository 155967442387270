import React, { useContext, useEffect, useRef } from 'react';

import moment from 'moment';
import useTranslation from '@hooks/useTranslation';

import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import FilterButtonArea from '../../../MainPages/Components/FilterSearchGroup/Components/FilterButtonArea';
import FilterGroup from '../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import {
    FilterList,
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import { Button, DatePicker, Select as ReactSelect, TextInput } from '@components';
import useConfirmModal from '@hooks/useConfirmModal';
import { makeParameter } from '../../util/commons';
import { reset, setKeyword, setSearchOption } from '../locationLogReducer';
import { LocationLogDispatchContext, LocationLogStateContext } from '../index';
import SearchButtonArea from '../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import { ButtonGroup, Row } from 'reactstrap';

/**
 *
 * 위치 로그 검색 기능 컴포넌트
 *
 * @param {function} promise 위치 로그 리스트 호출 callback
 * @param {date} startDate 검색 시작 일자
 * @param {date} endDate  검색 마지막 일자
 * @param {function} handleDateCallback  검색 시작 일자 set 함수
 * @param {null} ref 참조 값
 *
 * @author jinbeom-jung
 * */

const Search = ({ promise, startDate, endDate, handleDateCallback }) => {
    const t = useTranslation('Location Log');
    const dispatch = useContext(LocationLogDispatchContext);
    const { keyword, searchOption } = useContext(LocationLogStateContext);
    const searchOptions = [
        { value: '', label: t('Search Option', 'Search') },
        { value: 'targetName', label: t('Target Name') },
        { value: 'tagName', label: t('Device Name') },
    ];

    const handleSelectValueChange = selected => {
        const { value } = selected;
        if (value === '') {
            dispatch(setKeyword(''));
        }
        dispatch(setSearchOption(selected));
    };

    const handleRefreshClick = () => {
        dispatch(reset({ keyword: '', searchOption: searchOptions[0] }));
        handleDateCallback({ selected: 0, type: 'reset' });
        promise({
            startDate: moment().startOf('day').unix(),
            endDate: moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').unix(),
        });
    };

    const handleSearchInputChange = e => {
        const { value } = e.target;
        dispatch(setKeyword(value));
    };

    const handleSearchClick = () => {
        const param = { opt: searchOption.value, keyword };
        if (startDate) {
            if (!endDate) {
                toggleWarningModal();
                return false;
            } else {
                param['startDate'] = moment(startDate).unix();
                param['endDate'] = moment(endDate).unix();
            }
        }
        const result = makeParameter({ searchRequirement: param });
        promise(result);
    };

    const handleYesterdayButtonClick = () => {
        handleDateCallback({ selected: 0, type: 'yesterday' });
    };

    const { Modal: WarningModal, toggleModal: toggleWarningModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Please select an end date', 'ConfirmModal'),
    });

    const handleStartDate = selected => {
        if (endDate > selected) {
            handleDateCallback({ selected, type: 'startDate' });
        }
    };

    const handleEndDate = selected => {
        if (startDate < selected) {
            handleDateCallback({ selected, type: 'endDate' });
        }
    };

    const inputStartRef = useRef(null);
    const inputEndRef = useRef(null);
    useEffect(() => {
        if (inputStartRef && inputStartRef.current) {
            inputStartRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
        if (inputEndRef && inputEndRef.current) {
            inputEndRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
    }, [startDate, endDate]);

    return (
        <>
            <FilterSearchGroup className={'p-0'}>
                <SearchGroup>
                    <SelectGroup className={'w-20'}>
                        <div className="datePicker-container" ref={inputStartRef}>
                            <DatePicker
                                key={`dp_start_${startDate}_${endDate}`}
                                value={startDate}
                                handleChange={handleStartDate}
                                valueType="ms"
                                maxDate={endDate}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center">~</div>
                    <SelectGroup className={'w-20'}>
                        <div className="datePicker-container" ref={inputEndRef}>
                            <DatePicker
                                key={`dp_end_${startDate}_${endDate}`}
                                value={endDate}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment().valueOf()}
                                readOnly={!startDate}
                                handleChange={handleEndDate}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <ButtonGroup>
                        <Button className="btn-gray mr-2" onClick={handleYesterdayButtonClick}>
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button className="btn-line btn-icon-only" iconName="refresh" onClick={handleRefreshClick} />
                    </ButtonGroup>
                </SearchGroup>
                <SearchGroup>
                    <SearchWrap>
                        <SelectGroup>
                            <ReactSelect
                                name="selectInput"
                                placeholder={t('Search Option', 'Search')}
                                value={searchOption}
                                options={searchOptions}
                                onChange={handleSelectValueChange}
                                customControlStyles={{
                                    width: '100%',
                                }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </SelectGroup>
                        <InputWrap>
                            <TextInput
                                type="text"
                                size={60}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                name="name"
                                value={keyword}
                                handleChange={handleSearchInputChange}
                                disabled={!searchOption.value}
                            />
                        </InputWrap>
                        <Button className="btn-secondary mr-2" onClick={handleSearchClick}>
                            {t('Search', 'Search')}
                        </Button>
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>
            <WarningModal />
        </>
    );
};

export default Search;

import React, { Children, isValidElement, useContext } from 'react';
import { components } from 'react-select';
import { MultiSelectContext } from './index';
import useTranslation from '../../../../util/hooks/useTranslation';
import { ICON_CLASS } from '@components/Select';
import cx from 'classnames';

function getChildrenTypeNode(children, targetComponent) {
    const targetType = targetComponent.type;
    const childrenArray = Children.toArray(children);

    const targetChildren = childrenArray.filter(child => isValidElement(child) && child.type === targetType);
    const otherChildren = childrenArray.filter(child => isValidElement(child) && child.type !== targetType);

    return { targetChildren, otherChildren };
}

export const Control = ({ children, selectProps, ...rest }) => {
    const { iconName } = selectProps;
    const { menuIsOpen } = rest;
    const { targetChildren: indicatorChild, otherChildren } = getChildrenTypeNode(children, <IndicatorsContainer />);
    const { targetChildren: valueContainerChild } = getChildrenTypeNode(otherChildren, <ValueContainer />);
    const {
        props: { children: valueContainerChildren, ...otherProps },
    } = valueContainerChild[0];
    const { targetChildren: multiValueChildren, otherChildren: otherMultiValueChildren } = getChildrenTypeNode(
        valueContainerChildren,
        <MultiValue />,
    );
    const valueCount = multiValueChildren.length;
    const valuesText = multiValueChildren.map(({ props }) => props.children).join(', ');

    return (
        <components.Control
            className={`${iconName ? 'icon-on-left' : ''} ${menuIsOpen ? 'on' : ''}`}
            selectProps={selectProps}
            {...rest}
        >
            <button className={'select__btn'}>
                <div className="cont-wrap">
                    {valueCount ? (
                        <>
                            <components.ValueContainer {...otherProps}>
                                <span>{`[${valueCount}] ${valuesText}`}</span>
                            </components.ValueContainer>
                            {otherMultiValueChildren}
                        </>
                    ) : (
                        otherMultiValueChildren
                    )}
                </div>
                {indicatorChild}
            </button>
        </components.Control>
    );
};

export const IndicatorsContainer = ({ children, ...rest }) => {
    return <components.IndicatorsContainer {...rest}>{children}</components.IndicatorsContainer>;
};

export const DropdownIndicator = ({ ...rest }) => {
    return (
        <components.DropdownIndicator className={`${ICON_CLASS} p-0`} {...rest}>
            arrow_drop_down
        </components.DropdownIndicator>
    );
};

export const ValueContainer = ({ children, ...restProps }) => {
    return <components.ValueContainer {...restProps}>{children}</components.ValueContainer>;
};

export const MultiValue = ({ children, ...restProps }) => {
    return <components.MultiValue {...restProps}>{children}</components.MultiValue>;
};

export const MultiValueLabel = ({ children, ...restProps }) => {
    return <components.MultiValueLabel {...restProps}>{children}</components.MultiValueLabel>;
};

export const MultiValueContainer = ({ children, ...restProps }) => {
    return <components.MultiValueContainer {...restProps}>{children}</components.MultiValueContainer>;
};

export const MenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const selected = getValue();
    const t = useTranslation('TreeSelect');
    const { valueKey, labelKey } = useContext(MultiSelectContext);
    const handleChange = e => {
        const { checked } = e.currentTarget;
        setValue(checked ? options : []);
    };
    return (
        <components.MenuList className="select__options" {...restProps}>
            <div className={'flx-row tl p-1 border-bottom bg-depth-6'}>
                <p className="pnt-txt txt-bold color-trans-black">{t('Selected')}</p>
            </div>
            <div className={'select__options-list'}>
                {selected.length ? (
                    selected.map(v => (
                        <div
                            className={'styled-option select__option react-select__option'}
                            key={v[valueKey]}
                            onClick={e => {
                                setValue(selected.filter(selectedOption => selectedOption[valueKey] !== v[valueKey]));
                            }}
                        >
                            <label className="pnt-checkbox form-h-small check-checked" htmlFor={v[labelKey]}>
                                <input
                                    id={v[labelKey]}
                                    type="checkbox"
                                    value={v[valueKey]}
                                    checked
                                    onChange={() => null}
                                />
                                <span className="checkbox-effect" />
                                {/*<span className="material-icons-round md-18 color-secondary">check_box</span>*/}
                            </label>
                            <span className="overflow-hidden text-ellipsis">{v[labelKey]}</span>
                        </div>
                    ))
                ) : (
                    <div className={'styled-option-label'}>{t('Not Selected')}</div>
                )}
            </div>
            <div className="pnt-divider horizon-line border-gray opacity-6 mb-0" />
            <div className={'flx-row tl p-1 border-bottom bg-depth-5'}>
                <label
                    className={cx(
                        'pnt-checkbox',
                        'form-h-small',
                        selected.length === options.length && 'check-checked',
                    )}
                    htmlFor={'All'}
                >
                    <input
                        id={'All'}
                        type="checkbox"
                        onChange={handleChange}
                        checked={selected.length === options.length}
                    />
                    <span className="checkbox-effect" />
                </label>
                <p className="pnt-txt txt-bold color-trans-black">{t('All')}</p>
            </div>
            <div className={'select__options-list'}>{children}</div>
        </components.MenuList>
    );
};

export const Option = function ({ label, isSelected, ...restProps }) {
    return (
        <components.Option className={'styled-option select__option'} {...restProps}>
            <label className={'pnt-checkbox form-h-small'} htmlFor={label} title={label}>
                <input id={label} type="checkbox" checked={isSelected} onChange={() => null} />
                <span className="checkbox-effect" />
            </label>
            <span className="overflow-hidden text-ellipsis">{label}</span>
        </components.Option>
    );
};

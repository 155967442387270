import React, { useContext } from 'react';
import useTranslation from '@util/hooks/useTranslation';
import { LoginDispatchContext } from '../index';
import { setNextStep } from '../reducer';
import Button from '@components/Button';

const CompleteForm = () => {
    const t = useTranslation('Login');
    const dispatch = useContext(LoginDispatchContext);
    const handleGoLogin = () => {
        dispatch(setNextStep());
    };
    return (
        <>
            <div className="cont-box">
                <div className="flx-col gap-5 mt-5">
                    <div className="cont--txt flx-col mt-5">
                        <div className="flx-row gap-3">
                            <div className="icon-check icon-filter-brand icon-large mb-2" />
                            <h3 className="txt__main">{t('Password change is completed')}</h3>
                        </div>
                        <p className="txt__sub">{t('Log in again with the changed password.')}</p>
                    </div>
                    <div className="cont--btn">
                        <Button
                            className="btn-brand btn-large w-100 btn-icon"
                            onClick={handleGoLogin}
                            iconName="arrow_back"
                        >
                            {t('Go to login')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompleteForm;

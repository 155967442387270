import { useAsync, useAppSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchCompany } from '@api/login';
import { setAdditionalInfo } from '@reducer/UserInfo';
import { setMarkerConfig } from '@reducer/Common/AppConfig';

const useCompanyInfo = (): any => {
    const dispatch = useDispatch();
    const { companyInfo }: { companyInfo?: object } = useAppSelector(state => state.UserInfo.userInfo);
    const {
        promise: getCompany,
    } = useAsync({
        promise: fetchCompany,
        param: {},
        resolve: (res: any) => {
            if (res) {
                dispatch(
                    setMarkerConfig({
                        markerTransitionActive: res.markerTransitionActive,
                        markerPulseActive: res.markerPulseActive,
                        markerBorderRadius: res.markerBorderRadius,
                        markerPulseAnimation: res.markerPulseAnimation,
                        markerPulseRssi: res.markerPulseRssi,
                        markerPulseColor: res.markerPulseColor,
                    }),
                );
                dispatch(setAdditionalInfo({ companyInfo: res }));
            }
        },
        reject: (err: any) => {
            console.error(err);
        },
    });

    useEffect(() => {
        getCompany();
    }, []);

    return companyInfo;
};

export default useCompanyInfo;

import React, { useContext, useState } from 'react';
import { Button, TextInput } from '@components';
import { clearIotItemStatusWidget, setSearchTarget } from '../../iotItemStatusReducer';
import { useTranslation } from '@hooks';
import { IotItemStatusDispatchContext } from '../../index';
import { useSelector } from 'react-redux';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { InputGroup, InputWrap, SearchWrap } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';

const PLACEHOLDERS = {
    ASSET: 'Enter the name or ID of the asset.',
    PATIENT: "Enter the patient's name or ID.",
    PROTECTOR: "Enter the guardian's name or ID.",
};

const Search = ({ categoryCode, handleReset }) => {
    const t = useTranslation('IotItemStatus');
    const dispatch = useContext(IotItemStatusDispatchContext);
    const { categoryList } = useSelector(state => state.CategoryInfo);

    const [input, setInput] = useState('');
    const handleSearchInput = e => {
        const { value } = e.target;
        setInput(value);
    };

    const handleSearch = () => {
        dispatch(setSearchTarget(input));
    };

    const handleRefresh = () => {
        const assetList = categoryList.filter(category => {
            if (category.categoryCode === categoryCode) {
                return category;
            }
            return category.parentCode === categoryCode;
        });
        const param = assetList
            .map(({ categoryCode }) => categoryCode)
            .filter(item => item)
            .join(',');
        dispatch(clearIotItemStatusWidget({ categoryCodes: param }));
        handleReset();
        setInput('');
    };

    return (
        <SearchGroup>
            <SearchWrap>
                <InputWrap>
                    <InputGroup>
                        <TextInput
                            type="text"
                            name="target"
                            value={input}
                            handleChange={handleSearchInput}
                            placeholder={t(PLACEHOLDERS[categoryCode])}
                        />
                    </InputGroup>
                </InputWrap>
                <SearchButtonArea>
                    <Button className="btn-secondary" onClick={handleSearch}>
                        {t('Search', 'Search')}
                    </Button>
                </SearchButtonArea>
                <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={handleRefresh} />
            </SearchWrap>
        </SearchGroup>
    );
};

export default Search;

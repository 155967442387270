import React, { useState, createContext, useReducer } from 'react';

import Card from '../Components/WidgetCard';
import Search from './Component/Search';
import ExcelExport from './Component/ExcelExport';
import List from './Component/List';

import { useAsync } from '@hooks';
import { getIotItemMappingLogApi } from '@api/log';
import reducer, { initState } from './IotItemMappingLogReducer';
import { useSelector } from 'react-redux';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';

/**
 * Iot 아이템 맵핑 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const IotItemMappingLogStateContext = createContext();
export const IotItemMappingLogDispatchContext = createContext();
const IotItemMappingLog = ({ children, widgetInfo, ...restProps }) => {
    const { mode } = useSelector(state => state.ScreenInfo);
    const [state, dispatch] = useReducer(reducer, initState);
    const [list, setList] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const { promise: getIotItemMappingLog, state: iotItemMappingLogInfo } = useAsync({
        promise: getIotItemMappingLogApi,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => setList(res),
        reject: err => console.error(err),
    });

    return (
        <IotItemMappingLogDispatchContext.Provider value={dispatch}>
            <IotItemMappingLogStateContext.Provider value={state}>
                <Card
                    widgetInfo={widgetInfo}
                    headerAction={
                        mode !== SCREEN_MODE_EDIT &&
                        mode !== SCREEN_MODE_PREVIEW && <ExcelExport listCount={list.rows.length} />
                    }
                    {...restProps}
                >
                    <Search promise={getIotItemMappingLog} />
                    <List list={list} state={iotItemMappingLogInfo} promise={getIotItemMappingLog} />
                    {children}
                </Card>
            </IotItemMappingLogStateContext.Provider>
        </IotItemMappingLogDispatchContext.Provider>
    );
};

export default IotItemMappingLog;

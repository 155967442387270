import React, { useMemo, useEffect, useRef } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import useTranslation from '../../../../util/hooks/useTranslation';

const setScrollTop = (listEl, value) => {
    const selectedEl = listEl.querySelector(`button[value='${Number(value)}']`);
    if (selectedEl) {
        listEl.scrollTop = selectedEl.getBoundingClientRect().top - listEl.getBoundingClientRect().top;
    }
};

const TimeInput = ({ date, value, onChange }) => {
    const t = useTranslation('DatePicker');
    const hourRef = useRef();
    const minRef = useRef();
    const timeOptions = useMemo(() => {
        const h = [];
        const m = [];
        for (let i = 0; i < 60; i++) {
            if (i < 24) {
                h.push(i);
            }
            m.push(i);
        }
        return { h, m };
    }, []);

    useEffect(() => {
        if (value) {
            const [h, m] = value.split(':');
            const hourList = hourRef.current;
            const minList = minRef.current;
            if (hourList) {
                setScrollTop(hourList, h);
            }
            if (minList) {
                setScrollTop(minList, m);
            }
        }
    }, []);

    return (
        <>
            <div className={'react-datepicker__header'}>
                <div className={'react-datepicker-time__header'}>{t('Time')}</div>
                <div className={'time-label-box'}>
                    <div>{t('hour')}</div>
                    <div>{t('min')}</div>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <ListGroup vertical={'true'}>
                    <div className={'list-wrap'} ref={hourRef}>
                        {timeOptions.h.map(v => (
                            <ListGroupItem
                                key={v}
                                tag={'button'}
                                value={v}
                                action
                                onClick={e => {
                                    const currValue = e.currentTarget.value;
                                    const valueArr = value.split(':');
                                    valueArr.splice(0, 1, currValue);
                                    onChange(valueArr.join(':'));
                                }}
                                active={Number(value.split(':')[0]) === v}
                            >
                                {v}
                            </ListGroupItem>
                        ))}
                    </div>
                </ListGroup>
                <ListGroup vertical={'true'}>
                    <div className={'list-wrap'} ref={minRef}>
                        {timeOptions.m.map(v => (
                            <ListGroupItem
                                key={v}
                                tag={'button'}
                                value={v}
                                action
                                onClick={e => {
                                    const currValue = e.currentTarget.value;
                                    const valueArr = value.split(':');
                                    valueArr.splice(1, 1, currValue);
                                    onChange(valueArr.join(':'));
                                }}
                                active={Number(value.split(':')[1]) === v}
                            >
                                {v}
                            </ListGroupItem>
                        ))}
                    </div>
                </ListGroup>
            </div>
        </>
    );
};

export default TimeInput;

import React from 'react';
import Card from '../Components/WidgetCard';
import { Table, TableBody, TableHead, Td, Th, Tr } from '@components/Table/SimpleTable';
import { useSettings } from '../util/useSettings';
import useMonitor from '../util/useMonitor';
import ReactResizeDetector from 'react-resize-detector';
import { fetchIotItemCount } from '@api/asset';
import { useTopCategory } from '../util/useTopCategory';
import { useTranslation } from '@hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useFilter from '@hooks/useFilter';
import { DUMMY_DATA } from './constants';
import IotItemCountStatusChart from './IotItemCountStatusChart';

const sumTargetCnt = (acc, curr) => {
    acc += curr.targetCnt;
    return acc;
};

const SUMMARY_AREA_HEIGHT = '90px';
const TABLE_HEIGHT = '65px';

const IotItemCountStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('IotItemCountStatus');
    const settings = useSettings(config);
    const categoryList = useTopCategory();
    const filterParam = useFilter();

    const { data } = useMonitor({
        config,
        defaultData: DUMMY_DATA,
        dynamicParam: filterParam,
        fetchData: fetchIotItemCount,
        makeData: ({ newData }) => {
            const iotItemCountList = newData.rows;
            const chartData = [];
            let parentCodeList = [];

            if (iotItemCountList) {
                for (let i = 0; i < iotItemCountList.length; i++) {
                    if (iotItemCountList[i].categoryCodePath.indexOf('>') < 0) {
                        chartData.push({
                            key: iotItemCountList[i].categoryCode,
                            name: iotItemCountList[i].categoryName,
                            value: iotItemCountList[i].targetCnt,
                        });
                    } else {
                        const parentCode = iotItemCountList[i].categoryCodePath.split('>')[0];
                        parentCodeList.push(parentCode);
                    }
                }
                parentCodeList = [...new Set(parentCodeList)];

                for (let i = 0; i < parentCodeList.length; i++) {
                    const name = categoryList.filter(v => v.value === parentCodeList[i]);
                    chartData.push({
                        key: parentCodeList[i],
                        name: (name && name.label) || parentCodeList[i],
                        value: iotItemCountList
                            .filter(v => v.categoryCodePath.indexOf(parentCodeList[i]) === 0)
                            .reduce(sumTargetCnt, 0),
                    });
                }
            }
            return chartData;
        },
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <div className={'h-100'}>
                        <ReactResizeDetector handleHeight handleWidth>
                            {({ width, height }) => (
                                <div style={{ width, height: `calc(${height}px - ${SUMMARY_AREA_HEIGHT})` }}>
                                    <IotItemCountStatusChart data={data} settings={settings} />
                                </div>
                            )}
                        </ReactResizeDetector>
                        <div style={{ height: SUMMARY_AREA_HEIGHT }}>
                            {t('Current / Count')}
                            <Table style={{ height: TABLE_HEIGHT }}>
                                <TableHead>
                                    <Tr className={'active'}>
                                        {data.map(v => {
                                            return (
                                                <Th key={v.key} className={'justify-content-center'}>
                                                    {v.name}
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                </TableHead>
                                <TableBody>
                                    <Tr>
                                        {data.map(v => {
                                            return (
                                                <Td key={v.key} className={'justify-content-center'}>
                                                    {v.value}
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default IotItemCountStatus;

import React, { useContext, useState } from 'react';
import { IVInjectionMonitoringStateContext } from '../../index';
import useTranslation from '@hooks/useTranslation';
import Bookmark from '../../../Components/Bookmark';
import Modal from '@components/Modal';
import CategoryTag from './Component/CategoryTag';
import IVInjectionInfo from './Component/IVInjectionInfo';
import NoDataBlock from '../../../Components/NoDataBlock';

const IVInjectionMonitoringCardList = ({ handleCallbackApi }) => {
    const t = useTranslation('IVInjectionMonitoring');
    const modalT = useTranslation('ConfirmModal');
    const { IVInjectionList } = useContext(IVInjectionMonitoringStateContext);

    // bookmark error modal
    const [bookmarkErrorModal, setBookmarkErrorModal] = useState(false);
    const toggleBookmarkErrorModal = () => setBookmarkErrorModal(!bookmarkErrorModal);
    const bookmarkErrorModalOkCallback = handleCallbackApi;
    // bookmark 호출 후 event handler
    const handleBookmarkSuccess = handleCallbackApi;
    const handleBookmarkFailure = () => {
        setBookmarkErrorModal(true);
    };

    if (!(IVInjectionList && Object.entries(IVInjectionList).length)) {
        return <NoDataBlock />;
    }

    return (
        <>
            <div className="grid-container item-grid-300">
                {Object.entries(IVInjectionList).map(([ward, rooms]) =>
                    Object.entries(rooms).map(([room, people], roomIdx) => (
                        <div key={`${ward}_${room}_${roomIdx}`}>
                            {/* 병동 네임플레이트 */}
                            <CategoryTag ward={ward} visible={roomIdx === 0} />
                            {Object.entries(people).map(([bed, person], bedIndex) => {
                                // 백엔드 구조상 배열로 가져올 밖에 없음(튜닝 가능한지 추후 문의)
                                const { targetInfo, targetInjectionStateList } = person[0];
                                return (
                                    <div
                                        key={`${ward}_${room}_${bed}_${bedIndex}`}
                                        className={'IV-injection-table bg-depth-2'}
                                    >
                                        <div className="flx-row bg-depth-7 border-size-1 border-depth-7">
                                            {/* 병상 */}
                                            <div className="pnt-txt txt-bold">{room + bed}</div>
                                            {/* 환자 이름 */}
                                            <div className="pnt-txt txt-bold border-depth-8">
                                                {targetInfo.targetName}
                                            </div>
                                            {/* 환자 번호 */}
                                            <div className="pnt-txt flx-1 border-depth-8">{targetInfo.targetId}</div>
                                            {/* bookmark */}
                                            <div className="border-depth-8 p-0">
                                                <Bookmark
                                                    successCallback={handleBookmarkSuccess}
                                                    failureCallback={handleBookmarkFailure}
                                                    targetNum={targetInfo.targetNum}
                                                    isBookmark={targetInfo.isBookmark}
                                                />
                                            </div>
                                        </div>
                                        {/* 환자별 수액 리스트 */}
                                        {targetInjectionStateList?.length &&
                                            targetInjectionStateList.map(
                                                ({ ivInjection: IVInjection, sensor }, IVIdx) => (
                                                    <IVInjectionInfo
                                                        key={`${targetInfo.targetId}_IV_${IVIdx}`}
                                                        targetInfo={targetInfo}
                                                        IVInjection={IVInjection}
                                                        sensor={sensor}
                                                        handleCallbackApi={handleCallbackApi}
                                                    />
                                                ),
                                            )}
                                    </div>
                                );
                            })}
                        </div>
                    )),
                )}
            </div>
            {/* Bookmark error alert */}
            <Modal
                initModal={bookmarkErrorModal}
                toggleModal={toggleBookmarkErrorModal}
                headerTitle={t('Bookmark')}
                bodyText={modalT('Something has changed.')}
                removeCancel
                okCallback={bookmarkErrorModalOkCallback}
            />
        </>
    );
};

export default IVInjectionMonitoringCardList;

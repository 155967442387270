import React, { useContext } from 'react';
import moment from 'moment';
import { VitalSignsMonitoringDispatchContext } from '../../index';
import { setIsDetail, setTargetNum } from '../../vitalSignsMonitoringReducer';
import cx from 'classnames';

// 활력 징후 경고값
const VITAL_SIGN_RANGE = {
    // 최고 혈압(SBP)
    H_BLOOD_PRESSURE: {
        MIN: 90,
        MAX: 149,
    },
    // 최저 혈압(DBP)
    L_BLOOD_PRESSURE: {
        MAX: 99,
    },
    // 맥박(PR)
    PULSE_RATE: {
        MIN: 55,
        MAX: 110,
    },
    // 체온(BT)
    TEMPERATURE: {
        MIN: 35,
        MAX: 37.5,
    },
    // 호흡 수(RR)
    RESPIRATORY_RATE: {
        MIN: 9,
        MAX: 30,
    },
};

/**
 * 환자 활력징후 정보 card body
 *
 * @param personData 환자 센서 정보
 *
 * @since 2022-12-13
 * @author kunwoong-kim
 */
const VitalSignsCardBody = ({ personData }) => {
    const dispatch = useContext(VitalSignsMonitoringDispatchContext);
    const {
        targetId,
        targetNum,
        targetName,
        regDate,
        H_BLOODPRESSURE: H_BLOOD_PRESSURE,
        L_BLOODPRESSURE: L_BLOOD_PRESSURE,
        TEMPERATURE,
        PULSE_RATE,
    } = personData;

    const handleViewDetail = targetId => {
        dispatch(setTargetNum(targetId));
        dispatch(setIsDetail(true));
    };

    const date = moment(regDate * 1000).format('MM-DD HH:mm');
    const checkSBPWarning =
        H_BLOOD_PRESSURE <= VITAL_SIGN_RANGE.H_BLOOD_PRESSURE.MIN ||
        H_BLOOD_PRESSURE >= VITAL_SIGN_RANGE.H_BLOOD_PRESSURE.MAX;
    const checkDBPWarning = L_BLOOD_PRESSURE >= VITAL_SIGN_RANGE.L_BLOOD_PRESSURE.MAX;
    const checkPRWarning =
        PULSE_RATE <= VITAL_SIGN_RANGE.PULSE_RATE.MIN || PULSE_RATE >= VITAL_SIGN_RANGE.PULSE_RATE.MAX;
    const checkBPWarning =
        TEMPERATURE <= VITAL_SIGN_RANGE.TEMPERATURE.MIN || TEMPERATURE >= VITAL_SIGN_RANGE.TEMPERATURE.MAX;

    return (
        <div className="w-100 d-flex cursor-pointer" onClick={() => handleViewDetail(targetId)}>
            <div className={'flx-col justify-content-center align-items-center w-25'}>
                <span className={'pnt-txt txt-bold s-7'}>{targetNum}</span>
                <span className={'pnt-txt'}>{targetName}</span>
            </div>
            <div className="w-75">
                {/* 혈압 */}
                <div
                    className={cx(
                        'flx-row border-bottom border-left border-depth-7',
                        (checkSBPWarning || checkDBPWarning) && 'bg-warning',
                    )}
                >
                    <div className="pnt-txt px-2 py-1">BP</div>
                    <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-left border-depth-7">
                        <div>
                            <span className={cx('pnt-txt txt-bold s-7', checkSBPWarning && 'color-danger')}>
                                {H_BLOOD_PRESSURE ? Math.floor(H_BLOOD_PRESSURE) : '-'}
                            </span>
                            <span className="pnt-txt s-7"> / </span>
                            <span className={cx('pnt-txt txt-bold s-7', checkDBPWarning && 'color-danger')}>
                                {L_BLOOD_PRESSURE ? Math.floor(L_BLOOD_PRESSURE) : '-'}
                            </span>
                        </div>
                        <span className={'pnt-txt opacity-5'}>{date}</span>
                    </div>
                </div>
                {/* 맥박 */}
                <div
                    className={cx('flx-row border-bottom border-left border-depth-7', checkPRWarning && 'color-danger')}
                >
                    <div className="pnt-txt px-2 py-1">PR</div>
                    <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-left border-depth-7">
                        <span className={cx('pnt-txt txt-bold s-7', checkPRWarning && 'color-danger')}>
                            {PULSE_RATE ? Math.round(PULSE_RATE) : 'N/A'}
                        </span>
                        <span className={'pnt-txt opacity-5'}>{date}</span>
                    </div>
                </div>
                {/* 체온 */}
                <div className={cx('flx-row border-left border-depth-7', checkBPWarning && 'bg-warning')}>
                    <div className="pnt-txt px-2 py-1">BT</div>
                    <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-left border-depth-7">
                        <span className={cx('pnt-txt txt-bold s-7 mb-0', checkBPWarning && 'color-danger')}>
                            {TEMPERATURE ? TEMPERATURE.toFixed(2) : 'N/A'}
                        </span>
                        <span className={'pnt-txt opacity-5 mb-0'}>{date}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VitalSignsCardBody;

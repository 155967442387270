import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const initialState = {
    refrigeratorSensorList: [],
    refrigeratorSensorNumList: [],
    refrigeratorSensorLogList: [],
    refrigeratorSensorLogRegDateList: [],
    sensorInfo: {},
    targetNum: null,
    currentSensorNum: null,
    refrigeratorSensorLog: {
        floor: null,
        lat: 0,
        lng: 0,
        modDate: 0,
        sensorName: null,
        targetName: null,
        targetNum: null,
    },
    sensorNum: null,
    sensorDetail: {},
    isUpdatingData: false,
};

const { actions, reducer } = createSlice({
    name: 'refrigeratorSensor',
    initialState,
    reducers: {
        setRefrigeratorSensorList: (state, action) => {
            state.refrigeratorSensorList = action.payload;
            if (action.payload || action.payload.length !== 0) {
                const sensorNumbers = [];
                action.payload.forEach(item => {
                    let metadata;
                    try {
                        metadata = JSON.parse(item.metadata);
                    } catch (e) {
                        metadata = {};
                    }
                    // socket update 구분을 위한 센서번호 별도 저장
                    sensorNumbers.push(item.sensorNum);
                    // 냉장고 정보
                    state.sensorInfo = {
                        ...state.sensorInfo,
                        [item.sensorNum]: {
                            ...state.sensorInfo[item.sensorNum],
                            sensorNum: item.sensorNum,
                            sensorName: item.sensorName,
                            targetName: item.targetName,
                            ...metadata,
                        },
                    };
                });
                // socket update 구분을 위한 센서번호 리스트
                state.refrigeratorSensorNumList = sensorNumbers;
            }
        },
        updateEachRefrigeratorSensorList: (state, action) => {
            const refrigeratorSensor = action.payload;
            if (!refrigeratorSensor) {
                return;
            }
            try {
                const {
                    comNum,
                    sensor,
                    target,
                    targetCategory,
                    targetState,
                    sensingValues,
                    unixTime,
                } = refrigeratorSensor;
                const { sensorNum: currentSensorNum, sensorId, sensorName } = sensor;
                const { BATTERY: batteryStatus, TEMPERATURE: temperatureStatus } = targetState;
                const { BATTERY: batteryVal, TEMPERATURE: temperatureVal } = sensingValues;

                const findIndex = state.refrigeratorSensorNumList.indexOf(currentSensorNum);
                // 존재하지 않을 경우 return
                if (findIndex < 0) {
                    return;
                }
                state.refrigeratorSensorList[findIndex] = {
                    ...state.refrigeratorSensorList[findIndex],
                    batteryStatus,
                    batteryVal,
                    temperatureStatus,
                    temperatureVal,
                    modDate: unixTime,
                };
            } catch (e) {
                console.error(e);
            }
        },
        setRefrigeratorSensorLogList: (state, action) => {
            const data = action.payload || [];

            if (data.length) {
                state.refrigeratorSensorLogList = data.map(({ data }) => {
                    return JSON.parse(data).TEMPERATURE;
                });
                state.refrigeratorSensorLogRegDateList = data.map(({ regDate }) => {
                    return moment(regDate * 1000).format('HH');
                });
            } else {
                state.refrigeratorSensorLogList = [];
            }
        },
        setSensorInfo: (state, action) => {
            state.targetNum = action.payload;
            const refrigeratorSensorList = [...state.refrigeratorSensorList];
            state.sensorInfo = refrigeratorSensorList.find(({ targetNum }) => {
                return targetNum === action.payload;
            });
        },
        // 조회할 센서 번호
        setSensorNum: (state, action) => {
            if (!action.payload) {
                return;
            }
            state.sensorNum = action.payload;
        },
        setSensorDetail: (state, action) => {
            state.sensorDetail = action.payload;
        },
        startUpdatingData: state => {
            state.isUpdatingData = true;
        },
        stopUpdatingData: state => {
            state.isUpdatingData = false;
        },
    },
});
export const {
    setRefrigeratorSensorList,
    updateEachRefrigeratorSensorList,
    setRefrigeratorSensorLogList,
    setSensorNum,
    startUpdatingData,
    stopUpdatingData,
} = actions;
export default reducer;

export const menuIcons = [
    'icon-search',
    'icon-info',
    'icon-info-fill',
    'icon-info-itallic',
    // 'icon-zoomin',
    // 'icon-zoomout',
    // 'icon-plusCircle',
    // 'icon-minusCircle',
    // 'icon-hamburger',
    // 'icon-more',

    'icon-person',
    'icon-people',
    'icon-profile-fill',
    'icon-profile',
    'icon-board',
    'icon-setting',
    'icon-alert',
    'icon-warningBalloon',
    'icon-warning',
    'icon-copyright',

    'icon-home',
    'icon-building',
    'icon-camera',
    'icon-phone',
    'icon-mail',
    'icon-email',
    'icon-picture',
    'icon-calendar',
    'icon-clock',
    'icon-globe',

    // 'icon-fullscreen',
    // 'icon-refresh',
    // 'icon-reback',
    // 'icon-replay',
    // 'icon-resize',
    // 'icon-move',
    // 'icon-close',
    // 'icon-check',
    // 'icon-plus',
    // 'icon-minus',

    'icon-edit',
    'icon-delete',
    '    icon-clipboard',
    'icon-clip',
    'icon-history',
    'icon-favorite',
    'icon-file',
    'icon-copy',
    'icon-addBoard',
    'icon-download',

    'icon-wifi',
    'icon-eye',
    'icon-lock',
    'icon-alarm',
    'icon-sound',
    'icon-cctv',
    'icon-movieList',
    'icon-target',
    'icon-download-line',
    'icon-upload-line',

    'icon-heart',
    'icon-star',
    'icon-graph',
    'icon-filter',
    'icon-tool',
    'icon-tools',
    'icon-capture',
    'icon-bluetooth',
    'icon-rain',
    'icon-sun',

    'icon-createtab',
    'icon-newtab',
    'icon-forder',
    'icon-findFile',
    'icon-findFile-fill',
    'icon-customer',
    'icon-disconnect',
    'icon-star-line',
    'icon-heart-line',
    'icon-alarm-line',

    'icon-monitor',
    'icon-sensor',
    'icon-refrigerator',
];

import React, { useContext, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { deleteScreenApi } from '@api/menu';
import ConfirmModal from '@components/ConfirmModal';
import { useAsync, useTranslation } from '@hooks';
import { widgetInfoExport } from '../../../../Components/Screen/util';
import { ScreenListDispatchContext } from '../index';
import { selectScreen } from '../reducer';
import { deleteMenuScreen } from '@reducer/CustomMenu';
import { useDispatch } from 'react-redux';

const ScreenSubMenu = ({ row: { original } }) => {
    const storeDispatch = useDispatch();
    const dispatch = useContext(ScreenListDispatchContext);
    const t = useTranslation('Screen');
    const [deleteModal, setDeleteModal] = useState(false);

    const { promise: deleteScreen } = useAsync({
        promise: deleteScreenApi,
        resolve: () => {
            storeDispatch(deleteMenuScreen(original));
        },
    });

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const handleDelete = screenInfo => {
        deleteScreen(screenInfo);
    };

    return (
        <>
            <UncontrolledDropdown className="w-100">
                <DropdownToggle tag={'div'} className={'flex-center'}>
                    <span className="material-icons-round">menu</span>
                </DropdownToggle>
                <DropdownMenu className={'grid-sub-menu'}>
                    <DropdownItem
                        onClick={() => {
                            dispatch(selectScreen(original));
                        }}
                    >
                        <span className="material-icons-round md-18">info_outline</span>
                        <span className="ml-2">{t('Screen detail')}</span>
                    </DropdownItem>
                    {!isMobile && (
                        <Link to={`/setting/screen/edit/${original.screenCode}`}>
                            <DropdownItem>
                                <span className="material-icons-round md-18">add_to_queue</span>
                                <span className="ml-2">{t('Edit the screen')}</span>
                            </DropdownItem>
                        </Link>
                    )}
                    <DropdownItem onClick={toggleDeleteModal}>
                        <span className="material-icons-round md-18">delete_outline</span>
                        <span className="ml-2">{t('Delete the screen')}</span>
                    </DropdownItem>
                    {/*
                    대시보드 목록 버튼에 내보내기 버튼 추가
                    widgetInfo 데이터가 없을 경우 hidden
                       */}
                    {original.widgetInfo && (
                        <DropdownItem
                            onClick={() =>
                                widgetInfoExport({
                                    filename: `${original.screenName}.json`,
                                    exportWidgetInfo: JSON.parse(original.widgetInfo),
                                })
                            }
                        >
                            <span className="material-icons-round md-18">exit_to_app</span>
                            <span className="ml-2">{t('Export')}</span>
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
            <ConfirmModal
                initModal={deleteModal}
                toggleModal={toggleDeleteModal}
                header={{ title: t('Confirm', 'ConfirmModal') }}
                confirmText={t('Do you want to delete the screen?')}
                okCallback={handleDelete}
                callbackParam={original}
            />
        </>
    );
};

export { ScreenSubMenu };

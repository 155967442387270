import React, { useState, useMemo, useEffect, useContext } from 'react';
import useTranslation from '@util/hooks/useTranslation';
import { useSelector } from 'react-redux';
import TreeSelectWithOption from '../Components/SelectWithOption/TreeSelectWithOption';
import { FilterGeneratorContext } from '../FilterGenerator';

const CategorySelect = ({ selected, isEditable, handleChange }) => {
    const t = useTranslation('Filter');
    const { filterInfo } = useContext(FilterGeneratorContext);
    const [selectedOption, setSelectedOption] = useState(selected);

    const { categoryList } = useSelector(state => state.CategoryInfo);
    const treeData = useMemo(() => {
        const filterCategoryList = filterInfo.categoryCodes ? filterInfo.categoryCodes.split(',') : [];
        return filterCategoryList.length
            ? categoryList.reduce((acc, curr) => {
                  const { categoryCode, parentCode } = curr;
                  if (filterCategoryList.includes(categoryCode) || filterCategoryList.includes(parentCode)) {
                      acc.push({ ...curr, categoryCodes: categoryCode });
                  }
                  return acc;
              }, [])
            : categoryList.map(category => ({ ...category, categoryCodes: category.categoryCode }));
    }, [categoryList, filterInfo.categoryCodes]);

    const handleSelectedOptionChange = (selected, isEditable) => {
        handleChange(selected, isEditable);
        setSelectedOption(selected);
    };

    useEffect(() => {
        if (treeData.length && selected && selected.length) {
            treeData.reduce((acc, curr) => {
                if (selected.includes(curr.categoryCode)) {
                    acc.push(curr.categoryCode);
                }
                return acc;
            }, []);
            setSelectedOption(selected);
        } else {
            setSelectedOption([]);
        }
    }, [selected, treeData]);

    return (
        <TreeSelectWithOption
            data={treeData}
            title={t('Category')}
            valueKey={'categoryCode'}
            parentKey={'parentCode'}
            labelKey={'categoryName'}
            selected={selectedOption}
            onChange={handleSelectedOptionChange}
            isEditable={isEditable}
        />
    );
};

export default CategorySelect;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Hamburger from 'react-hamburgers';
import Button from '@components/Button';
import cx from 'classnames';
import { setEnableMobileMenu, setEnableMobileMenuSmall } from '@reducer/ThemeOptions';

const AppMobileMenu = props => {
    const dispatch = useDispatch();
    const { enableMobileMenu, enableMobileMenuSmall } = useSelector(state => state.ThemeOptions);

    const [activeMobile, setActiveMobile] = useState(false);
    const toggleActiveMobile = () => {
        setActiveMobile(!activeMobile);
    };

    const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] = useState(false);
    const toggleActiveSecondaryMenuMobile = () => {
        setActiveSecondaryMenuMobile(!activeSecondaryMenuMobile);
    };

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    const toggleMobileSmall = () => {
        dispatch(setEnableMobileMenuSmall(!enableMobileMenuSmall));
    };

    return (
        <>
            <div className="app-header__mobile-menu">
                <div className={'pt-1'} onClick={toggleMobileSidebar}>
                    <Hamburger active={enableMobileMenu} type="elastic" onClick={toggleActiveMobile} />
                </div>
            </div>
            <div className="app-header__menu">
                <span onClick={toggleMobileSmall}>
                    <Button
                        className={cx('btn-icon btn-icon-only btn-brand', {
                            active: activeSecondaryMenuMobile,
                        })}
                        iconName={'more_vert'}
                        onClick={toggleActiveSecondaryMenuMobile}
                    />
                </span>
            </div>
        </>
    );
};

export default AppMobileMenu;

import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import Button from '../../../../../Common/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { initUUIDv4 } from '@util/common/util';
import cx from 'classnames';

const DailyTable = ({ columns, rows, maxRowLength, maxHeight, handleActionRow }) => {
    const t = useTranslation('RefrigeratorSensor');
    const tbodyRef = React.useRef();

    // 행 상태 업데이트시 스크롤 하단으로 이동
    const scrollToBottom = useCallback(() => {
        if (tbodyRef.current && tbodyRef.current._container) {
            tbodyRef.current._container.scrollTop = tbodyRef.current._container.scrollHeight;
        }
    }, []);

    return (
        <div>
            <HeaderContainer className="">
                {/* 서울의료원 기준 기능 제거 */}
                {/*{rows.length < maxRowLength && (*/}
                {/*    <AddButtonContainer*/}
                {/*        className="btn-icon custom-border-daily-report"*/}
                {/*        onClick={() => {*/}
                {/*            handleActionRow();*/}
                {/*            scrollToBottom();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <span className="icon-plus icon-small opacity-4" />*/}
                {/*        <span className="text-nowrap">{t('Add', 'Button')}</span>*/}
                {/*    </AddButtonContainer>*/}
                {/*)}*/}
            </HeaderContainer>
            <StyledTable $maxHeight={maxHeight}>
                <thead className="bg-depth-7">
                    <tr className="border-bottom">
                        {columns.map((item, idx) => {
                            return (
                                <StyledTh key={idx} $width={item.width} className={cx(idx !== 0 && 'border-left')}>
                                    <span>{item.header ? t(item.header) : ''}</span>
                                </StyledTh>
                            );
                        })}
                    </tr>
                </thead>
                <PerfectScrollbar ref={tbodyRef} component={'tbody'}>
                    {rows.map((rowItem, rowIdx) => {
                        // 이상온도 조건 : 현재는 0 도 미만일때 row 의 font color 를 빨간색으로 변경
                        const temperatureWarning = Number(rowItem.currentValue.props.initValue) < 0;
                        return (
                            <tr
                                key={`${initUUIDv4()}_${rowIdx}`}
                                className={cx(rowIdx < rows.length - 1 && 'border-bottom')}
                            >
                                {columns.map((columnItem, colIdx) => {
                                    const component =
                                        typeof rowItem[columnItem.accessor] === 'function'
                                            ? rowItem[columnItem.accessor](rowIdx)
                                            : rowItem[columnItem.accessor];
                                    return (
                                        <StyledTd
                                            key={colIdx}
                                            className={cx(colIdx !== 0 && 'border-left')}
                                            $width={columnItem.width}
                                            $temperatureWarning={temperatureWarning}
                                        >
                                            {component}
                                        </StyledTd>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </PerfectScrollbar>
            </StyledTable>
        </div>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    padding-left: 0.625rem;
    justify-content: space-between;
    border-bottom: none !important;
`;
const AddButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    min-width: 4.5rem;
    padding: 0 0.625rem 0 0.25rem;

    &:hover {
        cursor: pointer;
        background-color: rgba(155, 155, 155, 0.4);
    }
`;
const StyledTable = styled.table`
    width: 100%;

    thead {
        position: sticky;
        top: 0;

        tr {
            border-bottom-color: white;
        }
    }

    tbody {
        display: block;
        td:nth-child(3) > input {
            text-align: left !important;
            text-overflow: ellipsis;
        }
        td > div:last-child:hover {
            background-color: rgba(155, 155, 155, 0.4) !important;
        }
    }

    tr {
        display: flex;
        align-content: center;

        &:last-child {
            border-bottom: none;
        }
    }

    th,
    td {
        min-height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        &:first-child {
            border-left: none;
        }
    }
`;
const StyledTh = styled.th`
    flex: ${props => (props.$width ? (1 * props.$width) / 100 : 1)};
`;
const StyledTd = styled.td`
    flex: ${props => (props.$width ? (1 * props.$width) / 100 : 1)};
    & > input {
        ${props =>
            props.$temperatureWarning &&
            css`
                color: red !important;
            `}
    }
`;

export default DailyTable;

import React, { useState } from 'react';
import Button from '../Button';
import Label from '../Label';
import Card from '../Card';
import TextInput from '../Input/TextInput';
import DateInput from '../Input/DateInput';
import Select from '../Select';
import SearchableSelect from '../Select/SearchableSelect';
import TreeSelect from '../Select/TreeSelect';
import styled from 'styled-components';
import DatePicker from '../DatePicker';
import Tab from '../Tab';
import RadioButton from '../Input/RadioButton';
import Nameplate from '../Nameplate';
import Checkbox from '../Input/Checkbox';
import ToggleButton from '../Button/ToggleButton';
import TabWithTitle from '../Tab/TabWithTitle';
import ConfirmModal from '../ConfirmModal';
import * as PropTypes from 'prop-types';
import { Portal } from 'react-overlays';
import Modal from '../Modal';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import useConfirmModal from '../../../util/hooks/useConfirmModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MultiSelect from '@components/Select/MultiSelect';

const DisplayRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 5rem;
    justify-content: space-between;
    align-items: start;
    width: 100%;
`;

Portal.propTypes = {
    container: PropTypes.any,
    children: PropTypes.node,
};

const Modules = () => {
    // For Button
    const handleButtonClick = e => {
        e.preventDefault();
        alert('button clicked');
    };

    // For Card Overlay
    const cardOverlay = false;

    // For Input
    const [input, setInput] = useState({
        textInput: '',
        dateInput: '',
    });
    const handleChange = e => {
        setInput({ ...input, [e.target.name]: e.target.value });
    };

    //For Select
    const options = [
        { value: 'chocolate', label: 'Chocolate', test: '123' },
        { value: 'strawberry', label: 'Strawberry', test: '123' },
        { value: 'vanilla', label: 'Vanilla', test: 234 },
    ];

    const [selectedOption, setSelectedOption] = useState(null);
    const [radioButton, setRadioButton] = useState('MSG_NO_RES');

    const handleSelectValueChange = value => {
        setSelectedOption(value);
    };

    const options2 = [
        { value: 'chocolate', label: 'Chocolate', test: '123' },
        { value: 'strawberry', label: 'Strawberry', test: '123' },
        { value: 'vanilla', label: 'Vanilla', test: 234 },
        { value: 'chocolate2', label: 'Chocolate2', test: '123' },
        { value: 'strawberry2', label: 'Long Strawberry2 Long Strawberry2', test: '123' },
        { value: 'vanilla2', label: 'Vanilla2', test: 234 },
        { value: 'chocolate3', label: 'Chocolate3', test: '123' },
        { value: 'strawberry3', label: 'Strawberry3', test: '123' },
        { value: 'vanilla3', label: 'Vanilla3', test: 234 },
        { value: 'chocolate4', label: 'Chocolate4', test: '123' },
        { value: 'strawberry4', label: 'Strawberry4', test: '123' },
        { value: 'vanilla4', label: 'Vanilla4', test: 234 },
    ];

    const [selectedOption2, setSelectedOption2] = useState(null);

    const handleSelectValueChange2 = selected => {
        setSelectedOption2(selected.map(v => v.value));
    };

    const options3 = [
        { value: '1', label: '1' },
        { value: '1-1', label: '1-1', parent: '1' },
        { value: '1-2', label: '1-2', parent: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '3-1', label: '3-1', parent: '3' },
        { value: '3-1-1', label: '3-1-1', parent: '3-1' },
        { value: '3-1-2', label: '3-1-2', parent: '3-1' },
        { value: '3-1-2-1', label: '3-1-2-1', parent: '3-1-2' },
        { value: '3-1-2-2', label: '3-1-2-2', parent: '3-1-2' },
        { value: '3-1-2-3', label: '3-1-2-3', parent: '3-1-2' },
        { value: '4', label: '4' },
    ];

    // const treeOptions = [
    //     {
    //         value: '1',
    //         label: '1',
    //         children: [
    //             { value: '1-1', label: '1-1', parent: '1' },
    //             { value: '1-2', label: '1-2', parent: '1' },
    //         ],
    //     },
    //     { value: '2', label: '2' },
    //     {
    //         value: '3',
    //         label: '3',
    //         children: [
    //             {
    //                 value: '3-1',
    //                 label: '3-1',
    //                 parent: '3',
    //                 children: [
    //                     { value: '3-1-1', label: '3-1-1', parent: '3-1' },
    //                     {
    //                         value: '3-1-2',
    //                         label: '3-1-2',
    //                         parent: '3-1',
    //                         children: [
    //                             { value: '3-1-2-1', label: '3-1-2-1', parent: '3-1-2' },
    //                             { value: '3-1-2-2', label: '3-1-2-2', parent: '3-1-2' },
    //                             { value: '3-1-2-3', label: '3-1-2-3', parent: '3-1-2' },
    //                         ],
    //                     },
    //                 ],
    //             },
    //         ],
    //     },
    //     { value: '4', label: '4' },
    // ];

    const [selectedOption3, setSelectedOption3] = useState([]);
    const [checkedCheckBox, setCheckedCheckBox] = useState(false);
    const [checkedToggleBtn, setCheckedToggleBtn] = useState(false);

    const handleSelectValueChange3 = selected => {
        setSelectedOption3(selected);
    };

    const [selectedDate, setSelectedDate] = useState(new Date() / 1000);
    const handleDateChange = selected => {
        setSelectedDate(selected);
    };
    const [selectedMsDate, setSelectedMsDate] = useState(new Date());
    const handleMsDateChange = selected => {
        setSelectedMsDate(selected);
    };

    const handleCheckedCheckBox = e => {
        setCheckedCheckBox(e.target.checked);
    };

    const handleCheckedToggleBtn = e => {
        setCheckedToggleBtn(e.target.checked);
    };

    // confirmModal && Modal
    const container = document.body;
    const [modalShow, setModalShow] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);

    const { Modal: UseConfirmModalShow, toggleModal: setUseConfirmModalShow } = useConfirmModal({
        initModal: false,
        header: { title: 'UseConfirmModal' },
        confirmText: 'UseConfirmModal',
    });

    // Toast
    const [toastShow, setToastShow] = useState(false);

    return (
        <PerfectScrollbar>
            <div>
                {/*For Button*/}
                <DisplayRow>
                    <div>
                        <p>Button</p>
                        <div>
                            <Button onClick={handleButtonClick}>Button</Button>
                        </div>
                    </div>
                    <div>
                        <p>Button (form-disable)</p>
                        <div>
                            <Button onClick={handleButtonClick} disabled>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Button (form-h-small)</p>
                        <div>
                            <Button className={'form-h-small'} onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Button (form-h-big)</p>
                        <div>
                            <Button className={'form-h-big'} onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Button (with Icon)</p>
                        <div>
                            <Button className={'btn-icon'} onClick={handleButtonClick} iconName="menu">
                                hamburger
                            </Button>
                        </div>
                    </div>

                    <div>
                        <p>Button (with Icon Only)</p>
                        <div>
                            <Button className={'btn-icon-only'} onClick={handleButtonClick} iconName="close" />
                        </div>
                    </div>
                    <div>
                        <p>Button (with shadow)</p>
                        <div>
                            <Button className={'btn-shadow'} onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                </DisplayRow>
                <DisplayRow>
                    <div>
                        <p>btn-brand</p>
                        <div>
                            <Button className="btn-brand" onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>btn-secondary</p>
                        <div>
                            <Button className="btn-secondary" onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>btn-danger</p>
                        <div>
                            <Button className="btn-danger" onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>btn-lightgray</p>
                        <div>
                            <Button className="btn-lightgray" onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>btn-gray</p>
                        <div>
                            <Button className="btn-gray" onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>btn-darkgray</p>
                        <div>
                            <Button className="btn-darkgray" onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>btn-line</p>
                        <div>
                            <Button className="btn-line" onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>btn-trans</p>
                        <div>
                            <Button className="btn-trans" onClick={handleButtonClick}>
                                Button
                            </Button>
                        </div>
                    </div>
                </DisplayRow>
                <div className="pnt-divider horizon-line" />
                {/*For Label*/}
                <DisplayRow>
                    <div>
                        <p>Label Group without value</p>
                        <Label name={'test'} />
                    </div>

                    <div>
                        <p>Label Group with value</p>
                        <Label name={'test'} value={'optionsss'} />
                    </div>

                    <div>
                        <p>Label with class</p>
                        <Label name={'test'} labelValueClassName={'label-dot'} />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                {/* For Input */}
                <DisplayRow>
                    <div>
                        <p>Text Input without button</p>
                        <TextInput
                            type={'text'}
                            name={'textInput'}
                            value={input.textInput}
                            handleChange={handleChange}
                        />
                    </div>

                    <div>
                        <p>Text Input with button</p>
                        <TextInput
                            type={'text'}
                            name={'textInput'}
                            value={input.textInput}
                            handleChange={handleChange}
                            buttonIcon="add"
                        />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                <DisplayRow>
                    <div>
                        <p>Select</p>
                        <Select
                            name={'selectInput'}
                            value={selectedOption}
                            onChange={handleSelectValueChange}
                            options={options}
                        />
                    </div>

                    <div>
                        <p>Select with icon</p>
                        <Select
                            name={'selectInput'}
                            value={selectedOption}
                            iconName="icecream"
                            onChange={handleSelectValueChange}
                            options={options}
                        />
                    </div>

                    <div>
                        <p>Multi Select</p>
                        <MultiSelect
                            options={options2}
                            values={selectedOption2}
                            onChange={handleSelectValueChange2}
                            title={'Category'}
                        />
                    </div>

                    <div>
                        <p>Searchable Select</p>
                        <SearchableSelect
                            data={options2}
                            selected={selectedOption2}
                            onChange={handleSelectValueChange2}
                            title={'Category'}
                        />
                    </div>

                    <div>
                        <p>Tree Select</p>
                        <TreeSelect
                            data={options3}
                            title={'Category'}
                            valueKey={'value'}
                            labelKey={'label'}
                            parentKey={'parent'}
                            selected={selectedOption3}
                            onChange={handleSelectValueChange3}
                        />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                <DisplayRow>
                    <div>
                        <p>DateInput</p>
                        <DateInput
                            name={'dateInput'}
                            value={input.dateInput}
                            handleDateChange={handleChange}
                            className={'w-100'}
                        />
                    </div>
                    <div>
                        <p>DatePicker (Time Select)</p>
                        <DatePicker value={selectedDate} handleChange={handleDateChange} showTimeSelect />
                    </div>
                    <div>
                        <p>DatePicker (Time Input)</p>
                        <DatePicker
                            value={selectedMsDate}
                            handleChange={handleMsDateChange}
                            valueType={'ms'}
                            showTimeInput
                        />
                    </div>
                    <div>
                        <p>DatePicker (without Time)</p>
                        <DatePicker value={selectedDate} handleChange={handleDateChange} withoutTime />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                <DisplayRow>
                    <div>
                        <p>Radio (form-h-small)</p>
                        <RadioButton
                            radioGroupClassName={'form-h-small'}
                            name={'msgType'}
                            radioList={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                            value={radioButton}
                            onChange={selected => setRadioButton(selected)}
                        />
                    </div>
                    <div>
                        <p>Radio (default)</p>
                        <RadioButton
                            name={'msgType'}
                            radioList={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                            value={radioButton}
                            onChange={selected => setRadioButton(selected)}
                        />
                    </div>
                    <div>
                        <p>Radio (form-h-big)</p>
                        <RadioButton
                            radioGroupClassName={'form-h-big'}
                            name={'msgType'}
                            radioList={[
                                { value: 'MSG_NO_RES', label: 'No reply' },
                                { value: 'MSG_RES', label: 'Reply' },
                                { value: 'MSG_EMERGENCY', label: 'Urgent' },
                            ]}
                            value={radioButton}
                            onChange={selected => setRadioButton(selected)}
                        />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                <DisplayRow>
                    <div>
                        <p>Nameplate (basic)</p>
                        <Nameplate name={'nameplate'} />
                    </div>
                    <div>
                        <p>Nameplate (with icon)</p>
                        <Nameplate name={'nameplate'} icon={'face'} />
                    </div>
                    <div>
                        <p>Nameplate (form-h-small)</p>
                        <Nameplate name={'nameplate'} className={'form-h-small'} />
                    </div>
                    <div>
                        <p>Nameplate (form-h-big)</p>
                        <Nameplate name={'nameplate'} className={'form-h-big'} />
                    </div>
                    <div>
                        <p>Nameplate (max-w-none)</p>
                        <Nameplate name={'People and Technology'} className={'max-w-none'} />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                <DisplayRow>
                    <div>
                        <p>Checkbox (basic)</p>
                        <Checkbox
                            name={'test1'}
                            id={'test1'}
                            checked={checkedCheckBox}
                            handleChecked={handleCheckedCheckBox}
                        />
                    </div>
                    <div>
                        <p>Checkbox (form-disable)</p>
                        <Checkbox name={'test2'} id={'test2'} disabled={true} />
                    </div>
                    <div>
                        <p>Checkbox (form-h-small)</p>
                        <Checkbox
                            name={'test3'}
                            id={'test3'}
                            checked={checkedCheckBox}
                            handleChecked={handleCheckedCheckBox}
                            className={'form-h-small'}
                        />
                    </div>
                    <div>
                        <p>Checkbox (form-h-big)</p>
                        <Checkbox
                            name={'test4'}
                            id={'test4'}
                            checked={checkedCheckBox}
                            handleChecked={handleCheckedCheckBox}
                            className={'form-h-big'}
                        />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                <DisplayRow>
                    <div>
                        <p>Toggle </p>
                        <ToggleButton checked={checkedToggleBtn} handleChecked={handleCheckedToggleBtn} />
                    </div>
                    <div>
                        <p>Toggle (form-disable)</p>
                        <ToggleButton checked={checkedToggleBtn} handleChecked={handleCheckedToggleBtn} disabled />
                    </div>
                    <div>
                        <p>Toggle (form-h-small)</p>
                        <ToggleButton
                            checked={checkedToggleBtn}
                            handleChecked={handleCheckedToggleBtn}
                            className={'form-h-small'}
                        />
                    </div>
                    <div>
                        <p>Toggle (form-h-big)</p>
                        <ToggleButton
                            checked={checkedToggleBtn}
                            handleChecked={handleCheckedToggleBtn}
                            className={'form-h-big'}
                        />
                    </div>
                    <div>
                        <p>Toggle (with text)</p>
                        <ToggleButton
                            checked={checkedToggleBtn}
                            handleChecked={handleCheckedToggleBtn}
                            className={'toggle-txt'}
                        />
                    </div>
                    <div>
                        <p>Toggle (with long txt)</p>
                        <ToggleButton
                            checked={checkedToggleBtn}
                            handleChecked={handleCheckedToggleBtn}
                            className={'toggle-txt toggle-txt-long'}
                            offText={'off long text'}
                            onText={'on long text'}
                        />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />
                {/* For Card */}
                <DisplayRow>
                    <div>
                        <p>Card without titleFunction</p>
                        <Card header={{ title: 'card test' }}>
                            <div>body jsx element test</div>
                        </Card>
                    </div>

                    <div>
                        <p>Card with header title sub & titleFunction</p>
                        <Card
                            header={{
                                title: 'card test',
                                subTitle: 'I am header title Sub',
                                action: (
                                    <Button
                                        className={'btn-danger btn-icon btn-shadow'}
                                        onClick={handleButtonClick}
                                        iconName="remove_red_eye"
                                    >
                                        check
                                    </Button>
                                ),
                            }}
                        >
                            <div>
                                Card title function has a common Button Component. If you click the title button, you
                                can see 'button clicked' on alert Please check the console after you click the button{' '}
                            </div>
                        </Card>
                    </div>

                    <div>
                        <p>Card with overlay</p>
                        <Card
                            header={{
                                title: 'card test',
                                subTitle: 'I am header title Sub',
                            }}
                            overlayOn={cardOverlay}
                            overlay={{
                                title: 'overlay Title',
                                content: <p>Hello, I am card overlay body :) </p>,
                            }}
                        >
                            <div>
                                Card title function has a common Button Component. If you click the title button, you
                                can see card Overlay.{' '}
                            </div>
                        </Card>
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                <DisplayRow>
                    <div style={{ width: '48%' }}>
                        <p>Tab component style 1 (without title)</p>
                        <Tab
                            tabs={[
                                {
                                    title: 'Status Schema',
                                    tabId: 't1',
                                    content: <div>All</div>,
                                },
                                {
                                    title: 'Status',
                                    tabId: 't2',
                                    content: (
                                        <div className="card-header mb-4 pl-0 pr-0">
                                            <div className="card-header__title">
                                                <h3 className="title__main">List : Status</h3>
                                                <p className="title__sub">자산의 상태 목록 조회 기능입니다.</p>
                                            </div>
                                            <div className="card-header__function">
                                                <Button className="btn-brand btn-icon" iconName="add">
                                                    Add Status
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Status Category',
                                    tabId: 't3',
                                    content: <div>Status Category</div>,
                                },
                            ]}
                        />
                    </div>

                    <div style={{ width: '48%' }}>
                        <p>Tab component style 1 (without title & header style)</p>
                        <Tab
                            styles={{ header: { justifyContent: 'center' }, headerItem: { width: '30%' } }}
                            tabs={[
                                {
                                    title: 'Status Schema',
                                    tabId: 't1',
                                    content: (
                                        <div className="card-header mb-4 pl-0 pr-0">
                                            <div className="card-header__title">
                                                <h3 className="title__main">List : Status</h3>
                                                <p className="title__sub">자산의 상태 목록 조회 기능입니다.</p>
                                            </div>
                                            <div className="card-header__function">
                                                <Button className="btn-brand btn-icon" iconName="add">
                                                    Add Status
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Status',
                                    tabId: 't2',
                                    content: <div>Status Tab</div>,
                                },
                                {
                                    title: 'Status Category',
                                    tabId: 't3',
                                    content: <div>Status Category</div>,
                                },
                            ]}
                        />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                <DisplayRow>
                    <div>
                        <p>Tab component style 2 (with title)</p>
                        <TabWithTitle
                            title={'Hello world'}
                            tabs={[
                                {
                                    title: 'Status Schema',
                                    tabId: 't1',
                                    content: (
                                        <div className="card-header mb-4 pl-0 pr-0">
                                            <div className="card-header__title">
                                                <h3 className="title__main">List : Status</h3>
                                                <p className="title__sub">자산의 상태 목록 조회 기능입니다.</p>
                                            </div>
                                            <div className="card-header__function">
                                                <Button className="btn-brand btn-icon" iconName="add">
                                                    Add Status
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Status',
                                    tabId: 't2',
                                    content: <div>Status Tab</div>,
                                },
                                {
                                    title: 'Status Category',
                                    tabId: 't3',
                                    content: <div>Status Category</div>,
                                },
                            ]}
                        />
                    </div>
                </DisplayRow>

                <div className="pnt-divider horizon-line" />

                {/*confirmModal && Modal*/}
                <DisplayRow>
                    <div>
                        <p>Confirm Modal</p>
                        <Button
                            className={'btn-secondary form-h-big'}
                            onClick={() => setConfirmModalShow(!confirmModalShow)}
                        >
                            ConfirmModal
                        </Button>
                    </div>
                    <div>
                        <p>useConfirm Modal</p>
                        <Button className={'btn-secondary form-h-big'} onClick={setUseConfirmModalShow}>
                            useConfirmModal
                        </Button>
                    </div>
                    <div>
                        <p>Modal</p>
                        <Button className={'btn-secondary form-h-big'} onClick={() => setModalShow(!modalShow)}>
                            Modal
                        </Button>
                    </div>
                    <Portal container={container}>
                        <ConfirmModal
                            initModal={confirmModalShow}
                            toggleModal={setConfirmModalShow}
                            header={{ title: 'Checked' }}
                            confirmText={'Are you sure?'}
                        />
                        <Modal initModal={modalShow} toggleModal={setModalShow} bodyText={"I'm the Modal!"} />
                        <UseConfirmModalShow />
                    </Portal>
                </DisplayRow>
                {/*reactstrap Toast*/}
                <DisplayRow>
                    <div>
                        <p>Reactstrap Toast</p>
                        <Button
                            id="liveToastBtn"
                            className={'btn-secondary form-h-big'}
                            onClick={() => {
                                if (!toastShow) {
                                    setTimeout(() => setToastShow(false), 2000);
                                }
                                setToastShow(!toastShow);
                            }}
                        >
                            Toast
                        </Button>
                        <Toast
                            isOpen={toastShow}
                            className="p-3 bg-secondary my-2"
                            transition={{ in: toastShow, timeout: 150 }}
                            // 화면 하단 오른쪽에 위치
                            style={{ position: 'absolute', right: '0', bottom: '3rem' }}
                        >
                            <ToastHeader>Sample Toast Header</ToastHeader>
                            <ToastBody>Sample Toast Body Text</ToastBody>
                        </Toast>
                    </div>
                </DisplayRow>
            </div>
        </PerfectScrollbar>
    );
};

export default Modules;

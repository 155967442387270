import React, { useContext, useState } from 'react';
import { editWidgetSettings } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { TagState } from '../Components/WidgetSettingOptions';
import useTranslation from '../../../util/hooks/useTranslation';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';

const TagStateAlarmListSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('TagStateAlarmList');
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useContext(ScreenEditDispatchContext);

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleTagStateSelectChange = selected => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, tagState: selected.map(v => v.value) };
        });
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Tag State Alarm List Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <TagState
                value={
                    updatedWidgetInfo.tagState
                        ? updatedWidgetInfo.tagState
                        : customSettings && customSettings.tagState
                        ? customSettings.tagState
                        : null
                }
                onChange={handleTagStateSelectChange}
            />
        </WidgetSettingModal>
    );
};

export default TagStateAlarmListSetting;

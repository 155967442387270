import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from './useSettings';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import TIMES from '@util/times';
import { useAppSelector, useAsync } from '@hooks';

const useMonitor = ({
    config,
    paramInfo = [],
    dynamicParam,
    defaultData,
    manualMonitor = false,
    fetchData,
    makeData = ({ newData }) => {
        return newData;
    },
    updateParamsBeforeRequest = ({ beforeParams }) => {
        return { ...beforeParams };
    },
    errorHandler = ({ error }) => {
        return error;
    },
}) => {
    const { mode } = useSelector(state => state.ScreenInfo);
    const { monitoring, globalIntervalTime } = useSelector(state => state.GlobalIntervalTime);
    const apiRequestDebounceTime = useAppSelector(state => state.AppInfo.apiRequestDebounceTime);
    const monitorTO = useRef(0);
    const requestDebounceTO = useRef(0);
    //초기 상탯값을 최초 렌더싱시에만 계산하기위해서 initialState(지연 초기 state, Lazy initial state)를 사용함
    const [data, setData] = useState(() => makeData({ newData: mode === SCREEN_MODE_MONITORING ? {} : defaultData }));
    const settings = useSettings(config);
    const { state, promise } = useAsync({ promise: fetchData });

    const monitor = () => {
        const param = {};
        paramInfo.forEach(paramKey => {
            if (settings[paramKey]) {
                param[paramKey] = settings[paramKey];
            }
        });
        // 전역 모니터링 상태가 아닐 경우
        if (!monitoring || globalIntervalTime === TIMES.ZERO) {
            return;
        }
        // 수동 모니터링 상태인 경우 (SH 프로젝트에서 미사용)
        // if (manualMonitor) {
        //     return;
        // }
        const updateParams = updateParamsBeforeRequest({ beforeParams: { ...param, ...dynamicParam } });
        promise(updateParams);
        monitorTO.current = setTimeout(monitor, settings.intervalTime);
    };

    const stop = () => {
        clearTimeout(monitorTO.current);
        clearTimeout(requestDebounceTO.current);
    };

    useEffect(() => {
        stop();
        // monitoring mode가 아닐 경우
        if (mode !== SCREEN_MODE_MONITORING) {
            setData(makeData({ newData: defaultData }));
            return;
        }
        requestDebounceTO.current = setTimeout(() => {
            monitor();
        }, apiRequestDebounceTime);

        return () => {
            // unmount시 setTimeout clear
            stop();
        };
    }, [mode, apiRequestDebounceTime, monitoring, settings, dynamicParam]);

    useEffect(() => {
        if (state.response) {
            setData(makeData({ newData: state.response }));
        } else if (state.error) {
            errorHandler({ error: state.error });
        }
    }, [state]);

    return { monitor, stop, data };
};

export default useMonitor;

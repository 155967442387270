import React, { createContext, useReducer, useRef, useState } from 'react';
import { Button, DatePicker } from '@components';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { fetchHeatMapLocationLogList } from '@api/log';
import FilterSearchGroup from '../../MainPages/Components/FilterSearchGroup';
import { FilterList, SelectGroup } from '../../MainPages/Components/FilterSearchGroup/Components/Part';
import 'nouislider/distribute/nouislider.css';
import FloorTreeSelect from '../Components/FloorTreeSelect';
import { fetchFloorList, fetchSelectedFloorInfo } from '@api/common';
import { setFloorList } from '@reducer/Common/FloorInfo';
import { useDispatch, useSelector } from 'react-redux';
import PlayController from './Components/PlayController';
import heatMapReducer, {
    initialState,
    setDetectedTargets,
    setEndTime,
    setLog,
    setSelectedFloor,
    setStartTime,
} from './heatMapReducer';
import HeatMap from './Components/HeatMap';
import moment from 'moment';
import SearchGroup from '../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import WidgetCard from '../Components/WidgetCard';

export const HeatMapDispatchContext = createContext();
export const HeatMapStateContext = createContext();

const AnalysisHeatMap = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('ConfirmModal');
    const widgetRef = useRef();
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(heatMapReducer, initialState);
    const { floorList } = useSelector(state => state.FloorInfo);

    const { promise: getDetectedTargetList } = useAsync({
        immediate: true,
        promise: fetchHeatMapLocationLogList,
        resolve: res => {
            const { additionalDatas, rows } = res;
            dispatch(setDetectedTargets(additionalDatas.detectedTargets));
            dispatch(setLog(rows));
            if (rows.length) {
                dispatch(setStartTime(rows[rows.length - 1]['regDate']));
                dispatch(setEndTime(rows[0]['regDate']));
            }
        },
        reject: err => {
            console.log(err);
        },
    });

    useAsync({
        promise: fetchFloorList,
        resolve: res => {
            storeDispatch(setFloorList(res));
            const { rows } = res;
            if (rows.length) {
                getFloorInfo({ floorId: rows[0].floorId });
            }
        },
        reject: err => {
            console.log(err);
        },
        immediate: true,
    });

    const { promise: getFloorInfo } = useAsync({
        promise: fetchSelectedFloorInfo,
        resolve: res => {
            dispatch(setSelectedFloor(res));
        },
        reject: err => {
            console.log(err);
        },
    });

    const [showSearchArea, setShowSearchArea] = useState(false);
    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const [floor, setFloor] = useState(null);
    const [floorName, setFloorName] = useState('');
    const handleFloorChange = selected => {
        const { floorId, floorName } = selected[0];
        setFloor(floorId);
        setFloorName(floorName);
    };

    const [startDate, setStartDate] = useState(null);
    const handleStartDateChange = selected => {
        setStartDate(selected);
    };

    const [endDate, setEndDate] = useState(null);
    const handleEndDateChange = selected => {
        setEndDate(selected);
    };

    const { Modal: SearchResultModal, toggleModal: toggleSearchResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result') },
        confirmText: t('Please specify a start and end date'),
    });
    const handleSearchClick = () => {
        if (startDate && endDate) {
            getDetectedTargetList({ startDate, endDate, floor });
            getFloorInfo({ floorId: floor });
        } else {
            toggleSearchResultModal();
        }
    };

    const handleRefreshClick = () => {
        setStartDate(null);
        setEndDate(null);
        setFloor(null);
    };

    return (
        <>
            <HeatMapDispatchContext.Provider value={dispatch}>
                <HeatMapStateContext.Provider value={state}>
                    <WidgetCard
                        bodyClassName={'p-1'}
                        widgetInfo={widgetInfo}
                        ref={widgetRef}
                        headerAction={
                            <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                                {t('Search', 'Button')}
                            </Button>
                        }
                        searchFilter={
                            showSearchArea && (
                                <FilterSearchGroup className={'card absolute-filter h-100 justify-content-center'}>
                                    <SearchGroup label={t('Search', 'AssetCountStatus')}>
                                        <FilterList className={'w-100'}>
                                            <SelectGroup>
                                                <div className={'datePicker-container'}>
                                                    <DatePicker
                                                        value={startDate}
                                                        handleChange={handleStartDateChange}
                                                        showTimeSelect
                                                    />
                                                </div>
                                            </SelectGroup>
                                            <SelectGroup>
                                                <div className={'datePicker-container'}>
                                                    <DatePicker
                                                        value={endDate}
                                                        handleChange={handleEndDateChange}
                                                        showTimeSelect
                                                        disabled={!startDate}
                                                        maxDate={moment().valueOf()}
                                                    />
                                                </div>
                                            </SelectGroup>
                                            <SelectGroup>
                                                <FloorTreeSelect
                                                    selectedFloorStr={floorName}
                                                    floorList={floorList}
                                                    mode={'radioSelect'}
                                                    handleChange={handleFloorChange}
                                                />
                                            </SelectGroup>
                                            <Button
                                                disabled={(!startDate && !endDate) || !floorName}
                                                className={'btn-secondary btn-icon'}
                                                onClick={handleSearchClick}
                                            >
                                                {t('Search', 'Search')}
                                            </Button>
                                            <Button
                                                className="btn-line btn-icon-only"
                                                iconName="refresh"
                                                onClick={handleRefreshClick}
                                            />
                                        </FilterList>
                                    </SearchGroup>
                                </FilterSearchGroup>
                            )
                        }
                        {...restProps}
                    >
                        <div className={'h-100 w-100'}>
                            <HeatMap ref={widgetRef} />
                            <PlayController />
                        </div>
                        {children}
                    </WidgetCard>
                </HeatMapStateContext.Provider>
            </HeatMapDispatchContext.Provider>
            <SearchResultModal />
        </>
    );
};

export default AnalysisHeatMap;

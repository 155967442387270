import React, { useContext, useEffect, useRef } from 'react';
import { Label, Map, RotatedImageOverlay } from '../../../Common';
import { useTranslation } from '@hooks';
import { RealTimeSensorMonitoringContext, RealTimeSensorMonitoringDispatchContext } from '../index';
import { useSelector } from 'react-redux';
import { setFloorInfo } from '../realTimeSensorMonitoringReducer';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import moment from 'moment';

const SelectedSensor = () => {
    const t = useTranslation('RealTimeSensorMonitoring');
    const { selectedSensor, floorInfo } = useContext(RealTimeSensorMonitoringContext);
    const dispatch = useContext(RealTimeSensorMonitoringDispatchContext);
    const { selectableFloorList } = useSelector(state => state.FloorInfo);

    const mapRef = useRef();

    useEffect(() => {
        dispatch(setFloorInfo(selectableFloorList.find(floor => floor.floorId === selectedSensor.floorId)));
        const map = mapRef.current.leafletElement;
        if (map) {
            map.setView([selectedSensor.lat, selectedSensor.lng]);
        }
    }, []);

    return (
        <>
            <div>
                <div className={'pnt-txt txt-bold txt-dot'}>{t('Location Information')}</div>

                <div className="pnt-divider horizon-line border-bold-2 mb-4" />

                <div className={'mx-1 mb-4 pnt-label-7 row'}>
                    <div className="col-xl-6 col-lg-12 mb-4">
                        <Label
                            name={t('Target Name')}
                            value={selectedSensor.targetName || '-'}
                            labelValueClassName={'pnt-txt txt-bold txt-dot'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-4">
                        <Label
                            name={t('Sensor Name')}
                            value={selectedSensor.sensorName || '-'}
                            labelValueClassName={'pnt-txt txt-bold txt-dot'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-4">
                        <Label
                            name={t('Current Floor')}
                            value={floorInfo.floorName || '-'}
                            labelValueClassName={'pnt-txt txt-bold txt-dot'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-4">
                        <Label
                            name={t('Measuring Time')}
                            value={
                                selectedSensor.lastDate === 0
                                    ? '-'
                                    : moment.unix(selectedSensor.lastDate).format('YYYY-MM-DD HH:mm:ss')
                            }
                            labelValueClassName={'pnt-txt txt-bold txt-dot'}
                        />
                    </div>
                </div>

                <div className="pnt-border border-w border-bold mb-4" />

                <Map ref={mapRef} style={{ height: '100vh' }}>
                    {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                        <RotatedImageOverlay
                            key={floorInfo.floorId}
                            url={floorInfo.imgURL}
                            deg={floorInfo.deg}
                            bounds={floorInfo.bounds}
                            onLoad={() => {
                                const map = mapRef.current.leafletElement;
                                if (map) {
                                    map.setView([selectedSensor.lat, selectedSensor.lng]);
                                }
                            }}
                        />
                    )}

                    <Marker
                        key={selectedSensor.targetId}
                        position={[selectedSensor.lat, selectedSensor.lng]}
                        icon={L.divIcon({
                            className: 'custom-asset-marker',
                            html: `<div></div>`,
                        })}
                    />
                </Map>
            </div>
        </>
    );
};

export default SelectedSensor;

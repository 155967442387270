import React from 'react';
import cx from 'classnames';

const PageTitle = ({
    heading,
    subheading,
    enablePageTitleIcon,
    enablePageTitleSubheading,
    titleAction,
    customIcon,
    ...rest
}) => {
    return (
        <div className={cx('app-page-title', rest.className)} style={rest.style}>
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        {customIcon ? customIcon : <span className="material-icons-round">edit</span>}
                    </div>
                    <h5 className="mb-0">{heading}</h5>
                    <p className={cx('page-title-subheading ml-3', { 'd-none': !enablePageTitleSubheading })}>
                        {subheading}
                    </p>
                </div>
                <div className="page-title-actions">{titleAction}</div>
            </div>
        </div>
    );
};

export default PageTitle;

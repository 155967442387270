import React, { useState, useRef, useMemo } from 'react';
import Card from '../Components/WidgetCard';
import { Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import { useTranslation } from '@hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import { convertState, setTagStatus } from '@reducer/TagInfo/processSocketData';

const CountWrapper = ({ children }) => {
    return <Col className={'widget-item-nums'}>{children}</Col>;
};

const TagCount = ({ start = 0, end = 0, label }) => {
    return (
        <div className={'col view flex-center'} style={{ flexDirection: 'column', height: '100%' }}>
            <CountUp
                className={'font-weight-bold flex-center'}
                style={{ fontSize: '2.5rem' }}
                start={start}
                end={end}
                separator=""
                decimals={0}
                decimal=","
                prefix=""
                useEasing={false}
                suffix=""
                duration="1"
            />
            <h6
                className={'mt-2'}
                style={{ fontWeight: 500, fontSize: '0.88rem', textAlign: 'center', wordBreak: 'keep-all' }}
            >
                {label}
            </h6>
        </div>
    );
};

const INIT_COUNT_DATA = {
    recognized: 0,
    sos: 0,
    lostSignal: 0,
    lowBattery: 0,
    longStay: 0,
};

const TagStatus = ({ children, widgetInfo, ...restProps }) => {
    // const { config } = widgetInfo;
    const t = useTranslation('TagStatus');
    const filterConfig = useMemo(() => ({}), []);
    const tagStatus = useRef({
        recognized: [],
        sos: [],
        lostSignal: [],
        battery: [],
        longStay: [],
    });
    const [countData, setCountData] = useState({ prev: INIT_COUNT_DATA, curr: INIT_COUNT_DATA });

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        filterConfig,
        handler: data => {
            const { target, targetState } = data;
            const state = convertState(targetState);
            setTagStatus(tagStatus.current, { ...data, state });
            if (!state.lostSignal) {
                tagStatus.current.recognized.push(target.targetNum);
            }
            setCountData(prev => ({
                prev: prev.curr,
                curr: Object.entries(tagStatus.current).reduce((accCountData, [statusKey, tagList]) => {
                    accCountData[statusKey] = tagList.length;
                    return accCountData;
                }, {}),
            }));
        },
    });

    return (
        <>
            <Card widgetInfo={widgetInfo} {...restProps}>
                <div className={'scrollbar-container'}>
                    <PerfectScrollbar>
                        <Row className={'flex-center'} style={{ height: '100%' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.recognized}
                                        end={countData.curr.recognized}
                                        label={t('Total number of detected tags')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <TagCount start={countData.prev.sos} end={countData.curr.sos} label={t('SOS')} />
                                </CountWrapper>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.lostSignal}
                                        end={countData.curr.lostSignal}
                                        label={t('Lost Signal')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.battery}
                                        end={countData.curr.battery}
                                        label={t('Low Battery')}
                                    />
                                </CountWrapper>
                                <CountWrapper>
                                    <TagCount
                                        start={countData.prev.longStay}
                                        end={countData.curr.longStay}
                                        label={t('Long Stay > 30 Seconds')}
                                    />
                                </CountWrapper>
                            </div>
                        </Row>
                    </PerfectScrollbar>
                </div>
                {children}
            </Card>
        </>
    );
};

export default TagStatus;

import React from 'react';
import Card from '../Components/WidgetCard';
import { Table, TableBody, TableHead, Td, Th, Tr } from '@components/Table/SimpleTable';
import { useSettings } from '../util/useSettings';
import useMonitor from '../util/useMonitor';
import ReactResizeDetector from 'react-resize-detector';
import { fetchScannerList } from '@api/asset';
import { useTranslation } from '@hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useFilter from '@hooks/useFilter';
import { DUMMY_DATA } from './constants';
import ScannerStatusChart from './ScannerStatusChart';

const SUMMARY_AREA_HEIGHT = '90px';
const TABLE_HEIGHT = '65px';

const ScannerStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('ScannerStatus');
    const settings = useSettings(config);
    const filterParam = useFilter();

    const { data } = useMonitor({
        config,
        defaultData: DUMMY_DATA,
        dynamicParam: filterParam,
        fetchData: fetchScannerList,
        makeData: ({ newData }) => {
            const scannerList = newData.rows;
            const chartData = [];
            chartData.push({
                key: 'on',
                name: 'On-Line',
                value: scannerList ? scannerList.filter(v => v.status === 'N').length : 0,
            });
            chartData.push({
                key: 'off',
                name: 'Off-Line',
                value: scannerList ? scannerList.filter(v => v.status === 'A').length : 0,
            });
            return chartData;
        },
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <div className={'h-100'}>
                        <ReactResizeDetector handleHeight handleWidth>
                            {({ width, height }) => (
                                <div style={{ width, height: `calc(${height}px - ${SUMMARY_AREA_HEIGHT})` }}>
                                    <ScannerStatusChart data={data} settings={settings} />
                                </div>
                            )}
                        </ReactResizeDetector>
                        <div style={{ height: SUMMARY_AREA_HEIGHT }}>
                            {t('Current / Count')}
                            <Table style={{ height: TABLE_HEIGHT }}>
                                <TableHead>
                                    <Tr className={'active'}>
                                        <Th className={'justify-content-center'}>{t('All Scanner')}</Th>
                                        <Th className={'justify-content-center'}>{t('On-Line')}</Th>
                                        <Th className={'justify-content-center'}>{t('Off-Line')}</Th>
                                    </Tr>
                                </TableHead>
                                <TableBody>
                                    <Tr>
                                        <Td className={'justify-content-center'}>{data[0].value + data[1].value}</Td>
                                        <Td className={'justify-content-center'}>{data[0].value}</Td>
                                        <Td className={'justify-content-center'}>{data[1].value}</Td>
                                    </Tr>
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default ScannerStatus;

import React, { useEffect, useReducer, useRef } from 'react';
import useTranslation from '@util/hooks/useTranslation';
import iotItemStatusReducer, { clearIotItemStatusWidget, initialState } from './iotItemStatusReducer';
import WidgetCard from '../Components/WidgetCard';
import { useSettings } from '../util/useSettings';
import IotItemWrapper from './IotItemWrapper';

export const IotItemStatusStateContext = React.createContext();
export const IotItemStatusDispatchContext = React.createContext();

const IotItemStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('IotItemStatus');
    const [state, dispatch] = useReducer(iotItemStatusReducer, initialState);

    const widgetRef = useRef();
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { categoryCode: currentCategoryCode } = settings;

    useEffect(() => {
        return () => {
            dispatch(clearIotItemStatusWidget());
        };
    }, []);

    return (
        <IotItemStatusDispatchContext.Provider value={dispatch}>
            <IotItemStatusStateContext.Provider value={state}>
                <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} subTitle={t(currentCategoryCode)} {...restProps}>
                    <IotItemWrapper widgetInfo={widgetInfo} />
                    {children}
                </WidgetCard>
            </IotItemStatusStateContext.Provider>
        </IotItemStatusDispatchContext.Provider>
    );
};

export default IotItemStatus;

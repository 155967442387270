import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from '@hooks';
import { useSelector } from 'react-redux';
import { HourlyCongestionStatusContext, HourlyCongestionStatusDispatchContext } from '../index';
import { setEndDate, setSelectedCategory, setSelectedFloor, setStartDate } from '../hourlyCongestionStatusReducer';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { Button, DatePicker } from '../../../Common';
import FloorTreeSelect from '../../Components/FloorTreeSelect';
import moment from 'moment';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { FilterList, SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import { Select } from '@components';
import FilterGroup from '../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';

const HourlyCongestionStatusFilterGroup = ({ handleSearchBtnClick }) => {
    const t = useTranslation('CongestionStatus');
    const [categoryListWithAllOption, setCategoryListWithAllOption] = useState([]);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useContext(HourlyCongestionStatusContext);
    const dispatch = useContext(HourlyCongestionStatusDispatchContext);

    const handleSelectCategoryChange = e => {
        dispatch(setSelectedCategory(e));
    };

    const handleYesterdayBtnClick = () => {
        dispatch(setStartDate(moment().subtract(1, 'days').startOf('day').valueOf()));
        dispatch(setEndDate(moment().subtract(1, 'days').endOf('day').valueOf()));
    };

    const handleTodayBtnClick = () => {
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    const handleFloorChange = selects => {
        if (selects[0]) {
            dispatch(setSelectedFloor(selects[0].floorId));
        }
    };

    useEffect(() => {
        setCategoryListWithAllOption([{ categoryCode: '', categoryName: 'All' }, ...categoryList]);
    }, [categoryList]);

    return (
        <>
            <FilterSearchGroup className={'card absolute-filter'}>
                <FilterGroup label={t('Filter')}>
                    <Select
                        placeholder={'All'}
                        value={categoryListWithAllOption.length > 0 ? selectedCategory : []}
                        onChange={handleSelectCategoryChange}
                        options={categoryListWithAllOption}
                        valueKey={'categoryCode'}
                        labelKey={'categoryName'}
                        listStyle={{ height: '200px', overflow: 'auto' }}
                    />
                    <FloorTreeSelect
                        key={selectedFloor}
                        floorList={floorList}
                        selectedFloorStr={selectedFloor}
                        mode={'radioSelect'}
                        handleChange={handleFloorChange}
                    />
                </FilterGroup>
                <SearchGroup label={t('Search')}>
                    <FilterList>
                        <SelectGroup>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => dispatch(setStartDate(selected))}
                                valueType={'ms'}
                                maxDate={endDate || new Date()}
                                showTimeInput
                            />
                        </SelectGroup>
                        <div> ~ </div>
                        <SelectGroup>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => dispatch(setEndDate(selected))}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={new Date()}
                                showTimeInput
                            />
                        </SelectGroup>
                        <SearchButtonArea>
                            <div className={'gap-1 flx-row'}>
                                <Button className={'btn-gray btn-yesterday'} onClick={handleYesterdayBtnClick}>
                                    {t('Yesterday', 'Button')}
                                </Button>
                                <Button className={'btn-gray btn-yesterday'} onClick={handleTodayBtnClick}>
                                    {t('Today', 'Button')}
                                </Button>
                                <Button className="pnt-btn btn-secondary" onClick={handleSearchBtnClick}>
                                    {t('Search')}
                                </Button>
                            </div>
                        </SearchButtonArea>
                    </FilterList>
                </SearchGroup>
            </FilterSearchGroup>
        </>
    );
};

export default HourlyCongestionStatusFilterGroup;

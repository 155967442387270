import React from 'react';
import cx from 'classnames';

export const Table = ({ className, style = {}, children }) => {
    return (
        <div role={'table'} className={cx('pnt-table', className)} style={{ height: '100%', ...style }}>
            <div className={'border-box'}>{children}</div>
        </div>
    );
};

export const TableHead = ({ className, children }) => {
    return <div className={cx('thead', className)}>{children}</div>;
};

export const Th = ({ className, children }) => {
    return (
        <div colSpan={'1'} role="columnheader" className={cx('th', className)}>
            {children}
        </div>
    );
};

export const TableBody = ({ className, children }) => {
    return (
        <div role={'rowgroup'} className={cx('tbody', className)}>
            <div>
                <div>
                    <div>{children}</div>
                </div>
            </div>
        </div>
    );
};

export const Tr = ({ className, children }) => {
    return (
        <div role={'row'} className={cx('tr', className)}>
            {children}
        </div>
    );
};

export const Td = ({ className, children }) => {
    return (
        <div role={'cell'} className={cx('td', className)}>
            {children}
        </div>
    );
};

import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { Map, RotatedImageOverlay } from '../../../Common';
import useTranslation from '@hooks/useTranslation';

import { Toast, ToastBody, ToastHeader } from 'reactstrap';
/**
 * @param {boolean} modal  모달창 on/off 상태 여부
 * @param {function} toggleModal, 모달 on/off 함수
 * @param {Object} selectedLog 선택한 row의 값
 * @param ref 참조 값
 * */
const LocationPopup = ({ modal, toggleModal, selectedLog }, ref) => {
    const t = useTranslation('Location Log');
    const { targetName, latLng, regDate, categoryCode, targetNum } = selectedLog;

    const { categoryToImg } = useSelector(state => state.CategoryInfo);
    const { floorList } = useSelector(state => state.FloorInfo);

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [floorInfo, setFloorInfo] = useState({});
    const mapRef = useRef();
    const popupRef = useRef();

    useEffect(() => {
        if (selectedLog && selectedLog.floorId) {
            setFloorInfo(floorList.find(floor => floor.floorId === selectedLog.floorId));
        }
        if (mapRef && mapRef.current) {
            const map = mapRef.current.leafletElement;

            map.setView(latLng);
        }
    }, [selectedLog]);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        const popupSize = getPopupSize();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = -widgetPosition.height + 60;
        setPosition({
            x: x,
            y: y,
        });
    }, [popupRef.current]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (ref.current) {
            const searchContainer = ref.current;
            const { offsetWidth: width, offsetHeight: height } = searchContainer;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };
    const getPopupSize = () => {
        const popupSize = { width: 0, height: 0 };
        if (popupRef.current) {
            const popupContainer = popupRef.current;
            const { offsetWidth: width, offsetHeight: height } = popupContainer;

            popupSize.width = width;
            popupSize.height = height;
        }
        return popupSize;
    };

    return (
        <Draggable key={JSON.stringify(position)} bounds=".locationLog-container" defaultPosition={position}>
            <Toast isOpen={modal} className="popup-open" innerRef={popupRef}>
                <ToastHeader toggle={toggleModal}>
                    {t('Location')} ({targetName} : {regDate})
                </ToastHeader>
                <ToastBody>
                    <div style={{ padding: '0.5rem 0', height: '100%' }}>
                        <Map ref={mapRef}>
                            {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                                <RotatedImageOverlay
                                    key={floorInfo.floorId}
                                    url={floorInfo.imgURL}
                                    deg={floorInfo.deg}
                                    bounds={floorInfo.bounds}
                                    onLoad={() => {
                                        if (mapRef.current) {
                                            const map = mapRef.current.leafletElement;
                                            if (map) {
                                                map.setView(latLng);
                                            }
                                        }
                                    }}
                                />
                            )}
                            {categoryCode ? (
                                <Marker
                                    key={targetNum}
                                    position={latLng}
                                    icon={L.divIcon({
                                        className: 'category-marker',
                                        html: `<img class="marker-img" src="${categoryToImg[categoryCode]}" alt=''/>`,
                                    })}
                                />
                            ) : (
                                <Marker
                                    key={targetNum}
                                    position={latLng}
                                    icon={L.divIcon({
                                        className: 'category-marker',
                                        html: `<div class="marker-img replace-img"><div></div></div>`,
                                    })}
                                />
                            )}
                        </Map>
                    </div>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default forwardRef(LocationPopup);

export const CustomNav = [];

export const SettingsNav = [
    // {
    //     id: 'menuRights',
    //     icon: 'icon-board',
    //     label: 'Menu Access Rights',
    //     to: '#/setting/menu',
    // },
    {
        id: 'authorization',
        icon: 'icon-board',
        label: 'Authorization Group Management',
        content: [
            {
                label: 'Authorization Group List',
                to: '#/setting/authorization-group/list',
            },
        ],
    },
    {
        id: 'menuManagement',
        icon: 'icon-board',
        label: 'Menu Management',
        content: [
            {
                label: 'Menu List',
                to: '#/setting/menu/list',
            },
        ],
    },
    {
        id: 'screen',
        icon: 'icon-board',
        label: 'Screen Management',
        content: [
            {
                label: 'Screen List',
                to: '#/setting/screen/list',
            },
        ],
    },
    {
        id: 'filter',
        icon: 'icon-board',
        label: 'Filters',
        content: [
            {
                label: 'Filter List',
                to: '#/setting/filter/list',
            },
        ],
    },
    {
        id: 'wmsConnection',
        icon: 'icon-tool',
        label: 'Move to WMS',
        to: '',
    },
];

import React, { useContext, useEffect, useState } from 'react';
import { Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { SensorStatusStateContext } from '../../../index';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';

const MonthlyTooltip = ({ active, payload, label }) => {
    const t = useTranslation('SensorStatus');
    if (active && payload && payload.length) {
        const regDate = payload[0]?.payload?.regDate;
        return (
            <div className="custom-tooltip">
                <p className="label mb-0">{`${label} ${regDate ? moment.unix(regDate).format('HH:mm') : ''}`}</p>
                <p className="label mb-0">{`${t('Data')} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const SensorLineGraph = ({ dataKey = 'regDate', selectedSensingType }) => {
    const t = useTranslation('SensorStatus');
    const { sensorLineChartData, selectedSensor } = useContext(SensorStatusStateContext);

    const [limit, setLimit] = useState({ maxRiskLimit: 0, minRiskLimit: 0 });
    useEffect(() => {
        if (selectedSensor) {
            const { sensorItems } = selectedSensor;
            const { minRiskLimit, maxRiskLimit } = sensorItems.find(
                sensorItem => selectedSensingType === sensorItem.sensingType,
            );
            setLimit({ maxRiskLimit, minRiskLimit });
        }
    }, [selectedSensor]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={sensorLineChartData}>
                <XAxis dataKey={dataKey} />
                <YAxis />
                <Line type="monotone" dataKey={'data'} stroke="#8884d8" fill="#8884d8" />
                <ReferenceLine y={limit.maxRiskLimit} stroke="red" label={t('Max Risk Limit')} />
                <ReferenceLine y={limit.minRiskLimit} stroke="red" label={t('Min Risk Limit')} />
                {dataKey === 'date' ? <Tooltip content={<MonthlyTooltip />} /> : <Tooltip />}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SensorLineGraph;

import React, { createContext, useEffect, useReducer, useRef, useState } from 'react';
import Card from '../Components/WidgetCard';
import List from './Component/List';
import ExcelExport from './Component/ExcelExport';
import { useAsync } from '@hooks';
import { fetchLocationLogList } from '@api/log';
import Search from './Component/Search';
import cx from 'classnames';
import moment from 'moment';
import reducer, { initState } from './locationLogReducer';
import { useSelector } from 'react-redux';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';

/**
 * 위치 로그 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const LocationLogStateContext = createContext();
export const LocationLogDispatchContext = createContext();
const LocationLog = ({ children, widgetInfo, className, ...restProps }) => {
    const { mode } = useSelector(state => state.ScreenInfo);
    const [state, dispatch] = useReducer(reducer, initState);
    const searchRef = useRef(null);
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf(),
    );
    const handleDateCallback = ({ selected, type }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().startOf('day').subtract(1, 'day');
                setStartDate(yesterday.valueOf());
                setEndDate(yesterday.add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf());
                break;
        }
    };

    const [logData, setLogData] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const { promise: getLocationLogList, state: locationLogListInfo } = useAsync({
        promise: fetchLocationLogList,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    logNum: log.logNum,
                    targetName: log.targetName,
                    tagName: log.tagName,
                    floorId: log.floorId,
                    latLng: [log.lat, log.lng],
                    regDate: log.regDate,
                    ...log,
                };
            });
            setLogData({ ...res, rows: data });
        },
    });
    useEffect(() => {
        getLocationLogList({ startDate: startDate / 1000, endDate: endDate / 1000 });
    }, []);
    return (
        <LocationLogDispatchContext.Provider value={dispatch}>
            <LocationLogStateContext.Provider value={state}>
                <Card
                    className={cx(className, 'locationLog-container')}
                    widgetInfo={widgetInfo}
                    headerAction={
                        mode !== SCREEN_MODE_EDIT &&
                        mode !== SCREEN_MODE_PREVIEW && (
                            <ExcelExport listCount={logData.rows.length} startDate={startDate} endDate={endDate} />
                        )
                    }
                    ref={searchRef}
                    {...restProps}
                >
                    <Search
                        startDate={startDate}
                        endDate={endDate}
                        handleDateCallback={handleDateCallback}
                        promise={getLocationLogList}
                    />
                    <List list={logData} state={locationLogListInfo} promise={getLocationLogList} ref={searchRef} />
                    {children}
                </Card>
            </LocationLogStateContext.Provider>
        </LocationLogDispatchContext.Provider>
    );
};

export default LocationLog;

import React, { Fragment, useContext, useEffect, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import { initUUIDv4 } from '@util/common/util';
import Modal from '@components/Modal';
import useAsync from '@hooks/useAsync';
import { getVitalSignsWorkTimeApi } from '@api/monitoring';
import { initialWorkTimeList, resetCurrentWorkTime, updateCurrentWorkTime } from '../vitalSignsMonitoringReducer';
import { VitalSignsMonitoringDispatchContext, VitalSignsMonitoringStateContext } from '../index';
import Nameplate from '@components/Nameplate';
import cx from 'classnames';

const FilterCheckbox = () => {
    const t = useTranslation('VitalSignMonitoring');
    const { workTimeList } = useContext(VitalSignsMonitoringStateContext);
    const dispatch = useContext(VitalSignsMonitoringDispatchContext);
    const [isAll, setIsAll] = useState(true);
    const handleToggle = () => {
        setIsAll(!isAll);
        handleAllToggle(!isAll);
    };

    // 담당필터 초기 호출
    useAsync({
        promise: getVitalSignsWorkTimeApi,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            // init work time list
            dispatch(initialWorkTimeList({ workTimeList: rows }));
        },
        reject: () => {},
    });

    // checkbox handler
    const handleCheckbox = ({ target }) => {
        const { id } = target;

        const originWorkTimeCount = workTimeList.current.length;
        const cloneDeepState = JSON.parse(JSON.stringify(workTimeList.current));
        cloneDeepState[id] = { ...cloneDeepState[id], checked: !cloneDeepState[id].checked };
        const deactivateWorkTimeCount = cloneDeepState.filter(item => !item.checked).length;

        // 모든 선택 값이 해제될 경우
        if (originWorkTimeCount === deactivateWorkTimeCount) {
            toggleCheckboxModal();
            return;
        }

        dispatch(updateCurrentWorkTime({ currentWorkTime: cloneDeepState }));
    };

    // 전체 선택 toggle
    const handleAllToggle = isAll => {
        if (isAll) {
            const cloneDeepState = JSON.parse(JSON.stringify(workTimeList.current)).map(item => ({
                ...item,
                checked: true,
            }));
            dispatch(updateCurrentWorkTime({ currentWorkTime: cloneDeepState }));
        } else {
            // 전체 선택 해제 => 현재 담당시간 선택
            dispatch(resetCurrentWorkTime());
        }
    };

    // must select work time modal
    const [checkboxModal, setCheckboxModal] = useState(false);
    const toggleCheckboxModal = () => setCheckboxModal(!checkboxModal);

    useEffect(() => {
        if (workTimeList.current) {
            const originCount = workTimeList.current.length;
            const activeCount = workTimeList.current.filter(item => item.checked).length;

            if (originCount === activeCount) {
                setIsAll(true);
            } else {
                setIsAll(false);
            }
        }
    }, [workTimeList.current]);

    return (
        <>
            <div className="d-flex align-items-center">
                <Nameplate name={t('Work Time')} />
                <div className="btn-group btn-group-toggle ml-2" role="group">
                    {workTimeList.current.map((value, idx) => {
                        const { workTimeType, checked } = value;
                        const workTimeKey = workTimeType.slice(0, 1).toUpperCase();
                        return (
                            <Fragment key={`${workTimeType}_${initUUIDv4()}`}>
                                <label
                                    className={cx('btn btn-outline-secondary py-1 mb-0', checked && 'active')}
                                    htmlFor={idx}
                                >
                                    <input
                                        type="checkbox"
                                        id={idx}
                                        autoComplete="off"
                                        value={workTimeType}
                                        onChange={handleCheckbox}
                                    />
                                    {workTimeKey}
                                </label>
                            </Fragment>
                        );
                    })}
                    <label className={cx('btn btn-outline-secondary py-1 mb-0', isAll && 'active')} htmlFor={'ALL'}>
                        <input type="checkbox" id={'ALL'} autoComplete="off" value={'ALL'} onChange={handleToggle} />
                        {'ALL'}
                    </label>
                </div>
            </div>
            <Modal
                initModal={checkboxModal}
                toggleModal={toggleCheckboxModal}
                headerTitle={t('Confirm', 'ConfirmModal')}
                bodyText={t('You must select at least one.', 'ConfirmModal')}
                removeCancel
            />
        </>
    );
};

export default FilterCheckbox;

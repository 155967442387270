import React from 'react';
import { useTranslation } from '@hooks';
import SensingFilter from './SensingFilter';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '../../../MainPages/Components/FilterSearchGroup/Components/FilterButtonArea';

const SensorType = () => {
    const t = useTranslation('AssetCountStatus');

    return (
        <FilterSearchGroup className="card absolute-filter">
            <FilterGroup>
                <SensingFilter />
                <FilterButtonArea />
            </FilterGroup>
        </FilterSearchGroup>
    );
};
export default SensorType;

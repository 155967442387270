import React from 'react';
import { Table } from '@components';
import { useColumns, useTranslation } from '@hooks';
import * as column from '../../../MainPages/util/grid/column';

/**
 *
 * 위치 로그 저장이력 테이블 생성 컴포넌트
 *
 * @param {Object} list 위치로그 저장이력 리스트 데이터
 * @param {Object} state 위치로그 저장이력 로그 리스트 API 호출 상태`값
 * @param {function} handleExcelLog 위치로그 저장이력 로그 리스트 호출 callback
 *
 * @author jinbeom-jung
 * */

const List = ({ list, state, promise }) => {
    const t = useTranslation('Location Log List');
    const columnT = useTranslation('CommonColumn');
    const columns = useColumns(
        [
            column.reqNum(),
            column.fileName(),
            column.dataStatus({ t }),
            column.downloadLog({ t }),
            column.registeredDate({ t: columnT }),
        ],
        t,
    );

    const handlePageChange = pageIndex => {
        const param = state.request;
        let result = {};
        for (let key in param) {
            if (param[key]) {
                result[key] = param[key];
            }
        }
        promise({ ...result, page: pageIndex });
    };
    return (
        <div style={{ height: '35rem' }}>
            <Table
                data={{ ...list, pageSize: 15 }}
                loading={state.isLoading}
                columns={columns}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default List;

import React, { useEffect, useState } from 'react';
import InfoTable from './InfoTable';
import moment from 'moment';
import useAsync from '@hooks/useAsync';
import { getVitalSignsDetailGraphApi } from '@api/monitoring';
import useTranslation from '@hooks/useTranslation';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import Search from './Search';
import NoDataBlock from '../../../../Components/NoDataBlock';
import styles from 'assets/pnt/widgets/VitalSignsMonitoring.module.scss';
import cx from 'classnames';

const SENSOR_TYPE = {
    BLOODPRESSURE: 'BP',
    AVERAGE: 'AVG',
};
const GUARANTEED_SENSOR_TYPE = ['PR', 'BT'];
const GRAPH_AREA = {
    WIDTH: 1250,
    HEIGHT: 300,
    MARGIN_TOP: 5,
    MARGIN_RIGHT: 20,
    MARGIN_BOTTOM: 40,
    MARGIN_LEFT: 0,
};
const LABEL_AREA = {
    TOP: 32,
};
const formatBpSensorList = (list = []) => {
    let name;
    let unit;
    const data = list
        .sort((a, b) => a.regDate - b.regDate)
        .map(value => {
            const { low, mid, high, sensingType, regDate, measurementUnit } = value;
            name = sensingType;
            unit = measurementUnit;

            return {
                x: moment.unix(regDate).format('HH:mm:ss'),
                y: [Number(low), Number(high)],
                avg: Number(mid),
            };
        });

    return { name, unit, data };
};
const formatSensorList = ({ PULSE_RATE: pulseRateList = [], TEMPERATURE: temperatureList = [] }) => {
    const lists = [pulseRateList, temperatureList]; // 맥박-체온 순서 보장

    return lists.map(list => {
        let name;
        let unit;
        const data = list.map(value => {
            const { currentValue, regDate, sensingType, measurementUnit } = value;
            name = sensingType;
            unit = measurementUnit;

            return {
                x: moment.unix(regDate).format('HH:mm:ss'),
                y: Number(currentValue),
            };
        });

        return { name, unit, data };
    });
};

const MainView = ({ targetId }) => {
    const t = useTranslation('VitalSignMonitoring');
    const [vitalSensor, setVitalSensor] = useState({});
    const [bpSensorList, setBpSensorList] = useState({}); // 백엔드 구조상 sensorList에 넣어 보낼 수 없음
    const [sensorList, setSensorList] = useState([]);

    const [date, setDate] = useState(moment().unix());
    const handleDate = selected => {
        setDate(selected);
    };

    // 센서 그래프 조회
    const { promise: getVitalSignsDetailGraph } = useAsync({
        promise: getVitalSignsDetailGraphApi,
        resolve: ({ recentVitalSensor, vitalBpSensorList, vitalSensorList }) => {
            setVitalSensor(recentVitalSensor);
            setBpSensorList(formatBpSensorList(vitalBpSensorList.BLOODPRESSURE));
            setSensorList(formatSensorList(vitalSensorList));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        getVitalSignsDetailGraph({ searchDate: date, targetId });
    }, [date]);

    return (
        <>
            <InfoTable vitalSensor={vitalSensor} />
            <Search date={date} onChange={handleDate} />

            <div className="mb-5">
                <div className="w-100 d-flex align-items-end mb-1">
                    <span className="pnt-txt txt-bold s-6">{t(SENSOR_TYPE.BLOODPRESSURE)}</span>
                    {bpSensorList.unit && <span className="pnt-txt ml-1">({bpSensorList.unit})</span>}
                </div>
                {bpSensorList.data && bpSensorList.data.length > 0 ? (
                    <ResponsiveContainer height={GRAPH_AREA.HEIGHT}>
                        <ComposedChart
                            // width={GRAPH_AREA.WIDTH}
                            height={GRAPH_AREA.HEIGHT}
                            margin={{
                                top: GRAPH_AREA.MARGIN_TOP,
                                right: GRAPH_AREA.MARGIN_RIGHT,
                                left: GRAPH_AREA.MARGIN_LEFT,
                                bottom: GRAPH_AREA.MARGIN_BOTTOM,
                            }}
                            data={bpSensorList.data}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x" angle={45} tickSize={LABEL_AREA.TOP} />
                            <YAxis domain={[dataMin => dataMin - 10, dataMax => dataMax + 10]} />
                            <Tooltip />
                            <Legend align={'right'} verticalAlign={'top'} />
                            <Bar name={t(SENSOR_TYPE[bpSensorList.name])} dataKey="y" fill="#8884d8" />
                            <Line name={t(SENSOR_TYPE.AVERAGE)} type="monotone" dataKey="avg" stroke="#82ca9d" />
                        </ComposedChart>
                    </ResponsiveContainer>
                ) : (
                    <NoDataBlock className={cx(styles.detail_graph, 'border-size-1 border-depth-7')} />
                )}
            </div>

            {/* 맥박-체온 순서 보장 */}
            {GUARANTEED_SENSOR_TYPE.map((value, index) => {
                const data = sensorList[index]?.data;
                const unit = sensorList[index]?.unit;
                return (
                    <div key={`${value}_${index}`} className="mb-5">
                        <div className="w-100 d-flex align-items-end mb-1">
                            <span className="pnt-txt txt-bold s-6">{t(value)}</span>
                            {unit && <span className="pnt-txt ml-1">({unit})</span>}
                        </div>
                        {data && data.length > 0 ? (
                            <ResponsiveContainer height={GRAPH_AREA.HEIGHT}>
                                <LineChart
                                    key={index}
                                    margin={{
                                        top: GRAPH_AREA.MARGIN_TOP,
                                        right: GRAPH_AREA.MARGIN_RIGHT,
                                        left: GRAPH_AREA.MARGIN_LEFT,
                                        bottom: GRAPH_AREA.MARGIN_BOTTOM,
                                    }}
                                    data={data}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="x"
                                        angle={45}
                                        tickSize={LABEL_AREA.TOP}
                                        padding={{ left: 0, right: 0 }}
                                    />
                                    <YAxis domain={[dataMin => dataMin - 10, dataMax => dataMax + 10]} />
                                    <Tooltip />
                                    <Legend align={'right'} verticalAlign={'top'} />
                                    <Line name={t(value)} type="monotone" dataKey="y" stroke="#82ca9d" />
                                </LineChart>
                            </ResponsiveContainer>
                        ) : (
                            <NoDataBlock className={cx(styles.detail_graph, 'border-size-1 border-depth-7')} />
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default MainView;

import React, { useContext } from 'react';
import { components } from 'react-select';
import { SearchableSelectContext } from '@components/Select/SearchableSelect';
import useTranslation from '@util/hooks/useTranslation';
import { SelectDispatchContext, SelectContext, OPTION_LOGIN_USER } from './index';
import cx from 'classnames';
import { setEditable } from '../../Components/SelectWithOption/reducer';
import { FilterGeneratorContext } from '../../FilterGenerator';
import { getOptionTitle } from '../util';

export const MenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const selected = getValue();
    const t = useTranslation('TreeSelect');
    const filterT = useTranslation('Filter');
    const { valueKey, labelKey } = useContext(SearchableSelectContext);
    const dispatch = useContext(SelectDispatchContext);
    const { filterEditMode } = useContext(FilterGeneratorContext);
    const { isEditable } = useContext(SelectContext);
    const handleChange = e => {
        const { checked } = e.currentTarget;
        setValue(checked ? options.filter(option => option[valueKey] !== OPTION_LOGIN_USER.value) : []);
    };

    return (
        <components.MenuList {...restProps}>
            <div className={'mb-2'}>
                <div className={'select-group-title'}>{t('Selected')}</div>
                {selected.length ? (
                    selected.map(v => (
                        <div
                            key={v[valueKey]}
                            className={cx(
                                'styled-option',
                                'd-flex',
                                isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                            )}
                        >
                            <div
                                onClick={e => {
                                    setValue(
                                        selected.filter(selectedOption => selectedOption[valueKey] !== v[valueKey]),
                                    );
                                }}
                            >
                                <label className="pnt-checkbox h-auto" htmlFor={v[labelKey]}>
                                    <input
                                        id={v[labelKey]}
                                        type="checkbox"
                                        style={{ cursor: 'pointer' }}
                                        value={v[valueKey]}
                                        checked
                                        onChange={() => null}
                                    />
                                    <span className="material-icons-round md-18 color-secondary">check_box</span>
                                    <p>{v[labelKey]}</p>
                                </label>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={'styled-option-label'}>{t('Not Selected')}</div>
                )}
            </div>
            <div>
                <div
                    className={cx(
                        'select-group-title pt-0 pb-0',
                        isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                    )}
                >
                    <label className={'pnt-checkbox h-auto align-center'} title="All Items">
                        <input type="checkbox" onChange={handleChange} checked={selected.length === options.length} />
                        <span
                            className={`material-icons-round md-18 ${
                                selected.length === options.length ? 'color-secondary' : ''
                            }`}
                        >
                            {selected.length === options.length ? 'check_box' : 'check_box_outline_blank'}
                        </span>
                        <p>{t('All')}</p>
                    </label>
                </div>
                {children}
            </div>
            <div className={cx(!filterEditMode && 'disabled-option')}>
                <div className={'select-group-title'}>{filterT('Option')}</div>
                <div className={'styled-option'}>
                    <label
                        className="pnt-checkbox h-auto"
                        htmlFor={'Permission'}
                        title={filterT('Permission to change')}
                    >
                        <input
                            id={'Permission'}
                            type="checkbox"
                            checked={isEditable === 'Y'}
                            onChange={e => {
                                dispatch(setEditable(e.currentTarget.checked ? 'Y' : 'N'));
                            }}
                        />
                        <span className={`material-icons-round md-18 ${isEditable === 'Y' ? 'color-secondary' : ''}`}>
                            {isEditable === 'Y' ? 'check_box' : 'check_box_outline_blank'}
                        </span>
                        <p>{filterT('Permission to change')}</p>
                    </label>
                </div>
            </div>
        </components.MenuList>
    );
};

export const Option = function ({ label, isSelected, getValue, setValue, innerProps, ...restProps }) {
    const selected = getValue();
    const { valueKey } = useContext(SearchableSelectContext);
    const { filterEditMode } = useContext(FilterGeneratorContext);
    const { isEditable } = useContext(SelectContext);

    return (
        <div className={cx('select-option', isEditable !== 'Y' && !filterEditMode && 'disabled-select-option')}>
            <components.Option
                {...restProps}
                innerProps={{
                    ...innerProps,
                    onClick: e => {
                        e.stopPropagation();
                        e.preventDefault();
                        const { data, value } = restProps;
                        let selectedList;
                        if (value === OPTION_LOGIN_USER.value) {
                            selectedList = [data];
                        } else {
                            selectedList = [
                                ...selected.filter(option => option[valueKey] !== OPTION_LOGIN_USER.value),
                                data,
                            ];
                        }
                        setValue(selectedList);
                    },
                }}
            >
                <label className={'text-ellipsis mb-0 select-label w-100'} title={getOptionTitle(label)}>
                    <input type="checkbox" checked={isSelected} onChange={e => null} />
                    {label}
                </label>
                {/*<label style={{ marginBottom: '4px' }}>{label}</label>*/}
            </components.Option>
        </div>
    );
};

export const Control = ({ children, options, ...restProps }) => {
    // console.log(restProps);
    return (
        <components.Control
            className={'select-control'}
            {...restProps}
            options={options}
            // innerProps={{
            //     onMouseDown: e => {
            //         console.log('mousedown');
            //     },
            //     onTouchEnd: e => {
            //         console.log('touchend');
            //     },
            // }}
        >
            {children}
        </components.Control>
    );
};

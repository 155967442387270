import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import reducer, { initialState, setInitState, setOptions } from '../../Components/SelectWithOption/reducer';
import { MenuList, Option, Control } from './Parts';
import SearchableSelect from '@components/Select/SearchableSelect';
import useTranslation from '@hooks/useTranslation';
import { FilterGeneratorContext } from '../../FilterGenerator';
import { useSelector } from 'react-redux';
import { QuestionTooltip } from '@components';
import { OPTION_LOGIN_GROUP } from '../LoginGroupSelect';

export const SelectContext = createContext();
export const SelectDispatchContext = createContext();

export const OPTION_LOGIN_USER = { name: 'Logged in user', value: '-1' };

const LoginUserSelect = ({ data, onChange, isEditable = 'Y', selected, valueKey, ...restProps }) => {
    const t = useTranslation('Filter');
    const { userInfo } = useSelector(state => state.UserInfo);
    const { monitoringMode } = useContext(FilterGeneratorContext);
    const [state, dispatch] = useReducer(reducer, { ...initialState, options: data });
    const { options } = state;
    const [selectedItem, setSelectedItem] = useState(selected);
    const handleChange = selectedOption => {
        setSelectedItem(selectedOption.map(v => v[valueKey]));
    };

    useEffect(() => {
        if (!monitoringMode) {
            dispatch(
                setOptions([
                    {
                        [valueKey]: OPTION_LOGIN_USER.value,
                        label: (
                            <span className="d-flex flx-center">
                                <QuestionTooltip
                                    contents={t('This option cannot be selected with other users.')}
                                    className={'mr-2'}
                                    placement={'top'}
                                />
                                <span className="ml-1">{t(OPTION_LOGIN_USER.name)}</span>
                            </span>
                        ),
                    },
                    ...data,
                ]),
            );
        }
    }, [data, monitoringMode, t, userInfo]);

    useEffect(() => {
        dispatch(setInitState({ isEditable }));
    }, [isEditable]);

    useEffect(() => {
        if (typeof onChange === 'function') {
            if (monitoringMode && selectedItem[0] === OPTION_LOGIN_USER.value) {
                setSelectedItem([userInfo.userNum.toString()] || []);
                return;
            }
            onChange(selectedItem, state.isEditable);
        }
    }, [selectedItem, state.isEditable, monitoringMode, userInfo.userNum]);

    return (
        <SelectDispatchContext.Provider value={dispatch}>
            <SelectContext.Provider value={state}>
                <SearchableSelect
                    {...restProps}
                    data={options}
                    selected={selectedItem}
                    valueKey={valueKey}
                    MenuListComponent={MenuList}
                    OptionComponent={Option}
                    ControlComponent={Control}
                    onChange={handleChange}
                />
            </SelectContext.Provider>
        </SelectDispatchContext.Provider>
    );
};

export default LoginUserSelect;

import React, { useContext, useState } from 'react';
import { useSettings } from '../util/useSettings';
import { WidgetName, IntervalTime, HiddenHeader } from './WidgetSettingOptions';
import Modal from '@components/Modal';
import useTranslation from '../../../util/hooks/useTranslation';
import { editWidgetSettings } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { useConfirmModal } from '@hooks';

const WidgetSettingModal = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetInfo,
    socketWidget,
    hiddenHeader,
    headerTitle,
    okCallback,
    cancelCallback,
    intervalTimeOptions,
    children,
    ...restProps
}) => {
    const dispatch = useContext(ScreenEditDispatchContext);
    const t = useTranslation('Widget');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings, defaultSettings } = config;
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(customSettings || {});
    const [validation, setValidation] = useState(true);

    const handleWidgetNameChange = name => {
        if (name && name.trim()) {
            setValidation(true);
        } else {
            setValidation(false);
        }
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, name: name };
        });
    };

    const handleIntervalTimeChange = selectedIntervalTime => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, intervalTime: selectedIntervalTime };
        });
    };

    const handleHiddenHeaderChange = checkedHiddenHeader => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, hiddenHeader: checkedHiddenHeader };
        });
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: data,
                }),
            );
        }
    };

    const handleCancelClick = () => {
        if (cancelCallback) {
            cancelCallback();
        }
        setUpdatedWidgetInfo(customSettings || {});
    };

    const { Modal: ValidationModal, toggleModal: toggleValidationModal } = useConfirmModal({
        header: { title: t('Validation Fail') },
        confirmText: t("Please enter a widget's name"),
        removeCancel: true,
    });

    const handleSave = widgetInfo => {
        const checkName = updatedWidgetInfo.name || settings.name;
        // 이름을 직접 수정 했는데 빈 문자열 아닌 경우
        if (
            (updatedWidgetInfo.name !== '' || (updatedWidgetInfo.name && updatedWidgetInfo.name.trim() !== '')) &&
            checkName &&
            checkName.trim()
        ) {
            if (okCallback && typeof okCallback === 'function') {
                okCallback(widgetInfo);
            } else {
                handleSubmitClick(widgetInfo);
            }
        } else {
            toggleValidationModal();
        }
    };

    return (
        <>
            <Modal
                initModal={modal}
                toggleModal={toggleModal}
                headerTitle={headerTitle}
                okValidation={validation}
                okCallback={handleSave}
                callbackParam={updatedWidgetInfo}
                cancelCallback={handleCancelClick}
                {...restProps}
            >
                <div className={'modal-body'}>
                    <div className={'flx-col gap-4 pnt-label-5'}>
                        <WidgetName
                            value={
                                updatedWidgetInfo.name !== undefined
                                    ? updatedWidgetInfo.name
                                    : customSettings && customSettings.name
                                    ? customSettings.name
                                    : t(`${defaultSettings.name}`)
                            }
                            onChange={handleWidgetNameChange}
                        />

                        {/*{!socketWidget && (*/}
                        {/*    <IntervalTime*/}
                        {/*        value={*/}
                        {/*            updatedWidgetInfo.intervalTime*/}
                        {/*                ? updatedWidgetInfo.intervalTime*/}
                        {/*                : settings.intervalTime*/}
                        {/*        }*/}
                        {/*        onChange={handleIntervalTimeChange}*/}
                        {/*        options={intervalTimeOptions ? intervalTimeOptions : null}*/}
                        {/*    />*/}
                        {/*)}*/}

                        {hiddenHeader && (
                            <HiddenHeader
                                checked={
                                    typeof updatedWidgetInfo.hiddenHeader !== 'undefined'
                                        ? updatedWidgetInfo.hiddenHeader
                                        : customSettings && typeof customSettings.hiddenHeader !== 'undefined'
                                        ? customSettings.hiddenHeader
                                        : false
                                }
                                onChange={handleHiddenHeaderChange}
                            />
                        )}
                        {children}
                    </div>
                </div>
            </Modal>
            <ValidationModal />
        </>
    );
};

export default WidgetSettingModal;

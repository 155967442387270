import React from 'react';
import SensorAndIotCount from './Components/SensorAndIotCount';
import SensorStatusCount from './Components/SensorStatusCount';

/**
 * 센서현황 카운트 컴포넌트
 *
 * @param targetCountData 전체 IoT 아이템 & 센서수
 * @param statusCountData 전체 상태 별 측정항목 수
 *
 * @author jinbeom-jung
 */
const SensorCount = ({ targetCountData, statusCountData }) => {
    return (
        <>
            <SensorAndIotCount targetCountData={targetCountData} />
            <SensorStatusCount statusCountData={statusCountData} />
        </>
    );
};

export default SensorCount;

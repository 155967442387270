import React, { useReducer, useRef } from 'react';
import reducer, { initialState, setIsDetail } from './vitalSignsMonitoringReducer';
import VitalSignsCardList from './Components/VitalSignsList';
import ViewDetail from './Components/ViewDetail';
import Button from '../../Common/Button';
import FilterCheckbox from './Components/FilterCheckbox';
import useTranslation from '@hooks/useTranslation';
import WidgetCard from '../Components/WidgetCard';

/**
 * 활력징후 모니터링 위젯
 *
 * @since 2022-12-16
 * @author kunwoong-kim
 * */
export const VitalSignsMonitoringStateContext = React.createContext();
export const VitalSignsMonitoringDispatchContext = React.createContext();

const VitalSignsMonitoring = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation();
    const { config } = widgetInfo;
    const widgetRef = useRef();
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <VitalSignsMonitoringDispatchContext.Provider value={dispatch}>
            <VitalSignsMonitoringStateContext.Provider value={state}>
                <WidgetCard
                    ref={widgetRef}
                    widgetInfo={widgetInfo}
                    headerAction={
                        state.isDetail ? (
                            <Button className="btn-lightgray" onClick={() => dispatch(setIsDetail(false))}>
                                {t('Back', 'Button')}
                            </Button>
                        ) : (
                            <FilterCheckbox />
                        )
                    }
                    {...restProps}
                >
                    {state.isDetail ? (
                        <ViewDetail targetNum={state.targetNum} />
                    ) : (
                        <VitalSignsCardList config={config} />
                    )}
                    {children}
                </WidgetCard>
            </VitalSignsMonitoringStateContext.Provider>
        </VitalSignsMonitoringDispatchContext.Provider>
    );
};

export default VitalSignsMonitoring;

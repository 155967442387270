import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMode, setFullScreen, SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import { useTranslation } from '@hooks';
import ScreenTitle from './ScreenTitle';
import ScreenFrame from '../ScreenFrame';
import Button from '@components/Button';
import useWidgetGenerator from '../util/useWidgetGenerator';
import { A_PATH_CUSTOM_SCREEN } from '../../Router/path';
import styled from 'styled-components';
import cx from 'classnames';

const CustomScreen = () => {
    const { params } = useRouteMatch(A_PATH_CUSTOM_SCREEN) || { params: {} };
    const t = useTranslation('Dashboard');
    const dispatch = useDispatch();
    const { originScreenList } = useSelector(state => state.CustomMenu);
    const [screenInfo, setScreenInfo] = useState({});
    const { layouts, Widgets } = useWidgetGenerator(screenInfo.widgetInfo);
    const { fullScreen } = useSelector(state => state.ScreenInfo);

    const handleFullscreenChange = () => {
        if (document.fullscreenElement) {
            dispatch(setFullScreen(true));
        } else {
            dispatch(setFullScreen(false));
        }
    };

    useEffect(() => {
        dispatch(setMode(SCREEN_MODE_MONITORING));

        const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
        dashboardEl.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            dashboardEl.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        const { menuCode, screenCode } = params;
        const originScreen =
            originScreenList.find(screen => screen.menuCode === menuCode && screen.screenCode === screenCode) || {};
        const selectedScreen = { ...originScreen };
        try {
            const { widgetInfo } = originScreen;
            selectedScreen.widgetInfo = JSON.parse(widgetInfo);
        } catch (e) {
            selectedScreen.widgetInfo = {};
        }

        setScreenInfo(selectedScreen);
    }, [params.screenCode, originScreenList]);

    return (
        <>
            {params.screenCode ? (
                <>
                    {/*{fullScreen && <ScreenTitle title={screenInfo.screenName} />}*/}
                    <ScreenFrame layouts={layouts}>{Widgets}</ScreenFrame>
                </>
            ) : (
                <div className={cx('app-page-content', fullScreen && 'dashboard-fullscreen')}>
                    <EmptyScreenWrapper className={'flex-center'}>
                        {t('This screen does not exist.')}
                        <Link to={'/'}>
                            <Button>{t('Go to home screen')}</Button>
                        </Link>
                    </EmptyScreenWrapper>
                </div>
            )}
        </>
    );
};

const EmptyScreenWrapper = styled.div`
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 80px);
    font-size: 2rem;
    color: #777;
`;

export default CustomScreen;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';
import { TreeSelectContext } from '@components/Select/TreeSelect';
import { components } from 'react-select';
import Tree from '@components/Select/TreeSelect/Tree';
import { mergeSelected, findAllOptions, findAllChildren } from '@components/Select/TreeSelect/Parts';
import { CustomTreeSelectContext, CustomTreeSelectDispatchContext } from './index';
import { setEditable } from '../reducer';
import { FilterGeneratorContext } from '../../../FilterGenerator';

export const TreeMenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const t = useTranslation('TreeSelect');
    const filterT = useTranslation('Filter');
    const selected = getValue();
    const { valueKey, labelKey, treeKey, parentKey, flattenData } = useContext(TreeSelectContext);
    const dispatch = useContext(CustomTreeSelectDispatchContext);
    const { filterEditMode } = useContext(FilterGeneratorContext);
    const { isEditable } = useContext(CustomTreeSelectContext);

    const mergedSelection = useMemo(() => {
        return mergeSelected(flattenData, treeKey, parentKey, selected);
    }, [treeKey, parentKey, flattenData, selected]);

    const allOptions = useMemo(() => {
        return findAllOptions(options);
    }, [options]);

    const handleChange = e => {
        const { checked } = e.currentTarget;
        setValue(checked ? allOptions : []);
    };

    // // 초기 체크될 값
    // const [checked, setChecked] = useState(selected ? makeCheckedDescendantsNode(selected.map(v => v[valueKey])) : []);
    const [checked, setChecked] = useState(
        selected.map(v => {
            if (typeof v === 'object') {
                return v[valueKey];
            }
            return v;
        }),
    );

    const handleCheck = useCallback(checked => {
        setChecked(checked);
    }, []);

    useEffect(() => {
        setChecked(
            selected.map(v => {
                if (typeof v === 'object') {
                    return v[valueKey];
                }
                return v;
            }),
        );
    }, [selected.length]);

    useEffect(() => {
        const checkedToStr = checked.map(v => v.toString());
        setValue(flattenData.filter(v => checkedToStr.includes(v[valueKey].toString())).map(v => v[valueKey]));
    }, [checked, flattenData, valueKey]);

    return (
        <components.MenuList {...restProps}>
            <div className={'mb-2'}>
                <div className={'select-group-title'}>{t('Selected')}</div>
                {mergedSelection.length ? (
                    mergedSelection.map((v, index) => {
                        return (
                            <div
                                key={v[valueKey] + index}
                                className={cx(
                                    'styled-option d-flex',
                                    isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                                )}
                                onClick={e => {
                                    const filtered = mergedSelection.filter(
                                        selectedOption => selectedOption[valueKey] !== v[valueKey],
                                    );
                                    // setValue(filtered);
                                    setChecked(
                                        findAllChildren(flattenData, treeKey, parentKey, filtered).map(
                                            selectedOption => selectedOption[valueKey],
                                        ),
                                    );
                                }}
                            >
                                <label className="pnt-checkbox h-auto" htmlFor={v[labelKey]}>
                                    <input
                                        id={v[labelKey]}
                                        type="checkbox"
                                        style={{ cursor: 'pointer' }}
                                        value={v[valueKey]}
                                        checked
                                        onChange={() => null}
                                    />
                                    <span className="material-icons-round md-18 color-secondary">check_box</span>
                                    <p>{v[labelKey]}</p>
                                </label>
                            </div>
                        );
                    })
                ) : (
                    <div className={'styled-option-label'}>{t('Not Selected')}</div>
                )}
            </div>
            <div
                className={cx(
                    'select-group-title pt-0 pb-0',
                    isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                )}
            >
                <label className="pnt-checkbox h-auto" htmlFor={'All_Items'}>
                    <input
                        id={'All_Items'}
                        type="checkbox"
                        onChange={handleChange}
                        checked={selected.length === allOptions.length}
                    />
                    <span
                        className={`material-icons-round md-18 ${
                            selected.length === allOptions.length ? 'color-secondary' : ''
                        }`}
                    >
                        {selected.length === allOptions.length ? 'check_box' : 'check_box_outline_blank'}
                    </span>
                    <p>{t('All Items')}</p>
                </label>
            </div>
            {!selected.length && !options.length ? (
                <div className={'styled-option-label'}>{t('No matches found')}</div>
            ) : (
                <div
                    className={cx(isEditable !== 'Y' && !filterEditMode && 'disabled-tree-option')}
                    style={{ padding: '.5rem' }}
                >
                    <Tree data={options} checked={checked} setChecked={handleCheck} />
                </div>
            )}
            <div className={cx(!filterEditMode && 'disabled-option')}>
                <div className={'select-group-title'}>{filterT('Option')}</div>
                <div className={'styled-option'}>
                    <label
                        className="pnt-checkbox h-auto"
                        htmlFor={'Permission'}
                        title={filterT('Permission to change')}
                    >
                        <input
                            id={'Permission'}
                            type="checkbox"
                            checked={isEditable === 'Y'}
                            onChange={e => {
                                dispatch(setEditable(e.currentTarget.checked ? 'Y' : 'N'));
                            }}
                        />
                        <span className={`material-icons-round md-18 ${isEditable === 'Y' ? 'color-secondary' : ''}`}>
                            {isEditable === 'Y' ? 'check_box' : 'check_box_outline_blank'}
                        </span>
                        <p>{filterT('Permission to change')}</p>
                    </label>
                </div>
            </div>
        </components.MenuList>
    );
};

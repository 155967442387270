import React, { useState, useContext, useMemo } from 'react';
import Modal from '@components/Modal';
import { ModalBody, ModalFooter } from 'reactstrap';
import useTranslation from '@hooks/useTranslation';
import { Button, Label, Table, TextInput } from '@components';
import { addUser, searchMappedUserList, setSearchedListInfo, unmapUser } from '../reducer';
import { AuthorizationGroupDetailContext, AuthorizationGroupDetailDispatchContext } from '../index';
import { getOwnershipListApi } from '@api/asset';
import useAsync from '@hooks/useAsync';
import Select from '@components/Select';
import { fetchGroupUserList } from '@api/menu';

export const MODAL_MODE_MAPP = 'mapp';
export const MODAL_MODE_UNMAPP = 'unmapp';

const initSearchInfo = { userId: '', userName: '', groupNum: null };

const BulkProcessModal = ({ initModal, mode, toggleModal, okCallback, cancelCallback }) => {
    const t = useTranslation('AuthorizationGroup');
    const title = mode === MODAL_MODE_MAPP ? t('Add user') : t('Unmap user');
    const [groupList, setGroupList] = useState([]);
    const finalInitSearchInfo = useMemo(() => {
        return { ...initSearchInfo, groupNum: groupList[0]?.groupNum };
    }, [groupList]);
    const [searchInfo, setSearchInfo] = useState(finalInitSearchInfo);
    const { searchedListInfo, userListInfo, origin, editing } = useContext(AuthorizationGroupDetailContext);
    const dispatch = useContext(AuthorizationGroupDetailDispatchContext);

    const handleToggleModal = () => {
        toggleModal();
        setSearchInfo(finalInitSearchInfo);
        dispatch(setSearchedListInfo([]));
    };

    useAsync({
        immediate: true,
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            let groupList = [];
            if (res.rows) {
                groupList = res.rows.sort((a, b) => (a.groupName > b.groupName ? 1 : -1));
            }
            setGroupList(groupList);
            if (groupList.length) {
                setSearchInfo({ ...searchInfo, groupNum: groupList[0].groupNum });
            }
        },
        reject: err => {
            console.log({ err });
            setGroupList([]);
        },
    });

    const {
        promise: getUserList,
        state: { request: reqInfo },
    } = useAsync({
        promise: fetchGroupUserList,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            let searchedList = res.rows ? res.rows : [];
            // API 조회 목록 중에서 이미 추가된 항목 제외
            searchedList = searchedList.filter(({ userNum }) => !editing.authUserNumList.includes(userNum));
            if (reqInfo) {
                const { userId, userName, groupNum } = reqInfo;
                // 기존에 추가되어 있던 목록(원천 데이터) 중에서 UI 목록에서 제외한 항목 추가
                searchedList = searchedList.concat(
                    searchMappedUserList({
                        searchParam: {
                            id: userId,
                            name: userName,
                            groupNum: groupNum,
                        },
                        searchList: origin.authUserList.filter(
                            ({ userNum }) => !editing.authUserNumList.includes(userNum),
                        ),
                    }),
                );
            }
            dispatch(setSearchedListInfo(searchedList));
        },
        reject: err => {
            console.log(err);
            dispatch(setSearchedListInfo([]));
        },
    });

    const handleSearch = () => {
        if (mode === MODAL_MODE_MAPP) {
            const searchParam = {};
            for (let key in searchInfo) {
                if (searchInfo.hasOwnProperty(key) && searchInfo[key]) {
                    searchParam[key] = searchInfo[key];
                }
            }
            getUserList(searchParam);
        } else {
            dispatch(
                setSearchedListInfo(
                    searchMappedUserList({
                        searchParam: {
                            id: searchInfo.userId,
                            name: searchInfo.userName,
                            groupNum: searchInfo.groupNum,
                        },
                        searchList: userListInfo.rows,
                    }),
                ),
            );
        }
    };

    const columns = useMemo(() => {
        return [
            {
                Header: t('ID'),
                className: 'pr-3 pl-3 text-ellipsis flex-center',
                accessor: 'userId',
                width: 120,
                Cell: ({ value }) => {
                    return <div className={'w-100 text-ellipsis'}>{value}</div>;
                },
            },
            {
                Header: t('Name'),
                className: 'pr-3 pl-3 text-ellipsis flex-center',
                accessor: 'userName',
                Cell: ({ value }) => {
                    return <div className={'w-100 text-ellipsis'}>{value}</div>;
                },
            },
            {
                Header: t('User Group'),
                className: 'pr-3 pl-3 text-ellipsis',
                accessor: 'loginGroupList',
                formatTitle: ({ value }) => value[0].groupName + (value[1] ? ` ${t('other')} ${value.length - 1}` : ''),
                Cell: ({ value }) => {
                    const label = value[0].groupName + (value[1] ? ` ${t('other')} ${value.length - 1}` : '');
                    return value[0] ? <Label name={label} /> : null;
                },
            },
        ];
    }, [t]);

    return (
        <Modal
            initModal={initModal}
            headerTitle={title}
            okCallback={okCallback}
            cancelCallback={cancelCallback}
            toggleModal={handleToggleModal}
            modalFooter={
                <ModalFooter>
                    <Button className={'btn-gray'} onClick={handleToggleModal}>
                        {t('Cancel', 'Button')}
                    </Button>

                    <Button
                        className={'btn-secondary'}
                        onClick={e => {
                            if (mode === MODAL_MODE_MAPP) {
                                dispatch(addUser(searchedListInfo.rows));
                            } else {
                                dispatch(unmapUser(searchedListInfo.rows.map(({ userNum }) => userNum)));
                            }
                            handleToggleModal();
                        }}
                    >
                        {mode === MODAL_MODE_MAPP ? t('Add') : t('Remove')}
                    </Button>
                </ModalFooter>
            }
        >
            <ModalBody>
                <Label
                    labelGroupClassName={'w-100 mb-3'}
                    name={t('ID')}
                    labelValueClassName={'label-dot w-30'}
                    value={
                        <TextInput
                            inputGroupClassName={'w-100'}
                            name={'userId'}
                            value={searchInfo.userId}
                            placeholder={t('Please enter a ID')}
                            handleChange={e => setSearchInfo({ ...searchInfo, userId: e.currentTarget.value })}
                        />
                    }
                />
                <Label
                    labelGroupClassName={'w-100 mb-3'}
                    name={t('Name')}
                    labelValueClassName={'label-dot w-30'}
                    value={
                        <TextInput
                            inputGroupClassName={'w-100'}
                            name={'userName'}
                            value={searchInfo.userName}
                            placeholder={t('Please enter a name')}
                            handleChange={e => setSearchInfo({ ...searchInfo, userName: e.currentTarget.value })}
                        />
                    }
                />
                <Label
                    labelGroupClassName={'w-100 mb-3'}
                    name={t('User Group')}
                    labelValueClassName={'label-dot w-30'}
                    value={
                        <div className={'w-100'}>
                            <Select
                                customControlStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                                value={groupList.find(group => group.groupNum === searchInfo.groupNum)}
                                options={groupList}
                                valueKey={'groupNum'}
                                labelKey={'groupName'}
                                onChange={selected => {
                                    setSearchInfo({ ...searchInfo, groupNum: selected.groupNum });
                                }}
                                isModalSelect
                            />
                        </div>
                    }
                />
                <div className={'d-flex mb-3'}>
                    <div className={'d-flex justify-content-end gap-1 w-100'}>
                        <Button className="btn-secondary" onClick={handleSearch}>
                            {t('Search', 'Search')}
                        </Button>
                    </div>
                </div>
                <div className={'w-100'}>
                    <div style={{ height: '300px' }}>
                        <Table paging={false} columns={columns} data={searchedListInfo} />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default BulkProcessModal;

import React, { useContext, useEffect, useRef, useState } from 'react';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import styled from 'styled-components';
import { HeatMapDispatchContext, HeatMapStateContext } from '../index';
import { setPlay, setPlayTime, setRepeatSectionEnd, setRepeatSectionStart, setSliderUpdate } from '../heatMapReducer';
import { dateToFormat } from '@util/common/util';
import useTranslation from '@hooks/useTranslation';
import Button from '@components/Button';

export const PlayControllerBar = () => {
    const dispatch = useContext(HeatMapDispatchContext);
    const { play, playTime, startTime, endTime } = useContext(HeatMapStateContext);
    const slideThrottle = useRef();
    const tempPlayState = useRef(null);

    return (
        <StyledNouislider
            className="tracing-play-bar"
            animate={false}
            connect={[true, false]}
            step={1}
            start={[playTime]}
            range={{ min: startTime, max: endTime }}
            onSlide={(render, handle, value) => {
                if (play) {
                    tempPlayState.current = true;
                    dispatch(setPlay(false));
                } else {
                    tempPlayState.current = false;
                }
                dispatch(setSliderUpdate(value[0]));
                dispatch(setPlayTime(value[0]));
                if (slideThrottle.current) {
                    clearTimeout(slideThrottle.current);
                }
                slideThrottle.current = setTimeout(() => {
                    dispatch(setSliderUpdate(value[0]));
                    slideThrottle.current = null;
                    if (tempPlayState.current) {
                        dispatch(setPlay(true));
                    }
                }, 200);
            }}
        />
    );
};

const PlayController = () => {
    const t = useTranslation('Button');
    const dispatch = useContext(HeatMapDispatchContext);
    const { play: playState, playTime, startTime, endTime } = useContext(HeatMapStateContext);

    const playInterval = useRef();

    const play = () => {
        playInterval.current = setInterval(() => {
            dispatch(setPlayTime());
        }, 1000 / 64);
    };

    const pause = () => {
        clearInterval(playInterval.current);
    };

    const handlePlayClick = () => {
        dispatch(setPlay(!playState));
    };

    useEffect(() => {
        if (playState) {
            play();
        } else {
            pause();
        }
        return () => {
            pause();
        };
    }, [playState]);

    useEffect(() => {
        if (playState && playTime >= endTime) {
            dispatch(setPlay(false));
            dispatch(setPlayTime(startTime));
            pause();
        }
    }, [playTime]);

    const [repeatSectionStartButtonState, setRepeatSectionStartButtonState] = useState(true);
    const [repeatSectionEndButtonState, setRepeatSectionEndButtonState] = useState(false);

    const handleRepeatSectionStartTimeSetting = () => {
        dispatch(setRepeatSectionStart({ status: true, playTime }));
        setRepeatSectionStartButtonState(!repeatSectionStartButtonState);
        setRepeatSectionEndButtonState(!repeatSectionEndButtonState);
    };

    const handleRepeatSectionEndTimeSetting = () => {
        dispatch(setRepeatSectionEnd({ status: true, playTime }));
        setRepeatSectionEndButtonState(!repeatSectionEndButtonState);
    };

    const handleRepeatSectionSetting = () => {
        dispatch(setRepeatSectionStart({ status: false, playTime }));
        dispatch(setRepeatSectionEnd({ status: false, playTime }));
        setRepeatSectionStartButtonState(true);
        setRepeatSectionEndButtonState(false);
    };

    const handleShowAllClick = () => {
        dispatch(setPlayTime(endTime));
    };

    return (
        <div className="play-body-custom w-100 h-20">
            <div className="body-container">
                <div className="mb-2 row-custom">
                    <PlayControllerBar />
                    <div className={'flx-sb mb-3 p-0'}>
                        <span className="play-time">
                            {dateToFormat(startTime * 1000, 'START_MONTH_2').replace(' ', ' / ')}
                        </span>
                        <span className="play-time">
                            {dateToFormat(playTime * 1000, 'START_MONTH_2').replace(' ', ' / ')}
                        </span>
                        <span className="play-time">
                            {dateToFormat(endTime * 1000, 'START_MONTH_2').replace(' ', ' / ')}
                        </span>
                    </div>
                </div>
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <Button
                            className="play-button btn-darkgray btn-icon-only br-4"
                            iconName={`${!playState ? 'play_arrow' : 'pause'}`}
                            onClick={handlePlayClick}
                        />
                        <div className="d-flex">
                            <Button className="show-all-button btn-secondary p-3" onClick={handleShowAllClick}>
                                {t('Show All')}
                            </Button>
                            {repeatSectionStartButtonState && (
                                <Button className="btn-secondary p-3" onClick={handleRepeatSectionStartTimeSetting}>
                                    {t('Start of repeat section')}
                                </Button>
                            )}
                            {repeatSectionEndButtonState && (
                                <Button className=" btn-secondary p-3" onClick={handleRepeatSectionEndTimeSetting}>
                                    {t('Cancel repeat section')}
                                </Button>
                            )}
                            {!repeatSectionStartButtonState && !repeatSectionEndButtonState && (
                                <Button className="btn-secondary p-3" onClick={handleRepeatSectionSetting}>
                                    {t('End of repeat section')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StyledNouislider = styled(Nouislider)`
    & .noUi-base {
        background-color: #dcd9e1 !important;
    }
    & .noUi-handle {
        cursor: pointer;
    }
    & .noUi-connect {
        background-color: #6748e3 !important;
    }
`;

export default PlayController;

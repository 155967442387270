import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@hooks';
import Draggable from 'react-draggable';
import moment from 'moment';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import Table from '../../../Common/Table';
import { useGeofenceTargetInfoColumns } from '../../../../util/grid/column';
import { useSelector } from 'react-redux';

const GeofenceInfoPopup = ({ modal, toggleModal, widgetRef, mapRef, selectedEvent }) => {
    const t = useTranslation('GeofenceStatus');
    const { floorList } = useSelector(state => state.FloorInfo);
    const { tagListByFloor } = useSelector(state => state.TagInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const [itemList, setItemList] = useState({ pageSize: 5, rows: [] });
    const [geofenceInfo, setGeofenceInfo] = useState({
        floorName: '',
    });

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        x: 0,
        y: 0,
    });

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    useEffect(() => {
        if (selectedEvent.hasOwnProperty('floor')) {
            setGeofenceInfo({
                ...geofenceInfo,
                floorName: floorList.find(floor => {
                    return floor.floorId === selectedEvent.floor;
                }).floorName,
            });
        }
    }, [selectedEvent]);

    useEffect(() => {
        const targetList =
            selectedEvent.hasOwnProperty('floor') &&
            Object.keys(tagListByFloor).length > 0 &&
            tagListByFloor[selectedEvent.floor];

        const filteredTargetList =
            targetList && targetList.length > 0
                ? targetList
                      .filter(v => {
                          return v.geofenceInOutState[selectedEvent.fcNum] && v.geofenceInOutState[selectedEvent.fcNum];
                      })
                      .map(v => {
                          const category = categoryList.find(category => {
                              return v.target.categoryCode === category.categoryCode;
                          });
                          return {
                              targetName: v.target.targetName,
                              category: category && category.categoryNamePath,
                              authorized:
                                  v.geofenceInOutState[selectedEvent.fcNum].permitted === 'unAuthorized' ? 'N' : 'Y',
                              entryTime: moment
                                  .unix(v.geofenceInOutState[selectedEvent.fcNum].inTime)
                                  .format('YYYY-MM-DD HH:mm:ss'),
                          };
                      })
                : [];
        setItemList({ ...itemList, rows: filteredTargetList });
    }, [tagListByFloor]);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;

        setPosition({
            x: x,
            y: y,
        });
    }, [popupSize]);

    const getPosition = (el, position = { top: 0, left: 0 }) => {
        if (el) {
            position.top += el.offsetTop;
            position.left += el.offsetLeft;
            getPosition(el.offsetParent, position);
        }
        return position;
    };

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (widgetRef.current) {
            const widget = widgetRef.current;

            const { offsetWidth: width, offsetHeight: height } = widget;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds=".leaflet-container"
            defaultPosition={position}
            onStop={() => mapRef.current.leafletElement.dragging.enable()}
            onStart={() => mapRef.current.leafletElement.dragging.disable()}
        >
            <Toast isOpen={modal} className={'popup-open'} innerRef={popupRef} style={{ height: '330px' }}>
                <ToastHeader toggle={toggleModal}>
                    {t('Geofence Name')} : {selectedEvent.fcName} | {t('Floor')} :{geofenceInfo.floorName}
                </ToastHeader>
                <ToastBody>
                    <Table
                        columns={useGeofenceTargetInfoColumns()}
                        data={{
                            rows: itemList.rows,
                            pageSize: 5,
                            totalPage: itemList.rows.length % 5 === 0 ? itemList.rows / 5 : itemList.rows / 5 + 1,
                            totalCount: itemList.rows.length,
                        }}
                        manualPagination={false}
                        autoResetPage={false}
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default GeofenceInfoPopup;

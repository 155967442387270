import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import ReactResizeDetector from 'react-resize-detector';
import { useAppSelector, useTranslation } from '@hooks';
import NoDataBlock from '../../Components/NoDataBlock';
import Charts from '@components/Charts/Highcharts';

const CHART_OPTIONS = {
    chart: {
        backgroundColor: null,
        type: 'pie',
        plotShadow: false,
        margin: 2,
    },
    title: {
        text: null,
    },
    plotOptions: {
        pie: {
            dataLabels: {
                enabled: false,
            },
            showInLegend: true,
        },
    },
    legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemMarginTop: 3,
        itemMarginBottom: 3,
        symbolRadius: 0,
        itemHiddenStyle: {
            color: '#77777C',
        },
        itemHoverStyle: {
            color: null,
        },
        itemStyle: {
            color: 'black',
        },
    },
    series: [
        {
            name: 'Count',
            data: [
                {
                    name: '',
                    y: 0,
                },
            ],
        },
    ],
    credits: false,
};

interface ChartData {
    ranking: number;
    key: string;
    name: string;
    y: number;
}

interface Props {
    data: ChartData[];
    tableHeight: string;
}

const ResponsivePieChart = ({ data, tableHeight }: Props) => {
    const t = useTranslation('ItemDetailStatus');
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const [chartOptions, setChartOptions] = useState(CHART_OPTIONS);
    const [containerProps, setContainerProps] = useState({
        style: {
            width: 0,
            height: 0,
        },
    });

    useEffect(() => {
        setChartOptions(prevState => ({
            ...prevState,
            legend: {
                ...prevState.legend,
                itemStyle: {
                    color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                },
            },
        }));
    }, [colorScheme]);

    useEffect(() => {
        setChartOptions(prevState => ({
            ...prevState,
            series: [
                {
                    name: t('Count'),
                    data,
                },
            ],
        }));
    }, [data, t]);

    const onResize = useCallback((width: number, height: number) => {
        setContainerProps({
            style: {
                width,
                height,
            },
        });
    }, []);

    return (
        <>
            {!data.length ? (
                <NoDataBlock />
            ) : (
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={100}
                    handleHeight
                    handleWidth
                    onResize={onResize}
                >
                    <Charts
                        reflowDeps={[containerProps, tableHeight]}
                        containerProps={containerProps}
                        options={chartOptions}
                    />
                </ReactResizeDetector>
            )}
        </>
    );
};

export default ResponsivePieChart;

import React, { useContext, useEffect, useRef } from 'react';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { SearchWrap, SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import { Button, DatePicker, SearchableSelect, Select as ReactSelect, TextInput } from '@components';

import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import useTranslation from '@hooks/useTranslation';
import FloorTreeSelect from '../../Components/FloorTreeSelect';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useConfirmModal from '@hooks/useConfirmModal';
import { makeParameter } from '../../util/commons';
import { reset, setFloorId, setFloorIdPath, setGeofence, setKeyword, setSearchOption } from '../geofenceLogReducer';
import { GeofenceLogDispatchContext, GeofenceLogStateContext } from '../index';
import { ButtonGroup } from 'reactstrap';

/**
 *
 * 지오펜스 로그 검색 기능 컴포넌트
 *
 * @param {function} promise 지오펜스 로그 리스트 호출 callback
 * @param {date} startDate  검색 시작 일자 set 함수
 * @param {date} endDate 검색 마지막 일자 set 함수
 * @param {function} handleDateCallback  날짜 변경 함수
 *
 * @author jinbeom-jung
 * */

const Search = ({ promise, startDate, endDate, handleDateCallback }) => {
    const t = useTranslation('Geofence Log');
    const dispatch = useContext(GeofenceLogDispatchContext);
    const { keyword, searchOption, fcNums, geofenceList, floorIds, floorIdPath } = useContext(GeofenceLogStateContext);

    const { floorList, selectableFloorList } = useSelector(state => state.FloorInfo);

    const handleSearchInputChange = e => {
        const { value } = e.target;
        dispatch(setKeyword(value));
    };

    const handleSelectedFloor = selected => {
        const { floorId, floorIdPath, floorName } = selected[0];
        dispatch(setFloorId({ floorId, floorName }));
        dispatch(setFloorIdPath(floorIdPath));
    };

    const handleSelectedGeofence = selected => dispatch(setGeofence(selected.map(({ value }) => value)));
    const searchOptions = [
        { value: '', label: t('Search Option', 'Search') },
        { value: 'targetName', label: t('Target Name') },
        { value: 'targetId', label: t('Target ID') },
    ];
    const handleSelectValueChange = selected => {
        const { value } = selected;
        if (value === '') {
            dispatch(setKeyword(''));
        }
        dispatch(setSearchOption(selected));
    };

    const handleSearchClick = () => {
        const params = {
            keyword: keyword,
            opt: searchOption.value,
            fcNums: fcNums.join(','),
            floorIds: floorIds.floorId,
            floorIdPath: floorIdPath,
        };
        if (startDate) {
            if (!endDate) {
                toggleWarningModal();
                return false;
            } else {
                params['startDate'] = moment(startDate).unix();
                params['endDate'] = moment(endDate).unix();
            }
        }
        const result = makeParameter({ searchRequirement: params });
        promise({
            ...result,
        });
    };

    const handleYesterdayButtonClick = () => {
        handleDateCallback({ selected: 0, type: 'yesterday' });
    };

    const handleRefreshClick = () => {
        const { floorId, floorIdPath, floorName } = selectableFloorList[0];
        dispatch(
            reset({
                keyword: '',
                searchOption: searchOptions[0],
                fcNums: geofenceList.map(({ value }) => value),
                floorIds: { floorId, floorName },
                floorIdPath: floorIdPath,
                geofenceList: geofenceList,
            }),
        );
        handleDateCallback({ selected: 0, type: 'reset' });
        promise({
            startDate: moment().startOf('day').unix(),
            endDate: moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').unix(),
            fcNums: geofenceList.map(({ value }) => value).join(','),
            floorIds: floorIds.floorId,
            floorIdPath: floorIdPath,
        });
    };
    useEffect(() => {
        const { floorId, floorIdPath, floorName } = selectableFloorList[0];
        dispatch(setFloorId({ floorId, floorName }));
        dispatch(setFloorIdPath(floorIdPath));
        dispatch(setSearchOption(searchOptions[0]));
    }, []);

    const { Modal: WarningModal, toggleModal: toggleWarningModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Please select an end date', 'ConfirmModal'),
    });

    const handleStartDate = selected => {
        if (endDate > selected) {
            handleDateCallback({ selected, type: 'startDate' });
        }
    };
    const handleEndDate = selected => {
        if (startDate < selected) {
            handleDateCallback({ selected, type: 'endDate' });
        }
    };

    const inputStartRef = useRef(null);
    const inputEndRef = useRef(null);
    useEffect(() => {
        if (inputStartRef && inputStartRef.current) {
            inputStartRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
        if (inputEndRef && inputEndRef.current) {
            inputEndRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
    }, [startDate, endDate]);

    return (
        <>
            <FilterSearchGroup className={'p-0'}>
                <SearchGroup>
                    <SelectGroup>
                        <FloorTreeSelect
                            mode="radioSelect"
                            floorList={floorList}
                            selectedFloorStr={floorIds.floorId}
                            handleChange={handleSelectedFloor}
                        />
                    </SelectGroup>
                    <SelectGroup>
                        <SearchableSelect
                            title={t('Geofence')}
                            data={geofenceList}
                            selected={fcNums}
                            onChange={handleSelectedGeofence}
                        />
                    </SelectGroup>
                </SearchGroup>
                <SearchGroup>
                    <SelectGroup className={'w-20'}>
                        <div className="datePicker-container" ref={inputStartRef}>
                            <DatePicker
                                key={`dp_start_${startDate}_${endDate}`}
                                value={startDate}
                                valueType="ms"
                                maxDate={endDate}
                                handleChange={handleStartDate}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center">~</div>
                    <SelectGroup className={'w-20'}>
                        <div className="datePicker-container" ref={inputEndRef}>
                            <DatePicker
                                key={`dp_end_${startDate}_${endDate}`}
                                value={endDate}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment().valueOf()}
                                disabled={!startDate}
                                handleChange={handleEndDate}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <Button className="btn-gray" onClick={handleYesterdayButtonClick}>
                        {t('Yesterday', 'Button')}
                    </Button>
                </SearchGroup>
                <SearchGroup>
                    <SearchWrap>
                        <SelectGroup>
                            <ReactSelect
                                name="selectInput"
                                placeholder={t('Search Option', 'Search')}
                                value={searchOption}
                                options={searchOptions}
                                onChange={handleSelectValueChange}
                                customControlStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </SelectGroup>
                        <TextInput
                            type="text"
                            name="name"
                            value={keyword}
                            size={60}
                            disabled={!searchOption.value}
                            handleChange={handleSearchInputChange}
                            placeholder={t('Please Enter your search keyword after selecting search option.', 'Search')}
                        />
                        <ButtonGroup>
                            <Button className="btn-secondary mr-2" onClick={handleSearchClick}>
                                {t('Search', 'Search')}
                            </Button>
                            <Button
                                className="btn-line btn-icon-only"
                                iconName="refresh"
                                onClick={handleRefreshClick}
                            />
                        </ButtonGroup>
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>
            <WarningModal />
        </>
    );
};

export default Search;

import React from 'react';
import cx from 'classnames';

/**
 * @param {Array} option  // 테마 컬러값
 * @param {function} onClick  // 테마 컬러 핸들링
 * @param {string} selectedColorScheme // 선택한 테마 컬러
 *
 @author created bu 정진범 2022-12-08
 */

const ThemeSwatch = ({ option, onClick, selectedColorScheme }) => {
    return (
        <div className="theme-settings-swatches">
            <div className="theme-settings-swatches">
                {option.map((className, idx) => {
                    return (
                        <div
                            key={idx}
                            className={cx('swatch-holder cursor-pointer', className, {
                                active: selectedColorScheme === className,
                            })}
                            onClick={() => onClick(className)}
                        />
                    );
                })}
            </div>
        </div>
    );
};
export default ThemeSwatch;

import React from 'react';
import styled from 'styled-components';
import useTranslation from '../../../../../../util/hooks/useTranslation';
import cx from 'classnames';

const MonthlyTable = ({ columns, rows }) => {
    const t = useTranslation('RefrigeratorSensor');

    return (
        <TableContainer>
            <thead className="bg-depth-7">
                <tr>
                    {/* date */}
                    <th rowSpan={2}>{t('Date')}</th>
                    {/* 9H */}
                    <th rowSpan={2}>{t('Time')}</th>
                    <th colSpan={2}>{`${t('Measured Temp')}(℃)`}</th>
                    <th>{'Reset'}</th>
                    <th rowSpan={2}>{t('Checker')}</th>
                    {/* 17H */}
                    <th rowSpan={2}>{t('Time')}</th>
                    <th colSpan={2}>{`${t('Measured Temp')}(℃)`}</th>
                    <th>{'Reset'}</th>
                    <th rowSpan={2}>{t('Checker')}</th>
                    <th rowSpan={2}>{t('Note')}</th>
                </tr>
                <tr>
                    {/* 9H */}
                    <th>{t('Current Temp')}</th>
                    <th>{'Max/Min'}</th>
                    <th>{`${t('Whether')} (Y/N)`}</th>
                    {/* 17H */}
                    <th>{t('Current Temp')}</th>
                    <th>{'Max/Min'}</th>
                    <th>{`${t('Whether')} (Y/N)`}</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((rowItem, rowIdx) => {
                    return (
                        <tr key={rowIdx} className={cx(rowIdx % 2 !== 0 && 'bg-depth-3')}>
                            {columns.map((columnItem, colIdx) => {
                                return (
                                    <TdContainer
                                        key={colIdx}
                                        width={columnItem.width}
                                        className={colIdx === 0 ? 'bg-depth-7' : ''}
                                    >
                                        {rowItem[columnItem.accessor]}
                                    </TdContainer>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </TableContainer>
    );
};

const TableContainer = styled.table`
    width: 100%;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-spacing: 0;
    border-collapse: separate;

    thead {
        border-bottom: 1px solid gray !important;
        tr {
            border-color: lightgray !important;
            & th {
                border-bottom: 1px solid lightgray;
            }
            @media print {
                background-color: #f6f4fa !important;
                & th:last-child {
                    border-right: 1px solid lightgray;
                }
            }
        }
        tr:first-child > th:first-child {
            border-left: none;
            @media print {
                border-left: 1px solid lightgray;
            }
        }
    }

    tbody {
        tr {
            & > td:first-child {
                border-left: none;
                @media print {
                    border-left: 1px solid lightgray;
                    background-color: #f6f4fa;
                }
            }

            @media print {
                & td:last-child {
                    border-right: 1px solid lightgray;
                }
            }
        }

        td {
            border-bottom: 1px solid lightgray;
        }
    }

    tr {
        align-content: center;
    }

    th,
    td {
        text-align: center;
        padding: 0.5rem 1rem;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-left-width: 1px;
        font-size: small;
        @media print {
            font-size: 10px;
            padding: 0.4rem 0.25rem;
        }
    }
`;

const TdContainer = styled.td`
    flex: ${props => (props.width ? (1 * props.width) / 100 : 1)};
    border-left-color: lightgray;
`;

export default MonthlyTable;

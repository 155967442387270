import React, { useContext, useRef } from 'react';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { fetchExcelLogApi, fetchExcelSensorLogExport } from '@api/log';
import moment from 'moment';
import { Button } from '@components';
import { excelExport } from '../../util/excelExport';
import { SensorLogStateContext } from '../index';
import { makeParameter } from '../../util/commons';

const INTERVAL_TIME = 10000;

/**
 *
 * 센서 로그 정보 엑셀로 출력하는 컴포넌트
 *
 * @param {number} listCount 리스트 카운트
 * @param {date} startDate 검색 시작 일자
 * @param {date} endDate  검색 마지막 일자
 *
 * @author jinbeom-jung
 * */

const ExcelExport = ({ listCount, startDate, endDate }) => {
    const t = useTranslation('Sensor Log');
    const excelTimerRef = useRef({});
    const { targetNums } = useContext(SensorLogStateContext);
    const { promise: createNewLogFile } = useAsync({
        promise: fetchExcelSensorLogExport,
        fixedParam: {
            startDate: startDate ? moment(startDate).unix() : moment(moment().format('YYYY-MM-DD')).unix(),
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            zipFileName: 'Sensor_Log',
            fileName: 'Sensor_Log',
            columnMetas: [
                { key: 'logNum', name: t('No.', 'CommonColumn') },
                { key: 'deviceName', name: t('Sensor Name', 'CommonColumn') },
                { key: 'deviceId', name: t('Sensor Id', 'CommonColumn') },
                { key: 'deviceType', name: t('Sensor Type', 'CommonColumn') },
                { key: 'VTBI', name: t('Injection Volume', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'TOTAL_DOSAGE',
                    name: t('Total Dose', 'CommonColumn'),

                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'STEP', name: t('Step Count', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'SPO2',
                    name: t('Blood Oxygen Saturation', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'SOUND_NOISE', name: t('Noise', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'SO2', name: t('SO2', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'SLEEP', name: t('Sleep Index', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'SEISMIC_INTENS',
                    name: t('Earthquake Intensity', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'R_HUMIDITY',
                    name: t('Relative Humidity', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'RESPIRATION_RATE',
                    name: t('Respiratory Rate', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'RATE', name: t('Infusion Rates', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'PULSE_RATE', name: t('Pulse Rate', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'PCA_BOLUS',
                    name: t('One Time PCA Injection', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'PACKET_COUNTER',
                    name: t('Number Of Packet Transmissions', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'O2', name: t('O2 ', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },

                { key: 'NAPPY_GAS', name: t('Diaper-Urine', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'NAPPY_SMALL', name: t('Diaper-Fart', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'Diaper-Feces', name: t('Diaper-Feces', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'MOVEMENT', name: t('Movement', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'H_BLOODPRESSURE', name: t('Diastolic', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'LEL', name: t('LEL', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'Injected Amount',
                    name: t('Injected Amount', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'L_BLOODPRESSURE',
                    name: t('Systolic Blood Pressure', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'HUMIDITY', name: t('Humidity', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'HEIGHT', name: t('Height', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'HEAT_STROKE', name: t('Heatstroke', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'WEIGHT', name: t('Weight', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'H2S', name: t('H2S', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'ETVOC',
                    name: t('Volatile Organic Compounds', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'ECO2',
                    name: t('Equivalent Carbon Dioxide', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'DUST', name: t('Dust', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'DROP_RATE', name: t('Drop Raid', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'DROP_COUNT', name: t('Drop Water', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'DOSAGE', name: t('Dosage', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'DISTANCE', name: t('Distance', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'DISCOMFORT_IDX',
                    name: t('Discomfort Index', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'CRACK', name: t('Crack', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'CONC', name: t('Density', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'CO', name: t('CO', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'CAPACITY', name: t('Capacity', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                { key: 'CALORIES', name: t('Calories', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'BUTTON2_COUNT',
                    name: t('Button Two Clicks', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'BUTTON1_COUNT',
                    name: t('Button One Clicks', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'BATTERY', name: t('Battery', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
                {
                    key: 'ACCELER_Z_AXIS',
                    name: t('Z-Axis Angular Velocity', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'ACCELER_Y_AXIS',
                    name: t('Y-Axis Angular Velocity', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'ACCELER_X_AXIS',
                    name: t('X-Axis Angular Velocity', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'ANBIENT_LIGHT',
                    name: t('Ambient Light', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'ANGULAR_Z_AXIS',
                    name: t('Z Side Acceleration', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'ANGULAR_Y_AXIS',
                    name: t('Y Side Acceleration', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                {
                    key: 'ANGULAR_X_AXIS',
                    name: t('X Side Acceleration', 'CommonColumn'),
                    isMapFormat: 'Y',
                    mapName: 'dataMap',
                },
                { key: 'TEMPERATURE', name: t('Temperature', 'CommonColumn'), isMapFormat: 'Y', mapName: 'dataMap' },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL({ reqNum });
            }
        },
        reject: err => {
            toggleExcelConfirmModal();
            console.error(err);
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName, reqNum } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                toggleExcelDownloadResultModal();
            }
            clearExcelTimer({ reqNum });
        },
    });

    const downloadWithURL = ({ reqNum }) => {
        excelTimerRef.current[reqNum] = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = ({ reqNum }) => {
        clearInterval(excelTimerRef.current[reqNum]);
        excelTimerRef.current[reqNum] = null;
    };

    const handleExportFileClick = () => {
        if (listCount) {
            const result = makeParameter({ searchRequirement: { targetNums: targetNums.join(',') }, useIsAll: true });
            createNewLogFile(result);
        } else {
            toggleExcelConfirmModal();
        }
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'Report') },
        confirmText: t('Failed to download the excel.', 'Report'),
    });

    const { Modal: ExcelConfirmModal, toggleModal: toggleExcelConfirmModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t('No data', 'Report'),
    });

    return (
        <>
            <Button className="btn-lightgray btn-icon" onClick={handleExportFileClick}>
                <span className={'material-icons-round'}>file_upload</span>
                {t('Export', 'Button')}
            </Button>
            <ExcelConfirmModal />
            <ExcelDownloadResultModal />
        </>
    );
};

export default ExcelExport;

import React, { useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import RDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import '../../../assets/main/datepicker.scss';
import { dateToFormat } from '@util/common/util';
import moment from 'moment';
import TimeInput from './Components/TimeInput';
import useTranslation from '../../../util/hooks/useTranslation';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { InputGroup, InputGroupAddon } from 'reactstrap';

import ko from 'date-fns/locale/ko';
import en from 'date-fns/locale/en-US';

registerLocale('en', en);
registerLocale('ko', ko);

const DatePicker = ({
    value,
    handleChange,
    withoutDay,
    withoutTime,
    valueType,
    readOnly,
    disabled = false,
    ...restProps
}) => {
    const t = useTranslation('DatePicker');
    const { lang } = useSelector(state => state.UserInfo);
    const dateFormat = useMemo(() => {
        if (withoutDay) {
            return {
                datepicker: 'yyyy-MM',
                title: 'YYYY-MM-DD',
                placeholderText: '---- - --',
            };
        }
        if (withoutTime) {
            return {
                datepicker: 'yyyy-MM-dd',
                title: 'YYYY-MM-DD',
                placeholderText: '---- - -- - --',
            };
        }

        return {
            datepicker: 'yyyy-MM-dd HH:mm',
            title: 'YYYY-MM-DD HH:mm',
            placeholderText: '---- - -- - -- -- : --',
        };
    }, [withoutTime]);
    const handleChangeDate = useCallback(
        selected => {
            let selectedDate = selected;
            switch (valueType) {
                case 'ms':
                    selectedDate = moment(selectedDate).valueOf();
                    break;
                case 's':
                default:
                    selectedDate = moment(selectedDate).unix();
                    break;
            }
            handleChange(selectedDate);
        },
        [valueType],
    );
    const selectedValue = useMemo(() => {
        let selectedDate = value;
        switch (valueType) {
            case 'ms':
                break;
            case 's':
            default:
                selectedDate = value * 1000;
                break;
        }
        return selectedDate;
    }, [value, valueType]);

    return (
        <div
            className={cx(
                'pnt-datepicker-container',
                restProps.showTimeInput && 'time-input',
                restProps.showTimeSelect && 'time-select',
                withoutTime && 'time-none',
            )}
        >
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                        <span className="material-icons-round md-18">calendar_month</span>
                    </div>
                </InputGroupAddon>
                <RDatePicker
                    locale={lang}
                    className={cx(
                        'form-control',
                        readOnly && 'cursor-default form-disable',
                        disabled && 'form-disable',
                    )}
                    autoComplete="off"
                    selected={selectedValue}
                    onChange={handleChangeDate}
                    dateFormat={dateFormat.datepicker}
                    title={dateToFormat(selectedValue, dateFormat.title)}
                    withPortal={isMobile}
                    placeholderText={dateFormat.placeholderText}
                    popperClassName={'datepicker-popper-display'}
                    shouldCloseOnSelect={!!withoutTime}
                    closeOnScroll={true}
                    timeInputLabel={t('Time')}
                    customTimeInput={<TimeInput />}
                    timeFormat={'HH:mm'}
                    timeIntervals={5}
                    readOnly={readOnly}
                    disabled={disabled}
                    {...restProps}
                />
            </InputGroup>
        </div>
    );
};

export default DatePicker;

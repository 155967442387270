import useAppSelector from '@hooks/useAppSelector';
import useAsync from '@hooks/useAsync';
import { fetchBookmarkList } from '@api/common';
import { useDispatch } from 'react-redux';
import { setBookmarkedList } from '@reducer/Common/BookmarkedItem';
import { useEffect } from 'react';

interface Parameter {
    forceUpdate?: boolean;
}

const useBookmarkedList = ({ forceUpdate }: Parameter = {}): number[] => {
    const dispatch = useDispatch();
    const { bookmarkedList, loaded } = useAppSelector(state => state.BookmarkedItem);
    const {
        promise: getBookmarkedList,
        state: { isLoading },
    } = useAsync({
        promise: fetchBookmarkList,
        param: {},
        resolve: (res: any) => {
            dispatch(setBookmarkedList((res?.rows ?? []).map(({ targetNum }: { targetNum: number }) => targetNum)));
        },
        reject: (err: any) => {
            console.log(err);
        },
    });

    useEffect(() => {
        if (!isLoading && (!loaded || forceUpdate)) {
            getBookmarkedList();
        }
    }, []);

    return bookmarkedList;
};

export default useBookmarkedList;

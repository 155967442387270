import React, { createContext, useReducer, useState } from 'react';
import Card from '../Components/WidgetCard';
import List from './Component/List';
import ExcelExport from './Component/ExcelExport';
import { useAsync } from '@hooks';
import { getIotItemInfoLogListApi } from '@api/log';
import Search from './Component/Search';
import reducer, { initState } from './iotItemInfoLogReducer';
import { useSelector } from 'react-redux';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';

/**
 * 아이템 정보 로그 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const IotItemInfoLogStateContext = createContext();
export const IotItemInfoLogDispatchContext = createContext();
const IotItemInfoLog = ({ children, widgetInfo, ...restProps }) => {
    const { mode } = useSelector(state => state.ScreenInfo);
    const [state, dispatch] = useReducer(reducer, initState);
    const [list, setList] = useState({ totalCount: 0, totalPage: 1, rows: [] });

    const { promise: getIotItemInfoLogList, state: iotItemLogListInfo } = useAsync({
        promise: getIotItemInfoLogListApi,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => setList(res),
        reject: err => console.error(err),
    });

    return (
        <IotItemInfoLogDispatchContext.Provider value={dispatch}>
            <IotItemInfoLogStateContext.Provider value={state}>
                <Card
                    widgetInfo={widgetInfo}
                    headerAction={
                        mode !== SCREEN_MODE_EDIT &&
                        mode !== SCREEN_MODE_PREVIEW && <ExcelExport listCount={list.rows.length} />
                    }
                    {...restProps}
                >
                    <Search promise={getIotItemInfoLogList} />
                    <List list={list} state={iotItemLogListInfo} promise={getIotItemInfoLogList} />
                    {children}
                </Card>
            </IotItemInfoLogStateContext.Provider>
        </IotItemInfoLogDispatchContext.Provider>
    );
};

export default IotItemInfoLog;

import React, { forwardRef, useState } from 'react';
import { Table } from '@components';
import { useColumns, useTranslation } from '@hooks';
import * as column from '../../../MainPages/util/grid/column';
import LocationPopup from './LocationPopup';

/**
 *
 * 위치 로그 테이블 생성 컴포넌트
 *
 * @param {Object} list  위치 로그 리스트 데이터
 * @param {Object} state 위치 로그 리스트 API 호출 상태`값
 * @param {function} promise 위치 로그 리스트 호출 callback
 * @param {null} ref 참조 값
 *
 * @author jinbeom-jung
 * */

const List = ({ list, state, promise }, ref) => {
    const columnT = useTranslation('CommonColumn');

    const [selectedLog, setSelectedLog] = useState({});
    const [popup, setPopup] = useState(false);

    const handleLatLngClick = log => {
        setSelectedLog(log);
        setPopup(!popup);
    };

    const columns = useColumns([
        column.number(),
        column.targetName(),
        column.deviceName(),
        column.floor(),
        column.location({ handleLatLngClick, t: columnT, width: 350 }),
        column.registeredDate(),
    ]);

    const handlePageChange = pageIndex => {
        const param = state.request;
        let result = {};
        for (let key in param) {
            if (param[key]) {
                result[key] = param[key];
            }
        }
        promise({ ...result, page: pageIndex });
    };

    const handleToggleModal = () => {
        setPopup(!popup);
    };
    return (
        <div style={{ height: '35rem' }}>
            <Table
                data={{ ...list, pageSize: 15 }}
                columns={columns}
                onPageChange={handlePageChange}
                loading={state.isLoading}
            />
            <LocationPopup modal={popup} toggleModal={handleToggleModal} selectedLog={selectedLog} ref={ref} />
        </div>
    );
};

export default forwardRef(List);

import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useAsync } from '@hooks';
import { fetchScannerListApi, fetchScannerStateLogList } from '@api/log';
import Card from '../Components/WidgetCard';
import Search from './Component/Search';
import List from './Component/List';
import ExcelExport from './Component/ExcelExport';
import moment from 'moment';
import reducer, { initState, setScannerList, setScannerNums } from './scannerStatusLogReducer';
import { useSelector } from 'react-redux';
import useTranslation from '@hooks/useTranslation';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';

/**
 * 스캐너 상태 로그 위젯
 *
 * 추후 useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */
export const DEFAULT_SELECT_VALUE = 0;
export const ScannerStatusLogStateContext = createContext();
export const ScannerStatusLogDispatchContext = createContext();
const ScannerStatusLog = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('Scanner State Log');
    const { mode } = useSelector(state => state.ScreenInfo);
    const { selectableFloorList } = useSelector(state => state.FloorInfo);
    const [state, dispatch] = useReducer(reducer, initState);
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment().startOf('day').valueOf());
    const handleDateCallback = ({ selected, type }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().startOf('day').subtract(1, 'day');
                setStartDate(yesterday.valueOf());
                setEndDate(yesterday.add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment().startOf('day').valueOf());
                break;
        }
    };

    useAsync({
        promise: fetchScannerListApi,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            dispatch(
                setScannerList(rows.map(({ scannerName, scannerNum }) => ({ label: scannerName, value: scannerNum }))),
            );
            dispatch(setScannerNums(rows.map(({ scannerNum }) => scannerNum)));
        },
        reject: err => {
            dispatch(setScannerList([{ label: t('Not Select'), value: DEFAULT_SELECT_VALUE }]));
            dispatch(setScannerNums([DEFAULT_SELECT_VALUE]));
            console.error(err);
        },
    });

    const [list, setList] = useState({
        rows: [],
        totalPage: 1,
        totalCount: 0,
    });

    const { promise: getScannerStateLogList, state: scannerStateLogListInfo } = useAsync({
        promise: fetchScannerStateLogList,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const { rows } = res;
            const data = rows.map(log => {
                return {
                    logNum: log.logNum,
                    scannerName: log.scannerName,
                    macAddr: log.macAddr,
                    startDate: log.startDate,
                    endDate: log.endDate,
                    ...log,
                };
            });
            setList({ ...res, rows: data });
        },
        reject: err => console.error(err),
    });
    useEffect(() => {
        getScannerStateLogList({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            floorIds: selectableFloorList[0].floorId,
            floorIdPath: selectableFloorList[0].floorIdPath,
        });
    }, []);
    return (
        <ScannerStatusLogDispatchContext.Provider value={dispatch}>
            <ScannerStatusLogStateContext.Provider value={state}>
                <Card
                    widgetInfo={widgetInfo}
                    headerAction={
                        mode !== SCREEN_MODE_EDIT &&
                        mode !== SCREEN_MODE_PREVIEW && (
                            <ExcelExport startDate={startDate} endDate={endDate} listCount={list.rows.length} />
                        )
                    }
                    {...restProps}
                >
                    <Search
                        startDate={startDate}
                        endDate={endDate}
                        handleDateCallback={handleDateCallback}
                        promise={getScannerStateLogList}
                    />

                    <List list={list} state={scannerStateLogListInfo} promise={getScannerStateLogList} />
                    {children}
                </Card>
            </ScannerStatusLogStateContext.Provider>
        </ScannerStatusLogDispatchContext.Provider>
    );
};

export default ScannerStatusLog;

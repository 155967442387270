import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Select from '@components/Select';
import styled from 'styled-components';
import FloorTreeSelect from '../../Components/FloorTreeSelect';

const FloorGroupSelect = ({ buildingId, floorId, handleBuildingChange, handleFloorChange }) => {
    const { floorList: allFloorList } = useSelector(state => state.FloorInfo);
    const buildingFloorList = useMemo(() => {
        const buildingList = allFloorList.filter(floor => floor.levelNo === 0);
        const selectFloorList = [];
        const floorItemList = allFloorList.filter(floor => floor.levelNo !== 0);
        if (buildingId) {
            floorItemList.forEach(e => {
                const path = e.floorIdPath.split('>');
                if (path && path[0] === buildingId) {
                    const valuePath = path.filter((_, i) => i !== 0);
                    selectFloorList.push({
                        ...e,
                        floorIdPath:
                            valuePath.length > 1 ? valuePath.reduce((acc, cur) => acc + '>' + cur) : valuePath.join(''),
                        levelNo: e.levelNo - 1,
                        upperFloorId: e.upperFloorId === buildingId ? null : e.upperFloorId,
                    });
                }
            });
        }

        const floorList = allFloorList.filter(floor => floor.upperFloorId === buildingId);
        return { buildingList, floorList, selectFloorList };
    }, [allFloorList, buildingId, floorId]);

    useEffect(() => {
        if (buildingFloorList.floorList.find(floor => floor.floorId === floorId)) {
            if (typeof handleFloorChange === 'function') {
                handleFloorChange(null);
            }
        }
    }, [buildingFloorList]);

    return (
        <FloorGroupSelectWrapper>
            <FloorSelectOnMapControl
                options={buildingFloorList.buildingList}
                value={buildingFloorList.buildingList.find(building => building.floorId === buildingId)}
                onChange={selected => {
                    if (typeof handleBuildingChange === 'function') {
                        handleBuildingChange(selected);
                    }
                }}
            />
            <FloorTreeSelect
                floorList={buildingFloorList.selectFloorList}
                selectedFloorStr={floorId}
                mode={'radioSelect'}
                handleChange={selected => {
                    if (typeof handleFloorChange === 'function') {
                        handleFloorChange(selected);
                    }
                }}
                isMap
                disabled={buildingFloorList.selectFloorList.length === 0}
            />
        </FloorGroupSelectWrapper>
    );
};

const FloorGroupSelectWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

const FloorSelectOnMapControl = ({ options, value, onChange }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const wrapperRef = useRef();
    const toggleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    };
    useEffect(() => {
        const onClick = e => {
            if (wrapperRef.current) {
                const parent = wrapperRef.current;
                if (!parent.contains(e.target)) {
                    setMenuOpen(false);
                }
            }
        };
        document.addEventListener('click', onClick);
        return () => {
            document.removeEventListener('click', onClick);
        };
    }, []);

    return (
        <div ref={wrapperRef} onClick={toggleMenuOpen}>
            <Select
                className={'react-select pnt-select--group leaflet-map-select'}
                menuIsOpen={menuOpen}
                options={options}
                labelKey={'floorName'}
                valueKey={'floorId'}
                value={value}
                onChange={selected => {
                    if (typeof onChange === 'function') {
                        onChange(selected);
                    }
                }}
            />
        </div>
    );
};

export default FloorGroupSelect;

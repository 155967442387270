import React, { useContext } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { deleteMenuApi, fetchMenuList } from '@api/menu';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { A_PATH_MENU } from '../../../Components/Router/path';
import { MenuListDispatchContext } from './index';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuListInfo } from './reducer';
import { setMenuList } from '@reducer/CustomMenu';

const MenuListSubMenu = ({ row: { original } }) => {
    const storeDispatch = useDispatch();
    const dispatch = useContext(MenuListDispatchContext);
    const t = useTranslation('MenuManagement');
    const { userInfo } = useSelector(state => state.UserInfo);

    const { promise: getMenuList } = useAsync({
        promise: fetchMenuList,
        fixedParam: { isAll: 'Y', includeScreenInfo: 'Y', groupNum: userInfo.authGroupNum },
        resolve: res => {
            dispatch(setMenuListInfo(res));
            storeDispatch(setMenuList(res.rows ?? []));
        },
        reject: err => {
            console.log({ err });
            // dispatch(setMenuListInfo({}));
            // storeDispatch(setMenuList([]));
        },
    });

    const { promise: deleteMenu } = useAsync({
        promise: deleteMenuApi,
        resolve: () => {
            toggleDeleteSuccessModal();
        },
        reject: () => {
            toggleDeleteFailureModal();
        },
    });

    const { Modal: DeleteConfirmModal, toggleModal: toggleDeleteConfirmModal } = useConfirmModal({
        header: { title: t('Confirm', 'ConfirmModal') },
        confirmText:
            original.screenList && original.screenList.length
                ? t(
                      'There are screens mapped to this menu. You can delete the mapped screen after moving it to another menu.',
                  )
                : t('Do you want to delete the menu?'),
        removeCancel: original.screenList && original.screenList.length,
        callbackParam: original,
        okCallback: () => {
            if (!(original.screenList && original.screenList.length)) {
                deleteMenu(original);
            }
        },
    });

    const { Modal: DeleteSuccessModal, toggleModal: toggleDeleteSuccessModal } = useConfirmModal({
        confirmText: t('Menu deletion is complete.'),
        removeCancel: true,
        okCallback: () => {
            getMenuList();
        },
    });

    const { Modal: DeleteFailureModal, toggleModal: toggleDeleteFailureModal } = useConfirmModal({
        confirmText: t('The delete request failed.'),
        removeCancel: true,
    });

    return (
        <>
            <UncontrolledDropdown className="w-100">
                <DropdownToggle tag={'div'} className={'flex-center'}>
                    <span className="material-icons-round">menu</span>
                </DropdownToggle>
                <DropdownMenu className={'grid-sub-menu'}>
                    <Link to={`${A_PATH_MENU}/edit/${original.menuCode}`}>
                        <DropdownItem>
                            <span className="material-icons-round md-18">info_outline</span>
                            <span className="ml-2">{t('Menu Detail')}</span>
                        </DropdownItem>
                    </Link>
                    <DropdownItem onClick={toggleDeleteConfirmModal}>
                        <span className="material-icons-round md-18">delete_outline</span>
                        <span className="ml-2">{t('Delete the menu')}</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <DeleteConfirmModal />
            <DeleteSuccessModal />
            <DeleteFailureModal />
        </>
    );
};

export default MenuListSubMenu;

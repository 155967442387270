import React, { useEffect, useReducer, useRef, useState } from 'react';
import cx from 'classnames';
import { useAsync, useTranslation } from '../../../util/hooks';
import SensorList from './Components/SensorList/SensorList';
import styled from 'styled-components';
import sensorStatusReducer, {
    initialState,
    setSelectedSensor,
    setSelectedSensorStatusList,
    setSensingTypeOptions,
} from './sensorStatusReducer';
import useMonitor from '../util/useMonitor';

import { getMappedSensorStatusApi } from '@api/asset';
import { fetchRealTimeSensorMonitoring, fetchSensingTypeMetas } from '@api/monitoring';
import Button from '../../Common/Button';
import SensorCount from './Components/SensorCount';
import SensorDetailInfo from './Components/SensorDetailInfo';
import LocationPopup from './Components/SensorDetailInfo/Components/SensorReport/Components/LocationPopup';
import { useSelector } from 'react-redux';
import WidgetCard from '../Components/WidgetCard';

const defaultData = {
    sensorStatusCountStat: {
        normal: 11,
        warning: 11,
        critical: 18,
        outOfRange: 78,
        undefine: 50,
        total: 168,
    },
    sensorTargetCountStat: {
        mappedSensorCount: 52,
        mappedTargetCount: 43,
    },
};

const Container = styled.div`
    height: 100%;
    grid-template-rows: min-content;

    & > div {
        &:nth-child(1) {
            grid-column: 1 / 2;
        }

        &:nth-child(2) {
            grid-column: 2 / 4;
        }

        &:nth-child(3) {
            grid-column: 1 / 4;
            grid-row-end: fill;
        }
    }
`;

export const SensorStatusStateContext = React.createContext();
export const SensorStatusDispatchContext = React.createContext();

/**
 * 센서현황 컴포넌트
 *
 * @author jinbeom-jung
 * */
const SensorStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('SensorStatus');
    const { config } = widgetInfo;
    const widgetRef = useRef();
    const { lang } = useSelector(state => state.UserInfo);
    const [state, dispatch] = useReducer(sensorStatusReducer, initialState);
    const { selectedStatus, selectedSensor } = state;

    useEffect(() => {
        if (selectedStatus) {
            getRealTimeSensorMonitoring({ status: selectedStatus });
        }
    }, [selectedStatus]);

    const { promise: getSensingTypeMetas } = useAsync({
        promise: fetchSensingTypeMetas,
        resolve: ({ rows }) => {
            const filterRows = rows.filter(({ sensingType, language }) => sensingType && language);
            dispatch(setSensingTypeOptions(filterRows));
        },
        reject: error => console.error(error),
    });

    useEffect(() => {
        getSensingTypeMetas({ localType: lang });
    }, [lang]);

    const { promise: getRealTimeSensorMonitoring } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setSelectedSensorStatusList(res));
        },
    });

    const [statusCountData, setStatusCountData] = useState({
        prev: defaultData.sensorStatusCountStat,
        curr: defaultData.sensorStatusCountStat,
    });

    const [targetCountData, setTargetCountData] = useState({
        prev: { ...defaultData.sensorTargetCountStat },
        curr: { ...defaultData.sensorTargetCountStat },
    });

    useMonitor({
        config,
        defaultData,
        fetchData: getMappedSensorStatusApi,
        makeData: ({ newData }) => {
            const { sensorTargetCountStat, sensorStatusCountStat } = newData;
            setTargetCountData(prev => ({
                prev: { ...prev.curr },
                curr: { ...sensorTargetCountStat },
            }));

            setStatusCountData(prev => ({
                prev: { ...prev.curr },
                curr: { ...sensorStatusCountStat },
            }));
        },
    });

    const handleGoBackClick = () => {
        dispatch(setSelectedSensor(null));
    };

    return (
        <SensorStatusDispatchContext.Provider value={dispatch}>
            <SensorStatusStateContext.Provider value={state}>
                <WidgetCard
                    ref={widgetRef}
                    widgetInfo={widgetInfo}
                    bodyClassName="sensor-status-container"
                    headerAction={
                        <>
                            {selectedSensor && (
                                <Button className={'btn-brand'} onClick={handleGoBackClick}>
                                    {t('Back', 'Button')}
                                </Button>
                            )}
                        </>
                    }
                    {...restProps}
                >
                    {!selectedSensor ? (
                        <>
                            <Container className="grid-container">
                                <SensorCount targetCountData={targetCountData} statusCountData={statusCountData} />
                                <SensorList config={config} />
                            </Container>
                        </>
                    ) : (
                        <>
                            <Container id="printArea" className={cx(selectedSensor && 'sensor-report-view')}>
                                <SensorDetailInfo />
                            </Container>
                            <LocationPopup cardRef={widgetRef} />
                        </>
                    )}

                    {children}
                </WidgetCard>
            </SensorStatusStateContext.Provider>
        </SensorStatusDispatchContext.Provider>
    );
};

export default SensorStatus;

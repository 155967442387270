import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import reducer, { initialState, setInitState, setOptions } from '../../Components/SelectWithOption/reducer';
import { MenuList, Option } from './Parts';
import SearchableSelect from '@components/Select/SearchableSelect';
import useTranslation from '@hooks/useTranslation';
import { FilterGeneratorContext } from '../../FilterGenerator';
import { useSelector } from 'react-redux';
import { QuestionTooltip } from '@components';

export const SelectContext = createContext();
export const SelectDispatchContext = createContext();

export const OPTION_LOGIN_GROUP = { name: "Logged in user's group", value: '-1' };

const LoginGroupSelect = ({ data, onChange, isEditable = 'Y', selected, valueKey, ...restProps }) => {
    const t = useTranslation('Filter');
    const { userInfo } = useSelector(state => state.UserInfo);
    const { monitoringMode } = useContext(FilterGeneratorContext);
    const [state, dispatch] = useReducer(reducer, { ...initialState, options: data });
    const { options } = state;
    const [selectedItem, setSelectedItem] = useState(selected);
    const handleChange = selectedOption => {
        setSelectedItem(selectedOption.map(v => v[valueKey]));
    };

    useEffect(() => {
        if (monitoringMode) {
            if (selectedItem[0] === OPTION_LOGIN_GROUP.value) {
                const { groupNums } = userInfo;
                dispatch(setOptions(data.filter(option => groupNums.includes(Number(option[valueKey])))));
            } else {
                dispatch(setOptions(data));
            }
        } else {
            dispatch(
                setOptions([
                    {
                        [valueKey]: OPTION_LOGIN_GROUP.value,
                        label: (
                            <span className="d-flex flx-center">
                                <QuestionTooltip
                                    contents={t(
                                        'This option cannot be selected with other groups. If this option is selected, only groups to which the user belongs are exposed on the user screen.',
                                    )}
                                    placement={'top'}
                                />
                                <span className="ml-1">{t(OPTION_LOGIN_GROUP.name)}</span>
                            </span>
                        ),
                    },
                    ...data,
                ]),
            );
        }
    }, [data, monitoringMode, t, userInfo, selectedItem]);

    useEffect(() => {
        dispatch(setInitState({ isEditable }));
    }, [isEditable]);

    useEffect(() => {
        if (typeof onChange === 'function') {
            if (monitoringMode && selectedItem[0] === OPTION_LOGIN_GROUP.value) {
                setSelectedItem(userInfo.groupNums.map(groupNum => groupNum.toString()) || []);
                return;
            }
            onChange(selectedItem, state.isEditable);
        }
    }, [selectedItem, state.isEditable, monitoringMode, userInfo.groupNums]);

    return (
        <SelectDispatchContext.Provider value={dispatch}>
            <SelectContext.Provider value={state}>
                <SearchableSelect
                    {...restProps}
                    data={options}
                    selected={selectedItem}
                    valueKey={valueKey}
                    MenuListComponent={MenuList}
                    OptionComponent={Option}
                    onChange={handleChange}
                />
            </SelectContext.Provider>
        </SelectDispatchContext.Provider>
    );
};

export default LoginGroupSelect;

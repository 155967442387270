import { createSlice } from '@reduxjs/toolkit';

export const initState = {
    keyword: '',
    searchOption: '',
};

const { actions, reducer } = createSlice({
    name: 'locationLog',
    initState,
    reducers: {
        setKeyword: (state, action) => {
            state.keyword = action.payload;
        },
        setSearchOption: (state, action) => {
            state.searchOption = action.payload;
        },
        reset: (state, action) => {
            const initialState = { ...initState, ...action.payload };
            for (let key in initialState) {
                state[key] = initialState[key];
            }
        },
    },
});

export const { setKeyword, setSearchOption, reset } = actions;
export default reducer;

import React, { useEffect, useState } from 'react';
import Card from '../../../Common/Card';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { useConfirmModal, useTranslation } from '@hooks';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';

const COLORS = ['#f9d2dc', '#c7e1f5', '#e1e787', '#fcc8ac'];

const DivContainer = styled.div`
    max-height: 100%;
    height: 100%;
    background-image: url(${props => props.backgroundImage});
    background-color: ${props => props.backgroundColor};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    cursor: pointer;
    position: relative;
    border-radius: 5px;
`;

const CustomBadge = styled(Badge)`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 8px rgba(51, 51, 51, 0.4);
    background-color: white;
    position: absolute;
    right: 20px;
    top: 20px;
`;

const StatusContainer = styled.div`
    position: absolute;
    bottom: 30px;
    width: 100%;
    min-height: 5rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CategoryCard = ({ categoryCode, status, index, settings }) => {
    const t = useTranslation('Dashboard');

    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { mode } = useSelector(state => state.ScreenInfo);
    const { dashboardURL } = settings || {};

    const history = useHistory();

    const [backgroundImage, setBackgroundImage] = useState('');

    const { Modal: NotExistScreenModal, toggleModal: toggleNotExistScreenModal } = useConfirmModal({
        confirmText: t('Linked screen is not set. Please set it in screen editing.'),
        removeCancel: true,
    });

    const { Modal: MoveConfirmModal, toggleModal: toggleMoveConfirmModal } = useConfirmModal({
        confirmText: t('You cannot move the menu in edit mode.'),
        removeCancel: true,
    });

    useEffect(() => {
        const category = categoryList.find(category => category.categoryCode === categoryCode);

        if (category) {
            setBackgroundImage(category.normalIconURL);
        }
    }, [categoryList]);

    const handleDashboardClick = () => {
        let clickedDashboard;
        const { ivInjectionMonitoring, patientStatus, sensorStatus, assetStatus, fridgeSensor } = dashboardURL || {};
        switch (categoryCode) {
            case 'inj':
                clickedDashboard = ivInjectionMonitoring;
                break;
            case 'PATIENT':
                clickedDashboard = patientStatus;
                break;
            case 'scale':
                clickedDashboard = sensorStatus;
                break;
            case 'ASSET':
                clickedDashboard = assetStatus;
                break;
            case 'refrigerator':
                clickedDashboard = fridgeSensor;
                break;
        }
        if (mode !== SCREEN_MODE_MONITORING) {
            toggleMoveConfirmModal();
        } else if (clickedDashboard) {
            history.push(clickedDashboard);
        } else {
            toggleNotExistScreenModal();
        }
    };

    return (
        <>
            <Card className={'transition-card'} bodyClassName={'p-1'}>
                <DivContainer
                    backgroundImage={backgroundImage}
                    backgroundColor={COLORS[index % 4]}
                    onClick={handleDashboardClick}
                >
                    <CustomBadge className={'color-secondary pnt-txt s-3'}>{status.all}</CustomBadge>
                    <h5 className={'pnt-txt s-2 txt-bold p-4'} style={{ color: '#565672' }}>
                        {t(categoryCode)}
                    </h5>

                    <StatusContainer>
                        {Object.entries(status).map(([status, count], index) => {
                            let flag = false;

                            switch (status) {
                                case 'full':
                                case 'stop':
                                case 'isLostSignal':
                                case 'isLowBattery':
                                case 'CRITICAL':
                                    flag = true;
                                    break;
                            }

                            if (flag) {
                                return (
                                    <div key={status + index} className={'m-1 flx-row'}>
                                        <div className={'mr-1 pnt-txt s-5'} style={{ color: '#565672' }}>
                                            {t(status)}
                                        </div>
                                        <Badge
                                            className={
                                                'bg-info bg-light custom-badge flx-col flx-center align-items-center'
                                            }
                                        >
                                            {count}
                                        </Badge>
                                    </div>
                                );
                            }
                        })}
                    </StatusContainer>
                </DivContainer>
            </Card>
            <NotExistScreenModal />
            <MoveConfirmModal />
        </>
    );
};

export default React.memo(CategoryCard);

import React, { useContext, useEffect, useState } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { getRefrigeratorSensorDateApi } from '@api/monitoring';
import useAsync from '@hooks/useAsync';
import { RefrigeratorSensorStateContext } from '../../../../index';
import useTranslation from '@hooks/useTranslation';
import { LoadingBlock } from '@components';
import { useSelector } from 'react-redux';
import NoDataBlock from '../../../../../Components/NoDataBlock';

const Graph = ({ date }) => {
    const t = useTranslation('RefrigeratorSensor');
    const colorScheme = useSelector(state => state.ThemeOptions.colorScheme);
    const [fill, setFill] = useState('#000000BF');
    useEffect(() => {
        if (['pnt-dark', 'pnt-plant'].includes(colorScheme)) {
            setFill('#fff');
        } else {
            setFill('#000000BF');
        }
    }, [colorScheme]);

    const { sensorNum } = useContext(RefrigeratorSensorStateContext);
    const [graphInfo, setGraphInfo] = useState([]);

    const {
        promise: getRefrigeratorSensorDate,
        state: { isLoading },
    } = useAsync({
        promise: getRefrigeratorSensorDateApi,
        resolve: res => {
            const graphData = res.targetInfoList.map(({ dataMap: { TEMPERATURE }, regDate }) => {
                return {
                    x: moment(regDate * 1000).format('HH:mm'),
                    y: TEMPERATURE ? Number(TEMPERATURE.toFixed(2)) : null,
                };
            });

            setGraphInfo(graphData);
        },
        reject: err => console.error(err),
    });

    // 일자 변경
    useEffect(() => {
        const currentDate = moment().date();
        const selectedDate = moment(date * 1000).date();
        let newParams;

        // 현재일자 === 선택일자 ? 현재시간부터 전일 현재 시간까지
        if (currentDate === selectedDate) {
            newParams = { startDate: moment().subtract(1, 'days').unix(), endDate: moment().unix(), sensorNum };
        }
        // 현재일자 !== 선택일자 ? 선택일자 00:00:00 ~ 23:59:59
        else {
            newParams = {
                startDate: moment(date * 1000)
                    .startOf('date')
                    .unix(),
                endDate: moment(date * 1000)
                    .endOf('date')
                    .unix(),
                sensorNum,
            };
        }

        getRefrigeratorSensorDate(newParams);
    }, [date]);

    return (
        <LoadingBlock blocking={isLoading}>
            {graphInfo.length ? (
                <div className="w-100 h-100 p-2 border-size-1 border-depth-7">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={graphInfo} margin={{ top: 10, right: 20, bottom: 10, left: -10 }}>
                            <CartesianGrid strokeDasharray="5 3" />
                            <XAxis
                                dataKey="x"
                                angle={45}
                                interval={'preserveStartEnd'}
                                axisLine={{ stroke: '#888888' }}
                                tickLine={{ stroke: '#888888' }}
                                tickSize={16}
                                tick={{ fill }}
                            />
                            <YAxis
                                axisLine={{ stroke: '#888888' }}
                                tickLine={{ stroke: '#888888' }}
                                tickSize={16}
                                tick={{ fill }}
                            />
                            <Tooltip />
                            <Line dataKey="y" name={t('Temperature')} stroke="#3E66FB" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            ) : (
                <NoDataBlock className="border-size-1 border-depth-7" />
            )}
        </LoadingBlock>
    );
};

export default Graph;

import React from 'react';
import { Table } from '@components';
import { useColumns, useTranslation } from '@hooks';
import * as column from '../../../MainPages/util/grid/column';

/**
 *
 * 로그인 이력 테이블 생성 컴포넌트
 *
 * @param {Object} list  로그인 이력 로그 리스트 데이터
 * @param {Object} state 로그인 이력 리스트 API 호출 상태`값
 * @param {function} promise 로그인 이력 리스트 호출 callback
 *
 * @author jinbeom-jung
 * */

const List = ({ list, state, promise }) => {
    const t = useTranslation('Login History Log');

    const columns = useColumns([
        column.userId(),
        column.userName(),
        column.type({
            Cell: ({ value }) => {
                const letterObj = { F: 'Failure', S: 'Success' };
                return <span title={letterObj[value]}>{t(letterObj[value])}</span>;
            },
        }),
        column.description({
            Cell: ({ value }) => {
                const letter = value ? value : '-';
                return <span title={letter}>{letter}</span>;
            },
        }),
        column.ip(),
        column.registeredDate(),
    ]);

    const handlePageChange = pageIndex => {
        const param = state.request;
        let result = {};
        for (let key in param) {
            if (param[key]) {
                result[key] = param[key];
            }
        }
        promise({ ...state.request, page: pageIndex });
    };

    return (
        <div style={{ height: '35rem' }}>
            <Table
                data={{ ...list, pageSize: 15 }}
                columns={columns}
                onPageChange={handlePageChange}
                loading={state.isLoading}
            />
        </div>
    );
};

export default List;

import React from 'react';
import { fetchGeofenceCongestionStatus } from '../../../api/statistics';
import useMonitor from '../util/useMonitor';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useGeofenceColumn from './Components/useGeofenceColumn';
import { Table } from '@components';
import GeofenceCongestionPieChart from './Components/GeofenceCongestionPieChart';
import WidgetCard from '../Components/WidgetCard';

const defaultData = {
    rows: [
        { fcName: 'Example1', enterCnt: _.random(19, 20) },
        { fcName: 'Example2', enterCnt: _.random(15, 17) },
        { fcName: 'Example3', enterCnt: _.random(13, 14) },
        { fcName: 'Example4', enterCnt: _.random(5, 7) },
        { fcName: 'Example5', enterCnt: _.random(2, 3) },
    ],
};

const GeofenceCongestionStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;

    const geofenceColumn = useGeofenceColumn();

    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: fetchGeofenceCongestionStatus,
        makeData: ({ newData }) => {
            const geofenceCongestionList = newData.rows || [];
            let chartData = [];
            if (geofenceCongestionList && geofenceCongestionList.length > 0) {
                const length = geofenceCongestionList.length < 5 ? geofenceCongestionList.length : 5;
                for (let i = 0; i < length; i++) {
                    chartData.push({
                        key: geofenceCongestionList[i].fcName,
                        name: geofenceCongestionList[i].fcName,
                        value: Number(geofenceCongestionList[i].enterCnt),
                    });
                }
            }

            return chartData;
        },
    });

    const tableHeight = !data.length ? '15rem' : (31 + data.length * 36.4).toString() + 'px';

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <div style={{ height: `calc(100% - ${tableHeight})` }}>
                        <GeofenceCongestionPieChart data={data} tableHeight={tableHeight} />
                    </div>

                    <div style={{ height: tableHeight }}>
                        <Table data={{ rows: data }} columns={geofenceColumn} paging={false} />
                    </div>
                </PerfectScrollbar>
            </div>
            {children}
        </WidgetCard>
    );
};

export default GeofenceCongestionStatus;

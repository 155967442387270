import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    refrigeratorSensorDailyLogList: {},
    updateDailyLogList: [],
};

// daily log 초기값(add row 시 필요)
const initialDailyLog = {
    currentValue: 0,
    regDate: 0,
    contents: null,
};

const { actions, reducer } = createSlice({
    name: 'refrigeratorSensorLog',
    initialState,
    reducers: {
        /**
         * 냉장고 일간대장 리스트
         * @param state 일간대장 상태값
         * @param action 새로운 일간대장 상태 정보
         */
        setRefrigeratorSensorDailyLogList: (state, action) => {
            const data = action.payload || {};
            if (Object.keys(data).length) {
                state.refrigeratorSensorDailyLogList = data;
            } else {
                state.refrigeratorSensorDailyLogList = {};
            }
        },
        /**
         * 냉장고 일간대장 추가
         * @param state 일간대장 상태값
         * @param action 새로운 일간대장 상태 정보
         */
        addRefrigeratorSensorDailyLog: (state, action) => {
            const sensorNum = action.payload.sensorNum;
            const targetNum = action.payload.targetNum;
            const targetName = action.payload.targetName;
            const dept = action.payload.dept;
            const regDate = action.payload.regDate;
            if (!sensorNum) {
                return;
            }

            let dailyLogs;
            if (
                !state.refrigeratorSensorDailyLogList[sensorNum] ||
                state.refrigeratorSensorDailyLogList[sensorNum].length === 0
            ) {
                dailyLogs = [{ ...initialDailyLog, regDate, targetNum, targetName, dept }];
            } else {
                dailyLogs = [
                    ...state.refrigeratorSensorDailyLogList[sensorNum],
                    { ...initialDailyLog, regDate, targetNum, targetName, dept },
                ];
            }
            state.refrigeratorSensorDailyLogList[sensorNum] = [...dailyLogs];
        },
        /**
         * 냉장고 일간대장 수정
         * @param state 일간대장 상태값
         * @param action 새로운 일간대장 상태 정보
         */
        changeRefrigeratorSensorDailyLog: (state, action) => {
            if (!action.payload.sensorNum) {
                return;
            }
            const sensorNum = action.payload.sensorNum;
            const logNum = action.payload.logNum;
            const updateData = action.payload.data;
            // 변경 대상의 index
            let rowIdx = action.payload.idx;

            // 기존에 존재하는 row 인지 확인
            state.refrigeratorSensorDailyLogList[sensorNum].forEach((log, index) => {
                if (log.logNum && log.logNum === logNum) {
                    rowIdx = index;
                }
            });
            // 상태 업데이트
            state.refrigeratorSensorDailyLogList[sensorNum][rowIdx] = {
                ...state.refrigeratorSensorDailyLogList[sensorNum][rowIdx],
                ...updateData,
            };
        },
        deleteRefrigeratorSensorDailyLog: (state, action) => {
            if (!action.payload.sensorNum) {
                return;
            }
            const sensorNum = action.payload.sensorNum;
            const logNum = action.payload.logNum;
            const idx = action.payload.idx;

            if (logNum) {
                const filterItems = state.refrigeratorSensorDailyLogList[sensorNum].filter(
                    log => log.logNum !== logNum,
                );
                state.refrigeratorSensorDailyLogList = {
                    ...state.refrigeratorSensorDailyLogList,
                    [sensorNum]: [...filterItems],
                };
            } else {
                if (idx < state.refrigeratorSensorDailyLogList[sensorNum].length) {
                    state.refrigeratorSensorDailyLogList[sensorNum].splice(idx, 1);
                }
            }
        },
    },
});
export const {
    setRefrigeratorSensorDailyLogList,
    addRefrigeratorSensorDailyLog,
    changeRefrigeratorSensorDailyLog,
    deleteRefrigeratorSensorDailyLog,
} = actions;
export default reducer;

import React, { Children, ComponentType, isValidElement, ReactElement, ReactNode, useEffect, useState } from 'react';
import ReactSelect, { components, ControlProps, DropdownIndicatorProps, IndicatorsContainerProps } from 'react-select';
import { Props } from 'react-select/dist/declarations/src/Select';
import { OptionType, SelectProps } from '../type';
import useTranslation from '@util/hooks/useTranslation';

export const ICON_CLASS = 'material-icons-round md-18';

function getChildrenTypeNode(children: ReactNode, targetComponent: any) {
    const targetType = targetComponent.type;
    const childrenArray = Children.toArray(children);

    const targetChildren = childrenArray.filter(child => isValidElement(child) && child.type === targetType);
    const otherChildren = childrenArray.filter(child => isValidElement(child) && child.type !== targetType);

    return { targetChildren, otherChildren };
}

const Control: ComponentType<ControlProps<OptionType>> = ({
    children,
    selectProps,
    ...rest
}: ControlProps<OptionType>) => {
    const { iconName } = selectProps;
    const {
        isDisabled,
        clearValue,
        cx,
        getStyles,
        getClassNames,
        getValue,
        hasValue,
        isMulti,
        isRtl,
        options,
        selectOption,
        setValue,
        theme,
        menuIsOpen,
        isFocused,
    } = rest;
    const { targetChildren, otherChildren } = getChildrenTypeNode(
        children,
        <IndicatorsContainer
            isDisabled={isDisabled}
            clearValue={clearValue}
            cx={cx}
            getStyles={getStyles}
            getClassNames={getClassNames}
            getValue={getValue}
            hasValue={hasValue}
            isMulti={isMulti}
            isRtl={isRtl}
            options={options}
            selectOption={selectOption}
            selectProps={selectProps}
            setValue={setValue}
            theme={theme}
        >
            {children}
        </IndicatorsContainer>,
    );

    return (
        <components.Control
            className={`${iconName ? 'icon-on-left' : ''} ${menuIsOpen ? 'on' : ''}`}
            selectProps={selectProps}
            {...rest}
        >
            <button className={'select__btn'}>
                <div className="cont-wrap">
                    {iconName && <span className={ICON_CLASS}>{iconName}</span>}
                    {otherChildren}
                </div>
                {targetChildren}
            </button>
        </components.Control>
    );
};

const IndicatorsContainer = ({ children, ...rest }: IndicatorsContainerProps<OptionType>) => {
    return <components.IndicatorsContainer {...rest}>{children}</components.IndicatorsContainer>;
};

const DropdownIndicator = ({ ...rest }: DropdownIndicatorProps<OptionType>) => {
    return (
        <components.DropdownIndicator className={`${ICON_CLASS} p-0`} {...rest}>
            arrow_drop_down
        </components.DropdownIndicator>
    );
};

/**
 * 단일 선택
 *
 * @param name
 * @param value
 * @param options
 * @param defaultValue
 * @param closeMenuOnSelect
 * @param isSearchable
 * @param isClearable
 * @param isMulti
 * @param isModalSelect
 * @param iconName
 * @param onChange
 * @param onInputChange
 * @param noOptionsMessage
 * @param disabled
 * @param placeholder
 * @param valueKey
 * @param labelKey
 * @param restProps
 */
const Select = ({
    name,
    value,
    options,
    defaultValue,
    closeMenuOnSelect = true,
    isSearchable = false,
    isClearable = false,
    isMulti = false,
    isModalSelect = false,
    iconName,
    onChange,
    onInputChange,
    noOptionsMessage,
    disabled = false,
    placeholder,
    valueKey = 'value',
    labelKey = 'label',
    ...restProps
}: SelectProps): ReactElement<Props<OptionType>> => {
    const t = useTranslation('Select');
    const [selectedValue, setSelectedValue] = useState<OptionType | undefined | null>(null);
    const [innerDefaultValue, setInnerDefaultValue] = useState<OptionType | undefined | null>(null);

    const innerOptions = options.map(option => ({
        ...option,
        label: option[labelKey],
        value: option[valueKey],
    }));

    if (defaultValue) {
        const convertDefaultValue = {
            ...defaultValue,
            label: defaultValue[labelKey],
            value: defaultValue[valueKey],
        };
        setInnerDefaultValue(convertDefaultValue);
    }
    // 특정 option을 선택한 후 언어설정을 바꾸면, 언어설정을 바꾸기 전 언어로 남아있어서, 선택한 언어로 변경하기 위한 로직
    useEffect(() => {
        let selected = null;
        if (value) {
            const convertValue = {
                ...value,
                value: value[valueKey],
                label: value[labelKey],
            };

            selected = innerOptions.find(({ value }) => value === convertValue.value);
        }
        setSelectedValue(selected);
    }, [t, value]);

    return (
        <ReactSelect
            menuPortalTarget={document.getElementById(
                `${!isModalSelect ? 'select-container' : 'modal-select-container'}`,
            )}
            menuPosition={'fixed'}
            menuShouldBlockScroll
            closeMenuOnScroll
            name={name}
            value={selectedValue}
            options={innerOptions}
            defaultValue={innerDefaultValue ? innerDefaultValue : innerOptions[0]}
            onChange={onChange}
            onInputChange={onInputChange}
            closeMenuOnSelect={closeMenuOnSelect}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isMulti={false}
            unstyled
            className="react-select pnt-select--group"
            classNamePrefix="react-select"
            placeholder={placeholder || t('Select')}
            noOptionsMessage={() => noOptionsMessage || t('No options')}
            disabled={disabled}
            // 초기 객체값은 항상 빈값으로 존재 -> {}
            // 따라서 string 타입을 객체의 키값으로 사용할 수가 없음 -> 그 키가 객체에 존재하는지 알 수 없기 때문에
            // 하지만 valueKey와 labelKey가 무조건 존재한다고 확신할 수 있는 상황이기 때문에
            // Typescript에 valueKey와 labelKey는 항상 존재하는 키값이라고 선언해줄 수 있음 (as keyof)
            // getOptionValue={option => option[valueKey as keyof OptionType]}
            // getOptionLabel={option => option[labelKey as keyof OptionType]}
            components={{ Control, IndicatorsContainer, DropdownIndicator, IndicatorSeparator: null }}
            iconName={iconName}
            {...restProps}
        />
    );
};

export default Select;

import React, { createContext, useEffect, useReducer } from 'react';
import { Button, Card, Label, Page } from '@components';
import DynamicRowHeightTable from '@components/Table/DynamicRowHeightTable';
import { useColumns, useTranslation } from '@hooks';
import * as col from '../../../util/grid/column/menu';
import * as commonCol from '../../../util/grid/column';
import MenuListSubMenu from './MenuListSubMenu';
import { useHistory } from 'react-router-dom';
import reducer, { initialState, setMenuListInfo } from './reducer';
import useAsync from '@hooks/useAsync';
import { fetchMenuList } from '@api/menu';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuList } from '@reducer/CustomMenu';

export const MenuListContext = createContext();
export const MenuListDispatchContext = createContext();

const MenuList = () => {
    const storeDispatch = useDispatch();
    const t = useTranslation('MenuManagement');
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { userInfo } = useSelector(state => state.UserInfo);

    const { promise: getMenuList } = useAsync({
        promise: fetchMenuList,
        fixedParam: { isAll: 'Y', includeScreenInfo: 'Y', groupNum: userInfo.authGroupNum },
        resolve: res => {
            dispatch(setMenuListInfo(res));
            storeDispatch(setMenuList(res.rows ?? []));
        },
        reject: err => {
            console.log({ err });
            dispatch(setMenuListInfo({}));
            storeDispatch(setMenuList([]));
        },
    });

    const menuColumns = useColumns(
        [
            col.menuIcon(),
            col.menuName(),
            col.menuDescription(),
            {
                Header: 'Sort Order',
                className: 'd-flex flx-center',
                accessor: 'order',
                width: 50,
            },
            {
                Header: 'Screen List',
                className: 'pr-3 pl-3 text-ellipsis',
                accessor: 'screenList',
                formatTitle: ({ value }) =>
                    value[0]
                        ? `${value[0].menuName} > ${value[0].screenName}` +
                          (value[1] ? ` ${t('other')} ${value.length - 1}` : '')
                        : '',
                Cell: ({ value }) =>
                    value.map((data, idx) => (
                        <Label
                            key={`${data.menuName}_${data.screenName}_${idx}`}
                            name={`${data.menuName} > ${data.screenName}`}
                            labelValueClassName={`pnt-txt txt-dot txt-bold ${value.length !== idx + 1 ? 'mb-2' : ''}`}
                        />
                    )),
                style: {
                    maxHeight: '150px',
                    overflow: 'auto',
                },
            },
            commonCol.modDate(),
            {
                Header: 'Action',
                id: 'subMenu',
                width: 50,
                className: 'flex-center overflow-visible',
                Cell: MenuListSubMenu,
            },
        ],
        t,
    );

    useEffect(() => {
        if (userInfo && userInfo.roleNum) {
            getMenuList({ roleNum: userInfo.roleNum });
        }
    }, []);

    return (
        <MenuListDispatchContext.Provider value={dispatch}>
            <MenuListContext.Provider value={state}>
                <Page className="h-100" menuPath={[t('Menu Management', 'Menu'), t('Menu List', 'Menu')]}>
                    <Card
                        className="h-100"
                        header={{
                            title: t('Menu List'),
                            action: (
                                <Button
                                    className="btn-icon btn-secondary"
                                    iconName="add"
                                    onClick={() => history.push('/setting/menu/create')}
                                >
                                    {t('Create Menu')}
                                </Button>
                            ),
                        }}
                    >
                        <DynamicRowHeightTable
                            textAlign={'center'}
                            data={state.menuListInfo}
                            columns={menuColumns}
                            paging={false}
                            dynamicRowHeight
                        />
                    </Card>
                </Page>
            </MenuListContext.Provider>
        </MenuListDispatchContext.Provider>
    );
};

export default MenuList;

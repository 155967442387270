import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import Nav from '../AppNav/VerticalNavWrapper';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { setEnableMobileMenu } from '@reducer/ThemeOptions';

import { layout } from '@components/style';
import BottomMenu from './BottomMenu';
import { URL_ROOT_SETTING } from '@reducer/CustomMenu';

const AppSidebar = () => {
    const scrollRef = useRef();
    const [setting, setSetting] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const { enableSidebarShadow, enableMobileMenu, backgroundColor } = useSelector(state => state.ThemeOptions);
    const {
        userInfo: { settingAuth },
    } = useSelector(state => state.UserInfo);

    const currentLocation = location.pathname.split('/')[1];

    useEffect(() => {
        if (currentLocation === URL_ROOT_SETTING) {
            setSetting(true);
        }
    }, [currentLocation]);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    const handleSetting = () => {
        setSetting(prevState => !prevState);
    };

    return (
        <>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
            <ReactCSSTransitionGroup
                component="div"
                className={cx('app-sidebar', 'pnt-sidebar', 'nav-box__img-bg', backgroundColor, {
                    'sidebar-shadow': enableSidebarShadow,
                })}
                transitionName="SidebarAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}
            >
                <div className={'h-100'}>
                    <PerfectScrollbar ref={scrollRef}>
                        <div
                            className="app-sidebar__inner"
                            style={{ padding: `1rem ${layout.layoutSpacerX} ${layout.layoutSpacerX}` }}
                        >
                            <Nav
                                setting={setting}
                                onUpdateSize={() => {
                                    if (scrollRef.current) {
                                        scrollRef.current._container.scrollTop = 0;
                                    }
                                }}
                            />
                        </div>
                    </PerfectScrollbar>
                </div>
                {settingAuth && <BottomMenu setting={setting} handleSetting={handleSetting} />}
            </ReactCSSTransitionGroup>
        </>
    );
};

export default AppSidebar;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useTranslation from '../../../util/hooks/useTranslation';
import { editWidgetSettings } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import WidgetSettingModal from '../Components/WidgetSettingModal';

const IVInjectionMonitoringSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useDispatch();

    const t = useTranslation('IVInjectionMonitoring');

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('IV Injection Monitoring Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        />
    );
};

export default IVInjectionMonitoringSetting;

import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { initUUIDv4 } from '@util/common/util';

export const AlertTooltip = ({ tooltipId, contents, placement = 'auto', className = 'ml-2' }) => {
    const id = useMemo(() => {
        return tooltipId ?? `tooltip_${initUUIDv4()}`;
    }, []);
    return (
        <>
            <span id={id} className="material-icons-round md-18 cursor-default">
                info
            </span>
            <UncontrolledTooltip placement={placement} target={id}>
                {contents}
            </UncontrolledTooltip>
        </>
    );
};

export const QuestionTooltip = ({ tooltipId, contents, placement = 'auto', className = 'ml-2' }) => {
    const id = useMemo(() => {
        return tooltipId ?? `tooltip_${initUUIDv4()}`;
    }, []);
    return (
        <>
            <span id={id} className="material-icons-round md-18 cursor-default">
                help
            </span>
            <UncontrolledTooltip placement={placement} target={id}>
                {contents}
            </UncontrolledTooltip>
        </>
    );
};

const Tooltip = ({ tooltipId, contents, isAlert, Icon, placement = 'auto', className = 'ml-2' }) => {
    const id = useMemo(() => {
        return tooltipId ?? `tooltip_${initUUIDv4()}`;
    }, []);
    return Icon ? (
        <>
            <Icon id={id} className={className} />
            <UncontrolledTooltip placement={placement} target={id}>
                {contents}
            </UncontrolledTooltip>
        </>
    ) : isAlert ? (
        <AlertTooltip tooltipId={id} contents={contents} placement={placement} className={className} />
    ) : (
        <QuestionTooltip tooltipId={id} contents={contents} placement={placement} className={className} />
    );
};

export default Tooltip;

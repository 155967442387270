import { createSlice } from '@reduxjs/toolkit';
import { SOCKET_CONNECTING } from '@util/symbol/window';
import { Socket } from 'socket.io-client';
import { getLsUserInfo, initUUIDv4 } from '@util/common/util';
import { AnySocketData } from '@util/socket/socketData';
import { ObjectUnionType } from '@util/type/util';

// 서비스, 프로젝트 구분
const SCOPE_TYPE_COMMON = 'common';
const NAME_DELIMITER = ':';
type NameSlice = string | number | null;
function joinNameSlices(...nameSlice: NameSlice[]) {
    return Array.from(arguments).join(NAME_DELIMITER);
}

// 응답 데이터 타입 - 위치 측위
export const DATA_TYPE_LOCATION = 'REALTIME_IOT_ITEM_LOCATION';
// 응답 데이터 타입 - 수치형 센싱
export const DATA_TYPE_NUMERIC_SENSING = 'REALTIME_IOT_ITEM_NUMERIC_SENSING';
// 응답 데이터 타입 - 파형 센싱
export const DATA_TYPE_WAVEFORM_SENSING = 'REALTIME_IOT_ITEM_WAVEFORM_SENSING';

// 이벤트 타입 - 훅 응답
export const EVENT_TYPE_HOOK_RESPONSE = 'hook-response';
// 이벤트 타입 - 위치 측위 데이터
export const EVENT_TYPE_LOCATION = joinNameSlices(SCOPE_TYPE_COMMON, DATA_TYPE_LOCATION);
// 이벤트 타입 - 수치형 센싱 데이터
export const EVENT_TYPE_NUMERIC_SENSING = joinNameSlices(SCOPE_TYPE_COMMON, DATA_TYPE_NUMERIC_SENSING);
// 이벤트 타입 - 파형 센싱 데이터
export const EVENT_TYPE_WAVEFORM_SENSING = joinNameSlices(SCOPE_TYPE_COMMON, DATA_TYPE_WAVEFORM_SENSING);

// 요청 메시지 타입 - 구독 설정
export const MESSAGE_TYPE_HOOK = 'hook';
// 요청 메시지 타입 - 구독 해제
export const MESSAGE_TYPE_UNHOOK = 'unhook';
// 요청 메시지 타입 - 필터 설정
export const MESSAGE_TYPE_HOOK_FILTER_SETUP = 'hook-filter-setup';
// 요청 메시지 타입 - 필터 해제
export const MESSAGE_TYPE_HOOK_FILTER_UNSET = 'hook-filter-unset';
// 요청 메시지 타입 - 구독 시작(데이터 전송 시작)
export const MESSAGE_TYPE_HOOK_OPEN_SETUP = 'hook-open-setup';

export const DATA_TYPE = {
    DATA_TYPE_LOCATION,
    DATA_TYPE_NUMERIC_SENSING,
    DATA_TYPE_WAVEFORM_SENSING,
};
type DataType = ObjectUnionType<typeof DATA_TYPE>;

export const EVENT_TYPE = {
    EVENT_TYPE_LOCATION,
    EVENT_TYPE_NUMERIC_SENSING,
    EVENT_TYPE_WAVEFORM_SENSING,
};

// EVENT_TYPE_LOCATION | EVENT_TYPE_NUMERIC_SENSING | EVENT_TYPE_WAVEFORM_SENSING
export type EventType = ObjectUnionType<typeof EVENT_TYPE>;

type ComNum = null | number;

interface Channels {
    locatingChannelName?: null | string;
    numericSensingChannelName?: null | string;
    waveformSensingChannelName?: null | string;
}

const makeDataChannelName = (dataType: DataType, comNum: ComNum) =>
    joinNameSlices(MESSAGE_TYPE_HOOK, SCOPE_TYPE_COMMON, dataType, comNum);
const eventNameToDataType = (eventName: string) => {
    const splitWords = eventName.split(NAME_DELIMITER);
    return splitWords[1];
};

const makeChannelNames = (comNum: ComNum) => {
    const channels: Channels = {};
    if (comNum) {
        channels.locatingChannelName = makeDataChannelName(DATA_TYPE_LOCATION, comNum);
        channels.numericSensingChannelName = makeDataChannelName(DATA_TYPE_NUMERIC_SENSING, comNum);
        channels.waveformSensingChannelName = makeDataChannelName(DATA_TYPE_WAVEFORM_SENSING, comNum);
    } else {
        channels.locatingChannelName = null;
        channels.numericSensingChannelName = null;
        channels.waveformSensingChannelName = null;
    }
    return channels;
};

const PREFIX_SOCKET_FILTER_ID = 'filter_';
export const generateSocketFilterId = () => `${PREFIX_SOCKET_FILTER_ID}${initUUIDv4()}`;

export interface SocketInfo {
    comNum: ComNum;
    socket: null | typeof Socket;
    channels: Channels;
    filterList: string[];
}

const initialState: SocketInfo = {
    comNum: null,
    socket: null,
    channels: {
        locatingChannelName: null,
        numericSensingChannelName: null,
        waveformSensingChannelName: null,
    },
    filterList: [],
};

const { actions, reducer } = createSlice({
    name: 'socketInfo',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            const { userInfo } = getLsUserInfo();
            state.comNum = userInfo?.companyInfo?.comNum;
            state.socket = action.payload;
            state.channels = makeChannelNames(state.comNum);
            window[SOCKET_CONNECTING] = false;
        },
        setEventHandler: (state, action) => {
            const { messageType, callback } = action.payload;
            if (state.socket) {
                if (typeof callback === 'function') {
                    state.socket.on(messageType, callback);
                } else {
                    // Deprecated in the 2023.03 release
                    // Use the 'removeEventHandler' reducer.
                    state.socket.off(messageType);
                }
            }
        },
        setEventHandlerWithFilter: (state, action) => {
            const socket = state.socket;
            const {
                messageType,
                callback,
                filterInfo: { filterId, filterConfig },
            } = action.payload;
            if (socket) {
                socket.emit(MESSAGE_TYPE_HOOK_FILTER_SETUP, {
                    channel: makeDataChannelName(eventNameToDataType(messageType), state.comNum),
                    filterId: filterId,
                    filterConfig: filterConfig,
                });
                state.filterList.push(filterId);

                if (typeof callback === 'function') {
                    if (filterId) {
                        socket.on(messageType, (data: AnySocketData) => {
                            if (data.hooksMetadata.filterIds.includes(filterId)) {
                                callback(data);
                            }
                        });
                    } else {
                        socket.on(messageType, callback);
                    }
                }
            }
        },
        removeEventHandler: (state, action) => {
            const { messageType, callback, filterId } = action.payload;
            const socket = state.socket;
            if (socket) {
                if (typeof callback === 'function') {
                    socket.off(messageType, callback);
                    if (filterId) {
                        socket.emit(MESSAGE_TYPE_HOOK_FILTER_UNSET, {
                            channel: makeDataChannelName(eventNameToDataType(messageType), state.comNum),
                            filterId: filterId,
                        });
                        state.filterList = state.filterList.filter(existFilterId => existFilterId !== filterId);
                    }
                } else {
                    socket.off(messageType);
                }
            }
        },
    },
});

export const { setSocket, setEventHandler, setEventHandlerWithFilter, removeEventHandler } = actions;
export default reducer;

import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '@hooks/useTranslation';
import { editWidgetSettings } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import { Label, Select } from '@components';
import styled from 'styled-components';
import { useSettings } from '../util/useSettings';

const DashboardSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const settings = useSettings(config);

    const dispatch = useContext(ScreenEditDispatchContext);
    const { screenList } = useSelector(state => state.CustomMenu);

    // 카테고리 코드로 다국어 처리되고 있음
    const t = useTranslation('Dashboard');

    const initialWidgetInfo = settings?.dashboardURL ? { dashboardURL: settings.dashboardURL } : { dashboardURL: {} };
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(initialWidgetInfo);

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo(initialWidgetInfo);
    };

    const handleChangeMenu = (selected, action) => {
        const dashboardURL = updatedWidgetInfo?.dashboardURL || {};
        setUpdatedWidgetInfo({
            dashboardURL: { ...dashboardURL, [action.name]: selected.id },
        });
    };
    return (
        <WidgetSettingModal
            headerTitle={t('Dashboard Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('inj')}
                value={
                    <ScreenSelect
                        screenList={screenList}
                        name={'ivInjectionMonitoring'}
                        value={updatedWidgetInfo?.dashboardURL?.ivInjectionMonitoring}
                        handleChange={handleChangeMenu}
                    />
                }
            />
            <Label
                name={t('scale')}
                value={
                    <ScreenSelect
                        screenList={screenList}
                        name={'sensorStatus'}
                        value={updatedWidgetInfo?.dashboardURL?.sensorStatus}
                        handleChange={handleChangeMenu}
                    />
                }
            />
            <Label
                name={t('PATIENT')}
                value={
                    <ScreenSelect
                        screenList={screenList}
                        name={'patientStatus'}
                        value={updatedWidgetInfo?.dashboardURL?.patientStatus}
                        handleChange={handleChangeMenu}
                    />
                }
            />
            <Label
                name={t('ASSET')}
                value={
                    <ScreenSelect
                        screenList={screenList}
                        name={'assetStatus'}
                        value={updatedWidgetInfo?.dashboardURL?.assetStatus}
                        handleChange={handleChangeMenu}
                    />
                }
            />
            <Label
                name={t('refrigerator')}
                value={
                    <ScreenSelect
                        screenList={screenList}
                        name={'fridgeSensor'}
                        value={updatedWidgetInfo?.dashboardURL?.fridgeSensor}
                        handleChange={handleChangeMenu}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

const ScreenSelect = ({ screenList, name, value, handleChange }) => {
    return (
        <SelectWrapper>
            <Select
                customControlStyles={{ width: '100%' }}
                customMenuStyles={{ width: '100%' }}
                customOptionStyles={{ width: '100%' }}
                name={name}
                options={screenList}
                valueKey={'id'}
                labelKey={'label'}
                value={screenList.find(screen => screen.id === value)}
                onChange={handleChange}
                isModalSelect
            />
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div`
    width: 100%;
`;

export default DashboardSetting;

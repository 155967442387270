import React, { useContext } from 'react';
import Button from '@components/Button';
import useTranslation from '@util/hooks/useTranslation';
import { setFilterOpen } from '../filterSearchGroupReducer';
import { FilterSearchGroupDispatchContext, FilterSearchGroupStateContext } from '../index';
import { useSelector } from 'react-redux';
import IntervalTimeSelect from './IntervalTimeSelect';

const FilterButtonArea = ({ children, widgetSearch = false }) => {
    const t = useTranslation('Search');
    const useGlobalIntervalTime = useSelector(state => state.GlobalIntervalTime.useGlobalIntervalTime);
    const { filterOpen } = useContext(FilterSearchGroupStateContext);
    const dispatch = useContext(FilterSearchGroupDispatchContext);
    const handleSearchBoxToggle = () => dispatch(setFilterOpen(!filterOpen));
    return (
        <div className="filter-function">
            {useGlobalIntervalTime && <IntervalTimeSelect />}
            <Button className="btn-gray btn-icon show__under-mobile filter-open" onClick={handleSearchBoxToggle}>
                <span className="material-icons-round">arrow_drop_down</span>
                {t('More')}
            </Button>
            {children}
        </div>
    );
};

export default FilterButtonArea;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import ResizeDetector from 'react-resize-detector';
import AppMain from '../Layout/AppMain';
import { TopSwitch } from './Components/Router';
import { useTranslation } from 'react-i18next';
import { setInitAppInfo } from '@reducer/Common/AppInfo';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import LicenseModal from './Components/LicenseModal';
import useLicenseErrorCheck from '@hooks/useLicenseErrorCheck';

const Main = ({ appInfo, location }) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { colorScheme, enableMobileMenu, enableFixedSidebar, enableClosedSidebar, enablePageTabsAlt } = useSelector(
        state => state.ThemeOptions,
    );
    const { lang } = useSelector(state => state.UserInfo);
    const { fullScreen } = useSelector(state => state.ScreenInfo);
    const { mode } = useSelector(state => state.ScreenInfo);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    useEffect(() => {
        if (appInfo) {
            dispatch(setInitAppInfo(appInfo));
        }
    }, [appInfo]);

    useLicenseErrorCheck();

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <div
                    className={cx(
                        'fixed-header fixed-footer',
                        'app-container',
                        !location.pathname.match(/^\/login$/) && 'app-theme-' + colorScheme,
                        { 'background-color-unset': location.pathname.match(/^\/login$/) },
                        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                        { 'closed-sidebar-mobile': width < 1250 },
                        { 'sidebar-mobile-open': enableMobileMenu },
                        { 'body-tabs-shadow-btn': enablePageTabsAlt },
                        { 'full-screen-viewer': fullScreen },
                        { 'dashboard-edit-mode': [SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW].includes(mode) },
                    )}
                >
                    <TopSwitch MainComponent={AppMain} />
                    <LicenseModal />
                </div>
            )}
        />
    );
};

export default withRouter(Main);

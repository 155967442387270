import React from 'react';
import { Route } from 'react-router-dom';
import { ErrorHandleSwitch } from '../Components/Router';
import MenuManagement from './MenuManagement';
import ScreenManagement from './ScreenManagement';
import FilterManagement from './FilterManagement';
import AuthorizationGroupManagement from './AuthorizationGroupManagement';
import { A_PATH_MENU, A_PATH_SCREEN, A_PATH_FILTER, A_PATH_AUTHORIZATION_GROUP } from '../Components/Router/path';

const Settings = () => {
    return (
        <ErrorHandleSwitch>
            <Route path={A_PATH_MENU} component={MenuManagement} />
            <Route path={A_PATH_SCREEN} component={ScreenManagement} />
            <Route path={A_PATH_FILTER} component={FilterManagement} />
            <Route path={A_PATH_AUTHORIZATION_GROUP} component={AuthorizationGroupManagement} />
        </ErrorHandleSwitch>
    );
};

export default Settings;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    setSocket,
    setEventHandler,
    setEventHandlerWithFilter,
    removeEventHandler,
    SocketInfo,
    generateSocketFilterId,
} from '@reducer/SocketInfo';
import { SOCKET_CONNECTING } from '@util/symbol/window';
import { getApiURL } from '@api/index';
import { getUuid } from '@util/common/util';
import socketio, { Socket } from 'socket.io-client';
import useAppSelector from '@hooks/useAppSelector';

export type SocketEventHandler<T> = (data: T, ...args: any[]) => void;

interface SocketUtil {
    socket: null | typeof Socket;
    connectSocket(): void;
    sendMessage(eventName: string, data?: any): void;
    setSocketEvent<T>(messageType: string, callback: SocketEventHandler<T>, filterConfig?: object): string | void;
    removeSocketEvent<T>(messageType: string, callback?: SocketEventHandler<T>, filterId?: string): void;
    closeSocket(): void;
    socketInfo: SocketInfo;
}

const useSocket = (): SocketUtil => {
    const dispatch = useDispatch();
    const socketInfo = useAppSelector(state => state.SocketInfo);
    const { socket } = socketInfo;

    const setSocketEvent = useCallback(
        <DataType>(messageType: string, callback: SocketEventHandler<DataType>, filterConfig?: object) => {
            if (filterConfig) {
                const filterId = generateSocketFilterId();
                dispatch(setEventHandlerWithFilter({ messageType, callback, filterInfo: { filterId, filterConfig } }));
                return filterId;
            } else {
                dispatch(setEventHandler({ messageType, callback }));
            }
        },
        [dispatch],
    );

    const removeSocketEvent = useCallback(
        <DataType>(messageType: string, callback?: SocketEventHandler<DataType>, filterId?: string) => {
            dispatch(removeEventHandler({ messageType, callback, filterId }));
        },
        [dispatch],
    );

    const closeSocket = useCallback(() => {
        if (socket) {
            socket.close();
            dispatch(setSocket(null));
        }
    }, [dispatch, socket]);

    const connectSocket = useCallback(() => {
        // 연결된 소켓 없음, 연결중 아님
        if (!socket && !window[SOCKET_CONNECTING]) {
            window[SOCKET_CONNECTING] = true;
            getApiURL().then(({ wmsSocketUrl }) => {
                const uuid = getUuid();
                const ws = socketio(wmsSocketUrl, {
                    transports: ['websocket'],
                    forceNew: true,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                });
                ws.on('connect', function () {
                    console.log('SOCKET_CONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    dispatch(setSocket(ws));
                });
                ws.on('disconnect', function () {
                    console.log('SOCKET_DISCONNECT : ', wmsSocketUrl);
                    dispatch(setSocket(ws));
                });
                ws.on('reconnect', function () {
                    console.log('SOCKET_RECONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    dispatch(setSocket(ws));
                });
            });
        }
    }, []);

    const sendMessage = useCallback(
        (eventName: string, data?: any) => {
            if (socket) {
                socket.emit(eventName, data);
            }
        },
        [socket],
    );

    return {
        socket,
        connectSocket,
        sendMessage,
        setSocketEvent,
        removeSocketEvent,
        closeSocket,
        socketInfo,
    };
};

export default useSocket;

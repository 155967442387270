import React, { createContext, useCallback, useEffect, useReducer } from 'react';
import { Button, Card, Page, Table } from '@components';
import * as col from '../../../util/grid/column/filter';
import * as commonCol from '../../../util/grid/column';
import { useHistory } from 'react-router-dom';
import { useAsync, useColumns, useTranslation } from '@hooks';
import { getFilterListApi } from '@api/filter';
import Search from './Search';
import FilterSubMenu from './FilterSubMenu';
import reducer, { FILTER_LIST_PAGE_SIZE, initialState, setFilterListInfo } from './reducer';

export const FilterListContext = createContext();
export const FilterListDispatchContext = createContext();

const FilterList = () => {
    const t = useTranslation('Filter');
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { filterListInfo } = state;

    const filterColumns = useColumns(
        [
            col.filterName(),
            col.filterDescription(),
            commonCol.modDate(),
            {
                Header: 'Action',
                id: 'subMenu',
                width: 70,
                className: 'flex-center overflow-visible',
                Cell: FilterSubMenu,
            },
        ],
        t,
    );

    const handleTrClick = useCallback(
        selected => {
            history.push(`/setting/filter/${selected.filterNum}`);
        },
        [history],
    );

    const { promise: getFilterList, state: filterRequestState } = useAsync({
        promise: getFilterListApi,
        fixedParam: { pageSize: FILTER_LIST_PAGE_SIZE },
        resolve: res => {
            dispatch(setFilterListInfo({ ...res, rows: res.rows ?? [], pageSize: FILTER_LIST_PAGE_SIZE }));
        },
    });

    const handlePageChange = pageIndex => {
        let requestParam = {};
        if (filterRequestState.request && filterRequestState.request.keyword) {
            requestParam = filterRequestState.request;
        }
        getFilterList({ ...requestParam, page: pageIndex });
    };

    useEffect(() => {
        getFilterList();
    }, []);

    return (
        <FilterListDispatchContext.Provider value={dispatch}>
            <FilterListContext.Provider value={state}>
                <Page menuPath={[t('Filters', 'Menu'), t('Filter List', 'Menu')]} className={'h-100'}>
                    <Card
                        className="h-100"
                        bodyClassName="d-flex flex-column"
                        header={{
                            title: t('Filter List'),
                            action: (
                                <Button
                                    className="btn-icon btn-secondary"
                                    iconName="add"
                                    onClick={() => history.push('/setting/filter/create')}
                                >
                                    {t('Create Filter')}
                                </Button>
                            ),
                        }}
                    >
                        <Search fetchListData={getFilterList} />
                        <div className="flx-1">
                            <Table
                                onPageChange={handlePageChange}
                                textAlign={'center'}
                                data={filterListInfo}
                                columns={filterColumns}
                                onTrClick={handleTrClick}
                            />
                        </div>
                    </Card>
                </Page>
            </FilterListContext.Provider>
        </FilterListDispatchContext.Provider>
    );
};

export default FilterList;

import React, { useContext, useEffect, useRef, useState } from 'react';

import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { FilterList, SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import { Button, DatePicker, SearchableSelect } from '@components';
import useTranslation from '@hooks/useTranslation';
import useAsync from '@hooks/useAsync';
import moment from 'moment';
import useConfirmModal from '@hooks/useConfirmModal';
import { fetchIotItemList } from '@api/asset';
import { reset, setTargetNums } from '../sensorLogReducer';
import { SensorLogDispatchContext, SensorLogStateContext } from '../index';
import { makeParameter } from '../../util/commons';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';

/**
 *
 * 센서 로그 검색기능 컴포넌트
 *
 * @param {function} promise 센서 리스트 호출 callback
 * @param {date} startDate 검색 시작 일자
 * @param {date} endDate  검색 마지막 일자
 * @param {function} handleDateCallback  날짜 변경 함수
 *
 * @author jinbeom-jung
 * */

const Search = ({ promise, startDate, endDate, handleDateCallback }) => {
    const dispatch = useContext(SensorLogDispatchContext);
    const { targetNums } = useContext(SensorLogStateContext);
    const t = useTranslation('Sensor Log');

    const [selectableSensorList, setSelectableSensorList] = useState([]);
    useAsync({
        promise: fetchIotItemList,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            const filterOption = rows.map(({ targetName, targetNum }) => ({ label: targetName, value: targetNum }));
            dispatch(setTargetNums(filterOption.map(({ value }) => value)));
            setSelectableSensorList(filterOption);
        },
        reject: err => console.error(err),
    });

    const handleSelectChange = selected => dispatch(setTargetNums(selected.map(({ value }) => value)));

    const handleRefreshClick = () => {
        dispatch(reset({ targetNums: selectableSensorList.map(({ value }) => value) }));
        handleDateCallback({ selected: 0, type: 'reset' });
        promise({
            startDate: moment().startOf('day').unix(),
            endDate: moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
        });
    };

    const handleSearchClick = () => {
        const param = { targetNums: targetNums.join(',') };
        if (startDate) {
            if (!endDate) {
                toggleWarningModal();
                return false;
            } else {
                param['startDate'] = moment(startDate).unix();
                param['endDate'] = moment(endDate).unix();
            }
        }

        const result = makeParameter({ searchRequirement: param });
        promise(result);
    };

    const handleYesterdayButtonClick = () => {
        handleDateCallback({ selected: 0, type: 'yesterday' });
    };

    const { Modal: WarningModal, toggleModal: toggleWarningModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Please select an end date', 'ConfirmModal'),
    });

    const [today, setToday] = useState(moment().startOf('day').valueOf());
    const handleStartDate = selected => {
        const selectedDate = moment(selected).startOf('day').valueOf();

        if (today === selectedDate) {
            handleDateCallback({ selected, type: 'startDate' });
        } else {
            handleDateCallback({ selected, type: 'startDate' });
            handleDateCallback({
                selected: moment(selected).add(23, 'hour').add(59, 'minute').add(59, 'second').valueOf(),
                type: 'endDate',
            });
        }
    };

    const handleEndDate = selected => {
        const selectedDate = moment(selected).startOf('day').valueOf();

        if (today === selectedDate) {
            handleDateCallback({
                selected: moment(selected).subtract(23, 'hour').subtract(59, 'minute').subtract(59, 'second').valueOf(),
                type: 'startDate',
            });
            handleDateCallback({ selected, type: 'endDate' });
        } else {
            handleDateCallback({
                selected: moment(selected).subtract(23, 'hour').subtract(59, 'minute').subtract(59, 'second').valueOf(),
                type: 'startDate',
            });
            handleDateCallback({ selected, type: 'endDate' });
        }
    };

    const inputStartRef = useRef(null);
    const inputEndRef = useRef(null);
    useEffect(() => {
        if (inputStartRef && inputStartRef.current) {
            inputStartRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
        if (inputEndRef && inputEndRef.current) {
            inputEndRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
    }, [startDate, endDate]);

    return (
        <>
            <FilterSearchGroup className={'p-0'}>
                <SearchGroup>
                    <FilterList>
                        <SelectGroup>
                            <div className="datePicker-container" ref={inputStartRef}>
                                <DatePicker
                                    key={`dp_start_${startDate}_${endDate}`}
                                    value={startDate}
                                    handleChange={handleStartDate}
                                    valueType="ms"
                                    maxDate={moment().valueOf()}
                                    onKeyDown={e => {
                                        e.preventDefault();
                                    }}
                                    showTimeInput
                                />
                            </div>
                        </SelectGroup>
                        <div className="text-center"> ~ </div>
                        <SelectGroup>
                            <div className="datePicker-container" ref={inputEndRef}>
                                <DatePicker
                                    key={`dp_end_${startDate}_${endDate}`}
                                    value={endDate}
                                    valueType="ms"
                                    //minDate={startDate}
                                    maxDate={moment(startDate)
                                        .startOf('day')
                                        .add(23, 'hour')
                                        .add(59, 'minute')
                                        .add(59, 'second')
                                        .valueOf()}
                                    disabled={!startDate}
                                    handleChange={handleEndDate}
                                    onKeyDown={e => {
                                        e.preventDefault();
                                    }}
                                    showTimeInput
                                />
                            </div>
                        </SelectGroup>
                        <SelectGroup>
                            <SearchableSelect
                                data={selectableSensorList}
                                selected={targetNums}
                                onChange={handleSelectChange}
                                title={t('Target Name')}
                            />
                        </SelectGroup>
                        <Button className="btn-gray" onClick={handleYesterdayButtonClick}>
                            {t('Yesterday', 'Button')}
                        </Button>
                        <Button className="btn-secondary" onClick={handleSearchClick}>
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-line btn-icon-only" iconName="refresh" onClick={handleRefreshClick} />
                    </FilterList>
                </SearchGroup>
            </FilterSearchGroup>
            <WarningModal />
        </>
    );
};
export default Search;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@hooks';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import Label from '../../../Common/Label';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { useSettings } from '../../util/useSettings';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { Select } from '@components';

const TopFiveCongestedGeofenceSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('TopFiveCongestedGeofence');
    const dispatch = useContext(ScreenEditDispatchContext);
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const options = useMemo(
        () => [
            { value: 'category', label: t('Category') },
            { value: 'restricted', label: t('Authorization') },
        ],
        [t],
    );
    const [selectedType, setSelectedType] = useState(null);

    const handleSelectChange = e => {
        setSelectedType(e);
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, type: selectedType.value },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, type: selectedType.value },
                }),
            );
        }
    };

    useEffect(() => {
        if (settings.type) {
            setSelectedType(options.find(e => e.value === settings.type));
        }
    }, [settings]);

    return (
        <WidgetSettingModal
            headerTitle={t('Congested Geofence Top 5')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            {...restProps}
        >
            <Label
                name={t('Type')}
                value={<Select options={options} value={selectedType} onChange={handleSelectChange} isModalSelect />}
            />
        </WidgetSettingModal>
    );
};

export default TopFiveCongestedGeofenceSetting;

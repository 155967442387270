import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    screenListInfo: {},
    selectedScreen: null,
};

const { actions, reducer } = createSlice({
    name: 'screenList',
    initialState,
    reducers: {
        setScreenListInfo: (state, action) => {
            state.screenListInfo = action.payload;
        },
        selectScreen: (state, action) => {
            state.selectedScreen = action.payload;
        },
    },
});

export const { setScreenListInfo, selectScreen } = actions;
export default reducer;

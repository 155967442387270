import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync, useTranslation } from '@util/hooks';
import { setLanguage, setOauthInfo } from '@reducer/UserInfo';
import { fetchOAuthToken } from '@api/login';
import Button from '../../Common/Button';
import language from '@util/staticData/language.json';
import { initialState, reducer, setShowErrorMsg } from './reducer';
import cx from 'classnames';
import Select from '@components/Select';

// For Further CSS
import { decodeInfo } from '@util/common/util';
import LoginForm from './Components/LoginForm';
import FindForm from './Components/FindForm';
import ChangeForm from './Components/ChangeForm';
import CompleteForm from './Components/CompleteForm';
import useMoreUserInfo from './hooks/useMoreUserInfo';

export const LoginStateContext = createContext();
export const LoginDispatchContext = createContext();

const FormByStep = ({ step }) => {
    switch (step) {
        case 'LOGIN':
            return <LoginForm />;
        case 'FIND':
            return <FindForm />;
        case 'CHANGE':
            return <ChangeForm />;
        case 'COMPLETE':
            return <CompleteForm />;
        default:
            return <LoginForm />;
    }
};

const Login = props => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Login');
    const [state, dispatch] = useReducer(reducer, initialState);
    const { step } = state;
    const [showMobileLogin, setShowMobileLogin] = useState(false);

    const history = useHistory();
    const { oAuthInfo: ssoOAuthInfo } = useParams();

    const { userInfo, lang } = useSelector(state => state.UserInfo);

    const { promise: initialLogin } = useAsync({
        promise: fetchOAuthToken,
        resolve: res => {
            // 2. 로그인 인포가 보내지고 access token이 받아져왔을때
            if (res.scope) {
                storeDispatch(setOauthInfo(res));
                getMoreUserInfo();
            }
        },
        reject: err => {
            dispatch(setShowErrorMsg(true));
        },
    });

    const getMoreUserInfo = useMoreUserInfo();

    // 5. 유저 이름, 유저가 속한 회사 정보가 받아와진 경우 실행
    useEffect(() => {
        if (userInfo && userInfo.companyInfo && userInfo.companyInfo.timeZone) {
            // 대쉬보드의 홈설정이 되어있는경우
            if (userInfo.home) {
                history.replace(`/dashboards/${userInfo.home}`);
            }
            // 대쉬보드의 홈설정이 되어있지 않은경우
            else {
                history.replace('/');
            }
        } else {
            history.replace('/login');
        }
    }, [userInfo]);

    useEffect(() => {
        if (ssoOAuthInfo) {
            try {
                const decodedInfo = decodeInfo(atob(ssoOAuthInfo));
                initialLogin({
                    grant_type: 'refresh_token',
                    refresh_token: decodedInfo.refresh_token,
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [ssoOAuthInfo]);

    // 모바일 창에서 로그인 버튼 클릭시 아이디 패스워드를 입력할 수 있는 창으로 변경
    const handleMobileLoginClick = e => {
        e.preventDefault();
        setShowMobileLogin(!showMobileLogin);
    };

    // 언어 변환
    const handleLangChange = selectedOption => {
        storeDispatch(setLanguage(selectedOption.value));
    };

    return (
        <LoginDispatchContext.Provider value={dispatch}>
            <LoginStateContext.Provider value={state}>
                <div className="page-landering">
                    <div className={`page-landering__cont-wrap ${showMobileLogin && 'login_clicked'}`}>
                        {/* 모바일용 헤더 */}
                        <div className={`lander-mobile-header`}>
                            <div className="mobile-header__wrap">
                                <div className="logo-box">
                                    <div className="pnt-logo logo-o-w" />
                                </div>
                                <div className="language-box">
                                    <Select
                                        value={language.find(v => v.value === lang)}
                                        options={language}
                                        onChange={handleLangChange}
                                        iconName="language"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 데스크용 */}
                        <div className={`lander-info`}>
                            <div className="lander-info__wrap">
                                <div className="info--logo">
                                    <div className="pnt-logo logo-b-b" />
                                </div>
                                <div className="info--txt">
                                    <h2 className="txt__main">RMS</h2>
                                    <b className="txt__sub">{t('Efficiency starts with knowing where things are.')}</b>
                                    <p className="txt__desc">
                                        {t(
                                            "IndoorPlus+ provides you objective data for your next decision and action. Upon customer's request, customized dashboards are created to fit even more detailed customers' requirements and this is how our customers design their own way to manage their assets. Looking for something? Let IndoorPlus+ assist you.",
                                        )}
                                    </p>
                                </div>
                                <div className="info--language">
                                    <div className={'mt-3'}></div>
                                    <Select
                                        value={language.find(v => v.value === lang)}
                                        options={language}
                                        onChange={handleLangChange}
                                        iconName="language"
                                    />
                                </div>
                                <div className="info--login-btn">
                                    <Button
                                        className={'btn-brand btn-icon btn-animation-move'}
                                        iconName="arrow_forward_ios"
                                        onClick={handleMobileLoginClick}
                                    >
                                        {t('Login')}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className={`lander-login`}>
                            <div
                                className={cx(
                                    'lander-login__wrap flx-col',
                                    step === 'CHANGE' && 'flx-full pnt-label-6 label-col-mobile',
                                )}
                            >
                                <FormByStep step={step} />
                            </div>
                        </div>
                    </div>
                    <div className="page-landering__bg" />
                </div>
            </LoginStateContext.Provider>
        </LoginDispatchContext.Provider>
    );
};

export default Login;

import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useAsync, useTranslation } from '@hooks';
import { fetchRealTimeSensorMonitoring } from '@api/monitoring';
import realTimeSensorMonitoringReducer, {
    clearSensingTypes,
    initialState,
    setSelectedFloor,
    setSelectedSensor,
    setSelectedSensorType,
    setSensorList,
    setSensorType,
} from './realTimeSensorMonitoringReducer';
import SensorType from './Components/SensorType';
import SelectedSensor from './Components/SelectedSensor';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { Button, LoadingBlock } from '../../Common';
import NoDataBlock from '../Components/NoDataBlock';
import cx from 'classnames';
import styles from 'assets/pnt/widgets/RealTimeSensorMonitoring.module.scss';
import { SENSOR, STATUS } from './constants';
import WidgetCard from '../Components/WidgetCard';
import InnerItemCard from '@components/Card/InnerItemCard';

export const RealTimeSensorMonitoringContext = React.createContext();
export const RealTimeSensorMonitoringDispatchContext = React.createContext();

const RealTimeSensorMonitoring = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('RealTimeSensorMonitoring');
    const listWidgetRef = useRef();
    const specificSensorWidgetRef = useRef();

    const [state, dispatch] = useReducer(realTimeSensorMonitoringReducer, initialState);
    const { sensorType, sensorList, selectedSensor } = state;

    const [showSearchArea, setShowSearchArea] = useState(false);

    const { promise: getSensorList, state: sensorInfo } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const { rows } = res;
            dispatch(setSensorList(rows));
        },
    });

    useEffect(() => {
        getSensorList();
        dispatch(setSensorType('ALL TYPE'));
    }, []);

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleLocationClick = sensor => {
        dispatch(setSelectedSensor(sensor));
    };

    const handleGoBackClick = () => {
        dispatch(setSelectedSensor({}));
    };

    const handleRefreshClick = () => {
        dispatch(setSelectedSensorType([]));
        dispatch(setSelectedFloor([]));
        dispatch(clearSensingTypes());
        getSensorList();
    };

    return (
        <RealTimeSensorMonitoringDispatchContext.Provider value={dispatch}>
            <RealTimeSensorMonitoringContext.Provider value={state}>
                {Object.keys(selectedSensor).length === 0 ? (
                    <WidgetCard
                        widgetInfo={widgetInfo}
                        {...restProps}
                        ref={listWidgetRef}
                        subTitle={
                            sensorType === 'ALL TYPE'
                                ? t('Fixed Type & Mobile Type')
                                : sensorType === 'F'
                                ? t('Fixed Type')
                                : t('Mobile Type')
                        }
                        headerAction={
                            <>
                                <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                                    {t('Search', 'Button')}
                                </Button>
                                <Button
                                    className="btn-lightgray btn-icon-only"
                                    iconName="refresh"
                                    onClick={handleRefreshClick}
                                />
                            </>
                        }
                        searchFilter={showSearchArea && <SensorType />}
                    >
                        <LoadingBlock blocking={sensorInfo.isLoading}>
                            {!Array.isArray(sensorList) || !sensorList.length ? (
                                <NoDataBlock />
                            ) : (
                                <div className={`${isMobile && 'mt-2'} grid-container item-grid-250 pb-3`}>
                                    {sensorList.map(sensorItem => (
                                        <InnerItemCard
                                            key={`${sensorItem.sensorNum}_${sensorItem.sensingType}`}
                                            className={'m-0'}
                                            header={{
                                                className: '',
                                                title: `${sensorItem.targetName}`,
                                                subTitle: `${sensorItem.sensorName}`,
                                                action: (
                                                    <Button
                                                        className={`btn-trans btn-icon-only`}
                                                        iconClassName={'material-icons-round color-gray'}
                                                        iconName={'location_on'}
                                                        onClick={() => handleLocationClick(sensorItem)}
                                                    />
                                                ),
                                            }}
                                        >
                                            <div className={'flx-row'}>
                                                {/* Sensor image */}
                                                <div
                                                    className={cx(
                                                        styles.sensor_img,
                                                        sensorItem.status
                                                            ? `bg-${STATUS[sensorItem.status].color}`
                                                            : 'bg-depth-9',
                                                    )}
                                                >
                                                    {/* Sensor icon */}
                                                    <span
                                                        className={SENSOR[sensorItem.sensingType] ?? SENSOR.COMMON}
                                                    ></span>
                                                    {/* Sensor status */}
                                                    <div className={styles.status}>
                                                        <span className="material-icons-round">
                                                            {STATUS[sensorItem.status].iconName}
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Sensor info */}
                                                <div className="ml-3 flx-1 flex-column flx-sb align-start">
                                                    {/* Type */}
                                                    <div className={'pnt-txt s-9'}>
                                                        {`${t(sensorItem.sensingType)} ${
                                                            sensorItem.measurementUnit
                                                                ? `(${sensorItem.measurementUnit})`
                                                                : ''
                                                        }`}
                                                    </div>
                                                    <div className="flx-row flx-sb w-100">
                                                        {/* Current value */}
                                                        <span
                                                            className={cx(
                                                                'pnt-txt txt-bold s-4',
                                                                !sensorItem.currentValue && 'color-gray',
                                                            )}
                                                        >
                                                            {sensorItem.currentValue
                                                                ? Number.isInteger(sensorItem.currentValue)
                                                                    ? sensorItem.currentValue
                                                                    : sensorItem.currentValue.toFixed(2)
                                                                : 'N/A'}
                                                        </span>
                                                        {/* Status */}
                                                        <span
                                                            className={cx(
                                                                'pnt-txt txt-bold s-7',
                                                                STATUS[sensorItem.status].color &&
                                                                    `color-${STATUS[sensorItem.status].color}`,
                                                            )}
                                                        >
                                                            {t(sensorItem.status)}
                                                        </span>
                                                    </div>
                                                    {/* Last received time */}
                                                    <div className={'pnt-txt s-10 opacity-7'}>
                                                        {sensorItem.currentValue && sensorItem.modDate
                                                            ? moment
                                                                  .unix(sensorItem.modDate)
                                                                  .format('YYYY-MM-DD ( HH:mm:ss )') || '-'
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                        </InnerItemCard>
                                    ))}
                                </div>
                            )}
                        </LoadingBlock>

                        {children}
                    </WidgetCard>
                ) : (
                    <WidgetCard
                        widgetInfo={widgetInfo}
                        {...restProps}
                        ref={specificSensorWidgetRef}
                        handleGoBackClick={handleGoBackClick}
                    >
                        <SelectedSensor />
                        {children}
                    </WidgetCard>
                )}
            </RealTimeSensorMonitoringContext.Provider>
        </RealTimeSensorMonitoringDispatchContext.Provider>
    );
};

export default RealTimeSensorMonitoring;

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { temp } from '../../../../Widgets';

export const initialState = {
    origin: {},
    editable: {},
    tempWidgetLayout: null,
};

const { actions, reducer } = createSlice({
    name: 'screenEdit',
    initialState,
    reducers: {
        setEditScreen: (state, action) => {
            const screen = action.payload;
            if (screen) {
                try {
                    screen.widgetInfo = JSON.parse(screen.widgetInfo);
                    if (!screen.widgetInfo || screen.widgetInfo.length === 0) {
                        const id = moment().valueOf().toString(36);
                        screen.widgetInfo = [
                            {
                                menuNum: screen.menuNum,
                                id,
                                type: temp.type,
                                config: temp.config,
                                layout: {
                                    ...temp.layout,
                                    i: id,
                                    x: 0,
                                    y: 0,
                                },
                                edited: false,
                            },
                        ];
                    }
                    state.origin = screen;
                    state.editable = screen;
                } catch (e) {
                    state.origin = {};
                    state.editable = {};
                }
            } else {
                state.origin = {};
                state.editable = {};
            }
        },
        setTempWidgetLayout: (state, action) => {
            state.tempWidgetLayout = action.payload;
        },
        editWidget: (state, action) => {
            state.editable.widgetInfo = state.editable.widgetInfo.map(widget => {
                if (widget.id === action.payload.id) {
                    return action.payload;
                }
                return widget;
            });
        },
        editWidgetSettings: (state, action) => {
            const newId = `d${state.editable.menuNum}_${moment().valueOf().toString(36)}`;
            state.editable.widgetInfo = state.editable.widgetInfo.map(widget => {
                if (widget.id === action.payload.id) {
                    widget.config.settings = action.payload.settings;
                    widget.id = newId;
                    widget.layout.i = newId;
                }
                return widget;
            });
        },
        editWidgets: (state, action) => {
            state.editable.widgetInfo = action.payload;
        },
        editLayout: (state, action) => {
            const layout = action.payload;
            state.editable.widgetInfo = state.editable.widgetInfo.map(widgetInfo => {
                const layoutInfo = widgetInfo.layout;
                widgetInfo.layout = layout.find(v => v.i === layoutInfo.i);
                return widgetInfo;
            });
        },
        deleteWidget: (state, action) => {
            const widgetId = action.payload;
            state.editable.widgetInfo = state.editable.widgetInfo.reduce((acc, widgetInfo) => {
                if (widgetInfo.id !== widgetId) {
                    acc.push(widgetInfo);
                }
                return acc;
            }, []);
        },
        editFilterNum: (state, action) => {
            const filterNum = action.payload;
            state.editable.filterNum = filterNum ? filterNum : null;
        },
    },
});

export const {
    setEditScreen,
    setTempWidgetLayout,
    editWidget,
    editWidgetSettings,
    editWidgets,
    editLayout,
    deleteWidget,
    editFilterNum,
} = actions;
export default reducer;

import TagStatus from './TagStatus';
import TagStatusSetting from './TagStatus/TagStatusSetting';
import TagStateAlarmList from './TagStateAlarmList';
import TemporaryWidget from './TemporaryWidget';
import CategoryChart from './CategoryChart';
import CategoryChartSetting from './CategoryChart/CategoryChartSetting';
import TagStateAlarmListSetting from './TagStateAlarmList/TagStateAlarmListSetting';
import GeofenceStatus from './GeofenceStatus';
import GeofenceStatusSetting from './GeofenceStatus/GeofenceStatusSetting';
import IotItemStatus from './IotItemStatus';
import TagBatteryStatus from './TagBatteryStatus';
import ScannerStatus from './ScannerStatus';
import IotItemCountStatus from './IotItemCountStatus';
import ItemDetailStatus from './ItemDetailStatus';
import FloorPermissionStatus from './FloorPermissionStatus';
import ItemDetailStatusSetting from './ItemDetailStatus/ItemDetailStatusSetting';
import GeofenceCongestionStatus from './GeofenceCongestionStatus';
import TriggeredAlertStatus from './TriggeredAlertStatus';
import RealTimeLocationStatus from './RealTimeLocationStatus';
import RealTimeLocationSetting from './RealTimeLocationStatus/RealTimeLocationSetting';
import RealTimeSensorMonitoring from './RealTimeSensorMonitoring';
import RealTimeTagMonitoringSetting from './RealTimeTagMonitoring/RealTimeTagMonitoringSetting';
import RealTimeTagMonitoring from './RealTimeTagMonitoring';
import MassRealTimeLocationStatus from './MassRealTimeLocationStatus';
import CongestionStatus from './CongestionStatus';
import CongestionStatusSetting from './CongestionStatus/Component/CongestionStatusSetting';
import TopFiveCongestedGeofence from './TopFiveCongestedGeofence';
import TopFiveCongestedGeofenceSetting from './TopFiveCongestedGeofence/Component/TopFiveCongestedGeofenceSetting';
import HourlyCongestionStatus from './HourlyCongestionStatus';
import HourlyCongestionStatusSetting from './HourlyCongestionStatus/Component/HourlyCongestionStatusSetting';
import IotItemStatusSetting from './IotItemStatus/IotItemStatusSetting';
import Dashboard from './Dashboard';
import DashboardSetting from './Dashboard/DashboardSetting';
import IVInjectionMonitoring from './IVInjectionMonitoring';
import IVInjectionMonitoringSetting from './IVInjectionMonitoring/IVInjectionMonitoringSetting';
import RefrigeratorSensor from './RefrigeratorSensor';
import VitalSignsMonitoring from './VitalSignsMonitoring';
import SensorStatus from './SensorStatus';
import SensorStatusSetting from './SensorStatus/SensorStatusSetting';
import AnalysisHeatMap from './AnalysisHeatMap';
import IotItemInfoLog from './IotItemInfoLog';
import IotItemMappingLog from './IotItemMappingLog';
import LocationLog from './LocationLog';
import SensorLog from './SensorLog';
import LocationLogStorageHistory from './LocationLogStorageHistory';
import LoginHistoryLog from './LoginHistoryLog';
import ScannerStatusLog from './ScannerStatusLog';
import GeofenceLog from './GeofenceLog';

import Widget, { WIDGET_CATEGORY } from './staticInfo';

export const tagStatus: Widget = {
    type: 'tagStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Overview Of Tag Status',
    // name: 'Tag Status',
    desc:
        'The graph shows the beacon in use, the lost signal beacon, the beacon in the SOS state, the beacon in the low battery state, and the beacon that has not moved for more than 1 minute.',
    thumbnailClassName: 'w_chart',
    Component: TagStatus,
    SettingComponent: TagStatusSetting,
    config: {
        defaultSettings: {
            name: 'Overview Of Tag Status',
            // intervalTime: 10000,
        },
    },
    // config: { defaultSettings: { name: 'Tag Status', intervalTime: 10000 } },
    layout: {
        minW: 6,
        w: 10,
        maxW: 13,
        minH: 10,
        h: 12,
        maxH: 14,
    },
};
export const categoryChart: Widget = {
    type: 'categoryChart',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Category Chart',
    // name: 'Category Chart',
    desc: 'It shows the ratio of registered targets by category in charts.',
    thumbnailClassName: 'w_pie',
    Component: CategoryChart,
    SettingComponent: CategoryChartSetting,
    config: {
        defaultSettings: {
            name: 'Category Chart',
            // intervalTime: 10000,
            chartType: 'pie',
        },
    },
    // config: { defaultSettings: { name: 'Category Chart', intervalTime: 10000, chartType: 'pie' } },
    layout: {
        minW: 2,
        w: 4,
        maxW: 5,
        minH: 15,
        h: 18,
        maxH: 20,
    },
};
export const tagStateAlarmList: Widget = {
    type: 'tagStateAlarmList',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'List Of Triggered Event',
    // name: 'Tag State Alarm',
    desc: 'It monitors the status of tags.',
    thumbnailClassName: 'w_table',
    Component: TagStateAlarmList,
    SettingComponent: TagStateAlarmListSetting,
    config: {
        defaultSettings: {
            name: 'List Of Triggered Event',
            // , intervalTime: 10000
        },
    },
    // config: { defaultSettings: { name: 'Tag State Alarm', intervalTime: 10000 } },
    layout: {
        minW: 6,
        w: 8,
        maxW: 11,
        minH: 18,
        h: 22,
        maxH: 26,
    },
};

export const geofenceStatus: Widget = {
    type: 'geofenceStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Geofence Status',
    // name: 'Geofence Status',
    desc: 'The map shows the geofence for the selected floor.',
    thumbnailClassName: 'w_map',
    Component: GeofenceStatus,
    SettingComponent: GeofenceStatusSetting,
    config: {
        defaultSettings: {
            name: 'Geofence Status',
            // intervalTime: 10000
        },
    },
    // config: { defaultSettings: { name: 'Geofence Status', intervalTime: 10000 } },
    layout: {
        minW: 4,
        w: 6,
        maxW: 9,
        minH: 21,
        h: 27,
        maxH: 35,
    },
};

// 가장 혼잡합 지오펜스 Top 5
export const geofenceCongestionStatus: Widget = {
    type: 'geofenceCongestionStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Top 5 Most Congested Geofence',
    desc: 'It shows the chart and the table describing the top 5 geofence with most number of entry event today.',
    thumbnailClassName: 'w_pie_table',
    Component: GeofenceCongestionStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Top 5 Most Congested Geofence', intervalTime: 20000 } },
    layout: {
        minW: 4,
        w: 5,
        maxW: 6,
        minH: 25,
        h: 30,
        maxH: 33,
    },
};

//자산 현황
export const iotItemStatus: Widget = {
    type: 'iotItemStatus',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Iot Item Status',
    desc: 'It shows the current status of the assets selecting all of them or some of them by their status.',
    thumbnailClassName: 'w_chart_table',
    Component: IotItemStatus,
    SettingComponent: IotItemStatusSetting,
    config: { defaultSettings: { name: 'Iot Item Status', intervalTime: 10000, categoryCode: 'ASSET' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const tagBatteryStatus: Widget = {
    type: 'tagBatteryStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Tag Battery Status',
    desc: 'It shows battery information of all tags with icons and tables.',
    thumbnailClassName: 'w_battery_chart',
    Component: TagBatteryStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Tag Battery Status', intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 23,
        h: 25,
        maxH: 29,
    },
};

export const scannerStatus: Widget = {
    type: 'scannerStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Scanner Status',
    desc: 'It shows the number of all the online or the offline registered scanners.',
    thumbnailClassName: 'w_pie_chart',
    Component: ScannerStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Scanner Status', intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 20,
        h: 23,
        maxH: 28,
    },
};
export const iotItemCountStatus: Widget = {
    type: 'iotItemCountStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Number Of All IoT Item',
    desc: 'It shows the number of IoT Items that the user has registered in graphs and tables.',
    thumbnailClassName: 'w_pie_chart',
    Component: IotItemCountStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Number Of All IoT Item', intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 20,
        h: 23,
        maxH: 26,
    },
};

// WMS상 카테고리별 자산 , 사용자 상세 정보
export const itemDetailStatus: Widget = {
    type: 'itemDetailStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'IoT Item Details By Category',
    desc: 'The chart and the table shows the current Top 5 by category of assets and people.',
    thumbnailClassName: 'w_pie_table',
    Component: ItemDetailStatus,
    SettingComponent: ItemDetailStatusSetting,
    config: { defaultSettings: { name: 'IoT Item Details By Category', intervalTime: 20000, categoryCode: 'ASSET' } },
    layout: {
        minW: 4,
        w: 5,
        maxW: 6,
        minH: 25,
        h: 30,
        maxH: 33,
    },
};
export const floorPermissionStatus: Widget = {
    type: 'floorPermissionStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Permitted/Restricted Floor Graph of Tag',
    // name: 'Tag Status',
    desc: 'The graph shows the permitted/restricted number of beacons for each floor.',
    thumbnailClassName: 'w_line',
    Component: FloorPermissionStatus,
    SettingComponent: null,
    config: {
        defaultSettings: {
            name: 'Permitted/Restricted Floor Graph of Tag', //, intervalTime: 10000
        },
    },
    // config: { defaultSettings: { name: 'Tag Status', intervalTime: 10000 } },
    layout: {
        minW: 7,
        w: 12,
        maxW: 16,
        minH: 14,
        h: 16,
        maxH: 18,
    },
};

export const triggeredAlertStatus: Widget = {
    type: 'triggeredAlertStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Triggered Alarm Status',
    desc: 'It shows the type and the number of notifications that occurred today in graphs and tables.',
    thumbnailClassName: 'w_line_chart',
    Component: TriggeredAlertStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Triggered Alarm Status', intervalTime: 20000 } },
    layout: {
        minW: 5,
        w: 7,
        maxW: 12,
        minH: 22,
        h: 23,
        maxH: 25,
    },
};

//실시간 위치현황
export const realTimeLocationStatus: Widget = {
    type: 'realTimeLocationStatus',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Realtime Location Status',
    desc:
        'The map shows the targets that are located in the selected floor or in the geofence. You can also search a specific target and get detailed information about that specific target.',
    thumbnailClassName: 'w_filter_map',
    Component: RealTimeLocationStatus,
    SettingComponent: RealTimeLocationSetting,
    config: { defaultSettings: { name: 'Realtime Location Status', targetOption: 'ALL TARGET', floor: '' } },
    layout: {
        minW: 6,
        w: 8,
        maxW: 12,
        minH: 23,
        h: 30,
        maxH: 45,
    },
};

//실시간 센서 모니터링
export const realTimeSensorMonitoring: Widget = {
    type: 'realTimeSensorMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Realtime Sensor Monitoring',
    desc: 'It monitors the registered sensors.',
    thumbnailClassName: 'w_monitor',
    Component: RealTimeSensorMonitoring,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Realtime Sensor Monitoring' } },
    layout: {
        minW: 6,
        w: 8,
        maxW: 12,
        minH: 23,
        h: 27,
        maxH: 39,
    },
};

// 실시간 태그 모니터링
export const realTimeTagMonitoring: Widget = {
    type: 'realTimeTagMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Realtime Tag Monitoring',
    desc: 'It monitors the registered tags in a line chart format.',
    thumbnailClassName: 'w_filter_line',
    Component: RealTimeTagMonitoring,
    SettingComponent: RealTimeTagMonitoringSetting,
    config: { defaultSettings: { name: 'Realtime Tag Monitoring', tagOption: 20, DataInteger: false } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 26,
        maxH: 38,
    },
};
//대량 실시간 위치현황
export const massRealTimeLocationStatus: Widget = {
    type: 'massRealTimeLocationStatus',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Mass RealTime Location Status',
    desc: 'It marks the map with a large number of positioning targets on the currently selected layer or geofence.',
    thumbnailClassName: 'w_filter_map',
    Component: MassRealTimeLocationStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Mass RealTime Location Status', targetOption: 'ALL TARGET', floor: '' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 26,
        maxH: 38,
    },
};
//분석 - 히트맵
export const heatMap = {
    type: 'heatMap',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Heat Map',
    desc: 'It monitors the registered tags in a line chart format.',
    thumbnailClassName: 'w_filter_table_line',
    Component: AnalysisHeatMap,
    //SettingComponent: RealTimeTagMonitoringSetting,
    config: { defaultSettings: { name: 'Heat Map', tagOption: 20, DataInteger: false } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 20,
        h: 40,
        maxH: 38,
    },
};

// 혼잡도 위젯
export const congestionStatus: Widget = {
    type: 'congestionStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Congestion Status',
    desc: 'It displays congestion of each geofence',
    thumbnailClassName: 'w_search_map_chart_table',
    Component: CongestionStatus,
    SettingComponent: CongestionStatusSetting,
    config: { defaultSettings: { name: 'Congestion Status' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 40,
        h: 40,
        maxH: 50,
    },
};

// 혼잡한 지오펜스 top 5 위젯
export const topFiveCongestedGeofence: Widget = {
    type: 'topFiveCongestedGeofence',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Top five congested geofence',
    desc: 'It shows the top five crowded geofence by category or authorization',
    thumbnailClassName: 'w_pie_table',
    Component: TopFiveCongestedGeofence,
    SettingComponent: TopFiveCongestedGeofenceSetting,
    config: { defaultSettings: { name: 'Top five congested geofence', type: 'category' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 20,
        maxH: 50,
    },
};

// 시간별 혼잡도
export const hourlyCongestionStatus: Widget = {
    type: 'hourlyCongestionStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Hourly congestion status',
    desc: 'This widget shows congestion status hourly',
    thumbnailClassName: 'w_search_map_line',
    Component: HourlyCongestionStatus,
    SettingComponent: HourlyCongestionStatusSetting,
    config: { defaultSettings: { name: 'Hourly congestion status' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 50,
        h: 55,
        maxH: 60,
    },
};

// 대시보드
export const dashboard: Widget = {
    type: 'dashboard',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Dashboard',
    desc: 'It shows the count of each status for IV, patient, asset, scale and refrigerator',
    thumbnailClassName: 'w_dashboard',
    Component: Dashboard,
    SettingComponent: DashboardSetting,
    config: { defaultSettings: { name: 'Dashboard', intervalTime: 10000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

// 환자 현황
// export const patientStatus = {
//     type: 'patientStatus',
//     category: 'Dashboard',
//     name: 'Patient Status',
//     des: '',
//     thumbnailURL: null,
//     Component: PatientStatus,
//     SettingComponent: PatientStatusSetting,
//     config: { defaultSettings: { name: 'Patient Status', intervalTime: 10000, categoryCode: 'people' } },
//     layout: {
//         minW: 8,
//         w: 12,
//         maxW: 12,
//         minH: 26,
//         h: 46,
//         maxH: 54,
//     },
// };

// 수액 모니터링
export const ivInjectionMonitoring: Widget = {
    type: 'ivInjectionMonitoring',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'IV Injection Monitoring',
    desc: 'This widget monitors the current IV injection',
    thumbnailClassName: 'w_cardmonitor',
    Component: IVInjectionMonitoring,
    SettingComponent: IVInjectionMonitoringSetting,
    config: { defaultSettings: { name: 'IV Injection Monitoring', intervalTime: 30000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};
export const refrigeratorSensor = {
    type: 'refrigeratorSensor',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Refrigerator Sensor',
    desc: "This widget shows the current refrigerator sensors' status",
    thumbnailClassName: 'w_monitor',
    Component: RefrigeratorSensor,
    //SettingComponent: IVInjectionMonitoringSetting,
    config: { defaultSettings: { name: 'Refrigerator Sensor', intervalTime: 30000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const vitalSignsMonitoring = {
    type: 'vitalSignsMonitoring',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Vital Signs Monitoring',
    desc: 'Monitors vital signs',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: VitalSignsMonitoring,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Vital Signs Monitoring', intervalTime: 30000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 40,
        maxH: 64,
    },
};

// 센서 현황
export const sensorStatus: Widget = {
    type: 'sensorStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Sensor Status',
    desc: 'This widget shows the status of the sensors',
    thumbnailClassName: 'w_chart_search_table',
    Component: SensorStatus,
    SettingComponent: SensorStatusSetting,
    config: { defaultSettings: { name: 'Sensor Status', intervalTime: 10000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

/**
 *  IOT 아이템 정보 로그
 *
 * @author jinbeom-jung
 * */
export const iotItemInfoLog: Widget = {
    type: 'iotItemInfoLog',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Iot Item Information Log',
    desc: 'Indicates IoT item information',
    thumbnailClassName: 'w_search_table',
    Component: IotItemInfoLog,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Iot Item Information Log' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

/**
 * IOT 아이템 맵핑 로그
 *
 * @author jinbeom-jung
 * */
export const iotItemMappingLog: Widget = {
    type: 'iotItemMappingLog',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Iot Item Mapping Log',
    desc: 'Indicates IoT item mapping/unmapping information',
    thumbnailClassName: 'w_search_table',
    Component: IotItemMappingLog,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Iot Item Mapping Log' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

/**
 * 위치 로그 위젯
 *
 * @author jinbeom-jung
 * */
export const locationLog: Widget = {
    type: 'locationLog',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Location Log',
    desc: 'Indicates the location information of the target',
    thumbnailClassName: 'w_search_table',
    Component: LocationLog,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Location log' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

/**
 * 센서 로그
 *
 * @author jinbeom-jung
 * */
export const sensorLog: Widget = {
    type: 'sensorLog',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Sensor Log',
    desc: 'Indicates the sensor information of the target',
    thumbnailClassName: 'w_search_table',
    Component: SensorLog,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Sensor Log' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

/**
 * 위치로그 히스토리
 *
 * @author jinbeom-jung
 * */
export const locationLogStorageHistory: Widget = {
    type: 'locationLogStorageHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Location Log Storage History',
    desc: 'Indicates the geolocation file of the target',
    thumbnailClassName: 'w_search_table',
    Component: LocationLogStorageHistory,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Location Log Storage History' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

/**
 * 로그인 이력
 *
 * @author jinbeom-jung
 * */
export const loginHistoryLog: Widget = {
    type: 'loginHistoryLog',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Login History Log',
    desc: 'Indicates login history information',
    thumbnailClassName: 'w_search_table',
    Component: LoginHistoryLog,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Login History Log' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

/**
 * 스캐너 상태 이력
 *
 * @author jinbeom-jung
 * */
export const scannerStatusLog: Widget = {
    type: 'scannerStatusLog',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Scanner Status Log',
    desc: 'Indicates the status information of the scanner',
    thumbnailClassName: 'w_search_table',
    Component: ScannerStatusLog,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Scanner Status Log' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

/**
 * 지오펜스 이력
 *
 * @author jinbeom-jung
 * */
export const geofenceLog: Widget = {
    type: 'geofenceLog',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Geofence Log',
    desc: 'Indicates geofence information history',
    thumbnailClassName: 'w_search_table',
    Component: GeofenceLog,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Geofence Log' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

export const temp = {
    type: 'temp',
    name: 'Widget Name',
    desc: '',
    thumbnailClassName: null,
    Component: TemporaryWidget,
    SettingComponent: null,
    config: { defaultSettings: { name: 'New widget' } },
    layout: {
        w: 4,
        h: 15,
        minW: 2,
        minH: 9,
    },
};

const widgetList = {
    iotItemStatus,
    tagStatus,
    categoryChart,
    tagStateAlarmList,
    geofenceStatus,
    geofenceCongestionStatus,
    tagBatteryStatus,
    scannerStatus,
    iotItemCountStatus,
    itemDetailStatus,
    floorPermissionStatus,
    triggeredAlertStatus,
    realTimeLocationStatus,
    realTimeSensorMonitoring,
    congestionStatus,
    topFiveCongestedGeofence,
    hourlyCongestionStatus,
    sensorStatus,
    heatMap,
    iotItemInfoLog,
    iotItemMappingLog,
    locationLog,
    sensorLog,
    locationLogStorageHistory,
    loginHistoryLog,
    scannerStatusLog,
    geofenceLog,
    realTimeTagMonitoring,
    dashboard,
    ivInjectionMonitoring,
    refrigeratorSensor,
    vitalSignsMonitoring,
    massRealTimeLocationStatus,
    temp,
};

export default widgetList;

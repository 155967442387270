import React, { useCallback, useMemo, useState } from 'react';
import NoDataBlock from '../Components/NoDataBlock';
import { useAppSelector } from '@hooks';
// @ts-ignore
import ReactResizeDetector from 'react-resize-detector';
import Charts from '@components/Charts/Highcharts';

const CHART_OPTION = {
    chart: {
        type: 'pie',
        backgroundColor: null,
        plotShadow: false,
        margin: 2,
        animation: { duration: 500 },
    },
    title: null,
    plotOptions: {
        pie: {
            allowPointSelect: false,
            borderWidth: 0,
        },
        series: {
            dataLabels: {
                enabled: false,
            },
            showInLegend: true,
            // states: {
            //     hover: {
            //         enabled: false,
            //     },
            //     inactive: {
            //         opacity: 1,
            //     },
            // },
        },
    },
    legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemMarginTop: 3,
        itemMarginBottom: 3,
        symbolRadius: 0,
        itemHiddenStyle: {
            color: '#77777C',
        },
        itemHoverStyle: {
            color: null,
        },
        itemStyle: {
            color: 'black',
        },
    },
    series: [
        {
            name: '',
            data: [
                {
                    name: '',
                    y: 0,
                },
            ],
        },
    ],
    credits: {
        enabled: false,
    },
};

interface ChartData {
    ranking: number;
    key: string;
    name: string;
    value: number;
}
interface Props {
    data: ChartData[];
    settings: { chartType?: string };
}
const CategoryPieChart = ({ data, settings }: Props) => {
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const [containerProps, setContainerProps] = useState({
        style: {
            width: 0,
            height: 0,
        },
    });

    const onResize = useCallback((width: number, height: number) => {
        setContainerProps({
            style: {
                width,
                height,
            },
        });
    }, []);

    const chartOptions = useMemo(() => {
        return {
            ...CHART_OPTION,
            legend: {
                ...CHART_OPTION.legend,
                itemStyle: {
                    color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                },
            },
            series: [
                {
                    name: '',
                    data: data.map(e => {
                        return {
                            name: e.name,
                            y: e.value,
                        };
                    }),
                    innerSize: settings.chartType === 'pie' ? '0%' : '75%',
                },
            ],
        };
    }, [settings, colorScheme, data]);

    return (
        <>
            {!!data && !!data.length ? (
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={100}
                    handleHeight
                    handleWidth
                    onResize={onResize}
                >
                    <Charts containerProps={containerProps} options={chartOptions} />
                </ReactResizeDetector>
            ) : (
                <NoDataBlock />
            )}
        </>
    );
};

export default React.memo(CategoryPieChart);

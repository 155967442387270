import React from 'react';
import styled, { css } from 'styled-components';

// 절대값 계산
const calcRange = (minValue, targetValue) => {
    if (minValue === null || targetValue === null) {
        return null;
    }
    if (minValue < 0 && targetValue < 0) {
        return Math.abs(minValue - targetValue);
    } else if (minValue < 0 && targetValue >= 0) {
        return Math.abs(minValue) + targetValue;
    } else {
        return targetValue - minValue;
    }
};
// 백분율 계산
const calcPercentage = (targetValue, maxValue) =>
    targetValue !== null ? ((targetValue / maxValue) * 100).toFixed(2) : null;
// 그래프 최대 범위
const MAX_LIMIT = 100;

const RangeBar = ({
    currentTemperature,
    range: {
        minRangeLimit, // 최소값
        minRiskLimit, // 주의 시작 범위값
        minControlLimit, // 정상 시작 범위값
        maxControlLimit, // 주의 시작 범위값
        maxRiskLimit, // 위험 시작 범위값
        maxRangeLimit, // 최대값
    },
}) => {
    // min/max 절대값 계산 (0 ~ max)
    const rangeStart = 0; // 그래프 범위 시작값
    const minRiskStart = calcRange(minRangeLimit, minRiskLimit); // 주의 시작값
    const minControlStart = calcRange(minRangeLimit, minControlLimit); // 정상 시작값
    const maxControlStart = calcRange(minRangeLimit, maxControlLimit); // 주의 시작값
    const maxRiskStart = calcRange(minRangeLimit, maxRiskLimit); // 경고 시작값
    const rangeEnd = calcRange(minRangeLimit, maxRangeLimit); // 그래프 범위 끝값

    // 현재 온도 화살표 위치값
    const currentValue = calcRange(minRangeLimit, currentTemperature)?.toFixed(2);
    const currentValuePosition = (value => {
        // 값 없는 경우
        if (value === null) {
            return null;
            // 최저값 범위 이탈
        } else if (value < rangeStart) {
            return 0;
            // 최고값 범위 이탈
        } else if (value > rangeEnd) {
            return 100;
        } else {
            return calcPercentage(value, rangeEnd);
        }
    })(currentValue);

    // 그래프 범위 위치값 계산
    const safetyRangeWidth = calcPercentage(maxControlStart - minControlStart, rangeEnd); // 정상 범위 바 크기
    const minRiskStartPosition = calcPercentage(minRiskStart, rangeEnd); // 주의 시작 tick 위치
    const minControlStartPosition = calcPercentage(minControlStart, rangeEnd); // 정상 시작 tick 위치
    const maxControlStartPosition = calcPercentage(maxControlStart, rangeEnd); // 경고 시작 tick 위치
    const maxRiskStartPosition = calcPercentage(maxRiskStart, rangeEnd); // 위험 시작 tick 위치

    return (
        <Container>
            <RangeBarContainer className="bg-depth-6 border-lightgray">
                <RangeBarPointer className="pointer" $current={currentValuePosition} />
                <SafetyRangeBar
                    className="bg-success"
                    $rangePosition={minControlStartPosition}
                    $width={safetyRangeWidth}
                />
            </RangeBarContainer>
            <TickContainer>
                <TickValueContainer $rangePosition={rangeStart}>
                    <TickLine className="bg-lightgray" />
                    <TickValue className="color-gray">{minRangeLimit}</TickValue>
                </TickValueContainer>
                <TickValueContainer $rangePosition={minRiskStartPosition}>
                    <TickLine className="bg-lightgray" />
                    <TickValue className="color-gray">{Math.round(minRiskLimit)}</TickValue>
                </TickValueContainer>
                <TickValueContainer $rangePosition={minControlStartPosition}>
                    <TickLine className="bg-lightgray" />
                    <TickValue className="color-gray">{Math.round(minControlLimit)}</TickValue>
                </TickValueContainer>
                <TickValueContainer $rangePosition={maxControlStartPosition}>
                    <TickLine className="bg-lightgray" />
                    <TickValue className="color-gray">{Math.round(maxControlLimit)}</TickValue>
                </TickValueContainer>
                <TickValueContainer $rangePosition={maxRiskStartPosition}>
                    <TickLine className="bg-lightgray" />
                    <TickValue className="color-gray">{Math.round(maxRiskLimit)}</TickValue>
                </TickValueContainer>
                <TickValueContainer $rangePosition={MAX_LIMIT}>
                    <TickLine className="bg-lightgray" />
                    <TickValue className="color-gray">{maxRangeLimit}</TickValue>
                </TickValueContainer>
            </TickContainer>
        </Container>
    );
};

const Container = styled.div`
    width: 99%;
    height: 16px;
    margin-left: auto;
`;

const RangeBarContainer = styled.div`
    width: 100%;
    height: 6px;
    position: relative;
    margin-top: 2px;
`;

const RangeBarPointer = styled.div`
    display: ${({ $current }) => ($current !== null && $current <= MAX_LIMIT ? 'unset' : 'none')};
    position: absolute;
    top: -8px;
    ${({ $current }) =>
        $current !== null &&
        $current <= MAX_LIMIT &&
        css`
            left: ${$current}%;
        `};
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 8px 4px 0 4px;
    border-color: #000000 transparent transparent transparent;
`;

const SafetyRangeBar = styled.div`
    width: ${({ $width }) => $width}%;
    height: 100%;
    position: absolute;
    top: 0;
    left: ${({ $rangePosition }) => $rangePosition}%;
`;

const TickContainer = styled.div`
    width: 100%;
    height: 14px;
    position: relative;
`;
const TickValueContainer = styled.div`
    position: absolute;
    left: ${({ $rangePosition }) => $rangePosition}%;
    margin-top: 4px;
    // tick value transform
    & > span {
        transform: translateX(
            ${({ $rangePosition }) => {
                // 최소 range
                if ($rangePosition < 10) {
                    return 0;
                }
                // 최대 range
                if (90 < $rangePosition) {
                    return -100;
                }
                return -50;
            }}%
        );
    }

    // 첫번째 tick
    &:first-of-type {
        // tick line, tick value
        & > div,
        span {
            left: 0;
            transform: translateX(0);
        }
    }
    // 마지막 tick
    &:last-of-type {
        // tick line
        & > div {
            left: -1px;
            transform: translateX(0);
        }
        // tick value
        & > span {
            transform: translateX(-100%);
        }
    }
`;

const TickLine = styled.div`
    height: 4px;
    width: 1px;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
`;
const TickValue = styled.span`
    position: absolute;
    top: 0;
    font-size: 10px;
    line-height: 1;
    left: 50%;
    transform: translateX(-50%);
`;

export default RangeBar;

import { reqDelete, reqGet, reqPatch, reqPost } from './index';

// 메뉴
export const fetchMenuList = param => reqGet('/v3/api/common/menu', { ...param });
export const fetchMenu = param => reqGet(`/v3/api/common/menu/${param.menuCode}`, { ...param });
export const createMenuApi = param => reqPost('/v3/api/common/menu', param);
export const updateMenuApi = param => reqPatch(`/v3/api/common/menu/${param.menuCode}`, param);
export const deleteMenuApi = param => reqDelete(`/v3/api/common/menu/${param.menuCode}`);

// 화면
export const fetchScreenList = param => reqGet('/v3/api/common/screen', { ...param });
export const fetchScreen = param => reqGet(`/v3/api/common/screen/${param.screenCode}`, { ...param });
export const createScreenApi = param => reqPost('/v3/api/common/screen', param);
export const updateScreenApi = param => reqPatch(`/v3/api/common/screen/${param.screenCode}`, param);
export const deleteScreenApi = param => reqDelete(`/v3/api/common/screen/${param.screenCode}`);

// 권한 그룹
export const fetchAuthGroupList = param => reqGet('/v3/api/common/group/auth', { ...param });
export const fetchAuthGroup = param => reqGet(`/v3/api/common/group/auth/${param.groupNum}`);
export const createAuthGroupApi = param => reqPost('/v3/api/common/group/auth', param);
export const updateAuthGroupApi = param => reqPatch(`/v3/api/common/group/auth/${param.groupNum}`, param);
export const deleteAuthGroupApi = param => reqDelete(`/v3/api/common/group/auth/${param.groupNum}`);

export const fetchGroupUserList = param => reqGet('/v3/api/common/group/auth/user', { ...param });

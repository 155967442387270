import React, { useContext, useState } from 'react';
import { editWidgetSettings } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { useSettings } from '../util/useSettings';
import useTranslation from '../../../util/hooks/useTranslation';
import Label from '../../Common/Label';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { Select } from '@components';

const IotItemStatusSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useContext(ScreenEditDispatchContext);
    const settings = useSettings(config);

    const t = useTranslation('IotItemStatus');

    const categoryOptions = [
        { value: 'ASSET', label: t('asset') },
        { value: 'PATIENT', label: t('patient') },
        { value: 'PROTECTOR', label: t('protector') },
    ];

    const handleSubmitClick = data => {
        let settingInfo = { ...data, ...updatedWidgetInfo };
        if (customSettings) {
            settingInfo = { ...customSettings, ...settingInfo };
        }
        dispatch(editWidgetSettings({ id: widgetInfo.id, settings: settingInfo }));
    };

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});
    const handleCategoryChange = selectedCategory => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, categoryCode: selectedCategory.value };
        });
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const CategoryValue = categoryOptions.find(e =>
        updatedWidgetInfo.categoryCode ? e.value === updatedWidgetInfo.categoryCode : e.value === settings.categoryCode,
    );
    return (
        <WidgetSettingModal
            headerTitle={t('Iot Item Status Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Type of Category', 'CategoryChart')}
                labelGroupClassName={'w-100'}
                value={
                    <Select
                        options={categoryOptions}
                        value={CategoryValue}
                        onChange={handleCategoryChange}
                        isModalSelect
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default IotItemStatusSetting;

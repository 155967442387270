import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from '@hooks';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import Table from './CustomTable';
import Draggable from 'react-draggable';
import { HeatMapDispatchContext, HeatMapStateContext } from '../index';
import useColumns from '../../../../util/hooks/useColumns';
import * as column from '../../util/columns';
import { setTargetNum } from '../heatMapReducer';

const DetectedTargetPopup = ({ modal, toggleModal, widgetRef, mapRef }) => {
    const t = useTranslation('HeatMap');
    const state = useContext(HeatMapStateContext);
    const dispatch = useContext(HeatMapDispatchContext);
    const { detectedTargets, targetNum } = state;

    const [popupSize, setPopupSize] = useState({ x: 0, y: 0 });
    const columns = useColumns([column.categoryName(), column.targetName()]);

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();
            setPopupSize({ width, height });
        }
    }, []);

    const [position, setPosition] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;
        setPosition({
            x: x,
            y: y,
        });
    }, [popupSize]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (widgetRef.current) {
            const widget = widgetRef.current;
            const { offsetWidth: width, offsetHeight: height } = widget;
            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    const onTrClick = selected => {
        const { targetNum: selectedTargetNum } = selected;
        if (targetNum.length) {
            if (targetNum.includes(selectedTargetNum)) {
                const arrTargetNum = targetNum.reduce((acc, curr) => {
                    if (curr !== selectedTargetNum) {
                        acc.push(curr);
                    }
                    return acc;
                }, []);
                dispatch(setTargetNum(arrTargetNum));
            } else {
                dispatch(setTargetNum([...targetNum, selectedTargetNum]));
            }
        } else {
            dispatch(setTargetNum([selectedTargetNum]));
        }
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds=".leaflet-container"
            defaultPosition={position}
            onStop={() => mapRef.current.leafletElement.dragging.enable()}
            onStart={() => mapRef.current.leafletElement.dragging.disable()}
        >
            <Toast isOpen={modal} className={'popup-open floorDetail'} innerRef={popupRef}>
                <ToastHeader toggle={toggleModal}>{t('Detected Target')}</ToastHeader>
                <ToastBody>
                    <Table
                        columns={columns}
                        textAlign={'left'}
                        onTrClick={onTrClick}
                        data={{
                            rows: detectedTargets,
                            pageSize: 5,
                            totalPage:
                                detectedTargets.length % 5 === 0
                                    ? detectedTargets.rows / 5
                                    : detectedTargets.rows / 5 + 1,
                            totalCount: detectedTargets.length,
                        }}
                        manualPagination={false}
                        autoResetPage={false}
                        rowSelect={{
                            selected: targetNum,
                            rowKey: 'targetNum',
                            selectedRowClassName: 'selected-color',
                        }}
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default DetectedTargetPopup;

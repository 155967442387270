import React, { createContext, useEffect, useReducer, useState } from 'react';
import Card from '../Components/WidgetCard';
import { useAsync } from '@hooks';
import { fetchSensorLogList } from '@api/log';

import Search from './Component/Search';

import List from './Component/List';
import ExcelExport from './Component/ExcelExport';
import moment from 'moment';
import reducer, { initState } from './sensorLogReducer';
import { useSelector } from 'react-redux';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';

/**
 * 센서 로그 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const SensorLogStateContext = createContext();
export const SensorLogDispatchContext = createContext();
const SensorLog = ({ children, widgetInfo, ...restProps }) => {
    const { mode } = useSelector(state => state.ScreenInfo);
    const [state, dispatch] = useReducer(reducer, initState);
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').valueOf(),
    );

    const handleDateCallback = ({ selected, type }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                setEndDate(moment(selected).startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().startOf('day').subtract(1, 'day');
                setStartDate(yesterday.valueOf());
                setEndDate(yesterday.add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
        }
    };

    const [list, setList] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const { promise: getSensorLogList, state: sensorLogListInfo } = useAsync({
        promise: fetchSensorLogList,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    comNum: log.comNum,
                    logNum: log.logNum,
                    targetId: log.targetId,
                    targetName: log.targetName,
                    deviceId: log.deviceId,
                    deviceName: log.deviceName,
                    deviceType: log.deviceType,
                    floorId: log.floorId,
                    regDate: log.regDate,
                    ...JSON.parse(log.data),
                    ...log,
                };
            });
            setList({ ...res, rows: data });
        },
    });

    useEffect(() => {
        getSensorLogList({ startDate: moment(startDate).unix(), endDate: moment(endDate).unix() });
    }, []);
    return (
        <SensorLogDispatchContext.Provider value={dispatch}>
            <SensorLogStateContext.Provider value={state}>
                <Card
                    widgetInfo={widgetInfo}
                    headerAction={
                        mode !== SCREEN_MODE_EDIT &&
                        mode !== SCREEN_MODE_PREVIEW && (
                            <ExcelExport listCount={list.rows.length} startDate={startDate} endDate={endDate} />
                        )
                    }
                    {...restProps}
                >
                    <Search
                        startDate={startDate}
                        endDate={endDate}
                        handleDateCallback={handleDateCallback}
                        promise={getSensorLogList}
                    />
                    <List list={list} state={sensorLogListInfo} promise={getSensorLogList} />
                    {children}
                </Card>
            </SensorLogStateContext.Provider>
        </SensorLogDispatchContext.Provider>
    );
};

export default SensorLog;

import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import cx from 'classnames';

import AlarmDots from './Components/AlarmDots';

import HeaderLogo from '../AppLogo';
import { CustomNav, SettingsNav } from '../AppNav/NavItems';

import useTranslation from '@util/hooks/useTranslation';
import {
    A_PATH_AUTHORIZATION_GROUP_CREATE,
    A_PATH_AUTHORIZATION_GROUP_DETAIL,
    A_PATH_CUSTOM_SCREEN,
    A_PATH_FILTER_CREATE,
    A_PATH_FILTER_DETAIL,
    A_PATH_MENU_CREATE,
    A_PATH_MENU_DETAIL,
    A_PATH_SCREEN_EDIT,
} from '../../MainPages/Components/Router/path';
import UserBox from './Components/UserBox/UserBox';
import FullscreenDot from './Components/FullscreenDot';

const initialGnbMenu = { menuName: '', subMenuName: '' };

const Header = () => {
    const t = useTranslation('Menu');
    const location = useLocation();
    const match = useRouteMatch([A_PATH_CUSTOM_SCREEN, A_PATH_SCREEN_EDIT]);
    const filterMatch = useRouteMatch([A_PATH_FILTER_DETAIL, A_PATH_FILTER_CREATE]);
    const authGroupMatch = useRouteMatch([A_PATH_AUTHORIZATION_GROUP_DETAIL, A_PATH_AUTHORIZATION_GROUP_CREATE]);
    const menuManagementMatch = useRouteMatch([A_PATH_MENU_DETAIL, A_PATH_MENU_CREATE]);
    const [gnbMenu, setGnbMenu] = useState(initialGnbMenu);
    const { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = useSelector(
        state => state.ThemeOptions,
    );
    const { originScreenList } = useSelector(state => state.CustomMenu);

    useEffect(() => {
        let pathname = location.pathname;

        if (filterMatch) {
            setGnbMenu({
                menuName: 'Filters',
                subMenuName: 'Filter Detail',
            });
        } else if (authGroupMatch) {
            setGnbMenu({
                menuName: 'Authorization Group Management',
                subMenuName: 'Authorization Group Detail',
            });
        } else if (menuManagementMatch) {
            setGnbMenu({
                menuName: 'Menu Management',
                subMenuName: 'Menu Detail',
            });
        } else {
            let foundGnbMenu = null;
            if (match && match.params.screenCode) {
                const { screenCode } = match.params;
                foundGnbMenu = originScreenList.find(screen => screen.screenCode === screenCode);
                if (foundGnbMenu) {
                    setGnbMenu({
                        menuName: foundGnbMenu.menuName,
                        subMenuName: foundGnbMenu.screenName,
                        isCustom: true,
                    });
                }
            } else {
                // setting 으로 이동한 dashboard gnb menu label
                SettingsNav.forEach(menu => {
                    if (menu.content) {
                        const subMenu = menu.content;
                        const foundMenu = subMenu.find(menu => menu.to === `#${pathname}`);
                        if (foundMenu) {
                            foundGnbMenu = foundMenu;
                            setGnbMenu({
                                menuName: menu.label,
                                subMenuName: foundMenu.label,
                                isCustom: foundMenu.id && foundMenu.id.includes('customDashboard_'),
                            });
                            return false;
                        }
                    }
                });
                // 기존 gnb menu label
                CustomNav.forEach(menu => {
                    if (menu.content) {
                        const subMenu = menu.content;
                        const foundMenu = subMenu.find(menu => menu.to === `#${pathname}`);
                        if (foundMenu) {
                            foundGnbMenu = foundMenu;
                            setGnbMenu({
                                menuName: menu.label,
                                subMenuName: foundMenu.label,
                                isCustom: foundMenu.id && foundMenu.id.includes('filterMenu_'),
                            });
                            return false;
                        }
                    }
                });
            }
            if (!foundGnbMenu) {
                setGnbMenu(initialGnbMenu);
            }
        }
    }, [location, CustomNav, originScreenList]);

    return (
        <ReactCSSTransitionGroup
            component="div"
            className={cx('app-header', headerBackgroundColor)}
            transitionName="HeaderAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}
        >
            <HeaderLogo />

            <div className={cx('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })}>
                <div className="app-header-left">
                    <span className={'menu-sub'}>
                        {gnbMenu.menuName ? (gnbMenu.isCustom ? gnbMenu.menuName : t(gnbMenu.menuName)) : ''}
                    </span>
                    {gnbMenu.menuName ? <span className="material-icons-round md-18 mr-1">navigate_next</span> : ''}
                    <span className={'menu-main'}>
                        {gnbMenu.subMenuName && (gnbMenu.isCustom ? gnbMenu.subMenuName : t(gnbMenu.subMenuName))}
                    </span>
                </div>
                <div className="app-header-right">
                    <AlarmDots />
                    <FullscreenDot />
                    <UserBox />
                    {/*<HeaderRightDrawer />*/}
                </div>
            </div>
        </ReactCSSTransitionGroup>
    );
};

export default Header;

import { createSlice } from '@reduxjs/toolkit';

export const initState = {
    keyword: '',
    searchOption: '',
    fcNums: [],
    geofenceList: [],
    floorIds: { floorId: '', floorName: '' },
    floorIdPath: null,
};

const { actions, reducer } = createSlice({
    name: 'geofenceLog',
    initState,
    reducers: {
        setKeyword: (state, action) => {
            state.keyword = action.payload;
        },
        setSearchOption: (state, action) => {
            state.searchOption = action.payload;
        },
        setGeofence: (state, action) => {
            state.fcNums = action.payload;
        },
        setGeofenceList: (state, action) => {
            state.geofenceList = action.payload;
        },
        setFloorId: (state, action) => {
            state.floorIds = action.payload;
        },
        setFloorIdPath: (state, action) => {
            state.floorIdPath = action.payload;
        },
        reset: (state, action) => {
            const initialState = { ...initState, ...action.payload };
            for (let key in initialState) {
                state[key] = initialState[key];
            }
        },
    },
});

export const { setKeyword, setSearchOption, setGeofence, setGeofenceList, setFloorId, setFloorIdPath, reset } = actions;
export default reducer;

import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useAsync } from '@hooks';
import { fetchGeofenceLogList } from '@api/log';
import Card from '../Components/WidgetCard';
import List from './Component/List';
import Search from './Component/Search';
import ExcelExport from './Component/ExcelExport';
import moment from 'moment';
import reducer, { initState, setGeofence, setGeofenceList } from './geofenceLogReducer';
import { fetchGeofenceList } from '@api/common';
import { useSelector } from 'react-redux';
import useTranslation from '@hooks/useTranslation';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';

/**
 * 지오펜스 로그 위젯
 *
 * 추후 useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const DEFAULT_SELECT_VALUE = 0;
export const GeofenceLogStateContext = createContext();
export const GeofenceLogDispatchContext = createContext();
const GeofenceLog = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('Geofence Log');
    const [state, dispatch] = useReducer(reducer, initState);
    const { mode } = useSelector(state => state.ScreenInfo);
    const { selectableFloorList } = useSelector(state => state.FloorInfo);
    const [geofenceLogData, setGeofenceLogData] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf(),
    );
    const handleDateCallback = ({ selected, type }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().startOf('day').subtract(1, 'day');
                setStartDate(yesterday.valueOf());
                setEndDate(yesterday.add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf());
                break;
        }
    };

    useAsync({
        promise: fetchGeofenceList,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            dispatch(setGeofenceList(rows.map(({ fcName, fcNum }) => ({ label: fcName, value: fcNum }))));
            dispatch(setGeofence(rows.map(({ fcNum }) => fcNum)));
        },
        reject: err => {
            dispatch(setGeofenceList([{ label: t('Not Select'), value: DEFAULT_SELECT_VALUE }]));
            dispatch(setGeofence([DEFAULT_SELECT_VALUE]));
            console.error(err);
        },
    });

    const { promise: getGeofenceLogList, state: geofenceLogListInfo } = useAsync({
        promise: fetchGeofenceLogList,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const { rows } = res;
            const data = rows.map(log => {
                return {
                    comNum: log.comNum,
                    categoryCode: log.categoryCode,
                    targetName: log.targetName,
                    targetId: log.targetId,
                    floorIdPath: log.floorIdPath,
                    fcName: log.fcName,
                    permitted: log.permitted,
                    inDate: log.inDate,
                    inScannerName: log.inScannerName,
                    outDate: log.outDate,
                    outScannerName: log.outScannerName,
                    logNum: log.logNum,
                    ...log,
                };
            });
            setGeofenceLogData({ ...res, rows: data });
        },
    });

    useEffect(() => {
        getGeofenceLogList({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            floorIds: selectableFloorList[0].floorId,
            floorIdPath: selectableFloorList[0].floorIdPath,
        });
    }, []);

    return (
        <GeofenceLogDispatchContext.Provider value={dispatch}>
            <GeofenceLogStateContext.Provider value={state}>
                <Card
                    widgetInfo={widgetInfo}
                    headerAction={
                        mode !== SCREEN_MODE_EDIT &&
                        mode !== SCREEN_MODE_PREVIEW && (
                            <ExcelExport
                                startDate={startDate}
                                endDate={endDate}
                                listCount={geofenceLogData.rows.length}
                            />
                        )
                    }
                    {...restProps}
                >
                    <Search
                        startDate={startDate}
                        endDate={endDate}
                        handleDateCallback={handleDateCallback}
                        promise={getGeofenceLogList}
                    />
                    <List list={geofenceLogData} state={geofenceLogListInfo} promise={getGeofenceLogList} />
                    {children}
                </Card>
            </GeofenceLogStateContext.Provider>
        </GeofenceLogDispatchContext.Provider>
    );
};

export default GeofenceLog;

import React, { useContext, useState, useMemo } from 'react';
import { useSettings } from '../util/useSettings';
import { editWidgetSettings } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { DataInteger } from '../Components/WidgetSettingOptions';
import useTranslation from '../../../util/hooks/useTranslation';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import Label from '../../Common/Label';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { Select } from '@components';

const RealTimeTagMonitoringSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const dispatch = useContext(ScreenEditDispatchContext);

    const t = useTranslation('RealTimeTagMonitoring');
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const options = useMemo(() => {
        const options = [];
        // maxTime은 최대 50초 까지만 지정할것(데이터가 1분만 저장함)
        const maxTime = 30;
        for (let time = 10; time <= maxTime; time += 5) {
            options.push({ value: time, label: `${time} ${t('seconds')}` });
        }
        return options;
    }, [t]);

    const handleDataIntegerChange = checkedDataInteger => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, DataInteger: checkedDataInteger };
        });
    };

    const handleTagOptionChange = selectedTagOption => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, tagOption: selectedTagOption.value };
        });
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Real Time Tag Monitoring Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Tag Monitoring Duration')}
                value={
                    <Select
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                        options={options}
                        value={options.find(
                            option =>
                                option.value ===
                                (updatedWidgetInfo.tagOption ? updatedWidgetInfo.tagOption : settings.tagOption),
                        )}
                        onChange={handleTagOptionChange}
                        isModalSelect
                    />
                }
            />

            <DataInteger
                checked={
                    typeof updatedWidgetInfo.DataInteger !== 'undefined'
                        ? updatedWidgetInfo.DataInteger
                        : customSettings && typeof customSettings.DataInteger !== 'undefined'
                        ? customSettings.DataInteger
                        : false
                }
                onChange={handleDataIntegerChange}
            />
        </WidgetSettingModal>
    );
};

export default RealTimeTagMonitoringSetting;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import { CHART_COLORS } from '@components/Charts/config';
import { useAppSelector } from '@hooks';
import HighchartsReact from 'highcharts-react-official';
import HighCharts from 'highcharts';
import NoDataBlock from '../../Components/NoDataBlock';
import ReactResizeDetector from 'react-resize-detector';

const HIGHCHARTS_LIBRARY = { accessibility: { enabled: false } };

const CHART_OPTION = {
    chart: {
        type: 'column',
        marginBottom: 70,
        backgroundColor: 'transparent',
    },
    xAxis: {
        categories: [],
        labels: {
            style: {
                fontSize: '1rem',
            },
        },
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
        },
    },
    title: null,
    colors: CHART_COLORS,
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: false,
            },
        },
        series: {
            showInLegend: true,
        },
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        symbolRadius: 0,
        itemHiddenStyle: {
            color: '#77777C',
        },
        itemHoverStyle: {
            color: null,
        },
        itemStyle: {
            color: 'black',
        },
    },
    series: [
        {
            name: '',
            data: [
                {
                    name: '',
                    y: 0,
                },
            ],
        },
    ],
    credits: {
        enabled: false,
    },
};

const PermissionBarChart = ({ congestedGeofenceInfo }) => {
    // console.log(congestedGeofenceInfo);
    const t = useTranslation('TopFiveCongestedGeofence');
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const chartRef = useRef(null);
    const [chartOptions, setChartOptions] = useState(CHART_OPTION);
    const [containerProps, setContainerProps] = useState({
        style: {
            width: 0,
            height: 0,
        },
    });

    const onResize = useCallback((width, height) => {
        setContainerProps({
            style: {
                width,
                height,
            },
        });
        const chart = chartRef.current?.chart;

        if (chart) chart.reflow();
    }, []);

    useEffect(() => {
        const dataArr = ['permitted', 'restricted'];
        const chartArr = dataArr.map(e => {
            return {
                name: t(e),
                value: congestedGeofenceInfo.map(v => v[e]),
            };
        });

        setChartOptions(prevState => ({
            ...prevState,
            xAxis: {
                ...prevState.xAxis,
                categories: congestedGeofenceInfo.map(e => e.fcName),
            },
            series: chartArr.map(e => {
                return {
                    name: e.name,
                    data: e.value,
                };
            }),
        }));
    }, [congestedGeofenceInfo]);

    useEffect(() => {
        setChartOptions(prevState => ({
            ...prevState,
            legend: {
                ...prevState.legend,
                itemStyle: {
                    color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                },
            },
            xAxis: {
                ...prevState.xAxis,
                labels: {
                    style: {
                        ...prevState.xAxis.labels.style,
                        color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                    },
                },
            },
        }));
    }, [colorScheme]);

    return (
        <>
            {!!congestedGeofenceInfo && congestedGeofenceInfo.length ? (
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={100}
                    handleHeight
                    handleWidth
                    onResize={onResize}
                >
                    <HighchartsReact
                        ref={chartRef}
                        containerProps={containerProps}
                        highcharts={HighCharts}
                        options={chartOptions}
                        library={HIGHCHARTS_LIBRARY}
                    />
                </ReactResizeDetector>
            ) : (
                <NoDataBlock />
            )}
        </>
    );
};

export default PermissionBarChart;

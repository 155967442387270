import React from 'react';
import moment from 'moment';

export const menuIcon = ({ ...restProps } = {}) => {
    return {
        Header: 'Icon',
        headerClassName: '',
        className: 'd-flex flx-center',
        accessor: 'icon',
        width: 50,
        Cell: ({ value }) => {
            return value ? <i className={`metismenu-icon ${value}`} style={{ transform: 'scale(1.2)' }} /> : null;
        },
        ...restProps,
    };
};

export const menuName = ({ ...restProps } = {}) => {
    return {
        Header: 'Menu Name',
        headerClassName: '',
        className: 'd-flex flx-center text-ellipsis',
        accessor: 'menuName',
        width: 100,
        ...restProps,
    };
};

export const menuDescription = ({ ...restProps } = {}) => {
    return {
        Header: 'Menu Description',
        headerClassName: 'text-ellipsis',
        className: 'd-flex flex-start text-ellipsis',
        accessor: 'description',
        ...restProps,
    };
};

export const modDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Modified Date',
        headerClassName: '',
        className: '',
        accessor: 'modDate',
        width: 100,
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
        },
        ...restProps,
    };
};

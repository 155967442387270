import { reqDelete, reqGet, reqPut } from './index';

// 모니터링 - 실시간 위치현황
export const fetchRealTimeLocationStatus = param => reqGet('/v3/api/common/realtime/location/list', { ...param });

// 실시간 센서 모니터링
export const fetchRealTimeSensorMonitoring = param => reqGet('/v3/api/common/sensor', { ...param });
// 센싱 타입 조회
export const fetchSensingTypes = param => reqGet('/v3/api/common/sensor/sensingTypes', { ...param });
// 센싱 타입 조회
export const fetchSensingTypeMetas = param => reqGet('/v3/api/common/sensor/sensing-type-metas', { ...param });

//// 수액 모니터링
// 수액 리스트 조회
export const getIVInjectionMonitoringListApi = param => reqGet('/v3/api/sh01/ivinjection', param);
// 수액 상세 조회
export const getIVInjectionMonitoringApi = param => reqGet(`/v3/api/sh01/ivinjection/${param.IVInjectionNum}`, param);
// 수액 정보 수정
export const updateIVInjectionMonitoringApi = param =>
    reqPut(`/v3/api/sh01/ivinjection/${param.IVInjectionNum}`, param);
// 수액 센서 언맵핑
export const updateUnmapIVInjectionMonitoringApi = param => reqPut(`/v3/api/sh01/ivinjection`, param);

// 냉장고 센서
export const getRefrigeratorSensorListApi = param => reqGet('/v3/api/sh01/fridge', param);
export const getRefrigeratorSensorDailyLogApi = param => reqGet('/v3/api/sh01/fridge/dailylog', param);
export const updateRefrigeratorSensorDailyLogApi = param => reqPut('/v3/api/sh01/fridge/dailylog', param);
export const deleteRefrigeratorSensorDailyLogApi = param => reqDelete(`/v3/api/sh01/fridge/dailylog/${param.logNum}`);
export const getRefrigeratorSensorMonthlyLogApi = param => reqGet('/v3/api/sh01/fridge/monthlylog', param);
export const getRefrigeratorSensorLogListApi = param => reqGet('/v3/api/common/log/sensor', param);
export const getRefrigeratorSensorDetailApi = param => reqGet(`/v3/api/sh01/fridge/${param.sensorNum}`, param);
export const getRefrigeratorSensorDateApi = param => reqGet(`/v3/api/sh01/fridge/period`, param);
export const putRefrigeratorSensorDetailApi = param => reqPut(`/v3/api/common/sensor/${param.sensorNum}`, param);

// 활력징후 모니터링
// export const getVitalSignsRealTimeListApi = param => reqGet('/v3/api/sh01/vitality/by-ward/vitally', param);
export const getVitalSignsMonitoringListApi = param => reqGet('/v3/api/sh01/vitality/by-ward/temp-bp', param);
export const getVitalSignsDetailInfoApi = param =>
    reqGet(`/v3/api/sh01/vitality/by-ward/vitally/targetInfo/${param.targetId}`, param);
export const getVitalSignsDetailGraphApi = param => reqGet(`/v3/api/sh01/vitality/by-ward/vitally/graph`, param);
export const getVitalSignsWorkTimeApi = param => reqGet(`/v3/api/sh01/vitality/by-ward/worktime`, param);
// 활력징후 partron
export const getVitalSignsListOfPatronApi = param => reqGet('/v3/api/sh01/vitality/by-ward/patron', param);
export const getVitalSignsDetailOfPatronApi = param =>
    reqGet(`/v3/api/sh01/vitality/by-ward/patron/${param.targetId}`, param);
export const getVitalSignsDetailGraphOfPatronApi = param => reqGet(`/v3/api/sh01/vitality/by-ward/patron/graph`, param);

import React from 'react';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';

const Sheet = ({ data }) => {
    const t = useTranslation('RefrigeratorSensor');
    const { targetName, sensorName, wardInfo, lastDate, sensorItems } = data;
    const titles = [
        { key: 'Name', value: targetName },
        { key: 'Sensor Name', value: sensorName },
        { key: 'Ward/Department', value: wardInfo },
        {
            key: 'Temperature',
            value: sensorItems[1]?.currentValue
                ? `${sensorItems[1]?.currentValue.toFixed(2)} ${sensorItems[1].measurementUnit}`
                : '-',
        },
        { key: 'Status', value: sensorItems[1] ? t(sensorItems[1].status) : '-' },
        { key: 'Recognition date', value: moment(lastDate * 1000).format('YYYY-MM-DD') },
    ];
    return (
        <table className="w-100 h-100 border-size-1 border-depth-7">
            <tbody>
                {titles.map(({ key, value }, idx) => {
                    return (
                        <tr key={`${key}_${idx}`} className="border-bottom border-depth-7">
                            <td className="w-20 px-2 py-1 text-ellipsis bg-depth-4">
                                <span title={t(key)}>{t(key)}</span>
                            </td>
                            <td className="w-80 px-2 py-1 border-left border-depth-7">
                                <span title={value}>{value}</span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Sheet;

import React, { useCallback, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import useConfirmModal from '@hooks/useConfirmModal';

const useValidationModal = ({ initModal, targetData, validate, ...modalProps }) => {
    const t = useTranslation('Common');
    const [validationMsg, setValidationMsg] = useState([]);

    const isPassValidation = useCallback(() => {
        let result = [];
        if (typeof validate === 'function' && typeof targetData === 'object') {
            result = validate(targetData) || [];
        }
        setValidationMsg(result);
        return !result.length;
    }, [targetData, validate]);

    const { Modal, toggleModal } = useConfirmModal({
        initModal,
        header: { title: t('Data Validation') },
        confirmText: (
            <>
                {validationMsg.map((msg, i) => {
                    return (
                        <li key={`validation_${i}`}>
                            <span className="pnt-label--group">
                                <div className="label-main w-100" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    {msg}
                                </div>
                            </span>
                        </li>
                    );
                })}
            </>
        ),
        ...modalProps,
    });

    return { Modal, toggleModal, isPassValidation };
};

export default useValidationModal;

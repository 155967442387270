import React from 'react';
import { useTranslation } from '@hooks';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const HourlyCongestionStatusSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('Widget');
    return (
        <WidgetSettingModal
            headerTitle={t('Hourly congestion status')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            {...restProps}
        />
    );
};

export default HourlyCongestionStatusSetting;

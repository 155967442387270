import React from 'react';
import Button from '@components/Button';
import useTranslation from '@util/hooks/useTranslation';

const Profile = ({ count, name }) => {
    const t = useTranslation('UserBox');
    return (
        <div className="widget-content-left">
            <div className="profile-txt">
                <span className="user-name">{name}</span>
                <span className="unread-msg">
                    {t('There are')} <b>{count}</b>
                    {t('unread alerts')}
                </span>
            </div>
        </div>
    );
};

const LogOut = ({ onClick }) => {
    const t = useTranslation('UserBox');
    return (
        <div className="widget-content-right">
            <Button className="btn-brand btn-shadow" onClick={onClick}>
                {t('Logout')}
            </Button>
        </div>
    );
};

/**
 * @param userInfo // 유저정보
 * @param count // 안읽은 알람 카운트
 * @param toggleLogoutModal // 로그아웃 모달 함수
 *
 * @author created bu 정진범 2022-12-08
 * **/

const DropDownHeader = ({ count, userInfo, toggleLogoutModal }) => {
    const { userName } = userInfo;
    return (
        <div className="dropdown-menu-header">
            <Profile count={count} name={userName} />
            <LogOut onClick={toggleLogoutModal} />
        </div>
    );
};

export default DropDownHeader;

import { useEffect, useRef } from 'react';
import useSocket, { SocketEventHandler } from '@util/socket/hooks/useSocket';
import { EventType } from '@reducer/SocketInfo';
import useAppSelector from '@hooks/useAppSelector';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import { SocketResponseData } from '@util/socket/socketData';

type SocketBulkEventHandler = (data: SocketResponseData | SocketResponseData[]) => void;
type SingleDataHandler = SocketEventHandler<SocketResponseData>;

type SocketEvent = {
    name: EventType;
    handler: SocketBulkEventHandler;
    filterConfig?: object;
    enableBuffer?: boolean;
    intervalTime?: number;
};

interface SocketDataBuffer {
    interval: null | number;
    buffer: SocketResponseData[];
}

// 주의: deps 에만 의존해서 필터 요청을 갱신하므로 deps 가 바뀌지 않으면 중간에 다른 파라미터가 바뀌어도 제대로 적용되지 않음
// deps 가 넘기지 않으면 [filterConfig]를 기본값으로 사용. 따라서 filterConfig도 useMemo를 사용하여 관리
// 필터 갱신 타이밍을 커스텀해야 하는 경우(의존성 배열을 직접 넣을 경우),
// 필터를 다시 요청해야 하는 조건들을 묶어서 useMemo 로 관리 되어야함.
function useSocketEvent(
    { name, handler, filterConfig = {}, enableBuffer = false, intervalTime = 1000 }: SocketEvent,
    deps: ReadonlyArray<any> = [filterConfig],
): void {
    const { socket, setSocketEvent, removeSocketEvent } = useSocket();
    const { mode } = useAppSelector(state => state.ScreenInfo);

    const socketBuffer = useRef<SocketDataBuffer>({
        interval: null,
        buffer: [],
    });

    const flushData = () => {
        if (socketBuffer.current.buffer.length > 0) {
            handler(socketBuffer.current.buffer.splice(0));
        }
    };

    const flushDataInterval = () => {
        if (socketBuffer.current.interval) {
            clearInterval(socketBuffer.current.interval);
        }
        socketBuffer.current.interval = window.setInterval(() => {
            flushData();
        }, intervalTime);
    };

    const stopFlushDataInterval = () => {
        flushData();
        if (socketBuffer.current.interval) {
            clearInterval(socketBuffer.current.interval);
        }
        socketBuffer.current.interval = null;
    };

    useEffect(() => {
        if (mode === SCREEN_MODE_MONITORING) {
            let socketHandler: SingleDataHandler = handler;

            if (enableBuffer) {
                socketHandler = (data: SocketResponseData) => {
                    socketBuffer.current.buffer.push(data);
                };

                flushDataInterval();
            }

            const filterId = setSocketEvent(name, socketHandler, filterConfig) as string;

            return () => {
                removeSocketEvent(name, socketHandler, filterId);

                if (enableBuffer) {
                    stopFlushDataInterval();
                }
            };
        }
    }, [...deps, socket]);
}

export default useSocketEvent;

import React from 'react';
import RadioButton from '@components/Input/RadioButton';
import useTranslation from '@hooks/useTranslation';

/**
 * @param {string} value 선택한 알람 타입
 * @param {function} onChange 알람타입 변경 함수
 *
 * @author created bu 정진범 2022-12-08
 * */
const AlarmSetting = ({ value, onChange }) => {
    const t = useTranslation('UserBox');
    const ALARM_OPTIONS = [
        { value: 'group', label: t('Group') },
        { value: 'personal', label: t('Personal') },
    ];

    return (
        <RadioButton
            radioGroupClassName="mb-0"
            name="alarmType"
            radioList={ALARM_OPTIONS}
            value={value}
            onChange={onChange}
        />
    );
};

export default AlarmSetting;

import React, { useState, useEffect, useMemo } from 'react';
import { useSettings } from '../util/useSettings';
import { useSelector } from 'react-redux';
import ResponsiveBarChart from '../../Common/Charts/BarChart';
import Card from '../Components/WidgetCard';
import { useTranslation } from '@hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import FloorPermissionChart from './Components/FloorPermissionChart';

const initData = [
    {
        key: 'PNT',
        name: 'PNT',
        authorized: 12,
        unAuthorized: 2,
    },
    {
        key: 'ExampleFloor1',
        name: 'ExampleFloor1',
        authorized: _.random(0, 15),
        unAuthorized: _.random(0, 15),
    },
    {
        key: 'ExampleFloor2',
        name: 'ExampleFloor2',
        authorized: _.random(0, 15),
        unAuthorized: _.random(0, 15),
    },
    {
        key: 'ExampleFloor3',
        name: 'ExampleFloor3',
        authorized: _.random(0, 15),
        unAuthorized: _.random(0, 15),
    },
];

const FloorPermissionStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('FloorPermissionStatus');
    const settings = useSettings(config);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { mode } = useSelector(state => state.ScreenInfo);
    const { floorInOutState } = useSelector(state => state.TagInfo);
    const [floorPermissionStatus, setFloorPermissionStatus] = useState(initData);

    const chartMeta = {
        dataSet: [
            { key: 'authorized', name: t('Authorized'), color: '#1f77b4' },
            { key: 'unAuthorized', name: t('Unauthorized'), color: '#ff7f0e' },
        ],
    };

    useEffect(() => {
        setFloorPermissionStatus(
            //selectableFloorList
            floorList.map(v => {
                return {
                    key: v.floorId,
                    name: v.floorName,
                    authorized:
                        floorInOutState && floorInOutState[v.floorId]
                            ? floorInOutState[v.floorId].authorized.length
                            : 0,
                    unAuthorized:
                        floorInOutState && floorInOutState[v.floorId]
                            ? floorInOutState[v.floorId].unAuthorized.length
                            : 0,
                };
            }),
        );
    }, [floorList, floorInOutState]);

    const data = useMemo(() => {
        return mode === SCREEN_MODE_EDIT ? initData : floorPermissionStatus;
    }, [mode, floorPermissionStatus]);

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <FloorPermissionChart data={data} chartMeta={chartMeta} />
                    {/*<ResponsiveBarChart settings={settings} meta={chartMeta} data={data} />*/}
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default FloorPermissionStatus;

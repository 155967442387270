import React, { useEffect, useReducer, useRef } from 'react';
import IVInjectionMonitoringReducer, {
    initialState,
    setIVInjectionList,
    toggleRemainingValueDisplay,
} from './IVInjectionMonitoringReducer';
import useTranslation from '@hooks/useTranslation';
import IVInjectionMonitoringCardList from './Components/IVInjectionMonitoringCardList';
import IVInjectionUpdateModal from './Components/IVInjectionUpdateModal';
import useAsync from '@hooks/useAsync';
import { getIVInjectionMonitoringListApi } from '@api/monitoring';
import useMonitor from '../util/useMonitor';
import useFilter from '@hooks/useFilter';
import { useSelector } from 'react-redux';
import WidgetCard from '../Components/WidgetCard';
import ToggleButton from '@components/Button/ToggleButton';

export const IVInjectionMonitoringStateContext = React.createContext();
export const IVInjectionMonitoringDispatchContext = React.createContext();

const CATEGORY_CODE = 'patient';
const postParams = { categoryCodes: CATEGORY_CODE };
const IVInjectionMonitoring = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('IVInjectionMonitoring');
    const [state, dispatch] = useReducer(IVInjectionMonitoringReducer, initialState);
    const { monitoring } = useSelector(state => state.GlobalIntervalTime);
    const widgetRef = useRef();
    const { config } = widgetInfo;
    const filterParams = useFilter({ postParam: postParams });

    // 수액 List 조회
    const { promise: getIVInjectionMonitoringList } = useAsync({
        promise: getIVInjectionMonitoringListApi,
        immediate: true,
        param: filterParams,
        resolve: res => {
            dispatch(setIVInjectionList(res));
        },
    });

    // 수액 List 반복 조회
    useMonitor({
        config,
        defaultData: state.IVInjectionList,
        dynamicParam: filterParams,
        fetchData: getIVInjectionMonitoringList,
        makeData: ({ newData }) => {
            dispatch(setIVInjectionList(newData));
        },
    });

    // 수액 잔량 보기 토글
    const handleRemainingValueDisplay = () => {
        dispatch(toggleRemainingValueDisplay());
    };

    // 수액 리스트 호출
    const handleGetIVMonitoringList = () => getIVInjectionMonitoringList(filterParams);

    // 수액 모니링 중이 아니면 api 호출로 대체
    useEffect(() => {
        if (!monitoring) {
            getIVInjectionMonitoringList(filterParams);
        }
    }, [filterParams]);

    return (
        <IVInjectionMonitoringDispatchContext.Provider value={dispatch}>
            <IVInjectionMonitoringStateContext.Provider value={state}>
                <WidgetCard
                    ref={widgetRef}
                    widgetInfo={widgetInfo}
                    headerAction={
                        <div className="d-flex align-items-center">
                            <span>{t('Remaining Amount')}</span>
                            <ToggleButton
                                className="form-h-small ml-2"
                                checked={state.remainingValueDisplay}
                                handleChecked={handleRemainingValueDisplay}
                            />
                        </div>
                    }
                    {...restProps}
                >
                    <IVInjectionMonitoringCardList
                        widgetInfo={widgetInfo}
                        handleCallbackApi={handleGetIVMonitoringList}
                    />
                    <IVInjectionUpdateModal handleCallbackApi={handleGetIVMonitoringList} />
                    {children}
                </WidgetCard>
            </IVInjectionMonitoringStateContext.Provider>
        </IVInjectionMonitoringDispatchContext.Provider>
    );
};

export default IVInjectionMonitoring;

import { useCallback, useEffect, useState } from 'react';
import { hidden as globalHidden, visibilityChange } from '@util/crossBrowsingAttrKeys';

const useVisibility = handleVisibilityChange => {
    const [hidden, setHidden] = useState(document[globalHidden]);

    const onVisibilityChange = useCallback(
        e => {
            setHidden(document[globalHidden]);
            if (typeof handleVisibilityChange === 'function') {
                handleVisibilityChange(document[globalHidden], e);
            }
        },
        [handleVisibilityChange],
    );

    useEffect(() => {
        document.addEventListener(visibilityChange, onVisibilityChange);
        return () => {
            document.removeEventListener(visibilityChange, onVisibilityChange);
        };
    }, [onVisibilityChange]);

    return { hidden };
};

export default useVisibility;

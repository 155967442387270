import React from 'react';
import VitalSignsCard from './VitalSignsCard';

/**
 * 병상 리스트 컴포넌트
 *
 * @param roomNum 병실 번호
 * @param bedList 병상 목록
 * @param handleRefreshData 환자별 활력징후 데이터 새로고침 handler
 *
 * @since 2023-02-07
 * @author kunwoong-kim
 * */
const VitalSignsBedList = ({ roomNum, bedList, handleRefreshData }) => {
    // 렌더링을 위한 Entry
    const bedEntries = Object.entries(bedList);

    return bedEntries.map(([bedNum, people], idx) => (
        <VitalSignsCard
            key={`${roomNum}_${bedNum}_${idx}`}
            roomNum={roomNum}
            bedNum={bedNum}
            people={people}
            handleRefreshData={handleRefreshData}
        />
    ));
};

export default VitalSignsBedList;

import { useDispatch } from 'react-redux';
import useAsync from '@util/hooks/useAsync';
import { fetchCompany, fetchUserInfo, getUserGroupInfoApi } from '@api/login';
import { DEFAULT_LANGUAGE, setAdditionalInfo, setLanguage } from '@reducer/UserInfo';
import { setMarkerConfig } from '@reducer/Common/AppConfig';
import { fetchUserSetting } from '@api/common';
import { DEFAULT_COLOR_SCHEME, setColorScheme } from '@reducer/ThemeOptions';
import { DEFAULT_ALARM_TYPE, setAlarmType } from '@reducer/Notification';

// 유저가 만들어둔 대쉬보드, 유저의 이름, 유저가 속해있는 회사정보 불러오기
const useMoreUserInfo = () => {
    const dispatch = useDispatch();

    const { promise: getMoreUserInfo } = useAsync({
        promise: [fetchUserInfo, fetchCompany, getUserGroupInfoApi, fetchUserSetting],
        param: [
            { lookupItems: 'userName' },
            null,
            null,
            {
                propId: 'color',
            },
        ],
        resolve: ([{ data: userInfo }, { data: companyInfo }, { rows: groupInfo }, { data: setting }]) => {
            const additionalInfo = {
                userNum: userInfo.userNum,
                userName: userInfo.userName,
                roleNum: userInfo.roleNum,
                authGroupNum: userInfo.serviceProductInfo.authGroupNum,
                serviceProductRoleList: userInfo.serviceProductInfo.serviceProductRoleList,
                groupId: null,
                groupName: '',
                groupNum: '',
                groupNums: [],
                alertType: setting.alertType || DEFAULT_ALARM_TYPE,
                propValue: setting.propValue || DEFAULT_COLOR_SCHEME,
                lang: setting.description || DEFAULT_LANGUAGE,
                companyInfo,
            };
            if (companyInfo) {
                dispatch(
                    setMarkerConfig({
                        markerTransitionActive: companyInfo.markerTransitionActive,
                        markerPulseActive: companyInfo.markerPulseActive,
                        markerBorderRadius: companyInfo.markerBorderRadius,
                        markerPulseAnimation: companyInfo.markerPulseAnimation,
                        markerPulseRssi: companyInfo.markerPulseRssi,
                        markerPulseColor: companyInfo.markerPulseColor,
                    }),
                );
            }

            if (groupInfo && groupInfo[0]) {
                // 자산 대여를 위한 그룹 정보 저장. 추후 수정 필요
                additionalInfo.groupId = groupInfo[0].groupId;
                additionalInfo.groupName = groupInfo[0].groupName;
                additionalInfo.groupNum = groupInfo[0].groupNum;
                additionalInfo.groupNums = groupInfo.map(v => v.groupNum);
                additionalInfo.representationGroupInfo = groupInfo.find(group => !!group.floorId) || groupInfo[0];
                additionalInfo.groupInfoList = groupInfo;
            }

            // 임시 추후 수정 예정
            // 개인설정 값 (lang:언어, colorTheme:테마 색상, alertType:알람 타입)
            if (setting) {
                const { description: lang, propValue: colorTheme, alertType: alarmType } = setting;
                dispatch(setLanguage(lang || DEFAULT_LANGUAGE));
                dispatch(setColorScheme(colorTheme || DEFAULT_COLOR_SCHEME));
                dispatch(setAlarmType(alarmType || DEFAULT_ALARM_TYPE));
            }

            dispatch(setAdditionalInfo(additionalInfo));
        },
    });
    return getMoreUserInfo;
};

export default useMoreUserInfo;

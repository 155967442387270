import React from 'react';

import { useColumns, useTranslation } from '@hooks';

import * as column from '../../../MainPages/util/grid/column';
import DynamicRowHeightTable from '@components/Table/DynamicRowHeightTable';

/**
 *
 * Iot 아이템 정보 로그 테이블 생성 컴포넌트
 *
 * @param {Object} list Iot 아이템 정보 로그 리스트 데이터
 * @param {Object} state Iot 아이템 정보 로그 리스트 API 호출 상태`값
 * @param {function} promise Iot 아이템 정보 로그 리스트 호출 callback
 *
 * @author jinbeom-jung
 * */

const IotItemInfoLogList = ({ list, state, promise }) => {
    const t = useTranslation('Iot Item Information Log');

    const columns = useColumns([
        column.number(),
        column.dataType({ width: 80, Cell: ({ value }) => <span title={t(value)}>{t(value)}</span> }),
        column.targetNum({ width: 80 }),
        column.iotItemId({ width: 100 }),
        column.targetName({ Header: 'IoT Item Name', width: 80 }),
        column.registeredDate({ Header: 'Data Revision Date', width: 100 }),
        column.metaData({
            width: 200,
            Cell: ({ value }) => {
                const list = value ? Object.entries(value) : [];
                return (
                    <div className={'d-flex align-items-center'} style={{ flexWrap: 'wrap' }}>
                        <div className={'pnt-txt txt-border txt-bold mr-1 item_count mb-1'}>{list.length}</div>
                        {list.map((metaData, index) => {
                            const [key, value] = metaData;
                            const text = !Array.isArray(value) ? `${key}: ${value}` : `${key}: ${value.join(',')}`;

                            return (
                                <div key={index} className="item_badge bg-secondary bg-l-1 mb-1">
                                    <span className={'material-icons-round'}>sensors</span>
                                    <div className={'color-secondary pnt-txt txt-bold'}>{text}</div>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        }),
    ]);

    const handlePageChange = pageIndex => {
        const param = state.request;
        let result = {};
        for (let key in param) {
            if (param[key]) {
                result[key] = param[key];
            }
        }
        promise({ ...result, page: pageIndex });
    };

    return (
        <div style={{ height: '80%' }}>
            <DynamicRowHeightTable
                data={{ ...list, pageSize: 15 }}
                columns={columns}
                onPageChange={handlePageChange}
                loading={state.isLoading}
                dynamicRowHeight={true}
            />
        </div>
    );
};

export default IotItemInfoLogList;

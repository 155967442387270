import React, { useContext, useEffect, useState } from 'react';
import IotItemStatusCount from './Components/IotItemStatusCount/IotItemStatusCount';
import IotItemStatusList from './Components/IotItemStatusList/IotItemStatusList';
import useMonitor from '../util/useMonitor';
import { fetchAssetCountStatus, getTargetListWithLocationApi } from '@api/asset';
import { IotItemStatusStateContext } from './index';
import defaultData from './Components/IotItemStatusList/defaultData.json';
import { useAsync } from '@hooks';
import useFilter from '@hooks/useFilter';
import { useSettings } from '../util/useSettings';
import { useSelector } from 'react-redux';
import TIMES from '@util/times';
import { debounce } from 'lodash';

const DEFAULT_IoT_COUNT = {
    totalCount: 0,
    restrictedCount: 0,
    lostSignalCount: 0,
    lowBatteryCount: 0,
    neverDetectedCount: 0,
};

const DEFAULT_IoT_ITEM_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};

const DEFAULT_PARAMETERS = {
    pageSize: 15,
    page: 1,
    // 자산 태그 매핑 포함 여부 (포함 : Y, 미포함 : N)
    isIncludeConfig: 'Y',
};

const IotItemWrapper = ({ widgetInfo }) => {
    const { monitoring, globalIntervalTime } = useSelector(state => state.GlobalIntervalTime);
    const { config } = widgetInfo;
    const { selectedIotItemStatus: status, target } = useContext(IotItemStatusStateContext);
    const { categoryCode: parentCategory } = useSettings(config);

    const filterParam = useFilter({ postParam: DEFAULT_PARAMETERS });
    const [dynamicParam, setDynamicParam] = useState(filterParam);

    // 페이지 이동 event
    const handlePageChange = pageIndex => {
        setDynamicParam(prevState => {
            return { ...prevState, page: pageIndex };
        });
    };

    const [iotItemCount, setIotItemCount] = useState(DEFAULT_IoT_COUNT);
    useMonitor({
        config,
        defaultData: DEFAULT_IoT_COUNT,
        dynamicParam,
        fetchData: fetchAssetCountStatus,
        makeData: ({ newData }) => {
            setIotItemCount(newData);
        },
    });

    // IoT Item 목록정보
    const [iotItemList, setIotItemList] = useState(DEFAULT_IoT_ITEM_LIST);
    // IoT Item 목록 조회 (모니터링)
    useMonitor({
        config,
        defaultData,
        dynamicParam,
        fetchData: getTargetListWithLocationApi,
        makeData: ({ newData }) => {
            if (newData.rows) {
                setIotItemList({ ...DEFAULT_IoT_ITEM_LIST, ...newData });
            }
        },
    });

    // IoT Item Count 조회 (단일조회) ** 북마크용
    const { promise: getAssetCount } = useAsync({
        promise: fetchAssetCountStatus,
        param: dynamicParam,
        resolve: res => {
            setIotItemCount(res);
        },
        reject: err => console.error(err),
    });
    // IoT Item 목록 조회 (단일조회) ** 북마크용
    const { promise: getIotItems } = useAsync({
        promise: getTargetListWithLocationApi,
        param: dynamicParam,
        resolve: res => {
            setIotItemList(prevState => ({ ...res, page: prevState.page }));
        },
        reject: err => console.error(err),
    });

    // bookmark handling
    const bookmarkSuccessCallback = () => {
        getAssetCount();
        getIotItems();
    };

    // merge filter params and search params
    useEffect(() => {
        // ward 정보 -1 경우 (로그인 사용자 그룹)
        // fixme: filter parameter 중 병동 값 -1 을 막기 위한 임시 코드
        if (filterParam['targetPropertiesSearch.propertySearchs[0].values'] < 0) {
            return;
        }
        setDynamicParam(prevState => {
            const defaultParams = { ...filterParam, target, status };
            if (!status) {
                delete defaultParams.status;
            }
            if (!target) {
                delete defaultParams.target;
            }
            if (prevState.page && prevState.page !== defaultParams.page) {
                return defaultParams;
            }
            return { ...defaultParams, ...DEFAULT_PARAMETERS };
        });
    }, [filterParam, status, target, parentCategory]);

    // 갱신 안함 시 호출
    useEffect(() => {
        const defaultParams = { ...filterParam, target, status };
        if (!status) {
            delete defaultParams.status;
        }
        if (!target) {
            delete defaultParams.target;
        }

        if (!monitoring || globalIntervalTime === TIMES.ZERO) {
            getAssetCount(defaultParams);
            getIotItems(defaultParams);
        }
    }, [filterParam, status]);

    return (
        <>
            <IotItemStatusCount widgetInfo={widgetInfo} iotItemCount={iotItemCount} />
            <IotItemStatusList
                widgetInfo={widgetInfo}
                dynamicParam={dynamicParam}
                handleIotItemListParam={handlePageChange}
                handleIotItemList={setIotItemList}
                iotItemList={iotItemList}
                bookmarkSuccessCallback={bookmarkSuccessCallback}
            />
        </>
    );
};

export default IotItemWrapper;

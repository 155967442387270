import React from 'react';
import CongestionSummary from './CongestionSummary';
import { useTranslation } from '@hooks';

const checkFalsyValue = value => {
    return value ? value : 0;
};

const AllCongestionSummary = ({ congestionInfo }) => {
    const t = useTranslation('CongestionStatus');
    return (
        <div className={'d-grid'} style={{ gridTemplateColumns: 'repeat(4,1fr)', gap: '8px' }}>
            <CongestionSummary
                count={checkFalsyValue(congestionInfo.totalPermittedEntryCount)}
                totalCount={checkFalsyValue(congestionInfo.totalRestrictedEntryCount)}
                label={t('Total count of entries')}
            />
            <CongestionSummary
                count={checkFalsyValue(congestionInfo.totalPermittedUniqueCount)}
                totalCount={checkFalsyValue(congestionInfo.totalRestrictedUniqueCount)}
                label={t('Unique count of entries')}
            />
            <CongestionSummary
                time={true}
                sec={checkFalsyValue(congestionInfo.totalAvgStayEntryTime)}
                label={t('Average length of stay of total entries')}
            />
            <CongestionSummary
                time={true}
                sec={checkFalsyValue(congestionInfo.totalAvgStayUniqueTime)}
                label={t('Average length of stay of unique entries')}
            />
        </div>
    );
};

export default AllCongestionSummary;

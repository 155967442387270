import React from 'react';
import cx from 'classnames';
import styles from 'assets/pnt/widgets/RefrigeratorSensor.module.scss';

const STATUS = {
    CRITICAL: { iconName: 'close', color: 'danger' },
    NORMAL: { iconName: 'done', color: 'success' },
    null: { iconName: 'remove', color: 'depth-9' },
    OUTOFRANGE: { iconName: 'question_mark', color: 'secondary' },
    WARNING: { iconName: 'priority_high', color: 'warning' },
};

const SensorStatus = ({ status }) => {
    return (
        <div className={cx(styles.sensor_img, STATUS[status] && `bg-${STATUS[status].color}`)}>
            <span className="s_temperature"></span>
            <div className={styles.status}>
                <span className="material-icons-round">{STATUS[status].iconName}</span>
            </div>
        </div>
    );
};

export default SensorStatus;

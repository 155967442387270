import React from 'react';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { useTranslation } from '@hooks';

const CongestionStatusSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('Widget');
    return (
        <WidgetSettingModal
            headerTitle={t('Congestion Status')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            {...restProps}
        />
    );
};

export default CongestionStatusSetting;

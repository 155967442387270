import React, { useContext, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { deleteAuthGroupApi, fetchAuthGroupList } from '@api/menu';
import ConfirmModal from '@components/ConfirmModal';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { A_PATH_AUTHORIZATION_GROUP } from '../../../../../Components/Router/path';
import { AuthorizationGroupListDispatchContext } from '../index';
import { setGroupList } from '../reducer';

const AuthorizationGroupSubMenu = ({ row: { original } }) => {
    const dispatch = useContext(AuthorizationGroupListDispatchContext);
    const t = useTranslation('AuthorizationGroup');
    const [deleteModal, setDeleteModal] = useState(false);

    const { Modal: DeleteSuccessModal, toggleModal: toggleDeleteSuccessModal } = useConfirmModal({
        confirmText: t('Authorization group deletion complete.'),
        removeCancel: true,
        okCallback: () => {
            getGroupList();
        },
    });

    const { Modal: DeleteFailureModal, toggleModal: toggleDeleteFailureModal } = useConfirmModal({
        confirmText: t('The delete request failed.'),
        removeCancel: true,
    });

    const { promise: getGroupList } = useAsync({
        promise: fetchAuthGroupList,
        fixedParam: { isAll: 'Y' },
        resolve: response => {
            dispatch(setGroupList(response.rows ?? []));
        },
    });

    const { promise: deleteAuthGroup } = useAsync({
        promise: deleteAuthGroupApi,
        resolve: () => {
            toggleDeleteSuccessModal();
        },
        reject: () => {
            toggleDeleteFailureModal();
        },
    });

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const handleDelete = groupInfo => {
        deleteAuthGroup(groupInfo);
    };

    return (
        <>
            <UncontrolledDropdown className="w-100">
                <DropdownToggle tag={'div'} className={'flex-center'}>
                    <span className="material-icons-round">menu</span>
                </DropdownToggle>
                <DropdownMenu className={'grid-sub-menu'}>
                    <Link to={`${A_PATH_AUTHORIZATION_GROUP}/${original.groupNum}`}>
                        <DropdownItem>
                            <span className="material-icons-round md-18">info_outline</span>
                            <span className="ml-2">{t('Group details')}</span>
                        </DropdownItem>
                    </Link>
                    <DropdownItem onClick={toggleDeleteModal}>
                        <span className="material-icons-round md-18">delete_outline</span>
                        <span className="ml-2">{t('Delete the group')}</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <ConfirmModal
                initModal={deleteModal}
                toggleModal={toggleDeleteModal}
                header={{ title: t('Confirm', 'ConfirmModal') }}
                confirmText={t(
                    'There is a screen where the selected authority group is applied. When deleting an authority group, the included users cannot access the screen. Are you sure you want to delete it?',
                )}
                okCallback={handleDelete}
                callbackParam={original}
            />
            <DeleteSuccessModal />
            <DeleteFailureModal />
        </>
    );
};

export default AuthorizationGroupSubMenu;

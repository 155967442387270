const SECOND = 1000;
const MINUTE = 60 * 1000;
const HOUR = 60 * 60 * 1000;

const TIMES = {
    ZERO: 0,
    SEC_1: SECOND,
    SEC_5: 5 * SECOND,
    SEC_10: 10 * SECOND,
    SEC_15: 15 * SECOND,
    SEC_20: 20 * SECOND,
    SEC_30: 30 * SECOND,
    SEC_40: 40 * SECOND,
    SEC_50: 50 * SECOND,
    MIN_1: MINUTE,
    MIN_5: 5 * MINUTE,
    MIN_10: 10 * MINUTE,
    MIN_15: 15 * MINUTE,
    MIN_20: 20 * MINUTE,
    MIN_30: 30 * MINUTE,
    MIN_40: 40 * MINUTE,
    MIN_50: 50 * MINUTE,
    HOUR_1: HOUR,
};

export default TIMES;

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useTranslation from '@hooks/useTranslation';
import useAsync from '@hooks/useAsync';
import { getRefrigeratorSensorMonthlyLogApi } from '@api/monitoring';
import { LoadingBlock } from '@components/LoadingBlock';
import MonthlyTable from './Components/MonthlyTable';
import DatePicker from '@components/DatePicker';
import { RefrigeratorSensorStateContext } from '../../index';
import moment from 'moment';
import { convertAmpmHours } from '../../../util/commons';
import { Button } from '@components';
import ReactToPrint from 'react-to-print';

const TIME_TITLE = 'AM 9:00 / PM 5:00';
const EMPTY_CONTENT = '-';

const RefrigeratorSensorMonthlyLog = () => {
    const t = useTranslation('RefrigeratorSensor');
    const buttonT = useTranslation('Button');
    const state = useContext(RefrigeratorSensorStateContext);
    const monthlyReportRef = useRef();
    const [currentMonthlyLog, setCurrentMonthlyLog] = useState({});
    const [monthlyLogRows, setMonthlyLogRows] = useState([]);

    // 월간대장 리스트 조회
    const { promise: getRefrigeratorSensorMonthlyLog, state: monthlyLogState } = useAsync({
        promise: getRefrigeratorSensorMonthlyLogApi,
        param: {
            startDate: moment().startOf('date').unix(),
            endDate: moment().endOf('date').unix(),
            sensorNum: state.sensorNum,
        },
        resolve: res => {
            setCurrentMonthlyLog(res);
        },
        reject: err => console.error(err),
    });
    // 월 선택
    const [selectedDate, setSelectedDate] = useState(moment().unix());
    const handleChangeDate = date => {
        setSelectedDate(date);
        const selectedMoment = moment(date * 1000);
        getRefrigeratorSensorMonthlyLog({
            startDate: selectedMoment.startOf('month').unix(),
            endDate: selectedMoment.endOf('month').unix(),
            monthLastDay: selectedMoment.endOf('month').date(),
            sensorNum: state.sensorNum,
        });
    };

    // 월간대장 테이블 Column 정보
    const initColumns = React.useMemo(
        () => [
            { header: 'Day', accessor: 'day' },
            { header: 'Time', accessor: 'firstTime' },
            { header: 'Temp', accessor: 'firstTemp' },
            { header: 'Range', accessor: 'firstRange' },
            { header: 'Flag', accessor: 'firstFlag' },
            { header: 'Manager', accessor: 'firstManager' },
            { header: 'Time', accessor: 'secondTime' },
            { header: 'Temp', accessor: 'secondTemp' },
            { header: 'Range', accessor: 'secondRange' },
            { header: 'Flag', accessor: 'secondFlag' },
            { header: 'Manager', accessor: 'secondManager' },
            { header: 'Note', accessor: 'note' },
        ],
        [],
    );

    /**
     * 냉장고별 월간대장 표 데이터 생성
     *
     * @param res 월간대장 응답값
     */
    const initTableRows = useCallback(
        res => {
            if (!res) {
                return [];
            }
            const { maxControlLimit: max, minControlLimit: min, fridgeLogValues } = res;
            let rows = [];
            for (const day in fridgeLogValues) {
                // 날짜 변경
                const convertRegDate = value =>
                    value
                        ? convertAmpmHours(
                              moment(value * 1000)
                                  .toDate()
                                  .getHours(),
                          )
                        : EMPTY_CONTENT;
                // 온도 변경
                const convertTemp = value => (value ? value.toFixed(2) : EMPTY_CONTENT);
                // row 생성
                const row = {
                    day: day,
                    firstTime: convertRegDate(fridgeLogValues[day][0] ? fridgeLogValues[day][0].regDate : null),
                    firstTemp: convertTemp(fridgeLogValues[day][0] ? fridgeLogValues[day][0].currentValue : null),
                    firstRange: (fridgeLogValues[day][0] ? fridgeLogValues[day][0].currentValue : null)
                        ? `${max} / ${min}`
                        : EMPTY_CONTENT,
                    firstFlag: '',
                    firstManager: '',
                    secondTime: convertRegDate(fridgeLogValues[day][1] ? fridgeLogValues[day][1].regDate : null),
                    secondTemp: convertTemp(fridgeLogValues[day][1] ? fridgeLogValues[day][1].currentValue : null),
                    secondRange: (fridgeLogValues[day][1] ? fridgeLogValues[day][1].currentValue : null)
                        ? `${max} / ${min}`
                        : EMPTY_CONTENT,
                    secondFlag: '',
                    secondManager: '',
                    note: '',
                };
                rows.push(row);
            }
            return rows;
        },
        [selectedDate],
    );

    // Mount/Unmount 시점
    useEffect(() => {
        getRefrigeratorSensorMonthlyLog({
            startDate: moment(selectedDate * 1000)
                .startOf('month')
                .unix(),
            endDate: moment(selectedDate * 1000)
                .endOf('month')
                .unix(),
            monthLastDay: moment(selectedDate * 1000)
                .endOf('month')
                .date(),
            sensorNum: state.sensorNum,
        });

        return () => {
            setCurrentMonthlyLog([]);
            setMonthlyLogRows([]);
        };
    }, []);

    // 서버 응답값 상태변화에 따른 표 데이터 생성
    useEffect(() => {
        const initRows = initTableRows(currentMonthlyLog);
        setMonthlyLogRows(initRows);
    }, [currentMonthlyLog]);

    // 프린트 대상 컴포넌트
    const reactToPrintContent = React.useCallback(() => {
        return monthlyReportRef.current;
    }, [monthlyReportRef.current]);

    // 정상 허용 최대값
    const max = currentMonthlyLog.maxControlLimit;
    // 정상 허용 최소값
    const min = currentMonthlyLog.minControlLimit;

    return (
        <PrintContainer ref={monthlyReportRef}>
            <PrintDisplayNoneContainer>
                <Title>{`${state.sensorInfo[state.sensorNum].targetName} ${t(
                    'Refrigerator temperature management ledger',
                )}`}</Title>
                <FlexSubTitle className="mb-3">
                    <div>
                        <SubTitle>{t('Department')}</SubTitle> :{' '}
                        <Value>{currentMonthlyLog.wardInfo ? currentMonthlyLog.wardInfo : EMPTY_CONTENT}</Value>
                        <SubTitle>{t('Measurement Time')}</SubTitle> : <Value>{TIME_TITLE}</Value>
                        <div>
                            <SubTitle>{t('Permitted Range')}</SubTitle> :{' '}
                            <Value>{min !== null && max !== null ? `${min} ~ ${max}(℃)` : EMPTY_CONTENT}</Value>
                        </div>
                    </div>
                    <FlexSubTitle>
                        <SubTitle className="mr-3">{t('Inspection Year Month')}</SubTitle>
                        <PrintButtonContainer>
                            <DatePicker
                                value={selectedDate}
                                valueType={'s'}
                                handleChange={selected => handleChangeDate(selected)}
                                maxDate={moment.now()}
                                withoutDay
                                showMonthYearPicker
                            />
                            <ReactToPrint
                                trigger={() => <Button className="pnt-btn btn-gray ml-3">{buttonT('Print')}</Button>}
                                targetComponent={monthlyReportRef.current}
                                content={reactToPrintContent}
                            />
                        </PrintButtonContainer>
                        <PrintValueContainer>
                            <Value>{moment(selectedDate * 1000).format('YYYY-MM')}</Value>
                        </PrintValueContainer>
                    </FlexSubTitle>
                </FlexSubTitle>
            </PrintDisplayNoneContainer>
            <PrintDisplayUnsetContainer>
                <h5 className="text-center ">{`${state.sensorInfo[state.sensorNum].targetName} ${t(
                    'Refrigerator temperature management ledger',
                )}`}</h5>
                <div className="border mb-3">
                    <div className="d-flex align-items-center border-bottom">
                        <div className="flx-1 text-center py-1 px-2">
                            <SubTitle>{t('Inspection Year Month')}</SubTitle>
                        </div>
                        <div className="flx-1 py-1 px-2 border-left">
                            <PrintTextContainer>{moment(selectedDate * 1000).format('YYYY-MM')}</PrintTextContainer>
                        </div>
                        <div className="flx-1 text-center py-1 px-2 border-left">
                            <SubTitle>{t('Permitted Range')}</SubTitle>
                        </div>
                        <div className="flx-1 py-1 px-2 border-left">
                            <PrintTextContainer>{min && max ? `${min}℃ ~ ${max}℃` : EMPTY_CONTENT}</PrintTextContainer>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="flx-1 text-center py-1 px-2">
                            <SubTitle>{t('Measurement Time')}</SubTitle>
                        </div>
                        <div className="flx-1 py-1 px-2 border-left">
                            <PrintTextContainer>{TIME_TITLE}</PrintTextContainer>
                        </div>
                        <div className="flx-1 text-center py-1 px-2 border-left">
                            <SubTitle>{t('Department')}</SubTitle>
                        </div>
                        <div className="flx-1 py-1 px-2 border-left">
                            <PrintTextContainer>
                                {currentMonthlyLog.wardInfo ? currentMonthlyLog.wardInfo : EMPTY_CONTENT}
                            </PrintTextContainer>
                        </div>
                    </div>
                </div>
            </PrintDisplayUnsetContainer>
            <Container>
                <LoadingBlock blocking={monthlyLogState.isLoading}>
                    <MonthlyTable columns={initColumns} rows={monthlyLogRows} />
                </LoadingBlock>
            </Container>
        </PrintContainer>
    );
};

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    @media print {
        color: black;
        font-size: 14px;
    }
`;

const FlexSubTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media print {
        color: black;
        align-items: flex-end;
    }
`;

const SubTitle = styled.span`
    min-width: 56px;
    text-align: justify;
    text-align-last: justify;
    display: inline-block;
    @media print {
        min-width: 48px;
        color: black;
        font-size: 12px;
    }
`;

const Value = styled.b`
    margin-right: 3rem;
    @media print {
        color: black;
        font-size: 12px;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, auto);
    grid-template-rows: repeat(auto-fill, auto);
    grid-gap: 10px;
`;

const PrintContainer = styled.div`
    padding-bottom: 1rem;
    @media print {
        margin: 8mm 8mm;
        padding-bottom: 0;
    }
`;
const PrintButtonContainer = styled.div`
    display: flex;
    @media print {
        display: none;
    }
`;
const PrintValueContainer = styled.div`
    display: none;
    margin-right: -2rem;
    @media print {
        display: flex;
    }
`;

const PrintTextContainer = styled.span`
    @media print {
        color: black;
        font-size: 12px;
    }
`;
const PrintDisplayNoneContainer = styled.div`
    display: unset;
    @media print {
        display: none;
    }
`;
const PrintDisplayUnsetContainer = styled.div`
    display: none;
    @media print {
        display: unset;
    }
`;
export default RefrigeratorSensorMonthlyLog;

import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { copyFilterApi, deleteFilterApi, getFilterListApi } from '@api/filter';
import { setFilterList as setAllFilterList } from '@reducer/FilterInfo';
import { FilterListDispatchContext } from './index';
import { refreshList } from './reducer';
import { INDOORPLUS_ERRORS } from '@util/errorCode';

const FilterSubMenu = ({ row: { original } }) => {
    const storeDispatch = useDispatch();
    const dispatch = useContext(FilterListDispatchContext);
    const t = useTranslation('Filter');
    const [deleteErrMsg, setDeleteErrMsg] = useState(t('Failed to delete filter.'));

    const filterListChangeHandler = () => {
        getAllFilterList();
        dispatch(refreshList());
    };

    const { Modal: CopyConfirmModal, toggleModal: toggleCopyConfirmModal } = useConfirmModal({
        confirmText: t('Do you want to copy the filter?'),
        okCallback: () => {
            copyFilter({ filterNum: original.filterNum });
        },
    });

    const { Modal: CopySuccessModal, toggleModal: toggleCopySuccessModal } = useConfirmModal({
        header: { title: t('Copy Completed') },
        confirmText: t('Filter was copied successfully'),
        removeCancel: true,
        okCallback: filterListChangeHandler,
        cancelCallback: filterListChangeHandler,
    });

    const { Modal: CopyFailModal, toggleModal: toggleCopyFailModal } = useConfirmModal({
        header: { title: t('Request Fail') },
        confirmText: t('Failed to copy filter'),
        removeCancel: true,
    });

    const { Modal: DetailConfirmModal, toggleModal: toggleDeleteConfirmModal } = useConfirmModal({
        confirmText: t('Do you want to delete the filter?'),
        okCallback: () => {
            deleteFilter({ filterNum: original.filterNum });
        },
    });

    const { Modal: DeleteSuccessModal, toggleModal: toggleDeleteSuccessModal } = useConfirmModal({
        confirmText: t('Filter has been deleted.'),
        removeCancel: true,
        okCallback: filterListChangeHandler,
        cancelCallback: filterListChangeHandler,
    });

    const { Modal: DeleteFailModal, toggleModal: toggleDeleteFailModal } = useConfirmModal({
        header: { title: t('Request Fail') },
        confirmText: deleteErrMsg,
        removeCancel: true,
    });

    const { promise: copyFilter } = useAsync({
        promise: copyFilterApi,
        resolve: () => {
            toggleCopySuccessModal();
        },
        reject: () => {
            toggleCopyFailModal();
        },
    });

    const { promise: getAllFilterList } = useAsync({
        promise: getFilterListApi,
        fixedParam: { isAll: 'Y' },
        resolve: ({ rows }) => {
            if (rows) {
                storeDispatch(setAllFilterList(rows));
            }
        },
    });

    const { promise: deleteFilter } = useAsync({
        promise: deleteFilterApi,
        resolve: () => {
            toggleDeleteSuccessModal();
        },
        reject: err => {
            if (
                err.data &&
                err.data.code === INDOORPLUS_ERRORS.ALREADY_MAPPED &&
                err.data.errorResponse.includes('screenCode')
            ) {
                setDeleteErrMsg(t('There is a screen with a filter applied. Please disable the filter and try again.'));
            } else {
                setDeleteErrMsg(t('Failed to delete filter.'));
            }
            toggleDeleteFailModal();
        },
    });

    return (
        <div
            className={'w-100'}
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <UncontrolledDropdown>
                <DropdownToggle tag={'div'} className={'flex-center'}>
                    <span className="material-icons-round">menu</span>
                </DropdownToggle>
                <DropdownMenu className={'grid-sub-menu'}>
                    <DropdownItem onClick={toggleCopyConfirmModal}>
                        <span className="material-icons-round md-18">content_copy</span>
                        <span className="ml-2">{t('Copy the filter')}</span>
                    </DropdownItem>
                    <Link to={`/setting/filter/${original.filterNum}`}>
                        <DropdownItem>
                            <span className="material-icons-round md-18">info_outline</span>
                            <span className="ml-2">{t('Edit the filter')}</span>
                        </DropdownItem>
                    </Link>
                    <DropdownItem onClick={toggleDeleteConfirmModal}>
                        <span className="material-icons-round md-18">delete_outline</span>
                        <span className="ml-2">{t('Delete the filter')}</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <CopyConfirmModal />
            <CopySuccessModal />
            <CopyFailModal />
            <DetailConfirmModal />
            <DeleteSuccessModal />
            <DeleteFailModal />
        </div>
    );
};

export default FilterSubMenu;

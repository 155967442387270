import L from 'leaflet';
import { Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import AllTarget from './Components/AllTarget';
import SingleTarget from './Components/SingleTarget';
import layerFactory from './Components/leaflet-canvas-markers';

import { useSettings } from '../util/useSettings';
import { setBookmarkList } from '../RealTimeLocationStatus/realTimeLocationReducer';

import massRealTimeLocationReducer, {
    initialState,
    setGeofenceInfo,
    setIotItemList,
    setRealTimeLog,
    setSelectedFloor,
} from '../MassRealTimeLocationStatus/massRealTimeLocationReducer';

import { useAsync, useTranslation } from '@hooks';
import { ConfirmModal, Map, RotatedImageOverlay } from '@components';
import errorMarker from '../../../assets/images/marker.png';

import { fetchIotItemList } from '../../../api/asset';
import { fetchBookmarkList, fetchGeofenceList } from '../../../api/common';
import WidgetCard from '../Components/WidgetCard';

L.CanvasIconLayer = layerFactory(L);

export const MassRealTimeLocationStatusContext = React.createContext();
export const MassRealTimeLocationStatusDispatchContext = React.createContext();

const SEARCH_PAGE_SIZE = 20;

const MassRealTimeLocationStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('RealTimeLocationStatus');
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const { geofenceInOutState, tagListByFloor } = useSelector(state => state.TagInfo);
    const { categoryToImg, categoryList } = useSelector(state => state.CategoryInfo);
    const [state, dispatch] = useReducer(massRealTimeLocationReducer, initialState);

    const { floorInfo, selectedFloor, iotItemList, selectedItem, realTimeLog, selectedCategory } = state;

    const [statusModal, setStatusModal] = useState(false);
    const [errorImg, setErrorImg] = useState({});
    const [loadImg, setLoadImg] = useState([]);

    const canvasRef = useRef(L.canvasIconLayer({}));
    const widgetRef = useRef();
    const mapRef = useRef();

    useAsync({
        promise: fetchBookmarkList,
        immediate: true,
        resolve: response => {
            const { rows } = response;
            dispatch(setBookmarkList(rows));
        },
    });
    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });

    useAsync({
        promise: fetchIotItemList,
        keepState: true,
        fixedParam: { pageSize: SEARCH_PAGE_SIZE },
        resolve: response => {
            const { rows } = response;

            if (response.page === 1) {
                dispatch(setIotItemList({ iotItemList: rows, t }));
            } else {
                dispatch(setIotItemList({ iotItemList: iotItemList.concat(rows), t }));
            }
        },
    });

    useEffect(() => {
        let unionImgLength = Object.keys(errorImg).length + loadImg.length;
        if (unionImgLength === categoryList.length) {
            if (settings.floor) {
                dispatch(setSelectedFloor(settings.floor));
            }
        }
        return () => {
            if (markerRef.current && markerRef.current.length) {
                for (let i = 0, len = markerRef.current.length; len > i; i++) {
                    canvasRef.current.removeMarker(markerRef.current[i], true);
                }
            }
        };
    }, [errorImg, loadImg]);

    useEffect(() => {
        if (selectedFloor) {
            const floor = Object.keys(tagListByFloor).filter(key => key === selectedFloor);
            const tagList = tagListByFloor[floor];
            let resultTagList = [];
            if (tagList && tagList.length > 0 && settings.targetOption === 'ALL TARGET') {
                if (selectedCategory.length === 0) {
                    resultTagList = tagList;
                }
                if (tagList && tagList.length > 0 && settings.targetOption === 'SINGLE TARGET' && selectedItem) {
                    resultTagList = tagList.filter(tag => tag.target.targetId === selectedItem.value);
                }
                dispatch(
                    setRealTimeLog({
                        count: resultTagList.length,
                        data: resultTagList,
                    }),
                );
            }
        }
    }, [selectedFloor, tagListByFloor]);

    useEffect(() => {
        if (selectedFloor) {
            // 층 값 변경시, 기존 값 삭제
            if (markerRef.current && markerRef.current.length) {
                for (let i = 0, len = markerRef.current.length; len > i; i++) {
                    canvasRef.current.removeMarker(markerRef.current[i], true);
                }
                markerRef.current = null;
            }
            getGeofenceList({ floor: selectedFloor });
            const map = mapRef.current.leafletElement;
            if (map && settings.targetOption === 'ALL TARGET') {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        }
    }, [selectedFloor]);

    useEffect(() => {
        const { response } = getGeofenceInfo;
        if (response) {
            dispatch(setGeofenceInfo({ geofenceInfo: response.rows, geofenceInOutState }));
        }
    }, [getGeofenceInfo]);

    const handleModalToggle = () => {
        setStatusModal(!statusModal);
    };

    const markerRef = useRef();
    const { markerConfigValue } = useSelector(state => state.AppConfig);

    const createMarker = log => {
        const { defaultSize, minSize, maxSize } = markerConfigValue;

        const zoom = mapRef.current.leafletElement.getZoom();
        let size = defaultSize * Math.pow(1 / 2, 18 - zoom);
        size = Math.min(Math.max(size, minSize), maxSize);

        if (markerRef.current && markerRef.current.length) {
            for (let i = 0, len = markerRef.current.length; len > i; i++) {
                canvasRef.current.removeMarker(markerRef.current[i], true);
            }
            markerRef.current = null;
        }

        markerRef.current = log.data.reduce((acc, curr) => {
            const { location, target } = curr;
            let strLength = String(target.targetNum).length;
            let imgUrl = '';

            if (errorImg[target.categoryCode]) {
                imgUrl = errorMarker;
            } else {
                imgUrl = `${categoryToImg[target.categoryCode]}`;
            }

            let marker = L.marker([location.lat, location.lng], {
                icon: L.icon({
                    iconUrl: imgUrl,
                    iconSize: [size, size],
                    iconAnchor: [size / 2, size],
                    text: `${target.targetName}`,
                    textAnchor: [strLength / 2 + 10, -10],
                    textFont: '10px bold',
                }),
            });
            acc.push(marker);
            return acc;
        }, []);
        canvasRef.current.addMarkers(markerRef.current);
    };

    const customMarker = useCallback(
        log => {
            createMarker(log);
        },
        [realTimeLog],
    );

    useEffect(() => {
        if (mapRef.current && mapRef.current.leafletElement) {
            if (canvasRef.current && canvasRef.current._canvas && realTimeLog.count) {
                customMarker(realTimeLog);
            } else if (realTimeLog.count) {
                canvasRef.current.addTo(mapRef.current.leafletElement);
                // canvasRef.current.addOnClickListener(function (e, data) {
                //     const { lat, lng } = e.latlng;
                //     let selectMarkers = data.reduce((acc, curr, i) => {
                //         const { data } = curr;
                //         const { _latlng } = data;
                //
                //         if (lat === _latlng.lat && lng === _latlng.lng) {
                //             acc.push(curr);
                //         }
                //         return acc;
                //     }, []);
                //
                //     let latlng = [lat, lng];
                //     L.popup()
                //         .setLatLng(latlng)
                //         .setContent('<p>Hello world!<br />This is a nice popup.</p>')
                //         .openOn(mapRef.current.leafletElement);
                // });
            }
        }
    }, [realTimeLog]);

    return (
        <MassRealTimeLocationStatusDispatchContext.Provider value={dispatch}>
            <MassRealTimeLocationStatusContext.Provider value={state}>
                <WidgetCard
                    bodyClassName={'p-1'}
                    widgetInfo={widgetInfo}
                    ref={widgetRef}
                    subTitle={t('All Target')}
                    {...restProps}
                >
                    {categoryList.map(({ normalIconURL, categoryCode }, i) => {
                        return (
                            <img
                                key={categoryCode + i}
                                onLoad={() => {
                                    setLoadImg([...loadImg, categoryCode]);
                                }}
                                hidden
                                src={normalIconURL}
                                alt={'marker'}
                                onError={() => {
                                    setErrorImg({ ...errorImg, [categoryCode]: categoryCode });
                                }}
                            />
                        );
                    })}
                    <Map ref={mapRef}>
                        {settings.targetOption === 'ALL TARGET' ? (
                            <AllTarget widgetRef={widgetRef} mapRef={mapRef} />
                        ) : (
                            <SingleTarget mapRef={mapRef} />
                        )}

                        {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                            <RotatedImageOverlay
                                key={floorInfo.floorId}
                                url={floorInfo.imgURL}
                                deg={floorInfo.deg}
                                bounds={floorInfo.bounds}
                                onLoad={() => {
                                    const map = mapRef.current.leafletElement;
                                    if (map && realTimeLog.data[0] && settings.targetOption === 'SINGLE TARGET') {
                                        map.setView(realTimeLog.data[0].location.latLng);
                                    }
                                }}
                            />
                        )}
                    </Map>
                    <ConfirmModal
                        initModal={statusModal}
                        header={{ title: t('Search Result', 'Search') }}
                        toggleModal={handleModalToggle}
                        confirmText={
                            <Container className={'flex-center'} style={{ flexDirection: 'column', padding: '1rem' }}>
                                <Row className={'mb-1'}>
                                    {t('The item you selected can not be tracked because there is no signal.')}
                                </Row>
                                <Row className={'mb-1'}>
                                    {t('The item may be out of range or there might be a problem with the tag.')}
                                </Row>
                                <Row className={'mb-1'}>
                                    {t('If there is an issue, please contact your system administrator.')}
                                </Row>
                            </Container>
                        }
                        removeCancel={true}
                    />
                    {children}
                </WidgetCard>
            </MassRealTimeLocationStatusContext.Provider>
        </MassRealTimeLocationStatusDispatchContext.Provider>
    );
};

export default MassRealTimeLocationStatus;

import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useTranslation from '@hooks/useTranslation';
import { useAsync, useConfirmModal } from '@util/hooks';
import { setScreenList } from '@reducer/CustomMenu';
import { Modal, Label, Button, Select, SearchableSelect, TextInput } from '@components';
import cx from 'classnames';
import { widgetInfoExport } from '../../../../Components/Screen/util';
import { createScreenApi, fetchAuthGroupList, fetchScreenList, updateScreenApi } from '@api/menu';
import useValidationModal from '@hooks/useValidationModal';
import { INDOORPLUS_ERRORS } from '@util/errorCode';

const initScreenInfo = {
    order: 0,
    groupNumList: [],
};

const ScreenDetailPopup = ({
    initOpen = false,
    setModal,
    toggleModal = function () {
        initOpen = !initOpen;
    },
    screenInfo,
    history,
}) => {
    const dispatch = useDispatch();
    const t = useTranslation('Screen');
    const { originMenuList } = useSelector(state => state.CustomMenu);
    const { userInfo } = useSelector(state => state.UserInfo);
    const [groupList, setGroupList] = useState([]);

    const fileInputRef = useRef();
    const [updateScreenInfo, setUpdateScreenInfo] = useState(screenInfo ?? initScreenInfo);
    const [widgetInfo, setWidgetInfo] = useState(null);
    const [saveErrMsg, setSaveErrMsg] = useState(t('The request failed.', 'ErrorHandler'));

    const { Modal: ValidationModal, isPassValidation, toggleModal: toggleValidationModal } = useValidationModal({
        targetData: updateScreenInfo,
        validate: screenInfo => {
            const pathRegExp = /^[a-z|A-Z|0-9]+$/gim;
            const msgArr = [];
            if (!screenInfo.screenCode || !screenInfo.screenCode.trim()) {
                msgArr.push(t('Please enter a screen ID'));
            }
            if (
                screenInfo.screenCode &&
                screenInfo.screenCode.trim() &&
                !pathRegExp.test(screenInfo.screenCode.trim())
            ) {
                msgArr.push(t('Screen ID can only contain English upper/lower case letters and numbers.'));
            }
            if (!screenInfo.screenName || !screenInfo.screenName.trim()) {
                msgArr.push(t('Please enter a screen name'));
            }
            if (!screenInfo.menuCode) {
                msgArr.push(t('Please select a display menu'));
            }
            return msgArr;
        },
    });

    const saveSuccessHandler = () => {
        getScreenList();
        setUpdateScreenInfo(initScreenInfo);
        toggleModal();
    };

    const { Modal: SaveSuccessModal, toggleModal: toggleSaveSuccessModal } = useConfirmModal({
        confirmText: screenInfo
            ? t('The screen information change request was successful.')
            : t('The screen information registration request was successful.'),
        okCallback: saveSuccessHandler,
        cancelCallback: saveSuccessHandler,
    });

    const { Modal: SaveFailureModal, toggleModal: toggleSaveFailureModal } = useConfirmModal({
        confirmText: saveErrMsg,
        okCallback: () => {},
        cancelCallback: () => {},
    });

    useEffect(() => {
        const initMergedScreenInfo = { ...initScreenInfo, ...screenInfo };
        if (originMenuList && originMenuList.length && !initMergedScreenInfo.menuCode) {
            initMergedScreenInfo.menuCode = originMenuList[0].menuCode;
        }
        setUpdateScreenInfo(initMergedScreenInfo);
        return () => {
            setUpdateScreenInfo(initScreenInfo);
        };
    }, [screenInfo, initOpen, originMenuList]);

    useAsync({
        promise: fetchAuthGroupList,
        immediate: true,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            setGroupList(res.rows ?? []);
        },
        reject: err => {
            console.log({ err });
            setGroupList([]);
        },
    });

    const { promise: getScreenList } = useAsync({
        promise: fetchScreenList,
        fixedParam: { isAll: 'Y', groupNum: userInfo.authGroupNum },
        resolve: res => {
            dispatch(setScreenList(res.rows ?? []));
        },
        reject: err => {
            console.log({ err });
            dispatch(setScreenList([]));
        },
    });

    const saveResolveHandler = () => {
        toggleSaveSuccessModal();
    };

    const saveErrHandler = ({ data }) => {
        if (data && data.code === INDOORPLUS_ERRORS.EXISTED_RESOURCE && data.errorResponse.includes('screenCode')) {
            setSaveErrMsg(t('The same screen ID exists. Please change the screen ID.'));
        } else {
            setSaveErrMsg(t('The request failed.', 'ErrorHandler'));
        }
        toggleSaveFailureModal();
    };

    const { promise: createScreen } = useAsync({
        promise: createScreenApi,
        resolve: saveResolveHandler,
        reject: saveErrHandler,
    });
    const { promise: updateScreen } = useAsync({
        promise: updateScreenApi,
        resolve: saveResolveHandler,
        reject: saveErrHandler,
    });

    const handleSubmitClick = () => {
        const newScreenInfo = updateScreenInfo;
        if (isPassValidation()) {
            newScreenInfo.screenCode = newScreenInfo.screenCode.trim();
            newScreenInfo.screenName = newScreenInfo.screenName.trim();

            if (screenInfo) {
                updateScreen(newScreenInfo);
            } else {
                createScreen({ ...newScreenInfo, widgetInfo });
            }
        } else {
            toggleValidationModal();
        }
    };

    const handleCancelButtonClick = () => {
        setUpdateScreenInfo(initScreenInfo);
        toggleModal();
    };

    const { Modal: UploadFileResultModal, toggleModal: toggleUploadFileResultModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Files other than JSON cannot be uploaded.', 'ConfirmModal'),
    });

    // 파일 업로드
    // 파일 선택 시 파일 명 상태 저장, json 외의 파일 선택 시 파일 명 초기화 및 확인 모달창
    const handleFile = async selected => {
        const { files } = selected.target;
        if (files[0] && files[0].type === 'application/json') {
            const filenameArr = files[0].name.split('.');
            const filename = filenameArr[0];
            setUpdateScreenInfo(prevState => {
                return { ...prevState, screenName: filename, show: 'Y' };
            });
            let text = await files[0].text();
            setWidgetInfo(text);
        } else if (files[0] && files[0].type !== 'application/json') {
            setWidgetInfo(null);
            setUpdateScreenInfo(initScreenInfo);
            fileInputRef.current.value = '';
            toggleUploadFileResultModal();
        } else {
            setWidgetInfo(null);
            setUpdateScreenInfo(initScreenInfo);
            fileInputRef.current.value = '';
        }
    };

    useEffect(() => {
        if (!updateScreenInfo.groupNumList || !updateScreenInfo.groupNumList.length) {
            setUpdateScreenInfo(prevState => ({
                ...prevState,
                groupNumList: groupList.map(group => group.groupNum),
            }));
        }
    }, [updateScreenInfo.groupNumList]);

    return (
        <>
            <Modal
                initModal={initOpen}
                toggleModal={toggleModal}
                headerTitle={t('Screen detail')}
                modalFooter={
                    <div className={'modal-footer'}>
                        <Button className={'btn-lightgray'} onClick={handleCancelButtonClick}>
                            {t('Cancel', 'ConfirmModal')}
                        </Button>
                        <Button className={'btn-secondary'} onClick={handleSubmitClick}>
                            {t('OK', 'ConfirmModal')}
                        </Button>
                    </div>
                }
            >
                <div className={'modal-body'}>
                    <div className={'flx-col gap-4 pnt-label-5'}>
                        {!screenInfo && (
                            <Label
                                name={t('Import')}
                                value={
                                    <div className="pnt-file--group w-100">
                                        <input
                                            type="file"
                                            id="dashboardFile"
                                            onChange={handleFile}
                                            accept="application/json"
                                            ref={fileInputRef}
                                        />
                                        <label htmlFor="dashboardFile">
                                            <span className="material-icons-round">folder_copy</span>
                                            {t('Upload File', 'Button')}
                                        </label>
                                    </div>
                                }
                            />
                        )}
                        <Label
                            name={t('Screen ID')}
                            value={
                                <>
                                    <TextInput
                                        disabled={!!screenInfo?.screenCode}
                                        inputGroupClassName={cx('w-100', 'form-must')}
                                        type={'text'}
                                        name={'screenCode'}
                                        id={'screenCode'}
                                        placeholder={''}
                                        maxlength={25}
                                        value={updateScreenInfo.screenCode || ''}
                                        handleChange={e => {
                                            const { value } = e.target;
                                            setUpdateScreenInfo(prevState => {
                                                return { ...prevState, screenCode: value };
                                            });
                                        }}
                                    />
                                </>
                            }
                        />
                        <Label
                            name={t('Screen Name')}
                            value={
                                <>
                                    <TextInput
                                        inputGroupClassName={cx(screenInfo ? 'w-75' : 'w-100', 'form-must')}
                                        type={'text'}
                                        name={'screenName'}
                                        id={'screenName'}
                                        placeholder={''}
                                        maxlength={25}
                                        value={updateScreenInfo.screenName || ''}
                                        handleChange={e => {
                                            const { value } = e.target;
                                            setUpdateScreenInfo(prevState => {
                                                return { ...prevState, screenName: value };
                                            });
                                        }}
                                    />
                                    {screenInfo && (
                                        <Button
                                            className={'btn-brand ml-2'}
                                            onClick={() => {
                                                const { screenName, widgetInfo } = screenInfo;
                                                widgetInfoExport({
                                                    filename: screenName,
                                                    exportWidgetInfo: widgetInfo,
                                                });
                                            }}
                                        >
                                            {t('Export', 'Button')}
                                        </Button>
                                    )}
                                </>
                            }
                        />

                        <Label
                            name={t('Screen Description')}
                            value={
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    type={'text'}
                                    name={'description'}
                                    id={'description'}
                                    placeholder={''}
                                    maxlength={100}
                                    value={updateScreenInfo.description || ''}
                                    handleChange={e => {
                                        const { value } = e.target;
                                        setUpdateScreenInfo(prevState => {
                                            return { ...prevState, description: value };
                                        });
                                    }}
                                />
                            }
                        />

                        <Label
                            name={t('Order')}
                            value={
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    type={'number'}
                                    id={'order'}
                                    handleChange={e => {
                                        const { value } = e.target;
                                        if (value > 100) {
                                            setUpdateScreenInfo(prevState => {
                                                return { ...prevState, order: 100 };
                                            });
                                        } else {
                                            setUpdateScreenInfo(prevState => {
                                                return { ...prevState, order: value };
                                            });
                                        }
                                    }}
                                    name={'order'}
                                    value={updateScreenInfo.order || 0}
                                    min={0}
                                    max={100}
                                />
                            }
                        />

                        <Label
                            name={t('Display Menu')}
                            value={
                                <Select
                                    className={'react-select w-100'}
                                    valueKey={'menuCode'}
                                    labelKey={'menuName'}
                                    value={(originMenuList || []).find(
                                        menu => menu.menuCode === updateScreenInfo.menuCode,
                                    )}
                                    options={originMenuList}
                                    customControlStyles={{ minWidth: '100%' }}
                                    customMenuStyles={{ minWidth: '100%' }}
                                    onChange={selected => {
                                        setUpdateScreenInfo(prevState => ({
                                            ...prevState,
                                            menuCode: selected.menuCode,
                                        }));
                                    }}
                                    isModalSelect
                                />
                            }
                        />

                        <Label
                            name={t('Authority')}
                            value={
                                <SearchableSelect
                                    className={'w-100'}
                                    title={t('Authority')}
                                    valueKey={'groupNum'}
                                    labelKey={'groupName'}
                                    selected={updateScreenInfo.groupNumList}
                                    data={groupList}
                                    onChange={selected => {
                                        setUpdateScreenInfo(prevState => ({
                                            ...prevState,
                                            groupNumList: selected.map(group => group.groupNum),
                                        }));
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            </Modal>
            <UploadFileResultModal />
            <ValidationModal />
            <SaveSuccessModal />
            <SaveFailureModal />
        </>
    );
};

export default withRouter(ScreenDetailPopup);

import React from 'react';
import language from '@util/staticData/language.json';
import Select from '@components/Select';

/**
 * @param {string} value 선택한 언어
 * @param {function} onChange 언어 변경 함수
 *
 * @author created bu 정진범 2022-12-08
 * */
const LanguageSelect = ({ value, onChange }) => {
    const selected = language.find(item => item.value === value);
    return (
        <Select
            customControlStyles={{
                width: '9rem',
            }}
            value={selected}
            options={language}
            onChange={onChange}
            isModalSelect
            // customSelectedValue={true}
        />
    );
};

export default LanguageSelect;

import React, { useEffect, useRef } from 'react';
import { useTranslation } from '@hooks';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import { Button, DatePicker } from '../../../Common';
import moment from 'moment';
import useConfirmModal from '@hooks/useConfirmModal';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';

/**
 * 위치로그 저장이력 검색기능 컴포넌트
 *
 * @param {function} promise 위치로그 저장이력 리스트 호출 callback
 * @param {number} startDate 검색 시작 일자 unixTime
 * @param {number} endDate  검색 마지막 일자 unixTime
 * @param {function} handleDateCallback  날짜 변경 함수
 *
 * @author jinbeom-jung
 * */
const Search = ({ promise, startDate, endDate, handleDateCallback }) => {
    const t = useTranslation('Location Log List');

    const handleSearchClick = () => {
        const param = {};
        if (startDate) {
            if (!endDate) {
                toggleWarningModal();
                return false;
            } else {
                param['startDate'] = moment(startDate).unix();
                param['endDate'] = moment(endDate).unix();
            }
        }
        promise(param);
    };

    const handleRefreshClick = () => {
        handleDateCallback({ selected: 0, type: 'reset' });
        promise({
            startDate: moment().startOf('day').unix(),
            endDate: moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').unix(),
        });
    };

    const handleYesterdayButtonClick = () => {
        handleDateCallback({ selected: 0, type: 'yesterday' });
    };
    const { Modal: WarningModal, toggleModal: toggleWarningModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Please select an end date', 'ConfirmModal'),
    });
    const handleStartDate = selected => {
        if (endDate > selected) {
            handleDateCallback({ selected, type: 'startDate' });
        }
    };
    const handleEndDate = selected => {
        if (startDate < selected) {
            handleDateCallback({ selected, type: 'endDate' });
        }
    };

    const inputStartRef = useRef(null);
    const inputEndRef = useRef(null);
    useEffect(() => {
        if (inputStartRef && inputStartRef.current) {
            inputStartRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
        if (inputEndRef && inputEndRef.current) {
            inputEndRef.current
                .querySelector('.react-datepicker__input-container>input')
                .setAttribute('readonly', true);
        }
    }, [startDate, endDate]);

    return (
        <>
            <FilterSearchGroup className={'p-0'}>
                <SearchGroup>
                    <SelectGroup>
                        <div className="datePicker-container" ref={inputStartRef}>
                            <DatePicker
                                key={`dp_start_${startDate}_${endDate}`}
                                value={startDate}
                                valueType="ms"
                                maxDate={endDate}
                                handleChange={handleStartDate}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className="text-center">~</div>
                    <SelectGroup>
                        <div className="datePicker-container" ref={inputEndRef}>
                            <DatePicker
                                key={`dp_end_${startDate}_${endDate}`}
                                value={endDate}
                                valueType="ms"
                                minDate={startDate}
                                maxDate={moment().valueOf()}
                                disabled={!startDate}
                                handleChange={handleEndDate}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <Button className="btn-gray" onClick={handleYesterdayButtonClick}>
                        {t('Yesterday', 'Button')}
                    </Button>
                    <Button className="btn-secondary" onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                    <Button className="btn-line btn-icon-only" iconName={'refresh'} onClick={handleRefreshClick} />
                </SearchGroup>
            </FilterSearchGroup>
            <WarningModal />
        </>
    );
};

export default Search;

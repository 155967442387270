import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '@hooks';
import NoDataBlock from '../Components/NoDataBlock';
import ReactResizeDetector from 'react-resize-detector';
import Charts from '@components/Charts/Highcharts';

const CHART_OPTION = {
    chart: {
        type: 'column',
        marginBottom: 50,
        backgroundColor: 'transparent',
    },
    xAxis: {
        categories: [],
        labels: {
            enabled: false,
        },
    },
    yAxis: {
        min: 0,
        softMax: 1,
        minTickInterval: 1,
        title: {
            text: '',
        },
    },
    title: null,
    plotOptions: {
        column: {
            dataLabels: {
                enabled: false,
            },
        },
        series: {
            showInLegend: true,
        },
    },
    legend: {
        align: 'center',
        verticalAlign: 'bottom',
        symbolRadius: 0,
        itemHiddenStyle: {
            color: '#77777C',
        },
        itemHoverStyle: {
            color: null,
        },
        itemStyle: {
            color: 'black',
        },
    },
    series: [
        {
            name: '',
            data: [
                {
                    name: '',
                    y: 0,
                },
            ],
        },
    ],
    credits: {
        enabled: false,
    },
};

const TriggeredAlertStatusChart = ({ data }) => {
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const [chartOptions, setChartOptions] = useState(CHART_OPTION);
    const [containerProps, setContainerProps] = useState({
        style: {
            width: 0,
            height: 0,
        },
    });

    const onResize = useCallback((width, height) => {
        setContainerProps({
            style: {
                width,
                height,
            },
        });
    }, []);

    useEffect(() => {
        const chartArr = data.map((e, i) => {
            return {
                name: e.name,
                data: [e.count],
            };
        });

        setChartOptions(prevState => ({
            ...prevState,
            xAxis: {
                ...prevState.xAxis,
                categories: data.map(e => e.key),
            },
            series: chartArr,
        }));
    }, [data]);

    useEffect(() => {
        setChartOptions(prevState => ({
            ...prevState,
            legend: {
                ...prevState.legend,
                itemStyle: {
                    color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                },
            },
            xAxis: {
                ...prevState.xAxis,
                labels: {
                    style: {
                        ...prevState.xAxis.labels.style,
                        color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                    },
                },
            },
        }));
    }, [colorScheme]);

    return (
        <>
            {!!data && data.length ? (
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={100}
                    handleHeight
                    handleWidth
                    onResize={onResize}
                >
                    <Charts reflowDeps={[containerProps]} containerProps={containerProps} options={chartOptions} />
                </ReactResizeDetector>
            ) : (
                <NoDataBlock />
            )}
        </>
    );
};

export default TriggeredAlertStatusChart;

import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { FeatureGroup, Popup } from 'react-leaflet';
import { useSettings } from '../util/useSettings';
import { useSelector } from 'react-redux';
import { fetchGeofenceList } from '@api/common';
import { AniMarker, Button, ConfirmModal, Label, Map, RotatedImageOverlay } from '../../Common';
import { useAsync, useTranslation } from '@hooks';
import L from 'leaflet';
import { fetchCategory, fetchIotItemList } from '@api/asset';
import SingleTarget from './Components/SingleTarget';
import AllTarget from './Components/AllTarget';
import realTimeLocationReducer, {
    initialState,
    setCategoryGroup,
    setCategoryImg,
    setCategoryList,
    setFloorGroup,
    setGeofenceInfo,
    setIotItemList,
    setRealTimeLog,
    setSelectedItem,
} from './realTimeLocationReducer';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Container, Row } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import FilterSearchGroup from '../../MainPages/Components/FilterSearchGroup';
import { FilterList, InputGroup } from '../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchGroup from '../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import useGroupFloor from './useGroupFloor';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { convertState } from '@reducer/TagInfo/processSocketData';
import { isIn } from '@util/mappInfo';
import useBookmarkedList from '@hooks/commonData/useBookmarkedList';
import cx from 'classnames';
import WidgetCard from '../Components/WidgetCard';

export const RealTimeLocationStatusContext = React.createContext();
export const RealTimeLocationStatusDispatchContext = React.createContext();

const SEARCH_PAGE_SIZE = 20;

const RealTimeLocationStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('RealTimeLocationStatus');
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const { geofenceInOutState } = useSelector(state => state.TagInfo);
    const { representationGroupInfo } = useSelector(state => state.UserInfo.userInfo);
    const [state, dispatch] = useReducer(realTimeLocationReducer, initialState);
    const { floorInfo, selectedFloor, iotItemList, selectedItem, realTimeLog, bookmark, selectedCategory } = state;
    const bookmarkedList = useBookmarkedList();

    const [search, setSearch] = useState(false);
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [searchTargetName, setSearchTargetName] = useState('');
    const [statusModal, setStatusModal] = useState(false);
    const [zoom, setZoom] = useState(null);

    const mapRef = useRef();
    const widgetRef = useRef();
    const match = useRouteMatch();
    const currFloorTags = useRef({});

    useAsync({
        promise: fetchCategory,
        fixedParam: { isAll: 'Y' },
        resolve: response => {
            const { rows } = response;

            const tempCategoryList = [{ value: '', label: t('All Asset') }];
            const tempCategoryForImg = {};
            if (rows && Array.isArray(rows)) {
                rows.sort((a, b) => (a.categoryCodePath > b.categoryCodePath ? 1 : -1));
                const parentCodes = rows.map(category => category.parentCode);
                rows.forEach(category => {
                    tempCategoryForImg[category.categoryCode] = category.normalIconURL;
                    if (!parentCodes.includes(category.categoryCode)) {
                        tempCategoryList.push(category);
                    }
                });
                tempCategoryList[0].value = tempCategoryList
                    .slice(1)
                    .map(category => category.categoryCode)
                    .join(',');
            }
            dispatch(setCategoryList(rows));
            dispatch(setCategoryGroup(rows));
            dispatch(setCategoryImg(tempCategoryForImg));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });
    const { promise: getIotItemList, state: iotItemInfo } = useAsync({
        promise: fetchIotItemList,
        keepState: true,
        fixedParam: { pageSize: SEARCH_PAGE_SIZE },
        resolve: response => {
            setSearch(true);
            const { rows } = response;

            if (response.page === 1) {
                dispatch(setIotItemList({ iotItemList: rows, t }));
            } else {
                // console.log(iotItemList.concat(rows));
                dispatch(setIotItemList({ iotItemList: iotItemList.concat(rows), t }));
            }
        },
    });

    useEffect(() => {
        if (selectedItem?.value) {
            if (currFloorTags.current[selectedItem.value]) {
                currFloorTags.current = { [selectedItem.value]: currFloorTags.current[selectedItem.value] };
            } else {
                currFloorTags.current = {};
            }
        } else {
            for (const [tagNum, tagInfo] of Object.entries(currFloorTags.current)) {
                if (
                    (selectedCategory.length && !selectedCategory.includes(tagInfo.targetCategory.categoryCode)) ||
                    (bookmark && !bookmarkedList.includes(tagInfo.target.targetNum))
                ) {
                    currFloorTags.current[tagNum] = null;
                    delete currFloorTags.current[tagNum];
                }
            }
        }
        const tagList = Object.values(currFloorTags.current);
        dispatch(
            setRealTimeLog({
                count: tagList.length,
                data: tagList,
            }),
        );
    }, [selectedCategory, bookmark, bookmarkedList, selectedItem]);

    useEffect(() => {
        currFloorTags.current = {};
        dispatch(setRealTimeLog({ count: 0, data: [] }));

        if (selectedFloor) {
            getGeofenceList({ floor: selectedFloor });
            const map = mapRef.current.leafletElement;
            if (map && settings.targetOption === 'ALL TARGET') {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        } else {
            dispatch(setGeofenceInfo({ geofenceInfo: [] }));
        }
    }, [selectedFloor]);

    useEffect(() => {
        const { response } = getGeofenceInfo;
        if (response) {
            dispatch(setGeofenceInfo({ geofenceInfo: response.rows, geofenceInOutState }));
        }
    }, [getGeofenceInfo]);

    const socketFilterConfig = useMemo(() => {
        const resultConfig = {};

        if (selectedItem?.value) {
            resultConfig.target = { targetId: selectedItem.value };
        } else {
            if (selectedFloor) {
                resultConfig.$or = [
                    { currentFloor: { floorId: selectedFloor } },
                    { previousFloor: { floorId: selectedFloor } },
                ];
            } else {
                resultConfig.currentFloor = { noneFloor: true };
            }
            if (selectedCategory && selectedCategory.length) {
                resultConfig.targetCategory = {
                    categoryCode: {
                        $in: selectedCategory,
                    },
                };
            }
            if (bookmark) {
                resultConfig.target = { targetNum: { $in: bookmarkedList } };
            }
        }
        return resultConfig;
    }, [selectedFloor, selectedCategory, bookmark, bookmarkedList, selectedItem]);

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        handler: data => {
            // console.log('realtime data', data);
            const {
                target,
                targetState,
                currentFloor,
                targetCategory: { categoryCode },
                latlng: { lat, lng },
            } = data;
            const { lostSignal } = convertState(targetState);
            if (!lostSignal && socketFilterConfig?.$or?.[0]?.currentFloor?.floorId === currentFloor.floorId) {
                currFloorTags.current[target.targetNum] = {
                    ...data,
                    target: { ...target, categoryCode: categoryCode },
                    location: {
                        lat,
                        lng,
                        latLng: [lat, lng],
                    },
                    floorInOutState: {
                        floorId: currentFloor.floorId,
                        floorName: currentFloor.floorName,
                        isIn: isIn(currentFloor.inOutState),
                        inTime: currentFloor.inUnixTime,
                        stayTime: currentFloor.stayUnixTime,
                        outTime: currentFloor.outUnixTime,
                    },
                };
            } else if (currFloorTags.current[target.targetNum]) {
                currFloorTags.current[target.targetNum] = null;
                delete currFloorTags.current[target.targetNum];
            }

            const tagList = Object.values(currFloorTags.current);
            dispatch(
                setRealTimeLog({
                    count: tagList.length,
                    data: tagList,
                }),
            );
        },
        filterConfig: socketFilterConfig,
    });

    useGroupFloor({
        handleChangeFloor: floorInfo => {
            if (floorInfo?.upperFloorId) {
                dispatch(setFloorGroup(floorInfo));
            }
        },
    });

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleModalToggle = () => {
        setStatusModal(!statusModal);
    };

    return (
        <RealTimeLocationStatusDispatchContext.Provider value={dispatch}>
            <RealTimeLocationStatusContext.Provider value={state}>
                <WidgetCard
                    bodyClassName={'p-1'}
                    widgetInfo={widgetInfo}
                    ref={widgetRef}
                    subTitle={
                        settings.targetOption === 'ALL TARGET'
                            ? t('All Target')
                            : settings.targetOption === 'SINGLE TARGET' && !selectedItem.hasOwnProperty('label')
                            ? t('Please Search For An Item Under The Search Area') + t('(Single Target)')
                            : selectedItem.label
                    }
                    headerAction={
                        settings.targetOption === 'SINGLE TARGET' && (
                            <>
                                <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                                    {t('Search', 'Button')}
                                </Button>
                            </>
                        )
                    }
                    searchFilter={
                        settings.targetOption === 'SINGLE TARGET' &&
                        showSearchArea && (
                            <FilterSearchGroup className={'card absolute-filter'}>
                                <SearchGroup label={t('Search', 'AssetCountStatus')}>
                                    <FilterList>
                                        <InputGroup>
                                            <AsyncTypeahead
                                                inputProps={{ className: 'pnt-input' }}
                                                isLoading={iotItemInfo.isLoading}
                                                placeholder={t('Search Item Name or Item ID')}
                                                paginationText={t('Display additional results...', 'Search')}
                                                emptyLabel={
                                                    !search
                                                        ? t('Please Search for the Item.')
                                                        : t('There are no search results', 'Search')
                                                }
                                                id="targetId"
                                                multiple={false}
                                                allowNew={false}
                                                options={iotItemList}
                                                maxResults={SEARCH_PAGE_SIZE - 1}
                                                onChange={selected => {
                                                    const item = selected[0];
                                                    if (
                                                        item &&
                                                        item.label &&
                                                        item.label.split('> ')[1] === t('Signal Not Detected')
                                                    ) {
                                                        dispatch(setSelectedItem({}));
                                                        setStatusModal(true);
                                                    } else {
                                                        dispatch(setSelectedItem(item));
                                                    }
                                                }}
                                                onInputChange={query => {
                                                    setSearchTargetName(query);
                                                }}
                                                paginate
                                                clearButton={true}
                                                onPaginate={(e, shownResults) => {
                                                    const page = Math.ceil(shownResults / SEARCH_PAGE_SIZE);
                                                    getIotItemList({
                                                        page,
                                                        opt: 'targetSearch',
                                                        keyword: searchTargetName,
                                                    });
                                                }}
                                                onSearch={() => {
                                                    getIotItemList({
                                                        opt: 'targetSearch',
                                                        keyword: searchTargetName,
                                                    });
                                                }}
                                                useCache={false}
                                            />
                                        </InputGroup>
                                    </FilterList>
                                </SearchGroup>
                            </FilterSearchGroup>
                        )
                    }
                    {...restProps}
                >
                    <Map
                        ref={mapRef}
                        onZoomend={e => {
                            setZoom(e.target.getZoom());
                        }}
                    >
                        {settings.targetOption === 'ALL TARGET' ? (
                            <AllTarget widgetRef={widgetRef} mapRef={mapRef} />
                        ) : (
                            <SingleTarget mapRef={mapRef} />
                        )}

                        {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                            <RotatedImageOverlay
                                key={floorInfo.floorId}
                                url={floorInfo.imgURL}
                                deg={floorInfo.deg}
                                bounds={floorInfo.bounds}
                                onLoad={() => {
                                    const map = mapRef.current.leafletElement;
                                    if (map) {
                                        if (realTimeLog.data[0] && settings.targetOption === 'SINGLE TARGET') {
                                            map.setView(realTimeLog.data[0].location.latLng);
                                        } else if (
                                            representationGroupInfo?.floorId &&
                                            representationGroupInfo.floorId === floorInfo.floorId &&
                                            representationGroupInfo.lat &&
                                            representationGroupInfo.lng
                                        ) {
                                            map.setView(
                                                [representationGroupInfo.lat, representationGroupInfo.lng],
                                                representationGroupInfo.zoom,
                                            );
                                        }
                                    }
                                }}
                            />
                        )}

                        <FeatureGroup>
                            {realTimeLog.data.map(log => {
                                const { target } = log;
                                return (
                                    <AniMarker key={target.targetNum} targetInfo={log} zoom={zoom}>
                                        <Popup attribution={{ autoClose: false }}>
                                            <Label
                                                name={target.targetNum}
                                                value={target.targetName}
                                                labelValueClassName={'label-dot'}
                                            />
                                        </Popup>
                                    </AniMarker>
                                );
                            })}
                        </FeatureGroup>
                    </Map>

                    <ConfirmModal
                        initModal={statusModal}
                        header={{ title: t('Search Result', 'Search') }}
                        toggleModal={handleModalToggle}
                        confirmText={
                            <Container className={'flex-center'} style={{ flexDirection: 'column', padding: '1rem' }}>
                                <Row className={'mb-1'}>
                                    {t('The item you selected can not be tracked because there is no signal.')}
                                </Row>
                                <Row className={'mb-1'}>
                                    {t('The item may be out of range or there might be a problem with the tag.')}
                                </Row>
                                <Row className={'mb-1'}>
                                    {t('If there is an issue, please contact your system administrator.')}
                                </Row>
                            </Container>
                        }
                        removeCancel={true}
                    />
                    {children}
                </WidgetCard>
            </RealTimeLocationStatusContext.Provider>
        </RealTimeLocationStatusDispatchContext.Provider>
    );
};

export default RealTimeLocationStatus;

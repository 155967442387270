import React, { useEffect, useReducer, useState } from 'react';
import { Button, GeofenceLayer, Map, RotatedImageOverlay } from '../../Common';
import Control from 'react-leaflet-control';
import hourlyCongestionStatusReducer, {
    initialState,
    setEndDate,
    setSelectedCategory,
    setSelectedFloor,
    setSelectedFloorInfo,
    setStartDate,
} from './hourlyCongestionStatusReducer';
import { useTranslation } from '@hooks';
import HourlyCongestionStatusFilterGroup from './Component/HourlyCongestionStatusFilterGroup';
import useAsync from '../../../util/hooks/useAsync';
import { fetchGeofenceList } from '@api/common';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { fetchEntryCountByHoursApi, fetchEntryCountInSelectedTimeRangeApi } from '@api/statistics';
import LineChartByTime from './Component/LineChartByTime';
import LineChartByDate from './Component/LineChartByDate';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import WidgetCard from '../Components/WidgetCard';

export const HourlyCongestionStatusContext = React.createContext();
export const HourlyCongestionStatusDispatchContext = React.createContext();
const entryCountFakeDataByDate = [
    {
        date: '31-16',
        visitCount: 8,
        uniqueCount: 5,
    },
    {
        date: '31-17',
        visitCount: 4,
        uniqueCount: 2,
    },
    {
        date: '09-08',
        visitCount: 3,
        uniqueCount: 1,
    },
];

const entryCountFakeDataByHour = [
    {
        time: '0',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '1',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '2',
        totalEntry: 10,
        uniqueEntry: 5,
    },
    {
        time: '3',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '4',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '5',
        totalEntry: 6,
        uniqueEntry: 3,
    },
    {
        time: '6',
        totalEntry: 11,
        uniqueEntry: 3,
    },
    {
        time: '7',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '8',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '9',
        totalEntry: 17,
        uniqueEntry: 9,
    },
    {
        time: '10',
        totalEntry: 9,
        uniqueEntry: 4,
    },
    {
        time: '11',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '12',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '13',
        totalEntry: 8,
        uniqueEntry: 2,
    },
    {
        time: '14',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '15',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '16',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '17',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '18',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '19',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '20',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '21',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '22',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '23',
        totalEntry: 0,
        uniqueEntry: 0,
    },
];

const HourlyCongestionStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('Button');
    const [state, dispatch] = useReducer(hourlyCongestionStatusReducer, initialState);
    const { selectedCategory, selectedFloor, selectedFloorInfo, startDate, endDate } = state;
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [selectedGeofence, setSelectedGeofence] = useState({});
    const { floorList } = useSelector(state => state.FloorInfo);
    const { mode } = useSelector(state => state.ScreenInfo);
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [filterParam, setFilterParam] = useState({});
    const [entryInfoInTimeRange, setEntryInfoInTimeRange] = useState([]);
    const [entryInfoByHours, setEntryInfoByHours] = useState([]);
    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            if (res) {
                setGeofenceInfo(
                    res.rows.map(geofence => {
                        return {
                            ...geofence,
                            bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        };
                    }),
                );
            }
        },
    });

    const { promise: getEntryCountInTimeRange } = useAsync({
        promise: fetchEntryCountInSelectedTimeRangeApi,
        resolve: res => {
            let tempObj = [];
            if (res.uniqueMap && res.visitMap) {
                const visitMap = Object.entries(res.visitMap);
                const uniqueMap = Object.entries(res.uniqueMap);
                visitMap.forEach((date, index) => {
                    tempObj.push({
                        date: visitMap[index][0],
                        visitCount: visitMap[index][1],
                        uniqueCount: uniqueMap[index][1],
                    });
                });
            }

            setEntryInfoInTimeRange(tempObj);
        },
    });

    const { promise: getEntryCountByHours } = useAsync({
        promise: fetchEntryCountByHoursApi,
        resolve: res => {
            let tempObj = [];
            if (res.totalEntryMap && res.uniqueEntryMap) {
                const totalEntryMap = Object.entries(res.totalEntryMap);
                const uniqueEntryMap = Object.entries(res.uniqueEntryMap);
                totalEntryMap.forEach((time, index) => {
                    tempObj.push({
                        time: totalEntryMap[index][0],
                        totalEntry: totalEntryMap[index][1],
                        uniqueEntry: uniqueEntryMap[index][1],
                    });
                });
            }

            setEntryInfoByHours(tempObj);
        },
    });

    const handleRefreshClick = () => {
        dispatch(setSelectedCategory(''));
        dispatch(setSelectedFloor(floorList[0] ? floorList[0].floorId : ''));
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
        dispatch(setSelectedFloorInfo(floorList[0] ? floorList[0] : {}));
    };

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const handleSearchBtnClick = () => {
        getEntryCountInTimeRange({
            ...filterParam,
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
        });
        getEntryCountByHours({
            ...filterParam,
            startDate: startDate ? moment(startDate).unix() : null,
            endDate: endDate ? moment(endDate).unix() : null,
        });
        setShowSearchArea(false);
    };

    useEffect(() => {
        if (floorList[0]) {
            dispatch(setSelectedFloorInfo(floorList[0]));
            getGeofenceList({ floor: floorList[0].floorId });
            getEntryCountInTimeRange({
                floorIds: floorList[0].floorId,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
            getEntryCountByHours({
                floorIds: floorList[0].floorId,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    }, []);

    useEffect(() => {
        if (selectedFloor) {
            getGeofenceList({ floor: selectedFloor });
            let getSelectedFloorInfo = floorList.find(floor => floor.floorId === selectedFloor);
            dispatch(setSelectedFloorInfo(getSelectedFloorInfo));
        }
    }, [selectedFloor]);

    useEffect(() => {
        if (selectedFloor) {
            getEntryCountInTimeRange({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
            getEntryCountByHours({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
            getGeofenceList({ floor: selectedFloor });
        }
    }, [filterParam]);

    useEffect(() => {
        const paramInfo = {};
        if (selectedCategory) {
            paramInfo.categoryCode = selectedCategory.value;
        }
        if (selectedFloor) {
            paramInfo.floorIds = selectedFloor;
        }
        if (selectedGeofence.fcNum) {
            paramInfo.fcNums = String(selectedGeofence.fcNum);
        }
        setFilterParam(paramInfo);
    }, [selectedCategory, selectedFloor, selectedGeofence]);

    return (
        <HourlyCongestionStatusDispatchContext.Provider value={dispatch}>
            <HourlyCongestionStatusContext.Provider value={state}>
                <WidgetCard
                    widgetInfo={widgetInfo}
                    {...restProps}
                    headerAction={
                        <>
                            <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                                {t('Search', 'Button')}
                            </Button>
                            <div className="pnt-border border-h" />
                            <Button
                                className="btn-lightgray btn-icon-only"
                                iconName="refresh"
                                onClick={handleRefreshClick}
                            />
                        </>
                    }
                    searchFilter={
                        showSearchArea && (
                            <HourlyCongestionStatusFilterGroup handleSearchBtnClick={handleSearchBtnClick} />
                        )
                    }
                >
                    <div className={'d-grid h-100'} style={{ gridTemplateRows: 'auto 350px 350px', rowGap: '15px' }}>
                        <div className={'h-100'}>
                            <Map>
                                {selectedFloorInfo && selectedFloorInfo.floorName && (
                                    <Control position="topleft" className={'control-container'}>
                                        {/*<div className={'item-container'}>*/}
                                        {/*    <span className={'map-text'}>{selectedFloorInfo.floorName}</span>*/}
                                        {/*</div>*/}
                                        <Button className={'pnt-nameplate leaflet-map-nameplate bg-gray bg-d-3'}>
                                            <span className={'material-icons-round'}>info_outline</span>
                                            {selectedFloorInfo.floorName}
                                        </Button>
                                    </Control>
                                )}
                                {selectedFloorInfo.imgURL && (
                                    <RotatedImageOverlay
                                        key={selectedFloorInfo.floorId}
                                        url={selectedFloorInfo.imgURL}
                                        deg={selectedFloorInfo.deg}
                                        bounds={selectedFloorInfo.bounds}
                                        onLoad={e => {
                                            const { target: layer } = e;
                                            layer._map.fitBounds(layer.getBounds());
                                        }}
                                    />
                                )}
                                {geofenceInfo.length > 0 && (
                                    <GeofenceLayer
                                        selectedGeofence={selectedGeofence}
                                        geofenceList={geofenceInfo}
                                        handleClick={geofence => {
                                            geofence.fcNum === selectedGeofence.fcNum
                                                ? setSelectedGeofence({})
                                                : setSelectedGeofence(geofence);
                                        }}
                                    />
                                )}
                            </Map>
                        </div>
                        <LineChartByDate
                            dataForEntryCount={
                                mode === SCREEN_MODE_EDIT ? entryCountFakeDataByDate : entryInfoInTimeRange
                            }
                        />
                        <LineChartByTime
                            dataForCongestion={mode === SCREEN_MODE_EDIT ? entryCountFakeDataByHour : entryInfoByHours}
                        />
                    </div>

                    {children}
                </WidgetCard>
            </HourlyCongestionStatusContext.Provider>
        </HourlyCongestionStatusDispatchContext.Provider>
    );
};

export default HourlyCongestionStatus;

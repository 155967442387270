import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { Button, Card, Label, QuestionTooltip, SearchableSelect, TextInput } from '@components';
import { FilterDetailDispatchContext, FilterDetailStateContext } from '../index';
import { setEditMode, updateFilter } from '../reducer';
import {
    defaultDynamicSelectFilterInfo,
    defaultFilterInfo,
    staticFilterInfo,
} from '../../../../Components/FilterWidget/Option';
import { createFilterApi, editFilterApi } from '@api/filter';
import FilterGenerator from '../../../../Components/FilterWidget/FilterGenerator';
import { useSelector } from 'react-redux';
import envKeys from '../../../../../../environment';

const { serviceCode } = envKeys;

const DropdownButton = ({ onClick }) => {
    const t = useTranslation('Filter');
    return (
        <Button className={'btn-secondary btn-icon'} iconName="add" onClick={onClick}>
            {t('Add filter')}
        </Button>
    );
};

const EditView = () => {
    const history = useHistory();
    const { filterNum } = useParams();
    const dispatch = useContext(FilterDetailDispatchContext);
    const { editing } = useContext(FilterDetailStateContext);
    const t = useTranslation('Filter');
    const buttonT = useTranslation('Button');
    const [validationMsg, setValidationMsg] = useState([]);
    const { searchableCategoryPropertiesList, topCategoryList } = useSelector(state => state.CategoryInfo);

    const handleSelectValueChange = selected => {
        const selectedCondition = selected.reduce((acc, curr) => {
            const conditionId = curr.value;
            const existFilter = editing.filterInfoCondition.find(filter => filter.conditionId === conditionId);
            if (existFilter) {
                acc.push(existFilter);
            } else {
                let addedFilterInitValue = defaultFilterInfo.find(filter => filter.conditionId === conditionId);
                if (!addedFilterInitValue) {
                    const categoryProp = searchableCategoryPropertiesList.find(prop => prop.propertyId === conditionId);
                    if (categoryProp) {
                        addedFilterInitValue = {
                            ...defaultDynamicSelectFilterInfo,
                            conditionId: categoryProp.propertyId,
                        };
                    }
                }

                if (addedFilterInitValue) {
                    acc.push(addedFilterInitValue);
                }
            }
            return acc;
        }, []);
        dispatch(updateFilter({ filterInfoCondition: selectedCondition }));
    };

    const saveSuccessCallback = () => {
        toggleSaveSuccessModal();
    };

    const { promise: createFilter } = useAsync({
        promise: createFilterApi,
        resolve: saveSuccessCallback,
        reject: () => {
            toggleSaveFailModal();
        },
    });

    const { promise: editFilter } = useAsync({
        promise: editFilterApi,
        resolve: saveSuccessCallback,
        reject: () => {
            toggleSaveFailModal();
        },
    });

    const { Modal: ConfirmSaveModal, toggleModal: toggleConfirmSaveModal } = useConfirmModal({
        confirmText: filterNum ? t('Do you want to save information?') : t('Do you want to create filter?'),
        okCallback: () => {
            const validation = checkValidation(editing);
            const { filterInfoAccessRight, ...restFilterInfo } = editing;
            if (validation) {
                if (filterNum) {
                    editFilter({ ...restFilterInfo, serviceCode });
                } else {
                    createFilter({ ...restFilterInfo, serviceCode });
                }
            } else {
                toggleValidationModal();
            }
        },
    });

    const { Modal: ValidationModal, toggleModal: toggleValidationModal } = useConfirmModal({
        header: { title: t('Validation Fail') },
        confirmText: (
            <>
                {validationMsg.map((msg, i) => {
                    return (
                        <li key={`validation_${i}`}>
                            <span className="pnt-label--group">
                                <div
                                    className="label-main label-dot w-100"
                                    style={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                                >
                                    {msg}
                                </div>
                            </span>
                        </li>
                    );
                })}
            </>
        ),
    });

    const { Modal: SaveSuccessModal, toggleModal: toggleSaveSuccessModal } = useConfirmModal({
        confirmText: filterNum ? t('Filter is changed successfully') : t('Filter is created successfully'),
        okCallback: () => {
            history.push('/setting/filter/list');
        },
    });

    const { Modal: SaveFailModal, toggleModal: toggleSaveFailModal } = useConfirmModal({
        confirmText: t('The request failed.', 'ErrorHandler'),
    });

    const checkValidation = filterInfo => {
        const errMsg = [];

        const { filterName, description, categoryCodes, filterInfoCondition } = filterInfo;

        if (filterName && filterName.length > 15) {
            errMsg.push(t('Filter name should be under 15 characters'));
        }
        if (!filterName || !filterName.trim().length) {
            errMsg.push(t('Filter name is required'));
        }
        if (!description || !description.length) {
            errMsg.push(t('Filter description is required'));
        }
        if (description && description.length > 100) {
            errMsg.push(t('Filter description should be under 100 characters'));
        }
        if (!categoryCodes) {
            errMsg.push(t('Category is required'));
        }
        if (filterInfoCondition.length === 0) {
            errMsg.push(t('At least one filter should be selected'));
        }

        setValidationMsg(errMsg);

        return !errMsg.length;
    };

    const filterConditionList = useMemo(() => {
        return [
            ...staticFilterInfo.map(v => ({
                value: v.conditionId,
                label: t(v.name),
            })),
            ...searchableCategoryPropertiesList.reduce((acc, curr) => {
                if (editing.categoryCodes === curr.categoryCode) {
                    acc.push({ value: curr.propertyId, label: curr.displayName });
                }
                return acc;
            }, []),
        ];
    }, [editing.categoryCodes, staticFilterInfo, searchableCategoryPropertiesList]);

    useEffect(() => {
        if (editing.categoryCodes) {
            const selectableConditionList = filterConditionList.map(condition => condition.value);
            dispatch(
                updateFilter({
                    filterInfoCondition: editing.filterInfoCondition.filter(condition =>
                        selectableConditionList.includes(condition.conditionId),
                    ),
                }),
            );
        }
    }, [filterConditionList]);

    return (
        <>
            <Card
                className="h-100"
                header={{
                    title: `${t('Filter')} - ${filterNum ? t('Edit') : t('Create')}`,
                    titleIcon: filterNum ? 'info' : 'edit',
                    action: (
                        <>
                            <Button
                                className="btn-gray"
                                onClick={() => {
                                    if (filterNum) {
                                        dispatch(setEditMode(false));
                                    } else {
                                        history.goBack();
                                    }
                                }}
                            >
                                {buttonT('Cancel')}
                            </Button>
                            <Button className={'btn-icon btn-brand'} iconName="save" onClick={toggleConfirmSaveModal}>
                                {buttonT('Save')}
                            </Button>
                        </>
                    ),
                }}
                bodyClassName={'overflow-visible'}
            >
                <Label
                    name={t('Name')}
                    labelGroupClassName={'mb-3'}
                    labelValueClassName={'mr-5'}
                    value={
                        <TextInput
                            inputGroupClassName={'form-must'}
                            name={'filterName'}
                            value={editing.filterName}
                            placeholder={t('Please enter a name for the filter')}
                            handleChange={e => dispatch(updateFilter({ filterName: e.target.value }))}
                        />
                    }
                />
                <Label
                    name={t('Description')}
                    labelGroupClassName={'mb-3'}
                    labelValueClassName={'mr-5'}
                    value={
                        <TextInput
                            name={'description'}
                            value={editing.description}
                            placeholder={t('Please enter a description')}
                            handleChange={e => dispatch(updateFilter({ description: e.target.value }))}
                        />
                    }
                />
                <div className="pnt-divider horizon-line mb-3" />
                <Label
                    name={
                        <>
                            {t('Filter Type')}
                            <QuestionTooltip
                                contents={t(
                                    'If more than one filter type is selected, category property filters are disabled and selected category property filters are deleted.',
                                )}
                            />
                        </>
                    }
                    labelGroupClassName={'mb-3'}
                    labelValueClassName={'mr-5 text-nowrap'}
                    value={
                        <SearchableSelect
                            title={t('Category')}
                            data={topCategoryList}
                            selected={(editing.categoryCodes ?? '').split(',')}
                            onChange={selected => {
                                dispatch(
                                    updateFilter({
                                        categoryCodes: selected.map(category => category.value).join(','),
                                    }),
                                );
                            }}
                        />
                    }
                />
                <Label
                    name={t('Filter setting')}
                    labelGroupClassName={'mb-3'}
                    labelValueClassName={'mr-5'}
                    value={
                        <div className={'d-flex justify-content-between w-100 align-items-center'}>
                            <div className={'d-flex'} style={{ columnGap: '0.5rem' }}>
                                <FilterGenerator
                                    filterEditMode
                                    filterInfo={editing}
                                    handleChange={(selected, isEditable, conditionInfo) => {
                                        dispatch(
                                            updateFilter({
                                                filterInfoCondition: editing.filterInfoCondition.map(filter => {
                                                    if (filter.conditionId === conditionInfo.conditionId) {
                                                        return {
                                                            ...filter,
                                                            isEditable,
                                                            conditionValues: selected,
                                                        };
                                                    }
                                                    return filter;
                                                }),
                                            }),
                                        );
                                    }}
                                />
                            </div>
                            <SearchableSelect
                                keepSortOrder
                                className={'align-right'}
                                data={filterConditionList}
                                selected={editing.filterInfoCondition.map(v => v.conditionId)}
                                onChange={handleSelectValueChange}
                                DropdownControlComponent={DropdownButton}
                            />
                        </div>
                    }
                />
                <div className="pnt-divider horizon-line mb-3" />
            </Card>
            <ConfirmSaveModal />
            <ValidationModal />
            <SaveSuccessModal />
            <SaveFailModal />
        </>
    );
};

export default EditView;

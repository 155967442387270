import React from 'react';
import Card from '../Components/WidgetCard';
import { Row } from 'reactstrap';
import { fetchTagBatteryCount } from '../../../api/asset';
import useMonitor from '../util/useMonitor';
import { useTranslation } from '@hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useFilter from '@hooks/useFilter';
import { Table } from '@components';
import { useBatteryStatusColumns } from './Components/useBatteryStatusColumns';

const defaultData = {
    0: 0,
    10: 0,
    30: 0,
    60: 0,
    80: 0,
    unknown: 0,
};

const Battery = ({ level, IconText, data }) => {
    const t = useTranslation('TagBatteryStatus');
    return (
        <div className={'bg-depth-6 p-3 flx-row gap-0 align-center'}>
            <div>
                <span className="material-icons-round" style={{ fontSize: '60px' }}>
                    {IconText}
                </span>
            </div>
            <div className="flx-col">
                <div className="flx-row gap-1">
                    <p className="pnt-txt txt-bold s-3">{data}</p>
                    <p className="pnt-txt s-6">{t('Count')}</p>
                </div>
                <div className="flx-row  opacity-8 gap-1">
                    <p className="pnt-txt s-8 txt-bold">{level}</p>
                    {IconText !== 'battery_unknown' && <p className="pnt-txt s-9 opacity-8">(%)</p>}
                </div>
            </div>
        </div>
    );
};

const TagBatteryStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('TagBatteryStatus');
    const filterParam = useFilter();

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchTagBatteryCount,
        makeData: ({ newData }) => {
            const batteryCountData = newData.rows;

            let batteryStatus = {
                0: 0,
                10: 0,
                30: 0,
                60: 0,
                80: 0,
                unknown: 0,
            };

            if (batteryCountData && batteryCountData.length > 0) {
                for (let i = 0; i < batteryCountData.length; i++) {
                    switch (batteryCountData[i].division) {
                        case '0':
                            batteryStatus[0] += batteryCountData[i].count;
                            break;
                        case '10':
                        case '20':
                            batteryStatus[10] += batteryCountData[i].count;
                            break;
                        case '30':
                        case '40':
                        case '50':
                            batteryStatus[30] += batteryCountData[i].count;
                            break;
                        case '60':
                        case '70':
                            batteryStatus[60] += batteryCountData[i].count;
                            break;
                        case '80':
                        case '90':
                            batteryStatus[80] += batteryCountData[i].count;
                            break;
                        case 'unknown':
                            batteryStatus['unknown'] += batteryCountData[i].count;
                            break;
                        default:
                            break;
                    }
                }
            }

            return batteryStatus;
        },
    });

    const tagBatteryColumn = useBatteryStatusColumns();

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <Row className={'grid-container item-grid-200 mb-3'}>
                        <Battery level={'100 ~ 80'} data={data[80]} IconText={'battery_full'} />
                        <Battery level={'60 ~ 79'} data={data[60]} IconText={'battery_5_bar'} />
                        <Battery level={'30 ~ 59'} data={data[30]} IconText={'battery_4_bar'} />
                        <Battery level={'10 ~ 29'} data={data[10]} IconText={'battery_2_bar'} />
                        <Battery level={'0 ~ 9'} data={data[0]} IconText={'battery_1_bar'} />
                        <Battery level={t('Unknown')} data={data['unknown']} IconText={'battery_unknown'} />
                    </Row>
                    <div style={{ height: '67px' }}>
                        {t('Current / Count')}
                        <Table data={{ rows: [data] }} columns={tagBatteryColumn} paging={false} />
                    </div>
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default TagBatteryStatus;

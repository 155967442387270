import React from 'react';
import { Table } from '@components';
import { useColumns } from '@hooks';
import * as column from '../../../MainPages/util/grid/column';

/**
 *
 * 스캐너 상태 로그 테이블 생성 컴포넌트
 *
 * @param {Object} list 스캐너 상태 로그 리스트 데이터
 * @param {Object} state 스캐너 상태 로그 리스트 API 호출 상태`값
 * @param {function} promise 스캐너 상태 로그 리스트 호출 callback
 *
 * @author jinbeom-jung
 * */

const List = ({ list, state, promise }) => {
    const columns = useColumns([
        column.number(),
        column.scannerName(),
        column.macAddress(),
        column.startDate(),
        column.endDate(),
    ]);

    const handlePageChange = pageIndex => {
        const param = state.request;
        let result = {};
        for (let key in param) {
            if (param[key]) {
                result[key] = param[key];
            }
        }
        promise({ ...result, page: pageIndex });
    };

    return (
        <div style={{ height: '35rem' }}>
            <Table
                data={{ ...list, pageSize: 15 }}
                columns={columns}
                onPageChange={handlePageChange}
                loading={state.isLoading}
            />
        </div>
    );
};

export default List;

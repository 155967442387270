import React, { createContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import Button from '@components/Button';
import { LoadingBlock } from '@components/LoadingBlock';
import RefrigeratorSensorCard from './Components/RefrigeratorSensorCard/RefrigeratorSensorCard';

import useTranslation from '@hooks/useTranslation';
import reducer, {
    initialState,
    setRefrigeratorSensorList,
    startUpdatingData,
    updateEachRefrigeratorSensorList,
} from './refrigeratorSensorReducer';

import useAsync from '@hooks/useAsync';
import { getRefrigeratorSensorListApi } from '@api/monitoring';
import RefrigeratorSensorDailyLog from './Components/RefrigeratorSensorLog/RefrigeratorSensorDailyLog';
import RefrigeratorSensorMonthlyLog from './Components/RefrigeratorSensorLog/RefrigeratorSensorMonthlyLog';
import ViewDetail from './Components/ViewDetail/ViewDetail';
import useFilter from '@hooks/useFilter';
import { useDispatch } from 'react-redux';
import { hideGlobalIntervalTimeDot, resetGlobalIntervalTimeDot } from '@reducer/Common/GlobalIntervalTime';
import WidgetCard from '../Components/WidgetCard';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';

export const RefrigeratorSensorStateContext = createContext(null);
export const RefrigeratorSensorDispatchContext = createContext(null);

export const REFRIGERATOR_DASHBOARD_TYPE = {
    LIST: 'LIST',
    DETAIL: 'DETAIL',
    DAILY: 'DAILY',
    MONTHLY: 'MONTHLY',
};
const CATEGORY_CODE = 'refrigerator';

const RefrigeratorSensor = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('RefrigeratorSensor');
    const buttonT = useTranslation('Button');
    const filterParams = useFilter();
    const [state, dispatch] = useReducer(reducer, initialState);
    const storeDispatch = useDispatch();
    const widgetRef = useRef();

    const { promise: getRefrigeratorSensorList, state: getRefrigeratorSensorListState } = useAsync({
        promise: getRefrigeratorSensorListApi,
        resolve: res => {
            const { rows } = res;
            dispatch(setRefrigeratorSensorList(rows));
        },
        reject: err => console.error(err),
    });

    // 냉장고 센서 리스트 새로고침
    const handleRefresh = () => {
        getRefrigeratorSensorList({
            ...filterParams,
            categoryCodes: CATEGORY_CODE,
        });
    };
    const socketFilterConfig = useMemo(() => {
        return {
            targetCategory: {
                categoryCode: {
                    $in: [CATEGORY_CODE],
                },
            },
        };
    }, []);

    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        filterConfig: socketFilterConfig,
        handler: data => {
            dispatch(updateEachRefrigeratorSensorList(data));
        },
    });

    // 대시보드 타입 handling
    const [dashboardType, setDashboardType] = useState(REFRIGERATOR_DASHBOARD_TYPE.LIST);
    const handleDashboardType = dashboardType => {
        setDashboardType(dashboardType);
    };

    // dashboard type에 따른 컴포넌트 분기
    const widgetContent = {
        [REFRIGERATOR_DASHBOARD_TYPE.DETAIL]: <ViewDetail handleRefresh={handleRefresh} />,
        [REFRIGERATOR_DASHBOARD_TYPE.DAILY]: <RefrigeratorSensorDailyLog />,
        [REFRIGERATOR_DASHBOARD_TYPE.MONTHLY]: <RefrigeratorSensorMonthlyLog />,
        [REFRIGERATOR_DASHBOARD_TYPE.LIST]: (
            <RefrigeratorSensorCard handleDashboardType={handleDashboardType} refreshCallback={handleRefresh} />
        ),
    };
    // 필터정보 변경 후 api 호출
    useEffect(() => {
        getRefrigeratorSensorList({
            ...filterParams,
            categoryCodes: CATEGORY_CODE,
        });
    }, [filterParams]);

    // 전역 타이머 사용 여부
    useEffect(() => {
        storeDispatch(hideGlobalIntervalTimeDot()); // interval time dot 미사용
        return () => {
            storeDispatch(resetGlobalIntervalTimeDot()); // interval time dot default
        };
    }, []);

    return (
        <RefrigeratorSensorDispatchContext.Provider value={dispatch}>
            <RefrigeratorSensorStateContext.Provider value={state}>
                <WidgetCard
                    ref={widgetRef}
                    widgetInfo={widgetInfo}
                    headerAction={
                        <>
                            {dashboardType === REFRIGERATOR_DASHBOARD_TYPE.LIST ? (
                                <Button
                                    className="btn-line"
                                    onClick={() => handleDashboardType(REFRIGERATOR_DASHBOARD_TYPE.DAILY)}
                                >
                                    {t('Daily Report')}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        className="btn-gray"
                                        onClick={() => handleDashboardType(REFRIGERATOR_DASHBOARD_TYPE.LIST)}
                                    >
                                        {buttonT('Back')}
                                    </Button>
                                    {dashboardType === REFRIGERATOR_DASHBOARD_TYPE.DETAIL && (
                                        <Button
                                            className={'btn-brand'}
                                            disabled={state.isUpdatingData}
                                            onClick={() => dispatch(startUpdatingData())}
                                        >
                                            {buttonT('Save')}
                                        </Button>
                                    )}
                                </>
                            )}
                        </>
                    }
                    {...restProps}
                >
                    <LoadingBlock blocking={getRefrigeratorSensorListState.isLoading}>
                        {widgetContent[dashboardType]}
                    </LoadingBlock>
                    {children}
                </WidgetCard>
            </RefrigeratorSensorStateContext.Provider>
        </RefrigeratorSensorDispatchContext.Provider>
    );
};

export default RefrigeratorSensor;

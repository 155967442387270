import React, { forwardRef, useRef, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CHART_COLORS } from './config';

Highcharts.setOptions({
    colors: CHART_COLORS,
});

interface Props extends HighchartsReact.Props {
    reflowDeps?: any[];
}

const LIBRARY = { accessibility: { enabled: false } };

const Charts = (
    { reflowDeps, containerProps, ...restProps }: Props,
    ref: React.ForwardedRef<HighchartsReact.RefObject>,
) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const memoReflowDeps = useMemo(() => {
        return Array.isArray(reflowDeps) ? reflowDeps : [containerProps];
    }, [reflowDeps, containerProps]);

    useEffect(() => {
        if (typeof ref === 'function') {
            return;
        }
        (ref ?? chartComponentRef).current?.chart?.reflow();
    }, memoReflowDeps);

    return (
        <HighchartsReact
            ref={ref ?? chartComponentRef}
            highcharts={Highcharts}
            library={LIBRARY}
            containerProps={containerProps}
            {...restProps}
        />
    );
};

export default forwardRef(Charts);

import React from 'react';

export const filterName = ({ ...restProps } = {}) => {
    return {
        Header: 'Filter Name',
        accessor: 'filterName',
        width: 100,
        ...restProps,
    };
};

export const filterDescription = ({ ...restProps } = {}) => {
    return {
        Header: 'Filter Description',
        className: 'd-flex flx-start',
        accessor: 'description',
        ...restProps,
    };
};

export const owner = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Owner',
        accessor: 'groupNamesForOwner',
        Cell: ({ value }) => {
            if (value === '-1') {
                return <span>{t('All users')}</span>;
            }
            return <span>{value}</span>;
        },
        ...restProps,
    };
};

export const accessRights = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Access rights',
        accessor: 'groupNamesForAccessRight',
        Cell: ({ value }) => {
            if (value === '-1') {
                return <span>{t('All users')}</span>;
            } else {
                return <span>{value}</span>;
            }
        },
        ...restProps,
    };
};
export const category = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Category',
        accessor: 'categoryNamePath',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const name = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Name',
        accessor: 'targetName',
        ...restProps,
    };
};

export const num = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Number',
        accessor: 'targetNum',
        ...restProps,
    };
};

export const itemOwner = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Owner',
        accessor: 'properties',
        Cell: ({ value }) => {
            if (value.pic_id) {
                return <span>{value.pic_id}</span>;
            } else {
                return <span></span>;
            }
        },
        ...restProps,
    };
};

export const location = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Location',
        accessor: 'floorNamePath',
        ...restProps,
    };
};

export const userName = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Name',
        width: 100,
        accessor: 'userName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userID = ({ t, ...restProps } = {}) => {
    return {
        Header: 'ID',
        accessor: 'userID',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userTeam = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Team',
        accessor: 'groupName',
        ...restProps,
    };
};

export const departmentName = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Name',
        width: 100,
        accessor: 'groupName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const departmentID = ({ t, ...restProps } = {}) => {
    return {
        Header: 'ID',
        accessor: 'groupId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const departmentPath = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Department path',
        accessor: 'groupNamePath',
        className: 'text-ellipsis',
        ...restProps,
    };
};

import React, { useCallback, useEffect, useState } from 'react';
import NoDataBlock from '../../Components/NoDataBlock';
import { useAppSelector } from '@hooks';
// @ts-ignore
import ReactResizeDetector from 'react-resize-detector';
import Charts from '@components/Charts/Highcharts';

const CHART_OPTIONS = {
    chart: {
        type: 'pie',
        backgroundColor: null,
    },
    title: '',
    plotOptions: {
        pie: {
            allowPointSelect: false,
            borderWidth: 0,
        },
        series: {
            dataLabels: {
                enabled: false,
            },
            showInLegend: true,
            // states: {
            //     hover: {
            //         enabled: false,
            //     },
            //     inactive: {
            //         opacity: 1,
            //     },
            // },
        },
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        floating: true,
        squareSymbol: false,
        symbolRadius: 0,
        itemMarginBottom: 10,
    },
    series: [
        {
            name: '',
            data: [
                {
                    name: '',
                    y: 0,
                },
            ],
        },
    ],
    credits: {
        enabled: false,
    },
};

interface ChartData {
    ranking: number;
    key: string;
    name: string;
    value: number;
}

interface Props {
    data: ChartData[];
    tableHeight: string;
}
const GeofenceCongestionPieChart = ({ data, tableHeight }: Props) => {
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const [chartOptions, setChartOptions] = useState(CHART_OPTIONS);
    const [containerProps, setContainerProps] = useState({
        style: {
            width: 0,
            height: 0,
        },
    });

    useEffect(() => {
        setChartOptions(prevState => ({
            ...prevState,
            legend: {
                ...prevState.legend,
                itemStyle: {
                    color: colorScheme === 'pnt-dark' ? 'white' : 'black',
                },
            },
        }));
    }, [colorScheme]);

    useEffect(() => {
        const chartData = data.map(v => ({
            name: v.name,
            y: v.value,
        }));

        setChartOptions(prevState => ({
            ...prevState,
            series: [{ name: '', data: chartData }],
        }));
    }, [data]);

    const onResize = useCallback((width: number, height: number) => {
        setContainerProps({
            style: {
                width,
                height,
            },
        });
    }, []);

    return (
        <>
            {!data.length ? (
                <NoDataBlock />
            ) : (
                <ReactResizeDetector
                    refreshMode="debounce"
                    refreshRate={100}
                    handleHeight
                    handleWidth
                    onResize={onResize}
                >
                    <Charts
                        reflowDeps={[containerProps, tableHeight]}
                        containerProps={containerProps}
                        options={chartOptions}
                    />
                </ReactResizeDetector>
            )}
        </>
    );
};

export default GeofenceCongestionPieChart;

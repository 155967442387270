import React, { useContext, useEffect, useMemo, useState } from 'react';
import { VitalSignsMonitoringDispatchContext, VitalSignsMonitoringStateContext } from '../index';
import NoDataBlock from '../../Components/NoDataBlock';
import { useSelector } from 'react-redux';
import { convertToWorkTimeCheckList, storeVitalSignList } from '../vitalSignsMonitoringReducer';
import useFilter from '@hooks/useFilter';
import useAsync from '@hooks/useAsync';
import { getVitalSignsMonitoringListApi } from '@api/monitoring';
import useMonitor from '../../util/useMonitor';
import VitalSignsRoomList from './VitalSignsRoomList';

// 선택한 담당시간 서버 요청 파라메터로 변경
const convertToWorkTimeFilterParams = workTimeCheckList => {
    const currentWorkTimeParams = {};
    workTimeCheckList.forEach((item, idx) => {
        if (!item.checked) {
            return;
        }
        currentWorkTimeParams[`worktimeList[${idx}].workTimeType`] = item.workTimeType;
        currentWorkTimeParams[`worktimeList[${idx}].startDate`] = item.startDate;
        currentWorkTimeParams[`worktimeList[${idx}].endDate`] = item.endDate;
    });
    return currentWorkTimeParams;
};

/**
 * 병실/병상, 환자 상태 리스트 컴포넌트
 *
 * @param config useMonitor config
 *
 * @since 2023-02-07
 * @author kunwoong-kim
 * */
const VitalSignsList = ({ config }) => {
    // 전역 모니터링 플래그 with redux
    const { monitoring, globalIntervalTime } = useSelector(state => state.GlobalIntervalTime);
    const { vitalSignsList, workTimeList } = useContext(VitalSignsMonitoringStateContext);
    const dispatch = useContext(VitalSignsMonitoringDispatchContext);

    const filterParams = useFilter();
    const [workTimeFilterParams, setWorkTimeFilterParams] = useState([]);

    const mergedParams = useMemo(() => {
        const nowWorkTimeCheckList = convertToWorkTimeCheckList(workTimeList.origin).map((item, idx) => {
            return {
                ...item,
                checked: workTimeList.current[idx].checked,
            };
        });
        return {
            ...filterParams,
            ...convertToWorkTimeFilterParams(nowWorkTimeCheckList),
        };
    }, [workTimeFilterParams, filterParams]);

    // 활력징후 List 조회
    const { promise: getVitalSignsMonitoringList } = useAsync({
        promise: getVitalSignsMonitoringListApi,
        param: mergedParams,
        resolve: res => {
            dispatch(storeVitalSignList(res));
        },
    });

    // 활력징후 모니터링
    const { stop: vitalSignListMonitorStop } = useMonitor({
        config,
        dynamicParam: mergedParams,
        fetchData: getVitalSignsMonitoringList,
        updateParamsBeforeRequest: ({ beforeParams }) => {
            // api 요청 전 현재시간 갱신
            const nowWorkTimeCheckList = convertToWorkTimeCheckList(workTimeList.origin).map((item, idx) => {
                return {
                    ...item,
                    checked: workTimeList.current[idx].checked,
                };
            });
            // 요청 파라메터로 변경
            const updateWorkTimeParams = convertToWorkTimeFilterParams(nowWorkTimeCheckList);
            return {
                ...beforeParams,
                ...updateWorkTimeParams,
            };
        },
        makeData: ({ newData }) => {
            dispatch(storeVitalSignList(newData));
        },
    });

    // 최신 담당 시간 및 필터 변경시 담당시간 요청 파라메터 갱신
    useEffect(() => {
        setWorkTimeFilterParams(convertToWorkTimeFilterParams(workTimeList.current));
    }, [filterParams, workTimeList.current]);

    useEffect(() => {
        // 모니터링 '갱신안함'의 경우
        if (!monitoring || globalIntervalTime === 0) {
            getVitalSignsMonitoringList();
        }
    }, [mergedParams]);

    useEffect(() => {
        return () => {
            vitalSignListMonitorStop();
        };
    }, []);

    // 활력징후 데이터 없는 경우
    if (vitalSignsList && !!!Object.entries(vitalSignsList).length) {
        return <NoDataBlock />;
    }
    // 렌더링을 위한 Entry
    const vitalSignsEntries = Object.entries(vitalSignsList);

    return (
        <div className="grid-container item-grid-300">
            {vitalSignsEntries.map(([wardNum, rooms], idx) => (
                <VitalSignsRoomList
                    key={`${wardNum}_${idx}`}
                    wardNum={wardNum}
                    roomList={rooms}
                    handleRefreshData={getVitalSignsMonitoringList}
                />
            ))}
        </div>
    );
};

export default VitalSignsList;

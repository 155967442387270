import React, { useEffect, useState } from 'react';

import { Col } from 'reactstrap';

import Modal from '@components/Modal';
import LanguageSelect from './Components/LanguageSelect';
import ThemeSwatch from './Components/ThemeSwatch';
import AlarmSetting from './Components/AlarmSetting';

import useTranslation from '@util/hooks/useTranslation';
import { useAsync } from '@hooks';
import { fetchUserSetting, updateUserSetting } from '@api/common';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_COLOR_SCHEME, setColorScheme } from '@reducer/ThemeOptions';
import { getLsUserInfo, setLsUserInfo } from '@util/common/util';
import { DEFAULT_LANGUAGE, setLanguage } from '@reducer/UserInfo';
import { DEFAULT_ALARM_TYPE, setAlarmType } from '@reducer/Notification';
import Label from '@components/Label';

/**
 @param initModal  // 모달 상탯값
 @param toggleModal  // 모달 함수

 @author created by 정진범 2022-12-08
 **/
const COLOR_OPTIONS = ['pnt-navy', 'pnt-dark'];

const PersonalizationModal = ({ initModal, toggleModal }) => {
    const t = useTranslation('UserBox');

    const dispatch = useDispatch();
    const lang = useSelector(state => state.UserInfo.lang);
    const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_LANGUAGE);
    const handleSelectedLanguage = selected => {
        const { value } = selected;
        setSelectedLanguage(value);
    };

    const colorScheme = useSelector(state => state.ThemeOptions.colorScheme);
    const [selectedColorScheme, setSelectedColorScheme] = useState(DEFAULT_COLOR_SCHEME);
    const handleSelectedColorScheme = selected => setSelectedColorScheme(selected);

    const alarmType = useSelector(state => state.Notification.alarmType);
    const [selectedAlarm, setSelectedAlarm] = useState(DEFAULT_ALARM_TYPE);
    const handleSelectedAlarmSetting = selected => setSelectedAlarm(selected);

    useEffect(() => {
        setSelectedLanguage(lang);
        setSelectedColorScheme(colorScheme);
        setSelectedAlarm(alarmType);
    }, [initModal]);

    const { promise: getConfigSetting } = useAsync({
        promise: fetchUserSetting,
        resolve: res => {
            const { oAuthInfo, userInfo } = getLsUserInfo();
            const { alertType: alarmType, propValue: colorTheme, description: lang } = res;
            userInfo.alertType = alarmType;
            userInfo.propValue = colorTheme;
            userInfo.lang = lang;
            setLsUserInfo({ oAuthInfo, userInfo });

            dispatch(setLanguage(lang));
            dispatch(setColorScheme(colorTheme));
            dispatch(setAlarmType(alarmType));
        },
        reject: err => console.error(err),
    });

    const { promise: updateTheme } = useAsync({
        promise: updateUserSetting,
        resolve: () => {
            getConfigSetting({
                propId: 'color',
            });
        },
        reject: err => console.error(err),
    });

    const childrenComponentList = [
        {
            title: 'Language selection',
            Component: <LanguageSelect value={selectedLanguage} onChange={handleSelectedLanguage} />,
        },
        {
            title: 'Choose color scheme',
            Component: (
                <ThemeSwatch
                    option={COLOR_OPTIONS}
                    onClick={handleSelectedColorScheme}
                    selectedColorScheme={selectedColorScheme}
                />
            ),
        },
        {
            title: 'Alarm settings',
            Component: <AlarmSetting value={selectedAlarm} onChange={handleSelectedAlarmSetting} />,
        },
    ];

    return (
        <Modal
            initModal={initModal}
            okCallback={updateTheme}
            toggleModal={toggleModal}
            callbackParam={{
                propId: 'color',
                propName: '컬러',
                propValue: selectedColorScheme,
                description: selectedLanguage,
                alertType: selectedAlarm,
            }}
            headerTitle={t('Personalization')}
            removeCancel
        >
            <div className="modal-body py-4">
                {childrenComponentList.map(({ title, Component }, idx) => (
                    <Col
                        key={idx + '_' + title}
                        className={`${childrenComponentList.length !== idx + 1 ? 'mb-3' : ''}`}
                    >
                        <Label name={t(title)} value={Component} />
                    </Col>
                ))}
            </div>
        </Modal>
    );
};

export default PersonalizationModal;

import React, { useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from '@hooks';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { Label, ToggleButton } from '../../../Common';
import { setBookmark, setSelectedCategory } from '../realTimeLocationReducer';
import { RealTimeLocationStatusContext, RealTimeLocationStatusDispatchContext } from '../index';
import CheckboxTree from 'react-checkbox-tree';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

const ICON_CLASS = 'material-icons-round md-18';

const findTree = (tree, categoryCodePathArr, level = 0) => {
    const findNode = tree.find(v => v.categoryCode === categoryCodePathArr[level]);
    if (findNode && !findNode.children) {
        findNode.children = [];
    }
    return findNode ? findTree(findNode.children, categoryCodePathArr, ++level) : tree;
};

const makeTreeData = options => {
    const tempOptions = options;
    const treeData = [];
    if (tempOptions.length) {
        const categoryInfo = tempOptions[0];
        treeData.push({ ...categoryInfo, value: categoryInfo.categoryCodePath, label: categoryInfo.categoryName });
    }
    for (let i = 1, len = tempOptions.length; i < len; i++) {
        const option = tempOptions[i];
        const categoryCodePathArr = option.categoryCodePath.split('>');
        const parent = findTree(treeData, categoryCodePathArr);
        parent.push({ ...option, value: option.categoryCodePath, label: option.categoryName });
    }
    return treeData;
};

const FilteringPopup = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetRef,
    mapRef,
}) => {
    const t = useTranslation('RealTimeLocationStatus');
    const formRef = useRef();
    const { categoryList, bookmark } = useContext(RealTimeLocationStatusContext);
    const dispatch = useContext(RealTimeLocationStatusDispatchContext);

    const [expanded, setExpanded] = useState([]);
    const [checked, setChecked] = useState([]);
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - 221) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;
        setPosition({
            x: Math.ceil(x),
            y: Math.ceil(y),
        });
    }, []);

    useEffect(() => {
        // 초기 필터링에 카테고리를 전부 선택
        const defaultCheckedCategory = categoryList.map(({ categoryCodePath }) => {
            return categoryCodePath;
        });

        const defaultSelectedCategory = categoryList.map(({ categoryCode }) => {
            return categoryCode;
        });

        setChecked(defaultCheckedCategory);
        dispatch(setSelectedCategory(defaultSelectedCategory));
    }, [categoryList]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (widgetRef.current) {
            const widget = widgetRef.current;
            const { offsetWidth: width, offsetHeight: height } = widget;
            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    const handleCheckboxChange = e => {
        dispatch(setBookmark(e.currentTarget.checked));
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds=".leaflet-container"
            defaultPosition={position}
            onStop={() => mapRef.current.leafletElement.dragging.enable()}
            onStart={() => mapRef.current.leafletElement.dragging.disable()}
        >
            <Toast
                isOpen={modal}
                className={'popup-open filtering bg-depth-3'}
                onScroll={() => mapRef.current.leafletElement.scrollWheelZoom.disable()}
            >
                <ToastHeader className="bg-depth-4" toggle={toggleModal}>
                    {t('IoT Filtering')}
                </ToastHeader>
                <ToastBody className={'bg-depth-4'}>
                    <form ref={formRef}>
                        <div className="mb-3">
                            <Label
                                name={t('Bookmark Filtering')}
                                value={
                                    <ToggleButton
                                        className="form-h-small"
                                        checked={bookmark}
                                        handleChecked={handleCheckboxChange}
                                    />
                                }
                            />
                        </div>

                        <div>
                            <Label labelGroupClassName="mb-2" name={t('Category Filtering')} />
                            <StyledDiv className="scroll-area-sm filtering-container">
                                <PerfectScrollbar>
                                    <CheckboxTree
                                        nodes={makeTreeData(categoryList)}
                                        showNodeIcon={false}
                                        checked={checked}
                                        expanded={expanded}
                                        onCheck={(checkedNode, targetNode) => {
                                            setChecked(checkedNode);
                                            const checkedCategory = checkedNode.map(node => {
                                                if (node.includes('>')) {
                                                    const leaf = node.split('>');
                                                    return leaf[leaf.length - 1];
                                                } else {
                                                    return node;
                                                }
                                            });
                                            dispatch(setSelectedCategory(checkedCategory));
                                        }}
                                        onExpand={expanded => {
                                            setExpanded(expanded);
                                        }}
                                        icons={{
                                            check: <span className={`${ICON_CLASS} color-secondary`}>check_box</span>,
                                            uncheck: <span className={ICON_CLASS}>check_box_outline_blank</span>,
                                            halfCheck: (
                                                <span className={`${ICON_CLASS} color-secondary`}>
                                                    indeterminate_check_box
                                                </span>
                                            ),
                                            expandClose: <span className={ICON_CLASS}>keyboard_arrow_right</span>,
                                            expandOpen: <span className={ICON_CLASS}>keyboard_arrow_down</span>,
                                            expandAll: <span className={ICON_CLASS}>add_box</span>,
                                            collapseAll: <span className={ICON_CLASS}>indeterminate_check_box</span>,
                                            parentOpen: <span className={ICON_CLASS}>folder_open</span>,
                                            parentClose: <span className={ICON_CLASS}>folder</span>,
                                            leaf: <span className={ICON_CLASS}>insert_drive_file</span>,
                                        }}
                                    />
                                </PerfectScrollbar>
                            </StyledDiv>
                        </div>
                    </form>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

const StyledDiv = styled.div`
    background: transparent;
    width: 12rem;
`;

export default FilteringPopup;

import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    groupList: [],
    groupListInfo: { totalPage: 1, page: 1, rows: [] },
};

const { actions, reducer } = createSlice({
    name: 'authorizationGroupList',
    initialState,
    reducers: {
        setGroupList: (state, action) => {
            const groupList = action.payload || [];
            state.groupList = groupList;
            state.groupListInfo = { ...state.groupListInfo, rows: groupList };
        },
    },
});

export const { setGroupList } = actions;
export default reducer;

import React, { useContext, useEffect, useRef } from 'react';
import Slider from './Components/Slider';
import useAsync from '@hooks/useAsync';
import { putRefrigeratorSensorDetailApi } from '@api/monitoring';
import { RefrigeratorSensorDispatchContext, RefrigeratorSensorStateContext } from '../../../../index';
import { stopUpdatingData } from '../../../../refrigeratorSensorReducer';
import useTranslation from '@hooks/useTranslation';

const RangeSetting = ({ data, promise, handleRefresh }) => {
    const t = useTranslation('RefrigeratorSensor');
    const { sensorNum, isUpdatingData } = useContext(RefrigeratorSensorStateContext);
    const dispatch = useContext(RefrigeratorSensorDispatchContext);

    const sliderRef = useRef([]);
    const { sensorItems } = data;

    const { promise: updateRefrigeratorSensorDetail } = useAsync({
        promise: putRefrigeratorSensorDetailApi,
        resolve: () => {
            promise({ sensorNum });
            handleRefresh();
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (isUpdatingData) {
            const updateSensorItems = sensorItems.map((data, index) => {
                const [minRiskLimit, minControlLimit, maxControlLimit, maxRiskLimit] = sliderRef.current[
                    index
                ].state.bounds;
                const currentMinRiskLimit = data.minRangeLimit >= minRiskLimit ? minRiskLimit + 0.1 : minRiskLimit;
                const currentMaxRiskLimit = data.maxRangeLimit <= maxRiskLimit ? maxRiskLimit - 0.1 : maxRiskLimit;
                return {
                    ...data,
                    minRiskLimit: currentMinRiskLimit,
                    minControlLimit,
                    maxControlLimit,
                    maxRiskLimit: currentMaxRiskLimit,
                };
            });

            updateRefrigeratorSensorDetail({ sensorNum, ...data, sensorItems: updateSensorItems }).then(() =>
                dispatch(stopUpdatingData()),
            );
        }
    }, [isUpdatingData]);

    return (
        <table className="w-100 border-size-1 border-depth-7">
            <tbody>
                {sensorItems.map((data, i) => {
                    const {
                        sensorNum,
                        sensingType,
                        currentValue,
                        status,
                        minRangeLimit,
                        minRiskLimit,
                        minControlLimit,
                        maxControlLimit,
                        maxRiskLimit,
                        maxRangeLimit,
                        measurementUnit,
                    } = data;
                    const valueText = currentValue ? `${currentValue.toFixed(2)} ${measurementUnit}` : '-';
                    return (
                        <tr
                            key={`${sensorNum}_${sensingType}_${i}`}
                            className="text-center border-bottom border-depth-7"
                        >
                            <td className="px-3 py-2 bg-depth-4">
                                <span>{t(sensingType)}</span>
                            </td>
                            <td className="px-3 py-2 border-left border-depth-7">
                                <span>{valueText}</span>
                            </td>
                            <td className="px-3 py-2 border-left border-depth-7">
                                <span>{t(status)}</span>
                            </td>
                            <td className="px-3 py-2 border-left border-depth-7">
                                <div className="mb-3">
                                    <Slider
                                        ref={el => (sliderRef.current[i] = el)}
                                        defaultValue={[minRiskLimit, minControlLimit, maxControlLimit, maxRiskLimit]}
                                        min={minRangeLimit}
                                        max={maxRangeLimit}
                                        measurementUnit={measurementUnit}
                                    />
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default RangeSetting;
